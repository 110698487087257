import { CustomLineItemForTimeline } from 'components/customer-detail/statement-timeline/statement-timeline.api';
import { TableSection } from 'styles/table.style';
import { DataRow } from 'components/payment-timeline/data-row';
import { lightning } from 'assets/icons/paths';
import { copyFor } from 'config/copy';
import { formatDateEST, formatAsMoney } from '@arcadiapower/warbler';
import {
  SectionHeader,
  Column,
  DataContainer,
  HeaderIcon,
  HeaderPrimary,
  SectionTitle,
} from '../sections.style';

const getCopy = copyFor('components.paymentTimeline.sections.customLineItem');

export type Props = {
  customLineItem: CustomLineItemForTimeline;
};

export const CustomLineItem = ({ customLineItem }: Props): JSX.Element => {
  return (
    <TableSection data-testid={`custom-line-item-${customLineItem.id}`}>
      <SectionHeader>
        <HeaderPrimary>
          <HeaderIcon path={lightning} />
          <SectionTitle>{getCopy('title')}</SectionTitle>
        </HeaderPrimary>
      </SectionHeader>
      <DataContainer>
        <Column>
          <DataRow value={customLineItem.id} label={getCopy('fields.id')} />
          <DataRow
            value={new Date(customLineItem.updatedAt)}
            label={getCopy('fields.updatedAt')}
            formatter={formatDateEST}
          />
          <DataRow
            value={new Date(customLineItem.createdAt)}
            label={getCopy('fields.createdAt')}
            formatter={formatDateEST}
          />
        </Column>
        <Column>
          <DataRow
            value={formatAsMoney(customLineItem.amountCents, {
              conversion: 'fromCents',
            })}
            label={getCopy('fields.amountCents')}
          />
          <DataRow value={customLineItem.name} label={getCopy('fields.name')} />
          <DataRow value={customLineItem.type} label={getCopy('fields.type')} />
        </Column>
      </DataContainer>
    </TableSection>
  );
};
