import { copyFor } from 'config/copy';
import { Text, Icon } from '@arcadiapower/shrike';
import { formatMaskedAccountNumber } from 'utils/formatters';
import { invertedDollarCircle } from 'assets/icons/paths';
import { BankName } from './ach-transfer.style';
import {
  PaymentHeader,
  Wrapper,
  PaymentMethodInfoWrapper,
  IconWrapper,
  PaymentNameAndIconWrapper,
} from '../payment-methods.style';

const getCopy = copyFor('components.paymentSource');

export type Props = {
  isDefault: boolean;
  paymentSource: AchPayment;
};

export const AchTransfer = ({
  paymentSource,
  isDefault,
}: Props): JSX.Element => {
  return (
    <Wrapper
      isDefault={isDefault}
      data-testid={`payment-source-${paymentSource.id}`}
    >
      <PaymentMethodInfoWrapper>
        <PaymentHeader color="secondary">
          {isDefault ? getCopy('status.default') : getCopy('status.other')}
        </PaymentHeader>
        <PaymentHeader color="secondary">{`ID ${paymentSource.id}`}</PaymentHeader>
      </PaymentMethodInfoWrapper>
      <PaymentMethodInfoWrapper>
        <PaymentNameAndIconWrapper>
          <IconWrapper>
            <Icon path={invertedDollarCircle} color="secondary" />
          </IconWrapper>
          <Text>{getCopy('achTransfer.name')}</Text>
        </PaymentNameAndIconWrapper>
        <Text>
          {formatMaskedAccountNumber(
            { maskedBlocks: 1 },
            paymentSource.filteredAccountNumber
          )}
        </Text>
      </PaymentMethodInfoWrapper>
      <BankName>{paymentSource.bankName}</BankName>
    </Wrapper>
  );
};
