import { copyFor } from 'config/copy';
import { BackLink } from '@arcadiapower/shrike';
import { overview } from 'config/routes';
import {
  Logo,
  LogoContainer,
  NavBarContent,
  NavBarDemoContainer,
} from './nav-bar.style';

const getCopy = copyFor('nav');

export const NavBarDemo = (): JSX.Element => {
  return (
    <NavBarDemoContainer>
      <NavBarContent>
        <LogoContainer>
          <Logo inverted />
          <BackLink to={overview} color="primaryInverse">
            {getCopy('links.returnToDashboard')}
          </BackLink>
        </LogoContainer>
      </NavBarContent>
    </NavBarDemoContainer>
  );
};
