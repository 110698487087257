import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const Wrapper = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.background.secondary};
  padding: 8px 24px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

export const Label = styled(Text).attrs({ textStyle: 'paragraph500' })``;

export const DisplayValue = styled(Text).attrs({ textStyle: 'heading400' })`
  text-align: right;
`;
