import { useCallback, useEffect, useMemo, useState } from 'react';
import { APIRequestBody, ArcAPIClient } from 'classes/arc-api-client.js';
import { useQuery } from '@tanstack/react-query';
import { addTime } from '@arcadiapower/warbler';
import { getStartOfNextHour } from 'utils/dates';

import {
  calculateEnergyUsageKwh,
  calculateMinimumChargeTimeDate,
  calculateMinutesToChargeComplete,
} from 'utils/energy-analytics';

export type Props = {
  utilityAccountId?: number;
  apiClient: ArcAPIClient;
};

export type EnergyAnalyticsInput = {
  chargeCurveCostInput: APIRequestBody<'calculateChargeCost'>;
  chargeCurveScheduleInput?: APIRequestBody<'calculateSmartChargeSchedule'>;
  chargePower: number;
  energyAdded: number;
  tariffRatesInput: APIRequestBody<'retrieveTariffRates'>;
};

export type EnergyAnalyticsQuery = ReturnType<
  typeof useEnergyAnalytics
>['energyAnalyticsQuery'];

export type EnergyAnalyticsQueryData = NonNullable<
  EnergyAnalyticsQuery['data']
>;

export interface Fields {
  energyAdded: number;
  chargePower: number;
  smartChargingEnabled: boolean;
  startTime: Date;
  minimumChargeTimeDate: Date;
  endTime: Date;
}

export const useEnergyAnalytics = ({ utilityAccountId, apiClient }: Props) => {
  const defaultEnergyAdded = 50;
  const defaultStartTime = getStartOfNextHour();
  const defaultChargePower = 7.7;
  const defaultMinimumChargeTimeDate = calculateMinimumChargeTimeDate(
    defaultEnergyAdded,
    defaultChargePower,
    defaultStartTime
  );

  const [fields, setFieldsValue] = useState<Fields>({
    chargePower: defaultChargePower,
    endTime: defaultMinimumChargeTimeDate,
    energyAdded: defaultEnergyAdded,
    minimumChargeTimeDate: defaultMinimumChargeTimeDate,
    smartChargingEnabled: false,
    startTime: defaultStartTime,
  });

  const [queryInput, setQueryInput] = useState<EnergyAnalyticsInput | null>(
    null
  );

  // Can pass this into shrike inputs to update the correct state.
  // For example: onChange={updateField('energyAdded')}
  const updateField = useCallback(
    (key: keyof Fields) => (value: Fields[typeof key]) => {
      setFieldsValue(prevFields => ({ ...prevFields, [key]: value }));
    },
    []
  );

  useEffect(() => {
    const newMinimumChargeTimeDate = calculateMinimumChargeTimeDate(
      fields.energyAdded,
      fields.chargePower,
      fields.startTime
    );

    updateField('minimumChargeTimeDate')(newMinimumChargeTimeDate);

    // If the startTime is changed by the user, we may need to recalibrate the endTime if it is less than the required min time
    if (fields.endTime < newMinimumChargeTimeDate) {
      updateField('endTime')(newMinimumChargeTimeDate);
    }
  }, [
    fields.startTime,
    fields.chargePower,
    fields.energyAdded,
    fields.endTime,
    updateField,
  ]);

  const canSubmit = useMemo(() => {
    const validCostCurveInput = !!(
      fields.energyAdded &&
      fields.startTime &&
      fields.chargePower
    );
    const validSmartChargeInput = !!(
      !fields.smartChargingEnabled || fields.endTime
    );
    return validCostCurveInput && validSmartChargeInput;
  }, [fields]);

  const getEnergyAnalyticsInput = useCallback(():
    | EnergyAnalyticsInput
    | undefined => {
    if (!utilityAccountId) return;
    const { startTime, smartChargingEnabled, endTime } = fields;
    const energyAdded = Number(fields.energyAdded);
    const chargePower = Number(fields.chargePower);

    const energyUsageKwh = calculateEnergyUsageKwh(energyAdded, chargePower);

    let chargeCurveScheduleInput:
      | APIRequestBody<'calculateSmartChargeSchedule'>
      | undefined;
    let tariffEndTime;
    if (smartChargingEnabled) {
      // Add a 15 minute buffer so the tariff matches the graph, which has a 15 minute
      // padding at the end
      tariffEndTime = addTime(endTime, { minutes: 15 });
      chargeCurveScheduleInput = {
        charge_block_resolution: '15m',
        end_time: endTime.toISOString(),
        minutes_to_charge_complete: calculateMinutesToChargeComplete(
          energyAdded,
          chargePower
        ),
        start_time: startTime.toISOString(),
        utility_account_id: utilityAccountId,
      };
    } else {
      // Either chart 24 hours or the energy useage kwh + 15 minutes for graphing
      // purposes
      const hoursToChartTariffs = Math.max(
        24,
        energyUsageKwh.length / 4 + 0.25
      );
      // addTime only takes whole numbers, so we need to
      // convert to minutes
      tariffEndTime = addTime(startTime, { minutes: hoursToChartTariffs * 60 });
    }

    return {
      chargeCurveCostInput: {
        charge_block_resolution: '15m',
        energy_usage_kwh: energyUsageKwh,
        start_time: startTime.toISOString(),
        utility_account_id: utilityAccountId,
      },
      chargeCurveScheduleInput,
      chargePower,
      energyAdded,
      tariffRatesInput: {
        end_time: tariffEndTime.toISOString(),
        start_time: startTime.toISOString(),
        time_block_resolution: '15m',
        utility_account_id: utilityAccountId,
      },
    };
  }, [fields, utilityAccountId]);

  const energyAnalyticsQuery = useQuery(
    ['submit-energy-analytics', queryInput],
    () => {
      if (queryInput) return apiClient.submitEnergyAnalytics(queryInput);
    },
    {
      enabled: !!queryInput,
      retry: false,
    }
  );

  const submitEnergyAnalyticsQuery = useCallback(() => {
    const input = getEnergyAnalyticsInput();
    if (input) setQueryInput(input);
  }, [getEnergyAnalyticsInput]);

  return {
    canSubmit,
    energyAnalyticsQuery,
    fields,
    queryInput,
    submitEnergyAnalyticsQuery,
    updateField,
  };
};
