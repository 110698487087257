import { useMemo } from 'react';
import { capitalize } from '@arcadiapower/warbler';
import { BadgeProps } from '@arcadiapower/shrike';
import { StyledStatusPill } from './status-pill.style';

type Colors = NonNullable<BadgeProps['backgroundColor']>;

export interface StatusPillProps {
  variant: 'credential' | 'account';
  status?: Maybe<string>;
}

const credentialColorMap: Record<string, Colors> = {
  error: 'error',
  pending: 'warning',
  rejected: 'error',
  verified: 'success',
};

const accountStatusColorMap: Record<string, Colors> = {
  active: 'success',
  closed: 'error',
  incomplete: 'warning',
  locked: 'error',
  unknown: 'tertiary',
};

export const StatusPill = ({ status, variant }: StatusPillProps) => {
  const backgroundColor = useMemo(() => {
    if (!status) return;

    switch (variant) {
      case 'credential':
        return credentialColorMap[status];
      case 'account':
        return accountStatusColorMap[status];
    }
  }, [variant, status]);

  const effectiveBackgroundColor = backgroundColor ?? 'error';

  return (
    <StyledStatusPill
      data-testid={`status-pill-${effectiveBackgroundColor}`}
      backgroundColor={effectiveBackgroundColor}
    >
      {status && capitalize(status)}
    </StyledStatusPill>
  );
};
