import { useCallback, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  SortingState,
  CellContext,
  OnChangeFn,
} from '@tanstack/react-table';
import { track, TrackEvents } from 'utils/analytics';
import qs from 'qs';
import { capitalize } from '@arcadiapower/warbler';
import { copyFor } from 'config/copy';
import { Page } from 'styles';
import { CustomerSearchBar } from 'components/forms/customer-search-bar';
import { useQuery } from '@apollo/client';
import { formatServiceAddressForUtilityAccount } from 'utils/formatters';
import {
  CUSTOMERS_PAGE_SIZE,
  CUSTOMERS_QUERY_PARAMS_KEY,
} from 'config/constants';
import { useNavigate, useLocation } from 'react-router';
import { Table, DefaultHeader, TableLink } from 'components/table';
import { StatusPill } from 'components/status-pill';
import { store } from 'config/store';
import { getCustomerDetailRoute } from 'config/paths';
import { DefaultCell, getCellInfo } from './components/default-cell';
import {
  querySortToTableSort,
  useCustomerPageReducer,
} from './hooks/use-customer-page-reducer';
import { Filters } from './components/filters';
import { TableSection, TableSectionWrapper } from './customers.style';
import {
  GET_TENANT_UTILITY_ACCOUNTS,
  UtilityAccountType,
} from './customers.api';

const getCopy = copyFor('customers');
const defaultColumn = {
  cell: DefaultCell,
  header: DefaultHeader,
};

export const Customers = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const [customersTableState] = useCustomerPageReducer();
  const {
    dispatchFns: {
      updateFilters,
      updateSort,
      updateSearch,
      toggleSandbox,
      paginateForwards,
      paginateBackwards,
    },
    page,
    sort,
    filters,
    searchTerm,
    sandboxed,
  } = customersTableState;

  const queryVariables: GetTenantUtilityAccountsQueryVariables = useMemo(() => {
    // the variables for get tenant utility account does not include page
    const { page, dispatchFns, ...value } = customersTableState;
    return value;
  }, [customersTableState]);

  const { data, previousData, error, loading } = useQuery<
    GetTenantUtilityAccountsQuery,
    GetTenantUtilityAccountsQueryVariables
  >(GET_TENANT_UTILITY_ACCOUNTS, {
    fetchPolicy: 'network-only',
    variables: queryVariables,
  });

  useEffect(() => {
    const { dispatchFns, ...urlState } = customersTableState;
    const locationWithQueryVariables = `${location.pathname}?${qs.stringify(
      urlState,
      { encode: false }
    )}`;
    navigate(locationWithQueryVariables, { replace: true });
    // Note - need to specify location.pathname in the dependency array instead of
    // location to prevent infinite rerenders
  }, [customersTableState, location.pathname, navigate]);

  // Allows us to leverage the old data while the new data is loading
  const utilityAccounts = data?.customers || previousData?.customers;

  const sortingState = querySortToTableSort(sort);

  const setSortingState: OnChangeFn<SortingState> = newSortingState => {
    if (typeof newSortingState === 'function') {
      updateSort(newSortingState(sortingState));
    } else {
      updateSort(newSortingState);
    }
  };

  const storePageHistory = useCallback(() => {
    store.set(CUSTOMERS_QUERY_PARAMS_KEY, location.search);
  }, [location]);

  const navigateToCustomerDetailPage = useCallback(
    (utilityAccount: UtilityAccountType) => {
      // We save the params in local storage so we can navigate back to the
      // correct page
      storePageHistory();
      navigate(getCustomerDetailRoute(utilityAccount.id));
    },
    [storePageHistory, navigate]
  );

  const { endCursor, startCursor } = data?.customers.pageInfo || {};
  const handlePaginationForwards = useMemo(
    () => paginateForwards(endCursor),
    [paginateForwards, endCursor]
  );
  const handlePaginationBackwards = useMemo(
    () => paginateBackwards(startCursor),
    [paginateBackwards, startCursor]
  );
  const paginationProps = {
    pageInfo: utilityAccounts?.pageInfo,
    pageSize: CUSTOMERS_PAGE_SIZE,
    page,
    handlePaginationForwards,
    handlePaginationBackwards,
    totalCount: utilityAccounts?.totalCount,
  };

  const handleFilters = useCallback(
    filters => {
      track(TrackEvents.CUSTOMER_PAGE_FILTERED, { filters });
      updateFilters(filters);
    },
    [updateFilters]
  );

  const renderFilters = () => (
    <Filters value={filters} onChange={handleFilters} />
  );

  const columns = useMemo(
    () => getColumns(storePageHistory),
    [storePageHistory]
  );

  const handleSearch = useCallback(
    (searchTerm: string) => {
      track(TrackEvents.CUSTOMER_PAGE_SEARCHED, { searchTerm });
      updateSearch(searchTerm);
    },
    [updateSearch]
  );

  return (
    <Page>
      <TableSectionWrapper>
        <TableSection>
          <CustomerSearchBar
            onSearch={handleSearch}
            initialSearchInput={searchTerm}
            toggleSandboxed={toggleSandbox}
            sandboxed={sandboxed}
          />
          <Table
            columns={columns}
            onRowInteraction={navigateToCustomerDetailPage}
            data={utilityAccounts?.nodes}
            defaultColumn={defaultColumn}
            renderFilters={renderFilters}
            paginationProps={paginationProps}
            loading={loading}
            error={error}
            sortingProps={{ sortingState, setSortingState }}
            name="Customers"
          />
        </TableSection>
      </TableSectionWrapper>
    </Page>
  );
};

const columnHelper = createColumnHelper<UtilityAccountType>();
const getColumns = (storePageHistory: () => void) => [
  columnHelper.accessor('accountNumber', {
    id: getCopy('table.columns.accountNumber'),
    meta: {
      width: '13%',
    },
    cell: props => {
      const { secondary, displayValue } = getCellInfo(
        props as CellContext<UtilityAccountType, unknown>
      );
      return (
        <TableLink
          onClick={e => {
            e.stopPropagation();
            storePageHistory();
          }}
          color={secondary ? 'secondary' : 'primary'}
          type="inline"
          openInNewTab={false}
          aria-label={getCopy('table.accountNumberAriaLabel')}
          to={getCustomerDetailRoute(props.row.original.id)}
        >
          {displayValue}
        </TableLink>
      );
    },
  }),
  columnHelper.accessor(row => (row.status ? capitalize(row.status) : ''), {
    id: getCopy('table.columns.accountStatus'),
    meta: {
      width: '13%',
    },
  }),
  columnHelper.accessor('serviceCustomerName', {
    id: getCopy('table.columns.customerName'),
    meta: {
      width: '14%',
    },
  }),
  columnHelper.accessor(row => formatServiceAddressForUtilityAccount(row), {
    id: getCopy('table.columns.serviceAddress'),
    meta: {
      width: '25%',
    },
    enableSorting: false,
  }),
  columnHelper.accessor('utilityCredential.username', {
    id: getCopy('table.columns.username'),
    meta: {
      width: '20%',
    },
  }),
  columnHelper.accessor('utilityCredential.status', {
    id: getCopy('table.columns.credentialsStatus'),
    // eslint-disable-next-line react/no-unstable-nested-components
    cell: props => (
      <StatusPill status={props.getValue()} variant="credential" />
    ),
    meta: {
      width: '15%',
    },
  }),
];
