import styled from 'styled-components';
import DemoConnectSVG from 'assets/demo/connect.svg';
import { Alert } from '@arcadiapower/shrike';

export const Wrapper = styled.div`
  display: flex;

  margin: 0 auto;
  padding: 24px;
  max-width: 1200px;
  align-items: center;
  gap: 120px;
  width: fit-content;

  ${props => props.theme.media.phone} {
    width: 100%;
    flex-direction: column;
    gap: 24px;
  }
`;

export const Background = styled.div`
  background-color: ${props => props.theme.colors.background.primaryInverse};
  flex-grow: 1;
`;

export const ConnectContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 10px solid rgba(249, 243, 232, 0.1);
  height: 600px;
  min-width: 300px;
  width: 300px;
  padding: 16px;
  border-radius: 50px;

  ${props => props.theme.media.phone} {
    border-bottom: none;
    border-radius: 50px 50px 0 0;
    padding-bottom: 32px;
    height: auto;
  }
`;

export const TextWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

export const DemoImage = styled.img.attrs({
  alt: 'connect',
  src: DemoConnectSVG,
})`
  margin: 80px auto 72px;
  height: 136px;
  width: 136px;
`;

export const DemoAlert = styled(Alert).attrs({ margin: { top: '16px' } })`
  color: ${props => props.theme.colors.content.primaryInverse};
`;
