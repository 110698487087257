import { PasswordInput, PasswordInputProps } from '@arcadiapower/shrike';
import { validPasswordRegex } from 'utils/validators';
import { copyFor } from 'config/copy';

export type Props = Omit<
  PasswordInputProps,
  | 'label'
  | 'requirementsText'
  | 'requirementsRegex'
  | 'iconButtonAriaLabel'
  | 'name'
>;

const getCopy = copyFor('components.newPasswordInput');

export const NewPasswordInput = (props: Props): JSX.Element => {
  return (
    <PasswordInput
      autoComplete="new-password"
      label={getCopy('label')}
      placeholder={getCopy('placeholder')}
      requirementsText={getCopy('requirementsText')}
      requirementsRegex={validPasswordRegex}
      iconButtonAriaLabel={getCopy('iconButtonAriaLabel')}
      {...props}
    />
  );
};
