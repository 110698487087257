import { useQuery } from '@apollo/client';
import { Alert, Skeleton } from '@arcadiapower/shrike';
import { useMemo } from 'react';
import { copyFor, getErrorCopy } from 'config/copy';
import { Table, TableHeader, TableTitle } from 'styles/table.style';
import { GET_TEAM } from './team.api';
import { ActiveMembers } from './components/active-members';

const getCopy = copyFor('settings.team');

export const Team = (): JSX.Element => {
  const { data, loading, error } = useQuery<
    GetTeamQuery,
    GetTeamQueryVariables
  >(GET_TEAM);

  const tableContent = useMemo(() => {
    if (loading)
      return (
        <Skeleton
          data-testid="active-members-loading"
          height="250px"
          width="100%"
        />
      );
    if (error) return <Alert>{getErrorCopy('generic')}</Alert>;
    if (data) return <ActiveMembers tenant={data.resourceOwner.tenant} />;
  }, [data, loading, error]);

  return (
    <Table>
      <TableHeader>
        <TableTitle>{getCopy('title')}</TableTitle>
      </TableHeader>
      {tableContent}
    </Table>
  );
};
