import styled from 'styled-components';

export const Page = styled.div<{ secondaryBackground?: boolean }>`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  ${props =>
    props.secondaryBackground &&
    `
    background-color: ${props.theme.colors.background.secondary}
  `}
`;

export const PageContent = styled.div`
  max-width: 1440px;
  padding: 64px 100px;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;

  ${props => props.theme.media.phone} {
    padding: 32px;
  }
`;
