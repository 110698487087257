import { NavBarItem } from 'components/nav-bar-item';
import styled from 'styled-components';

export const Wrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  list-style: none;
  max-width: 100%;
  padding: 0;
  border-bottom: 1px solid ${props => props.theme.colors.background.tertiary};
`;

export const ListItem = styled.li`
  height: 3.5rem;
`;
export const SubNavBarItem = styled(NavBarItem)`
  padding-left: 8px;
  padding-right: 24px;
`;
