import { formatDateEST, capitalize } from '@arcadiapower/warbler';
import { UtilityRemittanceForTimeline } from 'components/customer-detail/statement-timeline/statement-timeline.api';
import { TableSection } from 'styles/table.style';
import { DataRow } from 'components/payment-timeline/data-row';
import { lightning } from 'assets/icons/paths';
import { copyFor } from 'config/copy';
import { formatCheckingNumbersList } from 'utils/formatters';
import {
  SectionHeader,
  Column,
  DataContainer,
  HeaderIcon,
  HeaderPrimary,
  SectionTitle,
} from '../sections.style';

const getCopy = copyFor(
  'components.paymentTimeline.sections.utilityRemittance'
);

export type Props = {
  utilityRemittance: UtilityRemittanceForTimeline;
};

export const UtilityRemittance = ({
  utilityRemittance,
}: Props): JSX.Element => {
  return (
    <TableSection data-testid={`utility-remittance-${utilityRemittance.id}`}>
      <SectionHeader>
        <HeaderPrimary>
          <HeaderIcon path={lightning} />
          <SectionTitle>{getCopy('title')}</SectionTitle>
        </HeaderPrimary>
      </SectionHeader>
      <DataContainer>
        <Column>
          <DataRow
            value={utilityRemittance.id}
            label={getCopy('fields.utilityRemittanceId')}
          />
          <DataRow
            value={utilityRemittance.utility.name}
            label={getCopy('fields.utilityName')}
          />
          <DataRow<typeof utilityRemittance>
            formatter={formatCheckingNumbersList}
            value={utilityRemittance}
            label={getCopy('fields.checkNumber')}
          />
        </Column>
        <Column>
          <DataRow
            value={utilityRemittance.status}
            label={getCopy('fields.status')}
            formatter={capitalize}
          />
          <DataRow
            value={new Date(utilityRemittance.submitted)}
            label={getCopy('fields.submitted')}
            formatter={formatDateEST}
          />
          <DataRow
            value={new Date(utilityRemittance.posted)}
            label={getCopy('fields.posted')}
            formatter={formatDateEST}
          />
        </Column>
      </DataContainer>
    </TableSection>
  );
};
