import styled from 'styled-components';
import { Button } from '@arcadiapower/shrike';

export const TableTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  ${props => props.theme.media.phone} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledButton = styled(Button)`
  ${props => props.theme.media.phone} {
    width: 100%;
    margin-top: 15px;
    margin-bottom: ${props => (props.disabled ? '32px' : '0')};
  }
`;

export const AddMemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
