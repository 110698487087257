import Chart from 'react-apexcharts';
import { formatAsMoney, addTime } from '@arcadiapower/warbler';
import { copyFor } from 'config/copy';
import { theme } from 'config/theme';
import {
  EnergyAnalyticsInput,
  EnergyAnalyticsQueryData,
} from 'hooks/use-energy-analytics.hook';
import { formatTimeSeriesItem } from 'utils/dates';
import {
  Wrapper,
  CostWrapper,
  CostItem,
  CostDollars,
  CostHeader,
  ChartWrapper,
  axisLabelStyle,
  chartBackgroundColor,
  chartGridColor,
} from './energy-analytics-chart.style';

const getCopy = copyFor('spark.results.energyAnalyticsChart');

const generateChargeSeries = (
  chargeCurveCostData: EnergyAnalyticsInput['chargeCurveCostInput']
) => {
  const startDate = new Date(chargeCurveCostData.start_time);
  // Pad with a zero here for a "proper" charging curve
  const energyUsageKwh = [...chargeCurveCostData.energy_usage_kwh, 0];
  return energyUsageKwh.map((kwh, index) => {
    return {
      x: addTime(startDate, { minutes: index * 15 }),
      y: kwh,
    };
  });
};

const generateTariffRateSeries = (
  tariffRatesData: EnergyAnalyticsQueryData['tariffRates']
) => {
  return tariffRatesData.rates_cost_per_kwh.data.map(
    ({ timestamp, value }) => ({
      x: timestamp,
      y: value,
    })
  );
};

// Make the tariffs go up to 75% of the graph at most
const getTariffRatesMax = (
  tariffRatesData: EnergyAnalyticsQueryData['tariffRates']
) => {
  const values = tariffRatesData.rates_cost_per_kwh.data.map(
    ({ value }) => value
  );
  const rawMaxValue = Math.max(...values) / 0.75;
  const roundedMax = Math.round(rawMaxValue * 100) / 100;
  return roundedMax;
};

interface EnergyAnalyticsChartProps {
  chargeCurveCostToChart: EnergyAnalyticsInput['chargeCurveCostInput'];
  chargeCurveCostData: EnergyAnalyticsQueryData['chargeCurveCost'];
  tariffRatesData: EnergyAnalyticsQueryData['tariffRates'];
  smartChargeData: EnergyAnalyticsQueryData['smartCharge'];
}

export const EnergyAnalyticsChart = ({
  chargeCurveCostToChart,
  chargeCurveCostData,
  tariffRatesData,
  smartChargeData,
}: EnergyAnalyticsChartProps) => {
  // TODO: Currently the frequency is listed in the documentation as freq, but is actually frequency
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const interval = tariffRatesData.rates_cost_per_kwh.frequency;
  const apexChartProps = {
    height: '100%',
    options: {
      chart: {
        background: chartBackgroundColor,
        foreColor: theme.colors.background.primary,
        offsetX: -23,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: [theme.colors.content.accent4, theme.colors.content.accent3],
      dataLabels: {
        enabled: false,
      },
      fill: { opacity: 1, type: 'solid' as const },
      grid: {
        borderColor: chartGridColor,
        padding: {
          bottom: 36,
        },
        position: 'back' as const,
        show: true,
        strokeDashArray: 0,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: axisLabelStyle,
      stroke: {
        curve: 'stepline' as const,
      },
      tooltip: {
        style: axisLabelStyle,
        x: {
          formatter: (value: number) => formatTimeSeriesItem(value, interval),
          show: true,
        },
      },
      xaxis: {
        axisBorder: {
          color: chartGridColor,
        },
        axisTicks: {
          color: chartGridColor,
        },
        labels: {
          // https://apexcharts.com/docs/datetime/
          datetimeFormatter: {
            day: 'MM/dd/yy',
            hour: 'htt',
          },
          datetimeUTC: false,
          style: axisLabelStyle,
        },
        tooltip: {
          enabled: false,
        },
        type: 'datetime' as const,
      },
      yaxis: [
        { decimalsInFloat: 3, show: false },
        {
          decimalsInFloat: 2,
          forceNiceScale: true,
          labels: {
            formatter: (val: number) => {
              if (val === 0) return '';
              return val.toFixed(2);
            },
            style: axisLabelStyle,
          },
          max: getTariffRatesMax(tariffRatesData),
          min: 0,
          tickAmount: 4,
        },
      ],
    },
    series: [
      {
        data: generateChargeSeries(chargeCurveCostToChart),
        name: getCopy('chargeTitle'),
        type: 'area' as const,
      },
      {
        data: generateTariffRateSeries(tariffRatesData),
        name: getCopy('tariffTitle', {
          tariffCode: tariffRatesData.tariff.tariff_code,
        }),
        type: 'line' as const,
      },
    ],
    type: 'area' as const,
  };

  const renderChargeItem = ({
    title,
    cents,
    savings,
  }: {
    title: string;
    cents: number;
    savings?: boolean;
  }) => (
    <CostItem>
      <CostHeader>{title}</CostHeader>
      <CostDollars savings={savings}>
        {formatAsMoney(cents, { conversion: 'fromCents' })}
      </CostDollars>
    </CostItem>
  );

  const renderHeader = () => {
    const basicChargingCost = renderChargeItem({
      cents: chargeCurveCostData.cost_cents,
      title: smartChargeData
        ? getCopy('chargeItems.dumbCost')
        : getCopy('chargeItems.basic'),
    });

    if (!smartChargeData) return basicChargingCost;

    const smartChargeCostCents = smartChargeData.chargeCurveCost.cost_cents;
    const savingsCents = chargeCurveCostData.cost_cents - smartChargeCostCents;
    return (
      <>
        {renderChargeItem({
          cents: smartChargeCostCents,
          title: getCopy('chargeItems.cost'),
        })}
        {basicChargingCost}
        {renderChargeItem({
          cents: savingsCents,
          savings: true,
          title: getCopy('chargeItems.savings'),
        })}
      </>
    );
  };

  return (
    <Wrapper>
      <CostWrapper>{renderHeader()}</CostWrapper>
      <ChartWrapper>
        <Chart {...apexChartProps} />
      </ChartWrapper>
    </Wrapper>
  );
};
