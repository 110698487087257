import { gql } from '@apollo/client';

// Note: Naming the mutatin/type LOG_IN to match the pages/minimize confusion with
// SignUp
export const LOG_IN_TENANT_USER = gql`
  mutation logInTenantUser($input: LogInTenantUserInput!) {
    logInTenantUser(input: $input) {
      accessToken {
        token
        expiresAt
      }
      resourceOwner {
        id
        email
      }
    }
  }
`;

export const generateLogInTenantUser = (): LogInTenantUserMutation => ({
  logInTenantUser: {
    accessToken: {
      expiresAt: '2021-10-14 19:27:28 -0400',
      token: 'myToken',
    },
    resourceOwner: {
      email: 'my.cool.email@gmail.com',
      id: '2',
    },
  },
});

export const CHECK_SSO_ENROLLMENT = gql`
  mutation checkSsoEnrollment($input: CheckSsoEnrollmentInput!) {
    checkSsoEnrollment(input: $input) {
      ssoLink
      tenantName
      tenantLogoUrl
    }
  }
`;

export const generateCheckSsoEnrollment = (): CheckSsoEnrollmentMutation => ({
  checkSsoEnrollment: {
    ssoLink: 'https://arcadia.okta.com',
    tenantLogoUrl:
      'https://ok7static.oktacdn.com/fs/bco/1/fs0dbc6qdnO9vDYrq357',
    tenantName: 'Arcadia',
  },
});
