export const settings = {
  sideNav: {
    title: 'Settings',
    links: {
      profile: 'Profile',
      signOut: 'Sign out',
      team: 'Team',
    },
  },
  profile: {
    title: 'Profile',
    accountDetails: {
      title: 'Account details',
    },
    passwordVerification: {
      modalDescription: 'Modal to verify your previous password',
      header: 'Verify password',
      message: 'Please verify your password to edit your account details.',
      inputLabel: 'Current password',
      confirmButton: 'Confirm',
      cancelButton: 'Cancel',
      error: {
        wrongPassword: 'That password is incorrect. Please try again.',
      },
    },
  },
  team: {
    title: 'Team',
    addMembersButton: 'Add member',
    addMembersDisclaimer:
      'Your team uses Single Sign-On to manage access to this dashboard. If you want to add more team members, reach out to your SSO admin.',
    activeMembers: {
      title: 'Active members',
    },
  },
};
