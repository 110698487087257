import { Text } from '@arcadiapower/shrike';
import {
  Logo,
  NavBarTitleLogoContainer,
  NavBarContent,
  NavBarContainer,
  VerticalSeperator,
} from './nav-bar.style';

export type NavBarWithTitleProps = {
  title: string;
};

export const NavBarWithTitle = ({
  title,
}: NavBarWithTitleProps): JSX.Element => {
  return (
    <NavBarContainer withBorder={false}>
      <NavBarContent>
        <NavBarTitleLogoContainer>
          <Logo />
          <VerticalSeperator />
          <Text textStyle="heading600" color="secondary" tag="h2">
            {title}
          </Text>
        </NavBarTitleLogoContainer>
      </NavBarContent>
    </NavBarContainer>
  );
};
