import { copyFor } from 'config/copy';
import JSONPretty from 'react-json-pretty';
import {
  RequestWrapper,
  Endpoint,
  RequestsHeader,
  RequestType,
  ResponseHeader,
  Wrapper,
  prettyJSONTheme,
  Response,
} from './api-explorer.style';

const getCopy = copyFor('demo.results.apiExplorer');

export type Props = {
  data: Record<string, unknown>;
  endpoint: string;
  payload?: Record<string, unknown>;
  requestType: 'GET' | 'POST';
};

export const APIExplorer = ({
  data,
  endpoint,
  payload,
  requestType,
}: Props) => {
  return (
    <Wrapper>
      <RequestsHeader>{getCopy('requestHeader')}</RequestsHeader>
      <RequestWrapper>
        <RequestType type={requestType}>{requestType}</RequestType>
        <Endpoint>{endpoint}</Endpoint>
        {payload && (
          <JSONPretty
            data-testid="payload-json"
            data={payload}
            theme={prettyJSONTheme}
            mainStyle="margin-top: 24px"
          />
        )}
      </RequestWrapper>
      <ResponseHeader>{getCopy('responseHeader')}</ResponseHeader>
      <Response data-testid="response-json" data={data} />
    </Wrapper>
  );
};
