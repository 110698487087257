export const customers = {
  table: {
    columns: {
      accountNumber: 'Account #',
      accountStatus: 'Account status',
      customerName: 'Customer name',
      serviceAddress: 'Service address',
      username: 'Username',
      credentialsStatus: 'Credentials Status',
    },
    noValue: 'None available',
    noResults: 'No results',
    accountNumberAriaLabel: 'View account details for this account',
    filters: {
      accountStatus: 'Account status',
      credentialStatus: 'Credential status',
    },
  },
};
