import styled from 'styled-components';
import { Text, Icon, Link } from '@arcadiapower/shrike';

export const Wrapper = styled.div`
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  padding: 54px 28px;
`;

export const PromptIcon = styled.img`
  height: 40px;
  width: 40px;
  margin-bottom: 8px;
`;

export const PromptLink = styled(Link).attrs({
  hideIcon: true,
  openInNewTab: false,
  type: 'standalone',
})`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const PromptTitle = styled(Text).attrs({
  tag: 'h4',
  textStyle: 'heading600',
})``;

export const PromptLinkIcon = styled(Icon).attrs({
  icon: 'ChevronRight',
  margin: { left: '12px' },
})``;

export const PromptDescription = styled(Text).attrs({
  color: 'secondary',
  margin: { top: '8px' },
})``;
