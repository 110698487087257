import { copyFor, KeysFromCopyScope } from 'config/copy';
import { apiKeys, customization, demo } from 'config/routes';
import {
  support,
  quickStart,
  apiDocs,
  productGuides,
} from 'config/external-links';
import apiKeysIcon from 'assets/prompts/api-keys.svg';
import logoIcon from 'assets/prompts/logo.svg';
import demoIcon from 'assets/prompts/demo.svg';
import quickStartIcon from 'assets/resources/quick-start.svg';
import supportIcon from 'assets/resources/support.svg';
import { Props as PromptProps } from 'components/overview/prompt';
import { Props as ResourceProps } from 'components/overview/resource';
import { TrackEvents } from 'utils/analytics';

const getCopy = copyFor('overview');

const PROMPT_API_KEY: PromptProps = {
  description: getCopy('prompts.apiKeys.description'),
  icon: apiKeysIcon,
  route: apiKeys,
  title: getCopy('prompts.apiKeys.title'),
};

const PROMPT_LOGO_UPLOAD: PromptProps = {
  description: getCopy('prompts.logo.description'),
  icon: logoIcon,
  route: customization,
  title: getCopy('prompts.logo.title'),
};

const PROMPT_DEMO_TOOL: PromptProps = {
  description: getCopy('prompts.demoTool.description'),
  icon: demoIcon,
  route: demo,
  title: getCopy('prompts.demoTool.title'),
};

export const getPrompts = ({
  uploadedLogo,
}: {
  uploadedLogo: boolean;
}): PromptProps[] => {
  const prompts = [PROMPT_DEMO_TOOL, PROMPT_API_KEY];

  if (!uploadedLogo) {
    prompts.push(PROMPT_LOGO_UPLOAD);
  }

  return prompts;
};

type ResourceType = KeysFromCopyScope<'overview.resources'>;

const buildResourceCopy = (resourceLabel: ResourceType) => ({
  description: getCopy(`resources.${resourceLabel}.description`),
  title: getCopy(`resources.${resourceLabel}.title`),
});

export const RESOURCES: ResourceProps[] = [
  {
    ...buildResourceCopy('gettingStarted'),
    icon: quickStartIcon,
    links: [
      {
        label: getCopy(`linkLabels.quickStart`),
        link: quickStart,
        trackEvent: TrackEvents.QUICKSTART_GUIDES_CLICKED,
      },
      {
        label: getCopy(`linkLabels.productGuides`),
        link: productGuides,
        trackEvent: TrackEvents.PRODUCT_GUIDES_CLICKED,
      },
      {
        label: getCopy(`linkLabels.apiDocs`),
        link: apiDocs,
        trackEvent: TrackEvents.API_DOCS_CLICKED,
      },
    ],
    variant: 'secondary',
  },
  {
    ...buildResourceCopy('support'),
    icon: supportIcon,
    links: [
      {
        label: getCopy(`linkLabels.support`),
        link: support,
        trackEvent: TrackEvents.SUPPORT_CLICKED,
      },
    ],
    variant: 'secondary',
  },
];
