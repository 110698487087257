import { useState, useCallback, useEffect } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import { NavBarUnauthed } from 'components/nav-bar';
import { copyFor } from 'config/copy';
import * as analytics from 'utils/analytics';
import { Page } from 'styles';
import {
  SingleContentNarrow,
  StandaloneTitle,
} from 'styles/single-content.style';
import { accountSetupInProgress, authenticate } from 'config/auth';
import { useNavigate, useParams } from 'react-router';
import { ActionConfirmationModal } from 'components/action-confirmation-modal';
import { login, overview } from 'config/routes';
import { SetUpAccount as SetUpAccountForm } from 'components/forms/set-up-account';
import { Skeleton } from '@arcadiapower/shrike';
import {
  SET_UP_ACCOUNT,
  VERIFY_SET_UP_ACCOUNT_TOKEN,
} from './set-up-account.api';

const {
  INVITED_USER_LINK_EXPIRED,
  INVITED_USER_SET_UP_COMPLETED,
  INVITED_USER_SET_UP_VIEWED,
} = analytics.TrackEvents;

const getCopy = copyFor('setUpAccount');

export const SetUpAccount = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams<{ setUpAccountToken: string }>();
  const [hasTokenError, setHasTokenError] = useState(false);

  const [error, setError] = useState<Error | undefined>();
  const [pending, setPending] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [verifySetUpAccountToken, { called: tokenVerificationAttempted }] =
    useMutation<
      VerifySetUpAccountTokenMutation,
      VerifySetUpAccountTokenMutationVariables
    >(VERIFY_SET_UP_ACCOUNT_TOKEN);

  const [setUpAccount] = useMutation<
    SetUpAccountMutation,
    SetUpAccountMutationVariables
  >(SET_UP_ACCOUNT);

  const onExpiredModalClose = () => {
    navigate(login);
  };

  const onSuccessModalClose = () => {
    navigate(overview);
  };

  useEffect(() => {
    analytics.track(INVITED_USER_SET_UP_VIEWED);
  }, []);

  useEffect(() => {
    const verifyToken = async () => {
      if (accountSetupInProgress()) return;
      const { setUpAccountToken } = params;

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const input = { setUpAccountToken: setUpAccountToken! };
      try {
        const { data } = await verifySetUpAccountToken({
          variables: { input },
        });
        const accessToken =
          data?.convertSetUpAccountTokenToAccessToken.accessToken;
        if (accessToken) {
          authenticate(accessToken.token, accessToken.expiresAt, {
            accountSetupInProgress: true,
          });
        }
      } catch (e) {
        analytics.track(INVITED_USER_LINK_EXPIRED);
        setHasTokenError(true);
      }
    };
    verifyToken();
  }, [params, verifySetUpAccountToken]);

  const handleSubmit = useCallback(
    async ({
      firstName,
      lastName,
      password,
    }: {
      firstName: string;
      lastName: string;
      password: string;
    }) => {
      setPending(true);
      const input = { firstName, lastName, password };
      try {
        const { data } = await setUpAccount({ variables: { input } });
        const accessToken = data?.updateResourceOwner.accessToken;
        if (data && accessToken) {
          authenticate(accessToken.token, accessToken.expiresAt, {
            resourceOwner: data.updateResourceOwner.resourceOwner,
          });
        }
        analytics.track(INVITED_USER_SET_UP_COMPLETED);
        setShowSuccessModal(true);
      } catch (submissionError) {
        setError(submissionError as ApolloError);
      } finally {
        setPending(false);
      }
    },
    [setUpAccount]
  );

  return (
    <Page>
      <NavBarUnauthed />
      <SingleContentNarrow>
        <StandaloneTitle>{getCopy('title')}</StandaloneTitle>
        {tokenVerificationAttempted || accountSetupInProgress() ? (
          <SetUpAccountForm
            onSubmit={handleSubmit}
            pending={pending}
            error={error}
          />
        ) : (
          <Skeleton width="100%" height="300px" maxHeight="100%" />
        )}
      </SingleContentNarrow>
      {hasTokenError && (
        <ActionConfirmationModal
          copy={getCopy('expiredLinkModal')}
          onConfirmation={onExpiredModalClose}
          onClose={onExpiredModalClose}
        />
      )}
      {showSuccessModal && (
        <ActionConfirmationModal
          copy={getCopy('successModal')}
          onConfirmation={onSuccessModalClose}
          onClose={onSuccessModalClose}
        />
      )}
    </Page>
  );
};
