import { copyFor } from 'config/copy';
import { overview } from 'config/paths';
import { useCallback, useState } from 'react';
import * as analytics from 'utils/analytics';
import { Page } from 'styles';
import { SingleContentNarrow } from 'styles/single-content.style';
import { Signup as SignupForm, onSubmitParams } from 'components/forms/signup';
import { NavBarUnauthed } from 'components/nav-bar';
import { useMutation } from '@apollo/client';
import { authenticate } from 'config/auth';
import { APP_BUILD_ENV } from 'config/constants';
import { parseErrorMessage } from 'config/errors';
import { Link, Button } from '@arcadiapower/shrike';
import { contactForm } from 'config/external-links';
import {
  SignUpStandAloneTitle,
  SignUpSubTitle,
  AutofillButtonWrapper,
} from './signup.style';
import { SIGN_UP_TENANT_USER } from './signup.api';
import { autofill } from './signup.utils';

const getCopy = copyFor('signup');

export const Signup = (): JSX.Element => {
  // These are placeholders until we get a sign up endpoint
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [pending, setPending] = useState<boolean>(false);

  const [submitTenantUser] = useMutation<
    SignUpTenantUserMutation,
    SignUpTenantUserMutationVariables
  >(SIGN_UP_TENANT_USER);

  const handleSubmit = useCallback(
    async (input: onSubmitParams) => {
      try {
        setPending(true);
        setErrorMessage(undefined);
        const { data } = await submitTenantUser({ variables: { input } });
        if (data) {
          const { resourceOwner, accessToken } = data.signUpTenantUser;
          const { token, expiresAt } = accessToken;
          const { password, ...restOfInput } = input;
          analytics.track(analytics.TrackEvents.SIGN_UP, {
            ...restOfInput,
          });
          authenticate(token, expiresAt, {
            resourceOwner,
            navigateTo: overview,
          });
        }
      } catch (e) {
        setPending(false);
        setErrorMessage(parseErrorMessage(e as Error));
      }
    },
    [submitTenantUser]
  );

  return (
    <Page>
      <NavBarUnauthed />
      {APP_BUILD_ENV !== 'production' && (
        <AutofillButtonWrapper>
          <Button
            margin="0px 32px"
            onClick={autofill}
            data-testid="autofill-button"
          >
            {getCopy('autofillButton')}
          </Button>
        </AutofillButtonWrapper>
      )}
      <SingleContentNarrow>
        <SignUpStandAloneTitle>{getCopy('title')}</SignUpStandAloneTitle>
        <SignUpSubTitle>
          {getCopy('signUpSubHead.startText')}
          <Link type="inline" href={contactForm} color="primary">
            {getCopy('signUpSubHead.link')}
          </Link>
          {getCopy('signUpSubHead.endText')}
        </SignUpSubTitle>
        <SignupForm
          onSubmit={handleSubmit}
          pending={pending}
          errorMessage={errorMessage}
        />
      </SingleContentNarrow>
    </Page>
  );
};
