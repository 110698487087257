import { gql } from '@apollo/client';
import { DEFAULT_CLIENT_ID } from 'test/constants';

export const GET_DEMO_CONNECT_TOKEN = gql`
  query getDemoConnectToken {
    demoConnectToken {
      clientUserId
      connectToken
    }
  }
`;

export const generateDemoConnectToken = (
  value = 'demo-connect-token'
): GetDemoConnectTokenQuery => {
  return {
    demoConnectToken: {
      clientUserId: DEFAULT_CLIENT_ID,
      connectToken: value,
    },
  };
};

export const EXCHANGE_DEMO_CONNECT_TOKEN = gql`
  mutation exchangeDemoConnectToken($input: ExchangeDemoConnectTokenInput!) {
    exchangeDemoConnectToken(input: $input) {
      accessToken {
        token
      }
    }
  }
`;

export const generateExchangeDemoConnectToken = (
  value = 'demo-access-token'
): ExchangeDemoConnectTokenMutation => {
  return {
    exchangeDemoConnectToken: {
      accessToken: {
        token: value,
      },
    },
  };
};
