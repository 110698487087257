import { useMemo } from 'react';
import { copyFor } from 'config/copy';
import { customers } from 'config/routes';
import { store } from 'config/store';
import { CUSTOMERS_QUERY_PARAMS_KEY } from 'config/constants';
import {
  Wrapper,
  BackLinkIcon,
  BackLink,
  BackLinkLabel,
} from './nav-bar.style';

const getCopy = copyFor('components.navBar');

export type Props = Record<string, never>;

export const CustomerDetailNavBar = (): JSX.Element => {
  const customersLink = useMemo(() => {
    const customerParams = store.get(CUSTOMERS_QUERY_PARAMS_KEY);
    let link = customers;
    if (customerParams) link += customerParams;
    return link;
  }, []);

  return (
    <Wrapper margin={{ bottom: '40px' }}>
      <BackLink to={customersLink}>
        <BackLinkIcon />
        <BackLinkLabel>{getCopy('backLink.label')}</BackLinkLabel>
      </BackLink>
    </Wrapper>
  );
};
