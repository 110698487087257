import { ArcAPIClient } from 'classes/arc-api-client';
import { useCallback, useState } from 'react';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ValueOf } from 'type-fest';
import { UtilityCredentialArc } from 'typings/arcadia-api';
import { sortNumerically } from '@arcadiapower/warbler';
import {
  utilityCredentialStatusIs,
  utilityCredentialDataLoadInProgress,
  utilityStatementDataLoadInProgress,
  hasUtilityAccounts,
  intervalDataLoadInProgress,
  isRealCredential,
} from 'utils/utility-data';
import { IS_TEST } from 'config/constants';
import { copyFor } from 'config/copy';

export const POLLING_INTERVAL = IS_TEST ? 10 : 1000;

const getCopy = copyFor('demo');

export class DataError extends Error {
  showModal: boolean;

  constructor(message: string, { showModal = false } = {}) {
    super(message);
    this.name = 'DataError';
    this.showModal = showModal;
  }
}

const isUtilityCredentialsQueryCompleted = (
  utilityCredentials: UtilityCredentialArc[]
) =>
  !utilityCredentialStatusIs(utilityCredentials, 'pending') &&
  !utilityCredentialDataLoadInProgress(utilityCredentials);

export enum RESOURCE_STATES {
  ACCOUNTS,
  CREDENTIALS,
  DONE,
  ERROR,
}

type ArcClientReturnType<key extends keyof ArcAPIClient> = Awaited<
  ReturnType<
    ArcAPIClient[key] extends (...params: unknown[]) => unknown
      ? ArcAPIClient[key]
      : never
  >
>;

export type UseFetchUtilityData = {
  fetchingResource: ValueOf<typeof RESOURCE_STATES>;
  utilityAccountQuery: UseQueryResult<
    ArcClientReturnType<'getUtilityAccounts'>
  >;
  utilityCredentialQuery: UseQueryResult<
    ArcClientReturnType<'getUtilityCredentials'>
  >;
};

export const useFetchUtilityData = ({
  apiClient,
}: {
  apiClient: ArcAPIClient;
}): UseFetchUtilityData => {
  const [fetchingResource, setFetchingResource] = useState<
    ValueOf<typeof RESOURCE_STATES>
  >(RESOURCE_STATES.CREDENTIALS);

  // We need to go to the error state to prevent refetching when there are issues
  // with data
  const handleError = useCallback(error => {
    // eslint-disable-next-line no-console
    console.error(error);
    setFetchingResource(RESOURCE_STATES.ERROR);
  }, []);

  const utilityCredentialQuery = useQuery(
    ['getUtilityCredentials'],
    async () => {
      const result = await apiClient.getUtilityCredentials();
      const credentials = result.data;
      if (
        utilityCredentialStatusIs(credentials, 'rejected') ||
        utilityCredentialStatusIs(credentials, 'error')
      ) {
        throw new DataError(getCopy('errors.rejectedCredentials'), {
          showModal: true,
        });
      }
      return result;
    },
    {
      enabled: fetchingResource === RESOURCE_STATES.CREDENTIALS,
      onError: handleError,
      onSuccess: data => {
        if (isUtilityCredentialsQueryCompleted(data.data))
          setFetchingResource(RESOURCE_STATES.ACCOUNTS);
      },
      refetchInterval: POLLING_INTERVAL,
    }
  );

  const utilityAccountQuery = useQuery(
    ['getUtilityAccounts'],
    async () => {
      const results = await apiClient.getUtilityAccounts();
      const accounts = results.data;
      if (!hasUtilityAccounts(accounts)) {
        throw new DataError(getCopy('errors.noUtilityAccounts'));
      }
      // Accounts return by updated date. This can cause UI issues if the user
      // has multiple accounts, so we sort them by id here.
      results.data = sortNumerically(accounts, 'id');
      return results;
    },
    {
      enabled: fetchingResource === RESOURCE_STATES.ACCOUNTS,
      onError: handleError,
      onSuccess: result => {
        // We do not want to poll interval data for real accounts, because
        // they take too long
        const utililityCredentialIsRealCredential =
          utilityCredentialQuery.data?.data &&
          isRealCredential(utilityCredentialQuery.data.data);

        const intervalDataDonePolling =
          utililityCredentialIsRealCredential ||
          !intervalDataLoadInProgress(result.data);

        if (
          !utilityStatementDataLoadInProgress(result.data) &&
          intervalDataDonePolling
        ) {
          setFetchingResource(RESOURCE_STATES.DONE);
        }
      },
      refetchInterval: POLLING_INTERVAL,
    }
  );

  return {
    fetchingResource,
    utilityAccountQuery,
    utilityCredentialQuery,
  };
};
