import { addTime } from '@arcadiapower/warbler';

const truncateDecimal = (input: number) => parseFloat(input.toFixed(3));

export function calculateEnergyUsageKwh(
  energyAddedKwh: number,
  chargePowerKw: number
) {
  // Note that for the purposes of this demo, we're defining a charge block as 15 minutes
  const energyUsageKwhArr = [];
  let energyRemainingKwh = energyAddedKwh;
  while (energyRemainingKwh) {
    const chargeBlockKwh = Math.min(energyRemainingKwh, chargePowerKw / 4);
    energyUsageKwhArr.push(chargeBlockKwh);
    // Toss out extra digits past 3 that come from our floating points
    energyRemainingKwh = truncateDecimal(energyRemainingKwh - chargeBlockKwh);
  }

  return energyUsageKwhArr;
}

export function calculateMinutesToChargeComplete(
  energyAddedKwh: number,
  chargePowerKw: number
) {
  const hoursToCharge = Number(energyAddedKwh) / Number(chargePowerKw);
  return hoursToCharge * 60;
}

interface ChargeBlock {
  value: number;
}

export function calculateEnergyUsageKwhFromSmartChargeSchedule(
  energyAddedKwh: number,
  chargePowerKw: number,
  smartChargeSchedule: { data: ChargeBlock[] }
) {
  let energyRemainingKwh = energyAddedKwh;
  return smartChargeSchedule.data.map(chargeBlockActive => {
    if (!chargeBlockActive.value) return 0;
    const chargeBlockKwh = Math.min(energyRemainingKwh, chargePowerKw / 4);
    energyRemainingKwh = truncateDecimal(energyRemainingKwh - chargeBlockKwh);
    return chargeBlockKwh;
  });
}

export function calculateMinimumChargeTimeDate(
  totalChargeKwh: number,
  chargePowerKw: number,
  startDate: Date
) {
  const minChargeHours = Math.ceil(totalChargeKwh / chargePowerKw);
  return addTime(startDate, { hours: minChargeHours });
}
