import { MarkdownText } from 'components/markdown-text';
import { Modal, Text } from '@arcadiapower/shrike';

type Props = {
  copy: {
    title: string;
    contentTitle: string;
    description: string;
  };
  onClose: () => void;
};

/**
 * This is a helper component for the customer-overview component and is tested
 * within customer-overview.test.tsx.
 *
 * If you wish to extract the component, please write unit tests for its functionality
 */
export const CredentialInformationModal = ({
  copy,
  onClose,
}: Props): JSX.Element => (
  <Modal aria-label={copy.contentTitle}>
    <Modal.Header onClose={onClose} title={copy.title} />
    <Modal.Content>
      <Text textStyle="heading600" margin={{ bottom: '16px' }} tag="h4">
        {copy.contentTitle}
      </Text>
      <MarkdownText
        testId="credentialInformationModalText"
        text={copy.description}
      ></MarkdownText>
    </Modal.Content>
  </Modal>
);
