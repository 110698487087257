import deepmerge from 'deepmerge';
import { arcadiaLate2021Theme } from '@arcadiapower/shrike';

const breakpointSmall = 749;
const breakpointMedium = 1199;
const breakpointNavBar = 900;

const media = {
  breakpoints: {
    medium: breakpointMedium,
    small: breakpointSmall,
    navBar: breakpointNavBar,
  },
  desktop: `@media (min-width: ${breakpointMedium}px)`,
  desktopAndTablet: `@media (min-width: ${breakpointSmall}px)`,
  phone: `@media (max-width: ${breakpointSmall - 1}px)`,
  phoneAndTablet: `@media (max-width: ${breakpointMedium - 1}px)`,
  tablet: `@media (min-width: ${breakpointSmall}px)`,
  tabletOnly: `@media (min-width: ${breakpointSmall}px) and (max-width: breakpointMedium) `,
  navBarFull: `@media (min-width: ${breakpointNavBar}px)`,
  navBarMobile: `@media (max-width: ${breakpointNavBar - 1}px)`,
};

export const theme = deepmerge(arcadiaLate2021Theme, {
  colors: {
    modalBackdrop: '#000000',
    modal: '#ffffff',
  },
  media,
});

// This is a little hack to use our own media and hide shrike's.
// Shrike's theme did not have media/breakpoints when we started this project
// and we should be consistent in our own project.
// TODO: Come up with a strategy to reconcile the breakpoints
export type PlatformDashboardTheme = Omit<typeof theme, 'media'> & {
  media: typeof media;
};
