import { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { Label } from '@arcadiapower/shrike';
import { Margin } from 'styles/margin.style';
import CopySvg from 'assets/read-only-input/copy.svg';
import { copyFor } from 'config/copy';
import EyeHiddenSvg from 'assets/read-only-input/eye-hidden.svg';
import EyeVisibleSvg from 'assets/read-only-input/eye-visible.svg';
import {
  Value,
  ValueWrapper,
  InputGroup,
  ActionsWrapper,
  ActionButton,
  ActionIcon,
  ActionNotification,
} from './read-only-input.style';

type StandardProps = {
  className?: string;
  label: string;
  value?: string | null;
  masked?: false;
  canCopy?: boolean;
  canReveal?: false;
  // Matches shrike
  margin?: Margin;
};

type MaskedProps = Omit<StandardProps, 'masked' | 'canReveal'> & {
  masked: true;
  canReveal?: boolean;
};

export type Props = StandardProps | MaskedProps;

export const MASKED_INPUT = '••••••••••••••••••••••••••••••••••••';

const getCopy = copyFor('configuration.apiKeys.apiKeysDisplay');

export const ReadOnlyInput = ({
  label,
  value,
  masked = false,
  canCopy = false,
  canReveal = false,
  margin,
  className,
}: Props): JSX.Element => {
  const [maskInput, setMaskInput] = useState<boolean>(() => masked);
  const [isShowingActionNotification, setIsShowingActionNotification] =
    useState<boolean>(false);

  const displayValue = maskInput ? MASKED_INPUT : value;

  const timeout = useRef<NodeJS.Timeout | null>(null);

  const handleCopyClick = useCallback((value?: string | null) => {
    navigator.clipboard.writeText(value || '');
    setIsShowingActionNotification(true);
    timeout.current = setTimeout(() => {
      setIsShowingActionNotification(false);
    }, 1500);
  }, []);

  useEffect(() => {
    return () => {
      if (timeout.current) clearTimeout(timeout.current);
    };
  }, []);

  const renderAction = useCallback(
    ({ ariaLabel, icon, action, notification }) => (
      <>
        {notification && (
          <ActionNotification
            aria-hidden={!isShowingActionNotification}
            isVisible={isShowingActionNotification}
          >
            {notification}
          </ActionNotification>
        )}
        <ActionButton onClick={action} aria-label={ariaLabel}>
          <ActionIcon src={icon} alt={ariaLabel} />
        </ActionButton>
      </>
    ),
    [isShowingActionNotification]
  );

  const Actions = useMemo(() => {
    if (!canCopy && !canReveal) return;
    return (
      <ActionsWrapper>
        {canCopy &&
          renderAction({
            action: () => handleCopyClick(value),
            ariaLabel: 'copy',
            icon: CopySvg,
            notification: getCopy('copyKeys'),
          })}
        {canReveal &&
          renderAction({
            action: () => setMaskInput(maskInput => !maskInput),
            ariaLabel: maskInput ? 'reveal' : 'mask',
            icon: maskInput ? EyeVisibleSvg : EyeHiddenSvg,
          })}
      </ActionsWrapper>
    );
  }, [canCopy, canReveal, maskInput, value, renderAction, handleCopyClick]);

  return (
    <InputGroup className={className} margin={margin}>
      <Label>{label}</Label>
      <ValueWrapper>
        <Value>{displayValue}</Value>
        {Actions}
      </ValueWrapper>
    </InputGroup>
  );
};
