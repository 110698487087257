import { IS_PROD } from '../config/constants';

const initOneTrust = () => {
  const key = IS_PROD
    ? 'f5a13d0b-b1b9-4fba-a0dc-7af736f0bb94'
    : 'f5a13d0b-b1b9-4fba-a0dc-7af736f0bb94-test';

  const firstScript = document.createElement('script');
  firstScript.type = 'text/javascript';
  firstScript.src = `https://cdn.cookielaw.org/consent/${key}/OtAutoBlock.js`;

  const secondScript = document.createElement('script');
  secondScript.type = 'text/javascript';
  secondScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
  secondScript.charset = 'UTF-8';
  secondScript.setAttribute('data-domain-script', key);

  // ensure scripts are first in the head tag
  const head = document.getElementsByTagName('head')[0];
  head.insertBefore(secondScript, head.firstChild);
  head.insertBefore(firstScript, head.firstChild);
};

try {
  initOneTrust();
} catch (e) {
  // eslint-disable-next-line no-console
  console.error(e);
}
