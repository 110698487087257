import { gql } from '@apollo/client';

export const SET_UP_ACCOUNT = gql`
  mutation setUpAccount($input: UpdateResourceOwnerInput!) {
    updateResourceOwner(input: $input) {
      resourceOwner {
        id
        firstName
        lastName
        email
      }
      accessToken {
        token
        expiresAt
      }
    }
  }
`;

export const generateSetUpAccountOutput = ({
  token = 'longtoken',
}: { token?: string } = {}): SetUpAccountMutation => {
  return {
    updateResourceOwner: {
      accessToken: {
        expiresAt: '2022-05-22 18:27:28 -0400',
        token,
      },
      resourceOwner: {
        email: 'don.draper@gmail.com',
        firstName: 'Don',
        id: '3',
        lastName: 'Draper',
      },
    },
  };
};

export const VERIFY_SET_UP_ACCOUNT_TOKEN = gql`
  mutation verifySetUpAccountToken(
    $input: ConvertSetUpAccountTokenToAccessTokenInput!
  ) {
    convertSetUpAccountTokenToAccessToken(input: $input) {
      accessToken {
        expiresAt
        token
      }
    }
  }
`;

export const generateVerifySetUpAccountTokenOutput = ({
  token = 'temptoken',
}: { token?: string } = {}): VerifySetUpAccountTokenMutation => ({
  convertSetUpAccountTokenToAccessToken: {
    accessToken: {
      expiresAt: '2022-05-22 18:27:28 -0400',
      token,
    },
  },
});
