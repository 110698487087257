import styled from 'styled-components';
import { Card, Select } from '@arcadiapower/shrike';

export const TableSection = styled.div`
  padding: 0 24px;
  overflow: auto;
`;

export const TableSectionWrapper = styled(Card)`
  width: 100%;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const AccountSelect = styled(Select)`
  // Some grossness to hide the label
  min-height: unset;
  label {
    display: none;
  }

  svg {
    top: 4px;
  }
`;
