import { useState } from 'react';
import {
  UtilityStatement,
  PaymentTransaction,
  UtilityRemittance,
  UtilityRemittanceItem,
  UtilityRemittanceLineItem,
  CustomLineItem,
} from 'components/payment-timeline/sections';
import { StatementCardContents, Wrapper } from './statement-card.style';
import { StatementCardHeader } from './statement-card-header';

export type Props = {
  statement: NonNullable<
    NonNullable<
      GetStatementTimelineQuery['utilityAccount']
    >['statementTimeline']
  >[0];
};

export const StatementCard = ({ statement }: Props): JSX.Element => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Wrapper>
      <StatementCardHeader
        date={statement.date}
        type={statement.type}
        total={statement.total}
        status={statement.status}
        links={statement.links}
        id={statement.id}
        setExpanded={setExpanded}
        expanded={expanded}
      />
      {expanded && (
        <StatementCardContents data-testid="statement-contents">
          {statement.utilityRemittances.map(utilityRemittance => (
            <UtilityRemittance
              key={utilityRemittance.id}
              utilityRemittance={utilityRemittance}
            />
          ))}
          {statement.utilityRemittanceItems.map(utilityRemittanceItem => (
            <UtilityRemittanceItem
              key={utilityRemittanceItem.id}
              utilityRemittanceItem={utilityRemittanceItem}
            />
          ))}
          {statement.utilityRemittanceLineItem && (
            <UtilityRemittanceLineItem
              utilityRemittanceLineItem={statement.utilityRemittanceLineItem}
            />
          )}
          {statement.customLineItems &&
            statement.customLineItems.map(customLineItem => (
              <CustomLineItem
                key={customLineItem.id}
                customLineItem={customLineItem}
              />
            ))}
          {statement.paymentTransactions.map(paymentTransaction => (
            <PaymentTransaction
              key={paymentTransaction.id}
              paymentTransaction={paymentTransaction}
            />
          ))}
          {statement.utilityStatements.map(utilityStatement => (
            <UtilityStatement
              key={utilityStatement.id}
              utilityStatement={utilityStatement}
            />
          ))}
        </StatementCardContents>
      )}
    </Wrapper>
  );
};
