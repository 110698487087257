import { Page } from 'styles';
import * as analytics from 'utils/analytics';
import { NotificationModal } from 'contexts/notification-modal';
import { useParams } from 'react-router-dom';
import { history } from 'browser-history';
import { login, overview } from 'config/routes';
import { CenteredContentNarrow } from 'styles/single-content.style';
import { Alert, Text, Loading, Link } from '@arcadiapower/shrike';
import { NavBarUnauthed } from 'components/nav-bar';
import { copyFor } from 'config/copy';
import { useMutation } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { hasValidLoginToken } from 'config/auth';
import { MessageContainer } from './confirm-email.style';
import { CONFIRM_EMAIL } from './confirm-email.api';

const getCopy = copyFor('confirmEmail');

export const SUCCESS_NOTIFICATION = {
  cta: getCopy('success.cta'),
  description: getCopy('success.description'),
  title: getCopy('success.title'),
};

export const FAILURE_NOTIFICATION = {
  cta: getCopy('failure.cta'),
  description: getCopy('failure.description'),
  title: getCopy('failure.title'),
};

export const ConfirmEmail = (): JSX.Element => {
  const [confirmEmailAndGrantAccessToken] = useMutation<
    ConfirmEmailAndGrantAccessTokenMutation,
    ConfirmEmailAndGrantAccessTokenMutationVariables
  >(CONFIRM_EMAIL);

  const { confirmEmailToken } = useParams();
  const [pending, setPending] = useState<boolean>(false);

  const { setNotificationModal } = useContext(NotificationModal.Context);

  useEffect(() => {
    let unmounted = false;
    async function verifyToken() {
      setPending(true);
      try {
        const { data } = await confirmEmailAndGrantAccessToken({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          variables: { input: { confirmEmailToken: confirmEmailToken! } },
        });
        if (data) {
          analytics.track(analytics.TrackEvents.EMAIL_CONFIRMED);
          const destination = hasValidLoginToken() ? overview : login;
          setNotificationModal(SUCCESS_NOTIFICATION);
          history.push(destination);
        } else {
          throw new Error('Failed to confirm email.');
        }
      } catch (e) {
        const destination = hasValidLoginToken() ? overview : login;
        setNotificationModal(FAILURE_NOTIFICATION);
        history.push(destination);
      } finally {
        if (!unmounted) setPending(false);
      }
    }
    verifyToken();
    return () => {
      unmounted = true;
    };
  }, [
    confirmEmailToken,
    confirmEmailAndGrantAccessToken,
    setNotificationModal,
  ]);

  const loadingMessage = () => (
    <>
      <Text>{getCopy('pending')}</Text>
      <Loading />
    </>
  );
  const errorMessage = () => (
    <>
      <Alert>{getCopy('invalid.alert')}</Alert>
      <Link to={login} type="inline" openInNewTab={false}>
        {getCopy('invalid.cta')}
      </Link>
    </>
  );
  return (
    <Page>
      <NavBarUnauthed />
      <CenteredContentNarrow>
        <MessageContainer>
          {pending ? loadingMessage() : errorMessage()}
        </MessageContainer>
      </CenteredContentNarrow>
    </Page>
  );
};
