import { useEffect, useState } from 'react';

export const useMobile = () => {
  const mobileBreakpoint = 600;
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth < mobileBreakpoint
  );

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < mobileBreakpoint);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return isMobile;
};
