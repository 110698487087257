import { useMemo, useState } from 'react';
import { Text, Skeleton } from '@arcadiapower/shrike';
import { UseQueryResult } from '@tanstack/react-query';
import { TableSection } from 'utils/demo-mappers';
import { APIExplorer } from 'components/demo/api-explorer';
import {
  DataContainer,
  DataTitle,
  DataTitleHeader,
  Cell,
  Table,
  LoadingCell,
} from './data-table.style';
import { DataTableHeader } from './data-table-header.component';

export type Props<T> = {
  data?: T;
  endpoint: string;
  error: UseQueryResult['error'];
  loading: boolean;
  mapper: (params: T) => TableSection[];
  requestType: 'GET' | 'POST';
  title: string;
  helperText?: string;
};

export const DataTable = <T extends Record<string, unknown>>({
  data,
  endpoint,
  mapper,
  error,
  loading,
  requestType,
  title,
  helperText,
}: Props<T>): JSX.Element => {
  const [showAPIData, setShowAPIData] = useState(false);

  const formattedDataTables = useMemo(
    () => (data ? mapper(data) : null),
    [data, mapper]
  );

  const renderBody = () => {
    if (!formattedDataTables || !data) return;

    if (showAPIData)
      return (
        <APIExplorer
          data={data}
          requestType={requestType}
          endpoint={endpoint}
        />
      );

    return formattedDataTables.map((subTable, index) => (
      // The subtable title may not always be unique, so we
      // have to use index here
      <DataContainer
        // eslint-disable-next-line react/no-array-index-key
        key={`${index}-${title}`}
        data-testid={`${subTable.title}-data-table-data`}
      >
        {subTable.title && (
          <DataTitle>
            <DataTitleHeader>{subTable.title}</DataTitleHeader>
          </DataTitle>
        )}
        {subTable.rows.map(({ key, label, value }) => (
          <Cell key={key} data-testid={`${label}-cell`}>
            <Text textStyle="heading500" margin={{ bottom: '8px' }} tag="h5">
              {label}
            </Text>
            <Text color="secondary">{value}</Text>
          </Cell>
        ))}
      </DataContainer>
    ));
  };

  const renderLoading = () => {
    return (
      <DataContainer data-testid="data-table-loading">
        {new Array(5).fill(0).map((_, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <LoadingCell key={index}>
              <Skeleton />
            </LoadingCell>
          );
        })}
      </DataContainer>
    );
  };

  return (
    <Table data-testid={`${title}-data-table`}>
      <DataTableHeader
        apiExplorerButtonEnabled={!!data}
        loading={loading}
        title={title}
        error={error}
        helperText={helperText}
        showAPIData={showAPIData}
        setShowAPIData={setShowAPIData}
      />
      {loading ? renderLoading() : renderBody()}
    </Table>
  );
};
