import { copyFor } from 'config/copy';
import { IconButton, Text, Loading } from '@arcadiapower/shrike';
import { Wrapper } from './pagination-controls.style';

export type Props = {
  className?: string;
  loading: boolean;
  pageInfo?: {
    hasNextPage: boolean;
    hasPreviousPage: boolean;
  };
  page?: number;
  handlePaginationForwards: () => void;
  handlePaginationBackwards: () => void;
  pageSize?: number;
  totalCount?: number;
};

const getCopy = copyFor('components.paginationControls');

/**
 *
 * These controls should be compatible with react-relay
 *
 */
export const PaginationControls = ({
  className,
  pageSize,
  loading,
  page,
  pageInfo,
  handlePaginationForwards,
  handlePaginationBackwards,
  totalCount,
}: Props): JSX.Element => {
  const formattedRange = () => {
    if (!totalCount || !pageSize || !page) return 0;

    const startCount = pageSize * (page - 1) + 1;
    const endCount = Math.min(startCount + pageSize - 1, totalCount);

    if (startCount === endCount) return startCount;
    return `${startCount}-${endCount}`;
  };

  return (
    <Wrapper className={className} data-testid="paginationControls">
      {loading ? (
        <Loading />
      ) : (
        <Text margin={{ right: '8px' }}>
          {getCopy('results', { range: formattedRange(), totalCount })}
        </Text>
      )}
      <IconButton
        margin={{ right: '4px' }}
        icon="ChevronLeft"
        aria-label="previous"
        disabled={!pageInfo?.hasPreviousPage || loading}
        onClick={handlePaginationBackwards}
      />
      <IconButton
        icon="ChevronRight"
        aria-label="next"
        disabled={!pageInfo?.hasNextPage || loading}
        onClick={handlePaginationForwards}
      />
    </Wrapper>
  );
};
