import { useMemo, useState } from 'react';
import { IconButton, Alert, Skeleton } from '@arcadiapower/shrike';
import { copyFor, getErrorCopy } from 'config/copy';
import {
  Table,
  TableHeader,
  TableSection,
  TableTitle,
  TableSectionTitle,
  TableSectionHeader,
} from 'styles/table.style';
import { useQuery } from '@apollo/client';
import {
  AccountDetailsForm,
  AccountDetailsReadOnly,
} from 'components/forms/account-details';
import { PasswordVerificationModal } from 'components/password-verification-modal';
import { GET_PROFILE_RESOURCE_OWNER } from './profile.api';

const getCopy = copyFor('settings.profile');

export const Profile = (): JSX.Element => {
  const { data, loading, error } = useQuery<
    GetProfileResourceOwnerQuery,
    GetProfileResourceOwnerQueryVariables
  >(GET_PROFILE_RESOURCE_OWNER);

  const [editing, setEditing] = useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false);
  const [previousPassword, setPreviousPassword] = useState<string>('');

  const AccountDetails = useMemo(() => {
    if (loading) return <Skeleton height="250px" width="100%" />;
    if (error) return <Alert>{getErrorCopy('generic')}</Alert>;

    const resourceOwner = data?.resourceOwner;
    if (resourceOwner) {
      const Form = editing ? (
        <AccountDetailsForm
          resourceOwner={resourceOwner}
          onSuccess={() => setEditing(false)}
          onCancel={() => setEditing(false)}
          previousPassword={previousPassword}
        />
      ) : (
        <AccountDetailsReadOnly resourceOwner={resourceOwner} />
      );
      return (
        <>
          <TableSectionHeader data-testid="account-details-section">
            <TableSectionTitle>
              {getCopy('accountDetails.title')}
            </TableSectionTitle>
            <IconButton
              disabled={editing}
              icon="Pencil"
              onClick={() => setShowPasswordModal(true)}
              aria-label="edit"
            />
          </TableSectionHeader>
          {Form}
        </>
      );
    }
  }, [data, loading, error, editing, previousPassword]);

  return (
    <Table>
      <TableHeader>
        <TableTitle>{getCopy('title')}</TableTitle>
      </TableHeader>
      <TableSection>{AccountDetails}</TableSection>
      {showPasswordModal && (
        <PasswordVerificationModal
          onClose={() => setShowPasswordModal(false)}
          onSuccess={(password: string) => {
            setPreviousPassword(password);
            setShowPasswordModal(false);
            setEditing(true);
          }}
        />
      )}
    </Table>
  );
};
