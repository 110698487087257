import i18n from 'i18n-js';
import { coverage } from './coverage';
import { forms } from './forms';
import { login } from './login';
import { signup } from './signup';
import { nav } from './nav';
import { configuration } from './configuration';
import { errors } from './errors';
import { overview } from './overview';
import { settings } from './settings';
import { resetPassword } from './reset-password';
import { verifyResetToken } from './verify-reset-token';
import { changePassword } from './change-password';
import { confirmAccountLabel } from './confirm-account-label';
import { confirmEmail } from './confirm-email';
import { components } from './components';
import { customerOverview } from './customer-overview';
import { customerDetail } from './customer-detail';
import { statementTimeline } from './statement-timeline';
import { samlTokenHandler } from './saml-token-handler';
import { ssoLogin } from './sso-login';
import { demo } from './demo';
import { spark } from './spark';
import { setUpAccount } from './set-up-account';
import { customers } from './customers';
import { Get } from 'type-fest';

const copy = {
  en: {
    coverage,
    components,
    errors,
    login,
    forms,
    signup,
    overview,
    nav,
    configuration,
    settings,
    resetPassword,
    verifyResetToken,
    changePassword,
    confirmAccountLabel,
    confirmEmail,
    customers,
    customerOverview,
    customerDetail,
    statementTimeline,
    samlTokenHandler,
    setUpAccount,
    ssoLogin,
    demo,
    spark,
  },
};

i18n.translations = copy;

type PathImpl<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
    : K
  : never;

type Path<T> = PathImpl<T, keyof T> | keyof T;

type PathValue<T, P extends Path<T>> = P extends `${infer K}.${infer Rest}`
  ? K extends keyof T
    ? Rest extends Path<T[K]>
      ? PathValue<T[K], Rest>
      : never
    : never
  : P extends keyof T
  ? T[P]
  : never;

type CopyDictionary = typeof copy.en;
type GetCopyFunction<T> = Path<
  PathValue<CopyDictionary, T extends Path<CopyDictionary> ? T : never>
>;

export const copyFor =
  <T extends Path<CopyDictionary>>(scope: T) =>
  <P extends GetCopyFunction<T> & string>(
    property: P,
    ...args: Record<string, string | number | undefined | null>[]
  ) => {
    const result = i18n.t(`${scope}.${property}`, ...args);
    return result as Get<CopyDictionary, `${T}.${P}`, { strict: true }>;
  };

export type KeysFromCopyScope<PathString> = keyof PathValue<
  CopyDictionary,
  PathString extends Path<CopyDictionary> & string ? PathString : never
>;

export const getErrorCopy = copyFor('errors.messages');

// A utility to map a form's input type to the props for a shrike input -
// This also allows the "name" to fallback to the label
// Note: I could not type this with about an hour of trying. Would be neat if
// we were able to though!
export const getInputPropsFromCopy = <T>(
  getCopy: T extends Function ? T : never,
  inputKey: string,
  options = {}
) => ({
  label: getCopy(`inputs.${inputKey}.label`, options),
  name: getCopy(`inputs.${inputKey}.name`, {
    ...options,
    defaultValue: getCopy(`inputs.${inputKey}.label`),
  }),
  placeholder: getCopy(`inputs.${inputKey}.placeholder`, {
    ...options,
    defaultValue: '',
  }),
  helperText: getCopy(`inputs.${inputKey}.helperText`, {
    ...options,
    defaultValue: '',
  }),
});
