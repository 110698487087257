import {
  useState,
  useEffect,
  useMemo,
  useCallback,
  SetStateAction,
} from 'react';
import { track, TrackEvents } from 'utils/analytics';
import { useWindowDimensions } from 'hooks/use-window-dimensions.hook';
import { useQuery } from '@tanstack/react-query';
import { formatDate, isSameDay, addTime } from '@arcadiapower/warbler';
import { IconButton, Text } from '@arcadiapower/shrike';
import { theme } from 'config/theme';
import Chart from 'react-apexcharts';
import { copyFor } from 'config/copy';
import { IntervalDataItem, UtilityAccountArc } from 'typings/arcadia-api';
import { useArcAPIClientContext } from 'contexts/demo/arc-api-client';
import { DataError } from 'hooks/use-fetch-utility-data.hook';
import { Table, DataTableHeader } from '../data-table';
import {
  ChartWrapper,
  ChartTitleWrapper,
  ApexChartWrapper,
  chartBackgroundColor,
  chartGridColor,
  axisLabelStyle,
  ChartControlsWrapper,
  BoldText,
} from './interval-data-chart.style';
import {
  enrichIntervals,
  generateIntervalDataSeries,
  tooltipFormatter,
  xAxisLabelFormatter,
  getQueryRange,
} from './interval-data-chart.utils';
import { APIExplorer } from '../api-explorer';

const getCopy = copyFor('demo.results.plug.tables.intervalData');

export type Props = {
  accountString?: string;
  selectedAccountId?: number;
  accountError?: unknown;
  featureAvailability?: UtilityAccountArc['feature_availability']['utility_intervals'];
  isRealAccount?: boolean;
};

export const IntervalDataChart = ({
  accountString,
  selectedAccountId,
  accountError,
  featureAvailability,
  isRealAccount,
}: Props): JSX.Element => {
  const { apiClient } = useArcAPIClientContext();
  const [showAPIData, setShowAPIData] = useState(false);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const { width } = useWindowDimensions();

  const {
    data: intervalData,
    error: intervalDataError,
    isLoading: intervalDataLoading,
  } = useQuery(
    ['getIntervalDataByAccountId', { selectedAccountId }],
    async () => {
      if (!selectedAccountId) return;
      const { oneWeekBeforeYesterday, yesterday } = getQueryRange();
      const benchmarkStart = performance.now();
      const intervalData = await apiClient.getIntervalData({
        utilityAccountId: selectedAccountId,
        startTime: oneWeekBeforeYesterday,
        endTime: yesterday,
      });
      const benchmarkEnd = performance.now();
      const timeToFetch = Math.round((benchmarkEnd - benchmarkStart) / 1000);
      if (!intervalData.data.length) {
        track(TrackEvents.INTERVAL_DATA_LAST_WEEK_EMPTY, {
          timeToFetch,
        });
        throw new DataError(getCopy('noIntervalsError'));
      }
      track(TrackEvents.INTERVAL_DATA_LOADED, {
        timeToFetch,
      });
      return intervalData;
    },
    {
      enabled: !isRealAccount && featureAvailability === 'AVAILABLE',
    }
  );

  const isUnavailable =
    isRealAccount ||
    (featureAvailability &&
      !['AVAILABLE', 'NOT_READY'].includes(featureAvailability));

  const error = accountError || isUnavailable;
  const loading = !error && intervalDataLoading;

  useEffect(() => {
    if (isUnavailable) {
      track(TrackEvents.INTERVAL_DATA_UNAVAILABLE);
    }

    if (intervalDataError) {
      track(TrackEvents.INTERVAL_DATA_ERROR);
    }
  }, [isUnavailable, intervalDataError]);

  const updateShowApiData = (value: SetStateAction<boolean>) => {
    track(TrackEvents.INTERVAL_DATA_VIEW_API_DATA, {
      showAPIData: value,
    });
    setShowAPIData(value);
  };

  // Note - we take care of the date casting in this useMemo as a peformance optimization -
  // there are a lot of data points and this adds up!
  const intervals = useMemo(() => {
    if (intervalData) {
      return enrichIntervals(intervalData.data);
    }
  }, [intervalData]);
  const numIntervals = useMemo(() => intervals?.length, [intervals]);
  const largeAmountOfSeriesItems = !!(numIntervals && numIntervals > 36);

  useEffect(() => {
    if (intervals && numIntervals) {
      const newCurrentDate = intervals[numIntervals - 1].midpointDate;
      setCurrentDate(new Date(newCurrentDate));
    }
  }, [intervals, numIntervals]);

  const seriesData = useMemo(() => {
    if (intervals && currentDate)
      return generateIntervalDataSeries(intervals, currentDate);
  }, [intervals, currentDate]);

  const hasNegativeValues = useMemo(() => {
    return seriesData?.find(d => d.y < 0) !== undefined;
  }, [seriesData]);

  const showMobileChartStyles = width < 600;
  const showSmallDesktopChartStyles = width < 1200;

  // We save apexchartoptions in useMemo as a performance optimization
  const apexChartOptions = useMemo(() => {
    const options = {
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      chart: {
        background: chartBackgroundColor,
        foreColor: theme.colors.background.primary,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        offsetX: 0,
      },
      colors: [theme.colors.content.accent2],
      dataLabels: {
        enabled: false,
      },
      fill: { opacity: 1, type: 'solid' as const },
      grid: {
        borderColor: chartGridColor,
        padding: {
          bottom: 24,
        },
        position: 'back' as const,
        show: true,
        strokeDashArray: 0,
        xaxis: {
          lines: {
            show: false,
          },
          tickPlacement: 'on',
          tickAmount: 4,
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
      stroke: {
        show: false,
      },
      legend: axisLabelStyle,
      tooltip: {
        marker: {
          fillColors: [theme.colors.content.accent3],
        },
        style: axisLabelStyle,
        x: {
          show: false,
        },
        y: {
          formatter: (value: number) => `${value} kWh`,
          title: {
            formatter: (
              _: number,
              {
                dataPointIndex,
                w,
              }: {
                dataPointIndex: number;
                w: { config: { series: { data: IntervalDataItem[] }[] } };
              }
            ) => {
              const data = w.config.series[0].data[dataPointIndex];
              return tooltipFormatter(data.start_time, data.end_time);
            },
          },
        },
      },
      xaxis: {
        axisBorder: {
          color: theme.colors.content.primaryInverse,
        },
        axisTicks: {
          color: theme.colors.content.primaryInverse,
        },
        labels: {
          offset: 0,
          formatter: xAxisLabelFormatter,
          style: axisLabelStyle,
        },
        min: currentDate?.setHours(0, 0, 0),
        max: (currentDate?.setHours(23, 59, 59, 999) ?? 0) + 1,
        tooltip: {
          enabled: false,
        },
        tickAmount: 24,
        type: 'numeric',
      },
      yaxis: [
        {
          title: {
            text: 'kWh',
            style: axisLabelStyle,
            offsetY: 0,
            offsetX: 0,
          },
          forceNiceScale: true,
          labels: {
            offsetX: 0,
            formatter: (val: number) => {
              if (val === 0 && !hasNegativeValues) return '';
              return val.toFixed(2);
            },
            style: axisLabelStyle,
          },
          min: hasNegativeValues ? undefined : 0,
          tickAmount: 4,
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 4,
        },
      },
    };

    if (showSmallDesktopChartStyles) {
      // NB:Collin
      // Handles partial days on smaller screens having the correct border radius.// Once there are too many series items the large border radius causes rendering issues
      options.plotOptions.bar.borderRadius = largeAmountOfSeriesItems ? 2 : 4;
    }

    if (showMobileChartStyles) {
      // Basically have to reposition the y axis label and then adjust the chart to take up that space
      // Unfortunately there are no clear config options to handle this so this code looks a bit hacky.. but it works!
      options.chart.offsetX = -35;
      options.yaxis[0].title.offsetY = 95;
      options.yaxis[0].title.offsetX = 30;
      options.grid.padding.bottom = 10;
      options.plotOptions.bar.borderRadius = largeAmountOfSeriesItems ? 1 : 4;
    }
    return options;
  }, [
    currentDate,
    largeAmountOfSeriesItems,
    showMobileChartStyles,
    showSmallDesktopChartStyles,
    hasNegativeValues,
  ]);

  const chartHeight = '100%';
  let chartWidth = '100%';
  if (showMobileChartStyles) {
    chartWidth = '110%';
  }

  const renderBody = useCallback(() => {
    const navigateToPreviousDay = () => {
      track(TrackEvents.INTERVAL_DATA_PREVIOUS_DAY);
      setCurrentDate(addTime(currentDate, { days: -1 }));
    };
    if (!intervalData || !intervals || !numIntervals) return;
    return showAPIData ? (
      <APIExplorer
        data={intervalData}
        requestType="GET"
        endpoint="/plug/utility_intervals?utility_account_id={utility_account_id}&start_time={start_time}&end_time={end_time}"
      />
    ) : (
      <ChartWrapper data-testid="interval-data-chart">
        <ChartTitleWrapper>
          <BoldText color="primaryInverse" textStyle="paragraph400">
            {getCopy('chart.title')}
          </BoldText>
        </ChartTitleWrapper>
        <ApexChartWrapper>
          <Chart
            height={chartHeight}
            width={chartWidth}
            // @ts-expect-error apexChart type does not support all formats
            options={apexChartOptions}
            series={[{ data: seriesData ?? [], name: 'kWh', type: 'bar' }]}
            type="area"
          />
        </ApexChartWrapper>
        <ChartControlsWrapper>
          <IconButton
            color="primaryInverse"
            icon="ChevronLeft"
            aria-label={getCopy('chart.previousButtonLabel')}
            margin={{ right: '16px' }}
            disabled={isSameDay(intervals[0].start_time, currentDate)}
            onClick={navigateToPreviousDay}
          />
          <BoldText
            color="primaryInverse"
            textStyle="paragraph400"
            margin={{ right: '7px' }}
          >
            {formatDate(currentDate, {
              format: 'E',
              style: 'date',
            })}
          </BoldText>
          <Text color="primaryInverse" textStyle="paragraph400">
            {formatDate(currentDate, {
              format: 'P',
              style: 'date',
            })}
          </Text>
          <IconButton
            color="primaryInverse"
            icon="ChevronRight"
            aria-label={getCopy('chart.nextButtonLabel')}
            margin={{ left: '16px' }}
            disabled={isSameDay(
              intervals[numIntervals - 1].start_time,
              currentDate
            )}
            onClick={() => setCurrentDate(addTime(currentDate, { days: 1 }))}
          />
        </ChartControlsWrapper>
      </ChartWrapper>
    );
  }, [
    chartWidth,
    apexChartOptions,
    currentDate,
    intervalData,
    intervals,
    numIntervals,
    seriesData,
    showAPIData,
  ]);

  const warning: string | undefined = useMemo(() => {
    if (isUnavailable)
      return isRealAccount
        ? getCopy('unavailableWarningRealAccount')
        : getCopy('unavailableWarning');
  }, [isUnavailable, isRealAccount]);

  return (
    <Table data-testid="interval-data-table" margin={{ bottom: '24px' }}>
      <DataTableHeader
        apiExplorerButtonEnabled={!!intervalData}
        apiExplorerButtonType="graph"
        title={getCopy('title')}
        helperText={getCopy('helperText')}
        error={accountError || intervalDataError}
        warning={warning}
        loading={loading}
        loadingString={getCopy('loading')}
        showAPIData={showAPIData}
        setShowAPIData={updateShowApiData}
        subTitle={accountString}
      />
      {renderBody()}
    </Table>
  );
};
