export interface FileUpload {
  name: string;
  base64: string;
  size: number;
}

export const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result as string); // readAsDataUrl should always produce a base64 string
      } else {
        reject(new Error('No result'));
      }
    };
    reader.onerror = error => reject(error);
  });

export const convertAndValidateFileArray = async (
  fileArray: File[]
): Promise<FileUpload[]> => {
  const convertedFileArray = fileArray.map(async file => {
    const { name, size } = file;
    return {
      base64: await toBase64(file),
      name,
      size,
    };
  });

  const files = await Promise.all(convertedFileArray);
  return files;
};
