import { ComponentProps, MouseEventHandler } from 'react';

import { RequireAtLeastOne } from 'utils/typescript';
import { Icon, Text } from '@arcadiapower/shrike';
import { useLocation } from 'react-router-dom';
import { NavBarLink } from './nav-bar-item.style';

type Prop = {
  label: string;
  icon?: ComponentProps<typeof Icon>['icon'];
  svgPath?: string;
  route?: string;
  onClick?: MouseEventHandler;
  className?: string;
};

export type RouteProp = RequireAtLeastOne<Prop, 'svgPath' | 'icon'> &
  RequireAtLeastOne<Prop, 'onClick' | 'route'>;

export const NavBarItem = ({
  route,
  label,
  icon,
  className,
  svgPath,
  onClick,
}: RouteProp): JSX.Element => {
  const location = useLocation();
  route = route || '#';
  const isActive = location.pathname.includes(route);
  const color = isActive ? 'primary' : 'secondary';
  return (
    // We need to us a "transitory prop" from styled components so we don't
    // attempt to pass "active" to the DOM
    <NavBarLink
      $isActive={isActive}
      to={route}
      className={className}
      onClick={onClick}
    >
      <Icon
        icon={icon}
        margin={{ right: '12px' }}
        path={svgPath}
        color={color}
      />
      <Text color={color}>{label}</Text>
    </NavBarLink>
  );
};
