import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'styles';
import { client } from 'config/apollo-client';
import { ApolloProvider } from '@apollo/client';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  resetPassword,
  login,
  signup,
  verifyResetToken,
  changePassword,
  confirmEmail,
  setUpAccount,
  coverage,
  profile,
  team,
  webhooks,
  apiKeys,
  customization,
  overview,
  configuration,
  settings,
  customerDetail,
  customers,
  demo,
} from 'config/paths';
import * as routes from 'config/routes';
import { Login } from 'pages/login';
import { Signup } from 'pages/signup';
import { VerifyResetToken } from 'pages/verify-reset-token';
import { ResetPassword } from 'pages/reset-password';
import { Dashboard } from 'pages/dashboard';
import { ChangePassword } from 'pages/change-password';
import { ConfirmEmail } from 'pages/confirm-email';
import { Notification } from '@arcadiapower/shrike';
import { theme } from 'config/theme';
import { NotificationModal } from 'contexts/notification-modal';
import { SamlTokenHandler } from 'components/saml-token-handler';
import { ErrorBoundary } from 'components/error-boundary';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Demo } from 'pages/demo';
import { SetUpAccount } from 'pages/set-up-account';
import { getShouldShowHelpWidget, RouteGuard } from 'components/route-guard';
import { Coverage } from 'pages/coverage';
import { Overview } from 'pages/overview';
import { Settings } from 'pages/settings';
import { Configuration } from 'pages/configuration';
import { Customers } from 'pages/customers';
import { CustomerDetail } from 'pages/customers/customer-detail';
import { Profile } from 'pages/settings/profile';
import { Team } from 'pages/settings/team';
import { ApiKeys } from 'pages/configuration/api-keys';
import { Customization } from 'pages/configuration/customization';
import { Webhooks } from 'pages/configuration/webhooks';
import { hasValidLoginToken } from 'config/auth';
import Zendesk from 'react-zendesk';
import {
  hideHelpWidget,
  ZENDESK_KEY,
  ZENDESK_SETTINGS,
} from 'config/integrations';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 20 * 60 * 1000, // 20 minutes
      networkMode: 'always',
    },
    mutations: {
      networkMode: 'always',
    },
  },
});

const FallthroughRoute = (): JSX.Element => {
  return hasValidLoginToken() ? (
    <Navigate to={routes.overview} replace />
  ) : (
    <Navigate to={routes.login} replace />
  );
};

function App(): JSX.Element {
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ErrorBoundary>
            <Zendesk
              defer
              zendeskKey={ZENDESK_KEY}
              {...ZENDESK_SETTINGS}
              onLoaded={() => {
                // This is necessary because of a race condition where the widget loads
                // after the route, so we need to check whether we want to show the widget
                // after its loaded
                if (!getShouldShowHelpWidget(location.pathname))
                  hideHelpWidget();
              }}
            />
            <NotificationModal.Provider>
              <Notification.Provider>
                <SamlTokenHandler>
                  <RouteGuard>
                    <Routes>
                      <Route path="/*" element={<Dashboard />}>
                        <Route path={overview} element={<Overview />} />
                        <Route path={`${settings}/*`} element={<Settings />}>
                          <Route path={profile} element={<Profile />} />
                          <Route path={team} element={<Team />} />
                          <Route
                            path="*"
                            element={<Navigate to={profile} replace />}
                          />
                        </Route>
                        <Route
                          path={customerDetail}
                          element={<CustomerDetail />}
                        />
                        <Route path={customers} element={<Customers />} />
                        <Route
                          path={`${configuration}/*`}
                          element={<Configuration />}
                        >
                          <Route path={apiKeys} element={<ApiKeys />} />
                          <Route path={webhooks} element={<Webhooks />} />
                          <Route
                            path={customization}
                            element={<Customization />}
                          />
                          <Route
                            path="*"
                            element={<Navigate to={apiKeys} replace />}
                          />
                        </Route>
                        <Route path="*" element={<FallthroughRoute />} />
                      </Route>

                      <Route path={`${demo}/*`} element={<Demo />} />

                      <Route path={coverage} element={<Coverage />} />

                      <Route path={login} element={<Login />} />

                      <Route path={signup} element={<Signup />} />
                      <Route path={confirmEmail} element={<ConfirmEmail />} />
                      <Route path={setUpAccount} element={<SetUpAccount />} />

                      <Route path={resetPassword} element={<ResetPassword />} />
                      <Route
                        path={verifyResetToken}
                        element={<VerifyResetToken />}
                      />

                      <Route
                        path={changePassword}
                        element={<ChangePassword />}
                      />
                      <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                  </RouteGuard>
                </SamlTokenHandler>
              </Notification.Provider>
            </NotificationModal.Provider>
          </ErrorBoundary>
        </ThemeProvider>
      </QueryClientProvider>
    </ApolloProvider>
  );
}

export { App };
