import React from 'react';
import { CustomRouter } from 'custom-router';
import ReactDOM from 'react-dom';
import { initErrorReporting } from 'utils/analytics';
import { history } from 'browser-history';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

import '@arcadiapower/shrike/dist/shrike.css';

initErrorReporting();

ReactDOM.render(
  <React.StrictMode>
    <CustomRouter history={history}>
      <App />
    </CustomRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
