import { copyFor } from 'config/copy';
import { useEffect, useMemo, useState } from 'react';
import { Tooltip, Icon } from '@arcadiapower/shrike';
import { UtilityAccountArc } from 'typings/arcadia-api';
import { utilityAccountMapper } from 'utils/demo-mappers';
import { formatArcUtilityAccountLabel } from 'utils/formatters';
import {
  TableTitleRow,
  TableTitleWrapper,
  TableTitle,
  DataTable,
} from '../data-table';
import { StatementTable } from '../statement-table';
import { AccountSelect } from '../statement-table/statement-table.style';
import { AccountsContent, Wrapper } from './accounts.style';
import { IntervalDataChart } from '../interval-data-chart';

const getCopy = copyFor('demo.results.plug');

export type Props = {
  accountsData?: { data: UtilityAccountArc[] };
  error?: unknown;
  isRealAccount?: boolean;
  loading: boolean;
};

export const Accounts = ({
  accountsData,
  isRealAccount,
  loading,
  error,
}: Props): JSX.Element => {
  const [selectedAccountId, setSelectedAccountId] = useState<number>();

  // We only want to "select" an account the first time a new accountsData array
  // is loaded, so our dependency array here will be an array of account ids
  const accountIdToAutoSelect = accountsData?.data[0].id;
  useEffect(() => {
    if (accountIdToAutoSelect) {
      setSelectedAccountId(accountIdToAutoSelect);
    }
  }, [accountIdToAutoSelect]);

  const selectedAccount = useMemo(() => {
    return accountsData?.data.find(account => account.id === selectedAccountId);
  }, [accountsData, selectedAccountId]);

  const renderHeader = () => {
    const accountOptions =
      accountsData?.data.map(account => {
        return {
          text: formatArcUtilityAccountLabel(account),
          value: account.id,
        };
      }) ?? [];

    const showAccountDropdown = accountsData && accountOptions.length > 1;
    return (
      <TableTitleRow>
        <TableTitleWrapper>
          <TableTitle>{getCopy('accountsSection.title')}</TableTitle>
          {showAccountDropdown && (
            <>
              <Tooltip
                content={getCopy('accountsSection.helperText')}
                tooltipId="accounts-helper-text"
                label="accounts helper text"
                maxWidth={300}
              >
                <Icon icon="InfoCircleInverted" margin={{ right: '16px' }} />
              </Tooltip>
              <AccountSelect
                options={accountOptions}
                value={selectedAccountId}
                onChange={value => setSelectedAccountId(Number(value))}
                disabled={!accountOptions.length}
                name={getCopy('accountsSection.accountSelect.label')}
                label={getCopy('accountsSection.accountSelect.label')}
                aria-label={getCopy('accountsSection.accountSelect.label')}
              />
            </>
          )}
        </TableTitleWrapper>
      </TableTitleRow>
    );
  };

  const accountString = selectedAccount
    ? formatArcUtilityAccountLabel(selectedAccount)
    : undefined;
  const featureAvailability = selectedAccount?.feature_availability;

  return (
    <Wrapper>
      {renderHeader()}
      <AccountsContent>
        <DataTable
          data={accountsData}
          requestType="GET"
          endpoint="/utility_accounts?client_user_id={client_user_id}"
          loading={loading}
          error={error}
          mapper={data => utilityAccountMapper(data, selectedAccountId)}
          title={getCopy('tables.utilityAccounts.title')}
          helperText={getCopy('tables.utilityAccounts.helperText')}
        />
        <StatementTable
          available={featureAvailability?.utility_statements === 'AVAILABLE'}
          accountString={accountString}
          selectedAccountId={selectedAccountId}
          accountError={error}
          title={getCopy('tables.utilityStatements.title')}
        />
        <IntervalDataChart
          isRealAccount={isRealAccount}
          featureAvailability={featureAvailability?.utility_intervals}
          accountString={accountString}
          accountError={error}
          selectedAccountId={selectedAccountId}
        />
      </AccountsContent>
    </Wrapper>
  );
};
