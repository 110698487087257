import styled from 'styled-components';
import { Text, Icon, Link } from '@arcadiapower/shrike';
import { marginStyles, Margin } from 'styles/margin.style';

export const Wrapper = styled.div<{ margin?: Margin }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;

  ${props => marginStyles(props.margin)}
`;

export const BackLink = styled(Link).attrs({
  hideIcon: true,
  openInNewTab: false,
  type: 'standalone',
})`
  display: flex;
  align-items: center;
  text-decoration: none;
`;

export const BackLinkLabel = styled(Text).attrs({
  tag: 'h4',
  textStyle: 'heading600',
})``;

export const BackLinkIcon = styled(Icon).attrs({
  icon: 'ChevronLeft',
  margin: { right: '8px' },
})``;
