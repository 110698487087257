import styled from 'styled-components';
import { PageContent } from 'styles';

export const TableHeaderSection = styled(PageContent)`
  flex-grow: 0;
  padding: 48px 32px 40px;
`;

export const TableHeaderSectionContent = styled.div`
  max-width: 500px;
`;

export const TableSectionWrapper = styled.div`
  width: 100%;
  background-color: white;
  flex-grow: 1;
`;

export const TableSection = styled(PageContent)`
  padding: 0 32px 96px;
  flex-grow: 1;
`;
