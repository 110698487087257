import styled from 'styled-components';
import { Icon, Text, Link } from '@arcadiapower/shrike';

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`;

export const Column = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 4px;
  flex: 1 1 350px;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const HeaderPrimary = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderIcon = styled(Icon).attrs({
  color: 'secondary',
  margin: { right: '16px' },
})``;

export const DownloadIcon = styled(Icon).attrs({
  color: 'secondary',
  margin: { right: '.5rem' },
})``;

export const DownloadLink = styled(Link)`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin: 0 1rem;
`;

export const SectionTitle = styled(Text).attrs({ textStyle: 'heading500' })``;
