import { gql } from '@apollo/client';
import deepmerge from 'deepmerge';

export const GET_TENANT_UTILITY_ACCOUNTS = gql`
  query getTenantUtilityAccounts(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $searchTerm: String
    $filters: UtilityAccountFilter
    $sort: UtilityAccountSort
    $sandboxed: Boolean!
  ) {
    customers(
      first: $first
      after: $after
      last: $last
      before: $before
      searchTerm: $searchTerm
      filters: $filters
      sandboxed: $sandboxed
      sort: $sort
    ) {
      nodes {
        id
        accountNumber
        status
        serviceAddress
        serviceAddressStreetTwo
        serviceAddressState
        serviceAddressCity
        serviceAddressZip
        serviceCustomerName
        shellAccount
        utilityCredential {
          id
          username
          status
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export type UtilityAccountType = NonNullable<
  GetTenantUtilityAccountsQuery['customers']['nodes']
>[0];

export const generateUtilityAccount = (
  utilityAccount: Partial<UtilityAccountType>
): UtilityAccountType => {
  const baseUtilityAccount: UtilityAccountType = {
    __typename: 'UtilityAccount',
    accountNumber: '123453',
    status: 'active',
    id: 1,
    serviceAddress: '123 Fake St',
    serviceAddressCity: 'Springfield',
    serviceAddressState: 'NY',
    serviceAddressStreetTwo: null,
    serviceAddressZip: '11787',
    serviceCustomerName: 'Jeff',
    shellAccount: false,
    utilityCredential: {
      __typename: 'UtilityCredential',
      id: 3,
      status: 'verified',
      username: 'mahnamjeff@jeff.com',
    },
  };
  return deepmerge(baseUtilityAccount, utilityAccount);
};

export type generateTenantOptions = {
  variant?:
    | 'first'
    | 'last'
    | 'middle'
    | 'noResults'
    | 'shellAccount'
    | 'nullValues';
};

export const generateTenantUtilityAccounts = ({
  variant = 'first',
}: generateTenantOptions = {}): GetTenantUtilityAccountsQuery => {
  let customers;
  switch (variant) {
    case 'first':
      customers = {
        nodes: [
          generateUtilityAccount({ accountNumber: '111111', id: 1 }),
          generateUtilityAccount({ accountNumber: '222222', id: 2 }),
        ],
        pageInfo: {
          endCursor: '2',
          hasNextPage: true,
          hasPreviousPage: false,
          startCursor: '1',
        },
        totalCount: 5,
      };
      break;

    case 'middle':
      customers = {
        nodes: [
          generateUtilityAccount({ accountNumber: '333333', id: 3 }),
          generateUtilityAccount({ accountNumber: '444444', id: 4 }),
        ],
        pageInfo: {
          endCursor: '3',
          hasNextPage: true,
          hasPreviousPage: true,
          startCursor: '2',
        },
        totalCount: 5,
      };
      break;
    case 'last':
      customers = {
        nodes: [generateUtilityAccount({ accountNumber: '555555', id: 5 })],
        pageInfo: {
          endCursor: '3',
          hasNextPage: false,
          hasPreviousPage: true,
          startCursor: '3',
        },
        totalCount: 5,
      };
      break;
    case 'noResults':
      customers = {
        nodes: [],
        pageInfo: {
          endCursor: null,
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: null,
        },
        totalCount: 0,
      };
      break;
    case 'shellAccount':
      customers = {
        nodes: [
          generateUtilityAccount({
            id: 6,
            shellAccount: true,
            serviceCustomerName: 'shellUser',
          }),
        ],
        pageInfo: {
          endCursor: '2',
          hasNextPage: true,
          hasPreviousPage: false,
          startCursor: '1',
        },
        totalCount: 5,
      };
      break;
    case 'nullValues':
      customers = {
        nodes: [
          generateUtilityAccount({
            accountNumber: null,
            id: 7,
            serviceAddress: null,
            serviceAddressCity: null,
            serviceAddressState: null,
            serviceAddressStreetTwo: null,
            serviceAddressZip: null,
            serviceCustomerName: 'NoUtilityAccount',
            shellAccount: false,
            utilityCredential: {
              __typename: 'UtilityCredential',
              id: 3,
              status: 'rejected',
              username: null,
            },
          }),
        ],
        pageInfo: {
          endCursor: '2',
          hasNextPage: true,
          hasPreviousPage: false,
          startCursor: '1',
        },
        totalCount: 5,
      };
      break;
  }
  return { customers };
};
