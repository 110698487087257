import { useMemo, Fragment } from 'react';
import { copyFor } from 'config/copy';
import { getLegalLink } from 'config/external-links';
import { Text, Link } from '@arcadiapower/shrike';
import { Margin } from 'styles/margin.style';
import { Wrapper } from './agree-to-terms.style';

const getCopy = copyFor('forms.agreeToTerms');

const getJoiner = (index: number, length: number): undefined | string => {
  if (index + 1 === length) return;
  if (index + 2 === length) return getCopy('lastJoiner');
  return getCopy('regularJoiner');
};

export type Props = {
  className?: string;
  contractVersions: {
    id: number;
    displayName: string;
  }[];
  margin?: Margin;
};

export const AgreeToTerms = ({
  className,
  contractVersions,
  margin,
}: Props): JSX.Element => {
  const ContractLinks = useMemo(
    () =>
      contractVersions.map(({ id, displayName }, index) => (
        <Fragment key={id}>
          <Link href={getLegalLink(id)} type="inline">
            {displayName}
          </Link>
          {getJoiner(index, contractVersions.length)}
        </Fragment>
      )),
    [contractVersions]
  );

  return (
    <Wrapper className={className} margin={margin}>
      <Text color="secondary" textStyle="paragraph400">
        {getCopy('preContractText')} {ContractLinks}
        {getCopy('postContractText')}
      </Text>
    </Wrapper>
  );
};
