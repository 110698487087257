export const overview = {
  welcome: {
    defaultName: 'developer',
    preSetUp: {
      title: 'Welcome, %{name}! Finish setting up your account.',
      subtitle:
        'Update how your company’s name and logo appear within Connect in order to finish setting up your Arc account.',
    },
    setUp: {
      title: 'Welcome back, %{name}! Manage your account.',
      subtitle:
        'You can use the Dashboard to customize and manage your application’s integration with the Arcadia Platform.',
    },
  },
  prompts: {
    apiKeys: {
      title: 'View and save your API keys',
      description:
        'API keys are how your application authenticates with the Arcadia Platform. Keep these keys safe and secure.',
    },
    logo: {
      title: 'Add your company logo',
      description:
        'Your can embed your company logo within Connect to customize the integration and build trust with your users.',
    },
    demoTool: {
      title: 'Try the demo',
      description:
        'Preview the capabilities of Arc and see examples of how it can be used to create meaningful energy experiences for your customers.',
    },
  },
  resources: {
    gettingStarted: {
      title: 'Getting started: Guides, docs, and more',
      description:
        'Start building with the Arc Platform as quickly as possible by using our Quickstart or Product guides to get an overview of Arc. Or dive straight into our API docs to start setting things up.',
    },
    support: {
      title: 'Questions? Bugs? We’re here to help.',
      description:
        'We’re obsessed with building the best developer experience possible. Ping us with your questions or reach out with any bugs to take care of.',
    },
  },
  linkLabels: {
    quickStart: 'Quickstart guide',
    productGuides: 'Product guides',
    apiDocs: 'API docs',
    support: 'Contact support',
  },
};
