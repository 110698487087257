import { css } from 'styled-components';

export const responsiveStyle = (
  cssProperty: string,
  value?: string | string[]
) => {
  if (!value) return;

  if (typeof value === 'string') {
    return css`
      ${cssProperty}: ${value};
    `;
  }
  const [mobileValue, tabletValue, desktopValue] = value;

  let responsiveStyles = css`
    ${props => props.theme.media.phone} {
      ${cssProperty}: ${mobileValue};
    }
  `;

  if (tabletValue) {
    responsiveStyles = css`
      ${responsiveStyles}
      ${props => props.theme.media.desktopAndTablet} {
        ${cssProperty}: ${tabletValue};
      }
    `;
  }
  if (desktopValue) {
    responsiveStyles = css`
      ${responsiveStyles}
      ${props => props.theme.media.desktop} {
        ${cssProperty}: ${desktopValue};
      }
    `;
  }

  return responsiveStyles;
};
