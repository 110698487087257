import styled, { css } from 'styled-components';
import { Text, Link } from '@arcadiapower/shrike';

const borderColor = css`
  ${props => props.theme.colors.background.tertiary}
`;

export const TableSection = styled.div`
  border-top: 1px solid transparent;
  border-bottom: 1px solid ${borderColor};
  padding: 32px;
  overflow: auto;

  &:first-child {
    border-top: 1px solid ${borderColor};
  }

  &:last-child {
    border-bottom: 1px solid transparent;
  }
`;

export const Table = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${borderColor};
  margin: 0 auto;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  width: 100%;
  border-bottom: 1px solid ${borderColor};

  ${props => props.theme.media.phone} {
    flex-direction: column;
  }
`;

export const TableTitle = styled(Text).attrs({
  tag: 'h2',
  textStyle: 'heading800',
})``;

export const TableLink = styled(Link)``;

export const TableSectionHeader = styled.div<{
  mobileChildrenVertical?: boolean;
}>`
  display: flex;
  margin-bottom: 32px;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  ${props =>
    props.mobileChildrenVertical &&
    `${props.theme.media.phone} {
      flex-direction: column;

      > *:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  `}
`;

export const TableSpacer = styled.hr.attrs({
  'aria-hidden': true,
})`
  width: 100%;
  height: 1px;
  background-color: ${borderColor};
  border: none;
  margin: 32px 0;
`;

export const TableSectionTitle = styled(Text).attrs({
  tag: 'h3',
  textStyle: 'heading600',
})``;
