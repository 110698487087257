import { PlatformDashboardTheme } from 'config/theme';
import styled from 'styled-components';

export const Wrapper = styled.div<{
  variant: keyof PlatformDashboardTheme['colors']['background'];
}>`
  display: flex;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.background[props.variant]};
  max-width: 600px;
  padding: 54px 28px;
`;

export const Image = styled.img`
  height: 40px;
  width: 40px;
  margin-right: 28px;
  ${props => props.theme.media.phone} {
    display: none;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
`;
