import { copyFor } from 'config/copy';
import { Link, Loading } from '@arcadiapower/shrike';
import { Header, TenantLogo, Wrapper } from './sso-login.style';

export type Props = {
  tenantName?: string;
  ssoLink?: string;
  tenantLogoUrl?: string;
};

const getCopy = copyFor('ssoLogin');

export const SsoLogin = ({
  tenantLogoUrl,
  tenantName,
  ssoLink,
}: Props): JSX.Element => {
  return (
    <Wrapper>
      {tenantLogoUrl && (
        <TenantLogo
          data-testid="tenant-logo"
          src={tenantLogoUrl}
          alt={tenantName}
        />
      )}
      <Header>{ssoLink ? getCopy('welcome') : getCopy('loading')}</Header>
      {ssoLink ? (
        <Link
          href={ssoLink}
          type="CTA"
          fullWidth={true}
          openInNewTab={false}
          color="primaryInverse"
        >
          {getCopy('loginPrompt')}
        </Link>
      ) : (
        <Loading data-testid="loading-indicator" />
      )}
    </Wrapper>
  );
};
