export const spark = {
  title: 'Spark',
  description:
    'Spark provides easy access to energy tariff data and analytics, along with a suite of endpoints serving use cases that are often essential in features for your customers to convert DER opportunities or adopt EV and Smart Home technologies.',
  tableTitle: 'EV Charge Cost and Smart Charging',
  tableHelperText:
    'Here, you can see how long it will take to charge the device as well as how much it will cost. Enter the desired charge amount in kWh, the time that charging will start, and the charge rate in kW for the device’s plug type.',
  results: {
    title: 'Results',
    energyAnalyticsChart: {
      chargeItems: {
        basic: 'Basic Charging Cost',
        dumbCost: 'Charging cost without smart charge',
        cost: 'Total Charging Cost',
        savings: 'Cost savings with Smart Charging',
      },
      chargeTitle: 'Charge (kWh)',
      helperText:
        'The yellow line represents the changing cost of energy per your customer’s tariff schedule. The green shaded area represents the charge overtime that will be added to your device. If you enabled Smart Charge calculation, you can see the times we recommend charging the device for greatest cost savings.',
      tariffTitle: 'Cost on %{tariffCode} ($/kWh)',
      yAxisTitle: '$/kWh',
    },
  },
};
