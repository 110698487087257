import { RouteProp } from 'components/nav-bar-item';
import { Text } from '@arcadiapower/shrike';
import { Wrapper, ListItem, SideNavBarItem } from './side-nav.styles';

interface Props {
  routes: RouteProp[];
  title: string;
}

export const SideNav = ({ routes, title }: Props): JSX.Element => {
  return (
    <Wrapper>
      <Text textStyle="heading700" margin={{ bottom: '24px' }}>
        {title}
      </Text>
      {routes.map(({ route, label, onClick, icon, svgPath }) => {
        return (
          <ListItem key={label}>
            <SideNavBarItem
              route={route}
              label={label}
              icon={icon}
              svgPath={svgPath}
              onClick={onClick}
            />
          </ListItem>
        );
      })}
    </Wrapper>
  );
};
