import { store } from 'config/store';

export enum FeatureFlag {
  sample = 'sample', // used for testing purposes
  tariffDemo = 'tariffDemo', // used to demo the tariff selection mode to clients
}

export const isEnabled = (featureFlag: FeatureFlag): boolean =>
  !!store.get(featureFlag);

// Example: export const featureFlagCustomization = () => isEnabled(FeatureFlag.customization);
export const featureFlagTariffDemo = () => isEnabled(FeatureFlag.tariffDemo);
