import { gql } from '@apollo/client';

export const GET_OVERVIEW_USER = gql`
  query getOverviewUser {
    resourceOwner {
      id
      firstName
      tenant {
        id
        logoUrl
      }
    }
  }
`;

export const generateOverviewUser = (): GetOverviewUserQuery => ({
  resourceOwner: {
    firstName: 'Brandon',
    id: '1',
    tenant: {
      id: '4',
      logoUrl: null,
    },
  },
});
