export const nav = {
  links: {
    overview: 'Overview',
    configuration: 'Configuration',
    settings: 'Settings',
    customers: 'Customers',
    returnToDashboard: 'Return to dashboard',
  },
  brandText: 'by Arcadia',
};
