import { useCallback, useMemo, useState } from 'react';
import { isValidEmail, isValidPassword } from '../utils/validators';

export type SignupFormInputs = {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  password: string;
  contractVersionIds: number[] | null;
};

type UpdateInput = (
  key: keyof SignupFormInputs
) => (value: SignupFormInputs[typeof key]) => void;

export const useSignupForm = (): {
  canSubmit: boolean;
  inputs: SignupFormInputs;
  updateInput: UpdateInput;
} => {
  const [inputs, setInputs] = useState<SignupFormInputs>({
    company: '',
    contractVersionIds: null,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
  });

  // Allows you to change any field like so:
  // onChange={updateInput('firstName')}
  // to call directly use:
  // onChange('key')(value)
  const updateInput = useCallback<UpdateInput>(
    key => value => {
      setInputs(previousInputs => ({ ...previousInputs, [key]: value }));
    },
    []
  );

  const canSubmit = useMemo(() => {
    const {
      contractVersionIds,
      email,
      firstName,
      lastName,
      password,
      company,
    } = inputs;

    const allFieldsFilledOut = !!(
      contractVersionIds &&
      email &&
      firstName &&
      lastName &&
      password &&
      company
    );

    return (
      allFieldsFilledOut && isValidEmail(email) && isValidPassword(password)
    );
  }, [inputs]);

  return { canSubmit, inputs, updateInput };
};
