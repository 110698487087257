import { ComponentProps, forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Input } from '@arcadiapower/shrike';
import { noop } from 'lodash-es';

import 'react-datepicker/dist/react-datepicker.css';

type InputProps = ComponentProps<typeof Input>;

type InputForDatePickerProps = InputProps & {
  inputName: string;
};

// Name is a "reserved" key in forwardRef, so we need to rename it here
const InputForDatePicker = forwardRef<
  HTMLInputElement,
  InputForDatePickerProps
>(
  (
    { value, onFocus, inputName, ...inputProps }: InputForDatePickerProps,
    ref
  ) => {
    return (
      <Input
        {...inputProps}
        name={inputName}
        value={value}
        onFocus={onFocus}
        ref={ref}
      />
    );
  }
);

InputForDatePicker.displayName = 'InputForDatePicker';

interface DateTimeInputProps {
  disabled?: boolean;
  label: string;
  margin?: InputProps['margin'];
  maxDate?: Date;
  minDate: Date;
  name: string;
  onChange: (value: Date) => void;
  value: Date;
  variant?: string;
}

export const DateTimeInput = ({
  disabled,
  label,
  margin,
  maxDate,
  minDate,
  name,
  onChange,
  value,
  variant,
}: DateTimeInputProps) => {
  const inputProps = {
    disabled,
    inputName: name,
    label,
    margin,
    name,
    onChange: noop,
    variant,
  };

  // See https://reactdatepicker.com/#example-filter-times
  const filterTime = (time: Date) => {
    const selectedDate = new Date(time);

    if (maxDate)
      return (
        minDate.getTime() <= selectedDate.getTime() &&
        selectedDate.getTime() < maxDate.getTime()
      );
    else return minDate.getTime() <= selectedDate.getTime();
  };

  return (
    <ReactDatePicker
      customInput={<InputForDatePicker {...inputProps} />}
      onChange={onChange}
      selected={value}
      minDate={minDate}
      maxDate={maxDate}
      filterTime={filterTime}
      showTimeSelect
      timeIntervals={60}
      portalId="root-portal"
      dateFormat="MMMM d, yyyy h:mm aa"
    />
  );
};
