import { copyFor } from 'config/copy';
import { ApiKeyDisplayCopy } from 'components/settings/api-keys-display';

const getCopy = copyFor('configuration.apiKeys');

export const getCopyForApiKeySection = (
  key: 'sandbox' | 'live',
  { hasAdditionalText = false } = {}
): ApiKeyDisplayCopy => {
  const result: ApiKeyDisplayCopy = {
    description: getCopy(`${key}.description`),
    environment: getCopy(`${key}.environment`),
  };

  if (hasAdditionalText) {
    result.additionalText = {
      points: getCopy(`${key}.additionalText.points`),
      title: getCopy(`${key}.additionalText.title`),
      titleLinkCopy: getCopy(`${key}.additionalText.titleLinkCopy`),
    };
  }
  return result;
};
