import { copyFor } from 'config/copy';
import { Text, Icon } from '@arcadiapower/shrike';
import { formatDate } from '@arcadiapower/warbler';
import { formatMaskedAccountNumber } from 'utils/formatters';
import { ExpirationDate } from './credit-card.style';
import {
  PaymentHeader,
  Wrapper,
  PaymentMethodInfoWrapper,
  IconWrapper,
  PaymentNameAndIconWrapper,
} from '../payment-methods.style';

const getCopy = copyFor('components.paymentSource');

export type Props = {
  isDefault: boolean;
  isExpired: boolean;
  paymentSource: CreditCardPayment;
};

export const formatExpirationDateForCreditCard = (date: Date): string => {
  return `${getCopy('creditCard.expirationDate')}: ${formatDate(date, {
    style: 'monthYear',
  })}`;
};

export const CreditCard = ({
  isDefault,
  isExpired,
  paymentSource,
}: Props): JSX.Element => {
  const processPaymentStatus = () => {
    if (isExpired) {
      return getCopy('status.expired');
    }
    if (isDefault) {
      return getCopy('status.default');
    }
    return getCopy('status.other');
  };

  return (
    <Wrapper
      data-testid={`payment-source-${paymentSource.id}`}
      isExpired={isExpired}
      isDefault={isDefault}
    >
      <PaymentMethodInfoWrapper>
        <PaymentHeader isExpired={isExpired}>
          {processPaymentStatus()}
        </PaymentHeader>
        <PaymentHeader
          isExpired={isExpired}
        >{`ID ${paymentSource.id}`}</PaymentHeader>
      </PaymentMethodInfoWrapper>
      <PaymentMethodInfoWrapper>
        <PaymentNameAndIconWrapper>
          <IconWrapper>
            <Icon icon="CreditCard" color="secondary" />
          </IconWrapper>
          <Text color={isExpired ? 'secondary' : 'primary'}>
            {paymentSource.vendorName}
          </Text>
        </PaymentNameAndIconWrapper>
        <Text color={isExpired ? 'secondary' : 'primary'}>
          {formatMaskedAccountNumber(
            { maskedBlocks: 3 },
            paymentSource.filteredCardNumber
          )}
        </Text>
      </PaymentMethodInfoWrapper>

      <ExpirationDate>
        {formatExpirationDateForCreditCard(paymentSource.expirationDate)}
      </ExpirationDate>
    </Wrapper>
  );
};
