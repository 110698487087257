import { gql } from '@apollo/client';

export const ADD_TEAM_MEMBER = gql`
  mutation addTeamMember($input: InviteNewTenantUserInput!) {
    inviteNewTenantUser(input: $input) {
      resourceOwner {
        id
        tenant {
          id
          tenantUsers {
            id
            email
            firstName
            lastName
            status
          }
        }
      }
    }
  }
`;

export const generateAddTeamMemberOutput = ({
  email,
}: InviteNewTenantUserInput): AddTeamMemberMutation => {
  return {
    inviteNewTenantUser: {
      resourceOwner: {
        id: '123',
        tenant: {
          id: '456',
          tenantUsers: [
            {
              email: 'sara@gmail.com',
              firstName: 'sara',
              id: '2',
              lastName: 'cwynar',
              status: 'confirmed',
            },
            {
              email: 'ed@gmail.com',
              firstName: 'ed',
              id: '3',
              lastName: 'atkins',
              status: 'unconfirmed',
            },
            {
              email,
              firstName: null,
              id: '4',
              lastName: null,
              status: 'invited',
            },
          ],
        },
      },
    },
  };
};
