import { arcadiaLate2021Theme } from '@arcadiapower/shrike';

export const ZENDESK_KEY = 'f1efe3b5-2067-44c1-b313-4b8c4d5b31bf';
export const ZENDESK_SETTINGS = {
  webWidget: {
    color: {
      theme: arcadiaLate2021Theme.colors.content.primary,
    },
    offset: {
      horizontal: '16px',
      vertical: '16px',
    },
    position: { horizontal: 'right', vertical: 'bottom' },
  },
};

export const hideHelpWidget = () => window?.zE?.('webWidget', 'hide');

export const showHelpWidget = () => window?.zE?.('webWidget', 'show');
