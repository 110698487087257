import { copyFor } from 'config/copy';
import { UtilityConnectPreview } from 'components/forms/utility-connect-preview';

const getFormCopy = copyFor('forms.customization');

export type TenantLogoPreviewProps = {
  tenantName: string;
  logoUrl: string;
};

export const TenantLogoPreview = ({
  tenantName,
  logoUrl,
}: TenantLogoPreviewProps): JSX.Element => {
  return (
    <UtilityConnectPreview
      data-testid="utility-connect-preview"
      logoUrl={logoUrl}
      tenantName={tenantName}
      label={getFormCopy('inputs.utilityConnectPreview.label')}
    />
  );
};
