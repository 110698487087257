import styled from 'styled-components';

export const GridContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: grid;
  flex-grow: 1;
  grid-template-columns: minmax(100px, 291px) 1fr;
  grid-template-rows: auto;
  gap: 128px;
  padding: 48px 102px;
  grid-template-areas:
    'sidebar content'
    'sidebar content';

  ${props => props.theme.media.phoneAndTablet} {
    padding: 32px;
    gap: 24px;
    grid-template-areas:
      'sidebar sidebar'
      'content content';
  }
`;

export const SideBar = styled.div`
  grid-area: sidebar;
`;

export const Content = styled.div`
  grid-area: content;
  padding: 24px 0;
`;
