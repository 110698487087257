import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Alert, Skeleton } from '@arcadiapower/shrike';
import { copyFor, getErrorCopy } from 'config/copy';
import { apiKeysDocumentation } from 'config/external-links';
import {
  Table,
  TableHeader,
  TableTitle,
  TableLink,
  TableSection,
} from 'styles/table.style';
import { ApiKeysDisplay } from 'components/settings/api-keys-display';
import { GET_API_KEYS } from './api-keys.api';
import { getCopyForApiKeySection } from './api-keys.util';

const getCopy = copyFor('configuration.apiKeys');

export const ApiKeys = (): JSX.Element => {
  const { data, loading, error } = useQuery<
    GetApiKeysQuery,
    GetApiKeysQueryVariables
  >(GET_API_KEYS);

  const renderApiKeys = useCallback(() => {
    const credentials = data?.resourceOwner?.tenant.credentials;
    const sandboxCredentials = credentials?.find(
      credential => credential.sandboxed
    );

    const liveCredentials = credentials?.find(
      credential => !credential.sandboxed
    );

    return (
      <>
        {sandboxCredentials && (
          <ApiKeysDisplay
            credential={sandboxCredentials}
            copy={getCopyForApiKeySection('sandbox', {
              hasAdditionalText: true,
            })}
          />
        )}
        {liveCredentials && (
          <ApiKeysDisplay
            credential={liveCredentials}
            copy={getCopyForApiKeySection('live', {
              hasAdditionalText: true,
            })}
          />
        )}
      </>
    );
  }, [data]);

  const TableContent = useMemo(() => {
    if (loading) return <Skeleton height="250px" width="100%" />;
    if (error || !data?.resourceOwner)
      return (
        <TableSection>
          <Alert>{getErrorCopy('generic')}</Alert>
        </TableSection>
      );
    return renderApiKeys();
  }, [loading, error, data, renderApiKeys]);

  return (
    <Table>
      <TableHeader>
        <TableTitle>{getCopy('title')}</TableTitle>
        <TableLink href={apiKeysDocumentation}>
          {getCopy('documentationLink')}
        </TableLink>
      </TableHeader>
      {TableContent}
    </Table>
  );
};
