import { useCallback, useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Text, Notification, Link } from '@arcadiapower/shrike';
import * as analytics from 'utils/analytics';
import { noop } from 'utils/functions';
import {
  TableSection,
  TableSectionHeader,
  TableSectionTitle,
} from 'styles/table.style';
import { support } from 'config/external-links';
import { copyFor } from 'config/copy';
import { ReadOnlyInput } from 'components/read-only-input';
import { ActionConfirmationModal } from 'components/action-confirmation-modal';
import { AdditionalTextWrapper, Points } from './api-keys-display.style';
import { CYCLE_APPLICATION_CREDENTIALS } from './api-keys-display.api';

export type ApiKeyDisplayCopy = {
  environment: string;
  description: string;
  additionalText?: { title: string; titleLinkCopy: string; points: string[] };
};

export type Props = {
  credential: {
    id: string;
    clientId: string;
    clientSecret: string;
  };
  copy: ApiKeyDisplayCopy;
};

const getCopy = copyFor('configuration.apiKeys.apiKeysDisplay');

export const ApiKeysDisplay = ({
  credential,
  copy: { environment, description, additionalText },
}: Props): JSX.Element => {
  const { addNotification } = useContext(Notification.Context);
  const [openConfirmationModal, setOpenConfirmationModal] =
    useState<boolean>(false);

  const environmentLowerCase = environment.toLowerCase();

  const onSuccessfulCycle = useCallback(() => {
    analytics.track(analytics.TrackEvents.API_KEYS_ROTATED);
    setOpenConfirmationModal(false);
    addNotification({
      message: getCopy('success', { environment: environmentLowerCase }),
      variant: 'success',
    });
  }, [addNotification, environmentLowerCase]);

  const [cycleApplicationCredentials, { loading, error }] = useMutation<
    CycleApplicationCredentialsMutation,
    CycleApplicationCredentialsMutationVariables
  >(CYCLE_APPLICATION_CREDENTIALS, {
    onCompleted: onSuccessfulCycle,
    onError: noop,
    variables: {
      input: {
        clientId: credential.clientId,
      },
    },
  });

  const errorMessage =
    error && getCopy('error', { environment: environmentLowerCase });

  return (
    <>
      <TableSection>
        <TableSectionHeader mobileChildrenVertical>
          <div>
            <TableSectionTitle margin={{ bottom: '8px' }}>
              {environment}
            </TableSectionTitle>
            <Text color="secondary" textStyle="paragraph400">
              {description}
            </Text>
          </div>
          <Button onClick={() => setOpenConfirmationModal(true)}>
            {getCopy('rotateKeysCta')}
          </Button>
        </TableSectionHeader>
        <ReadOnlyInput
          value={credential.clientId}
          label={getCopy('clientId')}
          canCopy
          margin={{ bottom: '24px' }}
        />
        <ReadOnlyInput
          value={credential.clientSecret}
          label={getCopy('clientSecret')}
          masked
          canReveal
          canCopy
        />
        {additionalText && (
          <AdditionalTextWrapper>
            <Text
              color="secondary"
              textStyle="paragraph400"
              margin={{ bottom: '12px' }}
            >
              {additionalText.title}
              <Link type="inline" href={support} color="secondary">
                {additionalText.titleLinkCopy}
              </Link>
            </Text>
            <Points>
              {additionalText.points.map(point => (
                <li key={point}>
                  <Text color="secondary" textStyle="paragraph400">
                    {point}
                  </Text>
                </li>
              ))}
            </Points>
          </AdditionalTextWrapper>
        )}
      </TableSection>
      {openConfirmationModal && (
        <ActionConfirmationModal
          onConfirmation={cycleApplicationCredentials}
          onClose={() => setOpenConfirmationModal(false)}
          errorMessage={errorMessage}
          loading={loading}
          copy={{
            cta: getCopy('confirmationModal.cta', {
              environment: environmentLowerCase,
            }),
            description: getCopy('confirmationModal.description', {
              environment: environmentLowerCase,
            }),
            title: getCopy('confirmationModal.title', {
              environment,
            }),
          }}
        />
      )}
    </>
  );
};
