/**
 * This is a helper component for the customer-overview component and is tested
 * within customer-overview.test.tsx.
 *
 * If you wish to extract the component, please write unit tests for its functionality
 */

import { Text } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { DataLabel, DataFieldWrapper } from './customer-overview.style';

const getCopy = copyFor('customerOverview');

type Props = {
  label: string;
  children?: JSX.Element;
  value?: string | number | null | undefined;
};

export const DataField = ({ label, children, value }: Props): JSX.Element => {
  const renderBasicValue = () => (
    <Text color="secondary">{value ?? getCopy('missingData')}</Text>
  );

  return (
    <DataFieldWrapper>
      <DataLabel>{label}</DataLabel>
      {children ?? renderBasicValue()}
    </DataFieldWrapper>
  );
};
