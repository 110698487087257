import { useMutation } from '@apollo/client';
import { SsoLogin } from 'components/sso-login';
import {
  authenticate,
  getPathForSSORedirect,
  hasValidLoginToken,
  setPathForSSORedirect,
} from 'config/auth';
import { copyFor } from 'config/copy';
import { NotificationModal } from 'contexts/notification-modal';
import { useQueryParams } from 'hooks/use-query-params.hook';
import { useContext, useEffect, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { login } from 'config/routes';
import { CONVERT_SAML_TOKEN_TO_ACCESS_TOKEN } from './saml-token-handler.api';
import { FullScreen } from './saml-token-handler.style';

export type Props = {
  children?: JSX.Element;
};

const getCopy = copyFor('samlTokenHandler');

export const SAML_FAILURE_NOTIFICATION = {
  cta: getCopy('failure.cta'),
  description: getCopy('failure.description'),
  title: getCopy('failure.title'),
};

export const SamlTokenHandler = ({ children }: Props): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const [convertSamlTokenToAccessToken, { called, loading }] = useMutation<
    ConvertSamlTokenToAccessTokenMutation,
    ConvertSamlTokenToAccessTokenMutationVariables
  >(CONVERT_SAML_TOKEN_TO_ACCESS_TOKEN);

  const { setNotificationModal } = useContext(NotificationModal.Context);

  const queryParams = useQueryParams();
  const loginToken = queryParams.get('login_token');

  useEffect(() => {
    const handleSamlToken = async (token: string) => {
      // exchange login token for access token
      try {
        const { data } = await convertSamlTokenToAccessToken({
          variables: { input: { token } },
        });
        if (data) {
          const { token, expiresAt } =
            data.convertSamlTokenToAccessToken.accessToken;
          authenticate(token, expiresAt, {
            resourceOwner: data.convertSamlTokenToAccessToken.resourceOwner,
            navigateTo: getPathForSSORedirect() || '/',
          });
          setPathForSSORedirect(null);
        }
      } catch (error) {
        if (hasValidLoginToken()) return;
        navigate(login);
        setNotificationModal(SAML_FAILURE_NOTIFICATION);
      }
    };

    if (loginToken && !called) {
      handleSamlToken(loginToken);
    }
  }, [
    loginToken,
    convertSamlTokenToAccessToken,
    navigate,
    setNotificationModal,
    called,
    location.pathname,
  ]);

  const info = useMemo(
    () => (
      <FullScreen>
        <SsoLogin />
      </FullScreen>
    ),
    []
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{loading ? info : children}</>;
};
