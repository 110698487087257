import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';
import { Text, Link } from '@arcadiapower/shrike';
import { codeTextMixin } from 'styles/typography.style';

// TODO: Get this in shrike
export const RichShrikeText = styled(Text)`
  strong {
    font-weight: 700;
  }

  code {
    ${codeTextMixin}
  }
`;

export const StyledMarkdown = styled(Markdown).attrs({
  options: {
    forceBlock: true,
    overrides: {
      p: {
        component: RichShrikeText,
      },
      a: {
        component: Link,
        props: {
          type: 'inline',
        },
      },
    },
  },
})``;
