import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 200px;
    margin-right: 16px;
    min-height: 0px;
    ${props => props.theme.media.phone} {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;
    }
  }
`;
