import styled from 'styled-components';
import { Text, Button } from '@arcadiapower/shrike';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

export const Header = styled(Text).attrs({
  tag: 'h1',
  textStyle: 'heading800',
})`
  margin-bottom: 1.5rem;
`;

export const CTAButton = styled(Button)`
  width: 350px;
  max-width: 100%;
`;

export const TenantLogo = styled.img`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  margin-bottom: 1.5rem;
`;
