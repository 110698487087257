import { gql } from '@apollo/client';

export const VERIFY_RESET_TOKEN = gql`
  mutation convertResetPasswordTokenToAccessToken(
    $input: ConvertResetPasswordTokenToAccessTokenInput!
  ) {
    convertResetPasswordTokenToAccessToken(input: $input) {
      accessToken {
        expiresAt
        token
      }
    }
  }
`;

export const generateConvertResetPasswordTokenToAccessToken =
  (): ConvertResetPasswordTokenToAccessTokenMutation => ({
    convertResetPasswordTokenToAccessToken: {
      accessToken: {
        expiresAt: '2021-10-14 19:27:28 -0400',
        token: 'myToken',
      },
    },
  });
