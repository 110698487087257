// This file contains all the paths used in the application and should only be used in the App.tsx file.
// Use the routes.ts file for any links and navigation commands.
export const resetPassword = 'forgot-password';
export const verifyResetToken = 'verify/:resetPasswordToken';
export const confirmEmail = 'confirm-email/:confirmEmailToken';
export const changePassword = 'change-password';

export const login = 'login';
export const signup = 'signup';

export const overview = 'overview';
export const customers = 'customers';
export const customerDetail = 'customers/detail/:id';
export const getCustomerDetailRoute = (id: number) => `/customers/detail/${id}`;

export const settings = 'settings';

export const profile = 'profile';
export const team = 'team';

export const configuration = 'configuration';

export const apiKeys = 'api-keys';
export const customization = 'customization';
export const webhooks = 'webhooks';

export const demo = 'demo';
export const plug = 'plug';
export const spark = 'spark';

export const setUpAccountBase = 'set-up-account';
export const setUpAccount = `${setUpAccountBase}/:setUpAccountToken`;

export const coverage = 'coverage';
