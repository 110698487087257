import styled from 'styled-components';
import { PageContent } from 'styles';

export const TableSectionWrapper = styled.div`
  max-width: 100%;
  background-color: white;
  padding-top: 48px;
  overflow: auto;
`;

export const TableSection = styled(PageContent)`
  padding: 0 32px 96px;
  flex-grow: 1;
`;
