import { accountSetupInProgress, hasValidLoginToken } from 'config/auth';
import { hideHelpWidget, showHelpWidget } from 'config/integrations';
import {
  changePassword,
  configuration,
  customers,
  demo,
  login,
  settings,
  setUpAccount,
  overview,
  customerDetail,
  coverage,
} from 'config/routes';
import { Navigate, matchRoutes, useLocation } from 'react-router';

export type Props = {
  children: JSX.Element;
};

const authenticatedRoutes: string[] = [
  changePassword,
  demo,
  settings,
  configuration,
  customers,
  overview,
];

export const looselyMatches = (path: string, route: string): boolean => {
  return path.includes(route) || !!matchRoutes([{ path: route }], path)?.length;
};

const showHelpWidgetRoutes: string[] = [
  settings,
  configuration,
  customers,
  overview,
  customerDetail,
  coverage,
];

export const getShouldShowHelpWidget = (pathname: string) =>
  showHelpWidgetRoutes.some(route => looselyMatches(pathname, route));

export const RouteGuard = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const location = useLocation();
  const requiresAuth = authenticatedRoutes.some(route =>
    looselyMatches(location.pathname, route)
  );
  if (requiresAuth && !hasValidLoginToken()) {
    return (
      <Navigate
        to={`${login}?expired=true&next=${location.pathname}`}
        replace
      />
    );
  }

  if (
    accountSetupInProgress() &&
    !looselyMatches(location.pathname, setUpAccount)
  ) {
    return <Navigate to={setUpAccount} replace />;
  }

  if (getShouldShowHelpWidget(location.pathname)) {
    showHelpWidget();
  } else {
    hideHelpWidget();
  }

  return children;
};
