import { gql } from '@apollo/client';

export const UPDATE_TENANT_LOGO = gql`
  mutation updateTenantLogo($input: UpdateTenantLogoInput!) {
    updateTenantLogo(input: $input) {
      tenant {
        id
        name
        logoFilename
        logoUrl
      }
    }
  }
`;

export const generateUpdateTenantLogo = (): UpdateTenantLogoMutation => ({
  updateTenantLogo: {
    tenant: {
      id: '1',
      logoFilename: 'abc123.svg',
      logoUrl:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png',
      name: 'My Company',
    },
  },
});
