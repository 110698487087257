import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  ColumnDef,
  CellContext,
  HeaderContext,
  SortingState,
  Row,
  OnChangeFn,
} from '@tanstack/react-table';
import { copyFor } from 'config/copy';
import { isNumber, isString } from '@arcadiapower/warbler';
import {
  PaginationControls,
  Props as PaginationControlsProps,
} from 'components/pagination-controls';
import { Alert, Skeleton } from '@arcadiapower/shrike';
import { parseErrorMessage } from 'config/errors';
import { ReactNode, useEffect } from 'react';
import { track, TrackEvents } from 'utils/analytics';
import { useMobile } from 'hooks/use-mobile.hook';
import deepmerge from 'deepmerge';
import {
  TableControlsWrapper,
  TableHeader,
  TableHeaderGroup,
  TableRow,
  TableHeaderText,
  TableHeaderInternal,
  StyledTable,
  TableBody,
  TableText,
  TableWrapper,
  TableCell,
  TableInfoContainer,
  NoResultsText,
  NoResultsWrapper,
  StyledIcon,
  TableHeaderButton,
} from './table.style';

export type Props<TData extends Object> = {
  // An any is needed here because the createColumnHelpers causes type issues
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: ColumnDef<TData, any>[];
  /**
   * minWidth can be a string or an array of strings.
   * A string applies to all widths.
   * An array of one value applies to mobile only.
   * An array of two values has the first value apply to mobile and the second
   * value apply to tablet and desktop.
   * An array of three values has the first value apply to mobile, the second
   * one apply to tablet, and the third one apply to desktop.
   */
  minWidth?: string | string[];
  data?: TData[];
  defaultColumn?: Partial<ColumnDef<TData>>;
  loading?: boolean;
  error?: Error;
  renderFilters?: () => ReactNode;
  onRowInteraction?: (row: TData) => void;
  paginationProps?: Pick<
    PaginationControlsProps,
    | 'handlePaginationBackwards'
    | 'handlePaginationForwards'
    | 'totalCount'
    | 'pageSize'
    | 'pageInfo'
    | 'page'
  >;
  sortingProps?: {
    sortingState: SortingState;
    setSortingState: OnChangeFn<SortingState>;
  };
  name?: string;
  maxHeight?: string;
  sticky?: boolean;
};

export const DefaultCell = <TData,>(props: CellContext<TData, unknown>) => {
  const value = props.getValue();
  const shouldDisplayValue = isNumber(value) || (isString(value) && value);
  const displayValue = shouldDisplayValue ? value : getCopy('noValue');
  const overflow = props.column.columnDef.meta?.overflow;
  return (
    <TableText
      color={value ? 'primary' : 'secondary'}
      $overflow={overflow}
      $textAlign={props.column.columnDef.meta?.horizontalAlign}
    >
      {displayValue}
    </TableText>
  );
};

export const DefaultHeader = <TData,>(props: HeaderContext<TData, unknown>) => {
  const horizontalAlign = props.column.columnDef.meta?.horizontalAlign;
  const value = props.column.id;
  const sortDirection = props.column.getIsSorted(); // Is null if not sorted
  const isSorted = !!sortDirection;
  const canSort = props.column.getCanSort();
  const nextSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';
  const columnTitle = props.column.id;

  const convertAlignToJustify = (
    horizontalAlign: 'left' | 'right' | 'center'
  ) => {
    if (horizontalAlign === 'right') return 'flex-end';
    if (horizontalAlign === 'center') return 'center';
    else return 'flex-start';
  };

  const justifyContent = horizontalAlign
    ? convertAlignToJustify(horizontalAlign)
    : undefined;

  const renderSortIcon = () => {
    let icon: 'ArrowUp' | 'ArrowDown' = 'ArrowUp';
    if (isSorted && sortDirection === 'asc') icon = 'ArrowDown';
    return (
      <StyledIcon
        data-testid="sort-icon"
        icon={icon}
        $isSorted={isSorted}
        color="primary"
      />
    );
  };

  const handleOnClick = () => {
    const sortDescending = sortDirection === 'asc';
    track(TrackEvents.TABLE_SORTED, {
      tableName: props.table.options.meta?.name,
      column: columnTitle,
      sort: nextSortDirection,
    });
    props.column.toggleSorting(sortDescending);
  };

  return canSort ? (
    <TableHeaderButton
      onClick={handleOnClick}
      name={value}
      $isSorted={isSorted}
      aria-label={`Sort by ${value}, ${nextSortDirection}`}
      $justifyContent={justifyContent}
    >
      <TableHeaderText>{value}</TableHeaderText>
      {renderSortIcon()}
    </TableHeaderButton>
  ) : (
    <TableHeaderInternal $justifyContent={justifyContent}>
      <TableHeaderText>{value}</TableHeaderText>
    </TableHeaderInternal>
  );
};

const arcadiaDefaultColumn = {
  cell: DefaultCell,
  header: DefaultHeader,
  enableHiding: false,
};

const getCopy = copyFor('components.table');
/**
 * Note if you add more fields to the meta, change typings/tanstack-table
 */
export const Table = <TData extends Object>({
  columns,
  data,
  defaultColumn = {},
  renderFilters,
  minWidth,
  paginationProps,
  sortingProps,
  loading,
  error,
  onRowInteraction,
  name,
  maxHeight,
  sticky,
}: Props<TData>): JSX.Element => {
  const isMobile = useMobile();
  const table = useReactTable({
    data: data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting: sortingProps?.sortingState,
    },
    enableSorting: sortingProps !== undefined,
    onSortingChange: sortingProps?.setSortingState,
    defaultColumn: deepmerge(arcadiaDefaultColumn, defaultColumn),
    meta: {
      name,
      maxHeight,
      sticky,
    },
  });

  useEffect(() => {
    table.toggleAllColumnsVisible(!isMobile);
  }, [table, isMobile]);

  const renderTableControls = () => {
    if ((!paginationProps && !renderFilters) || error) return;
    return (
      <TableControlsWrapper renderFilters={!!renderFilters}>
        {renderFilters?.()}
        {paginationProps && (
          <PaginationControls loading={!!loading} {...paginationProps} />
        )}
      </TableControlsWrapper>
    );
  };

  const getRowActionProps = (row: Row<TData>) => {
    if (!onRowInteraction) return {};
    return {
      tabIndex: 0,
      onClick: () => onRowInteraction(row.original),
      onKeyDown: (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
          onRowInteraction(row.original);
        }
      },
    };
  };

  const renderTableBody = () => {
    if (error) return;
    if (data) {
      return table.getRowModel().rows.map(row => {
        const rowData = row.original;
        const testId = 'id' in rowData ? rowData.id : undefined;

        return (
          <TableRow
            key={row.id}
            data-testid={testId}
            {...getRowActionProps(row)}
          >
            {row.getVisibleCells().map(cell => (
              <TableCell
                key={cell.id}
                $overflow={cell.column.columnDef.meta?.overflow}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        );
      });
    }

    if (loading)
      return new Array(5).fill(0).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TableRow key={index}>
          {table.getAllColumns().map(column => (
            <TableCell key={column.id}>
              <Skeleton data-testid="table-loading" />
            </TableCell>
          ))}
        </TableRow>
      ));
  };

  const renderTableFailureStates = () => {
    if (error)
      return (
        <TableInfoContainer>
          <Alert>{parseErrorMessage(error)}</Alert>
        </TableInfoContainer>
      );
    if (data?.length === 0)
      return (
        <NoResultsWrapper>
          <NoResultsText>{getCopy('noResults')}</NoResultsText>
        </NoResultsWrapper>
      );
  };

  if (table.options.meta?.sticky && !maxHeight) maxHeight = '650px';

  return (
    <>
      {renderTableControls()}
      <TableWrapper $maxHeight={maxHeight}>
        <StyledTable $minWidth={minWidth || '1000px'}>
          <TableHeaderGroup $sticky={table.options.meta?.sticky}>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id} $header={true}>
                {headerGroup.headers.map(header => (
                  <TableHeader
                    key={header.id}
                    $width={header.column.columnDef.meta?.width}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableHeader>
                ))}
              </TableRow>
            ))}
          </TableHeaderGroup>
          <TableBody>{renderTableBody()}</TableBody>
        </StyledTable>
        {renderTableFailureStates()}
      </TableWrapper>
    </>
  );
};
