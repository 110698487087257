import { gql } from '@apollo/client';

export const SIGN_UP_TENANT_USER = gql`
  mutation signUpTenantUser($input: SignUpTenantUserInput!) {
    signUpTenantUser(input: $input) {
      accessToken {
        expiresAt
        token
      }
      resourceOwner {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const generateSignUpTenantUser = (): SignUpTenantUserMutation => ({
  signUpTenantUser: {
    accessToken: {
      expiresAt: '2021-10-14 19:27:28 -0400',
      token: 'myToken',
    },
    resourceOwner: {
      email: 'blah@gmail.com',
      firstName: 'Bob',
      id: '2',
      lastName: 'Lawblaw',
    },
  },
});
