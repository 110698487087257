import styled from 'styled-components';

export const DemoDescriptionWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  max-width: 750px;
  padding: 12px 0 36px;
`;

export const DemoDescriptionImage = styled.img`
  height: 55px;
  width: 55px;
`;
