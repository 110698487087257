import { NavBarItem } from 'components/nav-bar-item';
import styled from 'styled-components';

export const Wrapper = styled.ul`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  margin: 0;
  max-width: 290px;
`;
export const ListItem = styled.li`
  height: 3.5rem;
`;
export const SideNavBarItem = styled(NavBarItem)`
  border-bottom: 2px solid ${props => props.theme.colors.background.tertiary};
`;
