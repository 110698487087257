import { gql } from '@apollo/client';

export const GET_WEBHOOK_ENDPOINTS = gql`
  query getWebhookEndpoints {
    resourceOwner {
      id
      tenant {
        id
        webhookEndpoints {
          createdAt
          enabled
          signingKey
          id
          url
          sandboxed
        }
      }
    }
  }
`;

export const generateWebhookEndpointsQuery = ({
  sandboxed,
}: {
  sandboxed: boolean;
}): GetWebhookEndpointsQuery => ({
  resourceOwner: {
    id: '25',
    tenant: {
      id: '12097',
      webhookEndpoints: [
        {
          createdAt: '2022-08-01',
          enabled: true,
          signingKey: 'a1HGEoZIP1YypDuM',
          id: sandboxed
            ? '9bddf027-a72f-4c6a-a9a3-45499838e0d5'
            : '4bddf027-a72f-4c6a-a9a3-45499838e0d5',
          url: sandboxed
            ? 'https://www.sandboxedOne.com'
            : 'https://www.liveOne.com',
          sandboxed,
        },
        {
          createdAt: '2022-08-01',
          enabled: false,
          signingKey: 'a1HGEoZIP1YypDuM',
          id: sandboxed
            ? '9bddf027-a72f-4c6a-a9a3-00099987e0d5'
            : '4bddf027-a72f-4c6a-a9a3-00099987e0d5',
          url: sandboxed
            ? 'https://www.sandboxedTwo.com'
            : 'https://www.liveTwo.com',
          sandboxed,
        },
      ],
    },
  },
});
