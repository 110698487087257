import {
  Wrapper,
  PromptIcon,
  PromptTitle,
  PromptLink,
  PromptDescription,
  PromptLinkIcon,
} from './prompt.style';

export type Props = {
  className?: string;
  title: string;
  icon: string;
  route: string;
  description: string;
};

export const Prompt = ({
  className,
  title,
  icon,
  route,
  description,
}: Props): JSX.Element => {
  return (
    <Wrapper className={className}>
      <PromptIcon src={icon} alt={title} />
      <PromptLink to={route}>
        <PromptTitle>{title}</PromptTitle>
        <PromptLinkIcon />
      </PromptLink>
      <PromptDescription>{description}</PromptDescription>
    </Wrapper>
  );
};
