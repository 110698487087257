import { Text, Modal } from '@arcadiapower/shrike';

export type Props = {
  copy: {
    title: string;
    description: string;
    cta: string;
  };
  loading?: boolean;
  onConfirmation: () => void;
  onClose: () => void;
  errorMessage?: string;
  variant?: 'primaryInverse' | 'errorDark';
};

export const ActionConfirmationModal = ({
  copy,
  loading,
  errorMessage,
  onConfirmation,
  onClose,
  variant = 'primaryInverse',
}: Props): JSX.Element => {
  const handleModalClose = loading ? undefined : onClose;
  return (
    <Modal aria-label={copy.title} data-testid="modal-container">
      <Modal.Header onClose={handleModalClose} title={copy.title} />
      <Modal.Content>
        <Text color="secondary">{copy.description}</Text>
      </Modal.Content>
      <Modal.FooterStacked
        error={errorMessage}
        onSubmit={onConfirmation}
        primaryText={copy.cta}
        primaryBackgroundColor={variant}
        loading={loading}
      />
    </Modal>
  );
};
