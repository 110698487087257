import { SetStateAction, Dispatch } from 'react';
import { Tooltip, Icon, Text } from '@arcadiapower/shrike';
import { getErrorCopy } from 'config/copy';
import {
  TableTitleRow,
  TableTitleWrapper,
  TableTitle,
  TableSubTitle,
  DataTableAlert,
  LoadingContainer,
  LoadingSpinner,
} from './data-table.style';
import {
  APIExplorerButton,
  APIExplorerButtonType,
} from '../api-explorer-button';

export type Props = {
  title: string;
  apiExplorerButtonEnabled: boolean;
  apiExplorerButtonType?: APIExplorerButtonType;
  error?: unknown;
  warning?: string;
  helperText?: string;
  subTitle?: string;
  loading: boolean;
  loadingString?: string;
  showAPIData: boolean;
  setShowAPIData: Dispatch<SetStateAction<boolean>>;
};

export const DataTableHeader = ({
  title,
  apiExplorerButtonEnabled,
  apiExplorerButtonType = 'table',
  error,
  warning,
  helperText,
  subTitle,
  loading,
  loadingString,
  showAPIData,
  setShowAPIData,
}: Props): JSX.Element => {
  const renderLoading = () => {
    // If there is no loading string, we expect a loading state
    // to be covered by the table itself
    if (!loadingString) return;
    return (
      <LoadingContainer data-testid="loading-data-table">
        <LoadingSpinner />
        <Text color="secondary">{loadingString}</Text>
      </LoadingContainer>
    );
  };

  return (
    <TableTitleRow>
      <TableTitleWrapper>
        <TableTitle>{title}</TableTitle>
        {helperText && (
          <Tooltip
            content={helperText}
            tooltipId={`${title}-data-table-helper-text`}
            label={`${title} helper text`}
            maxWidth={300}
            data-testid="data-table-helper-text"
          >
            <Icon icon="InfoCircleInverted"></Icon>
          </Tooltip>
        )}
        {subTitle && <TableSubTitle>{subTitle}</TableSubTitle>}
        {error && (
          <DataTableAlert>{getErrorCopy('genericDemo')}</DataTableAlert>
        )}
        {warning && (
          <DataTableAlert variant="warning">{warning}</DataTableAlert>
        )}
        {loading && renderLoading()}
      </TableTitleWrapper>

      <APIExplorerButton
        disabled={!apiExplorerButtonEnabled}
        showAPI={showAPIData}
        setShowAPI={setShowAPIData}
        type={apiExplorerButtonType}
      />
    </TableTitleRow>
  );
};
