import styled from 'styled-components';

export const Wrapper = styled.div`
  border: ${props => props.theme.colors.background.tertiary} 1px solid;
  border-radius: 8px;
`;

export const AccountsContent = styled.div`
  padding: 48px 24px 64px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  // the 7F here is what adds the opacity. for more info
  // see the second answer here: https://stackoverflow.com/questions/7015302/css-hexadecimal-rgba
  background-color: ${props => `${props.theme.colors.background.secondary}7F`};
`;
