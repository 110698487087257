import styled from 'styled-components';
import { marginStyles, Margin } from 'styles/margin.style';

export const Wrapper = styled.label<{ margin?: Margin }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${props => marginStyles(props.margin)}
`;

export const CTAWrapper = styled.div`
  margin: 0 0.5rem;
  white-space: nowrap;
`;
