import { useMemo } from 'react';
import { Select } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { Wrapper } from './filters.style';

const getCopy = copyFor('customers.table.filters');

export interface Props {
  value?: GetTenantUtilityAccountsQueryVariables['filters'];
  onChange: (
    filters: GetTenantUtilityAccountsQueryVariables['filters']
  ) => void;
}

export const Filters = ({ value = {}, onChange }: Props) => {
  const onAccountStatusChange = useMemo(
    () => handleChange({ key: 'utilityAccountStatus', value, onChange }),
    [value, onChange]
  );
  const onCredentialStatusChange = useMemo(
    () => handleChange({ key: 'utilityCredentialStatus', value, onChange }),
    [value, onChange]
  );

  return (
    <Wrapper>
      <Select
        label={getCopy('accountStatus')}
        name="account-status"
        value={value?.utilityAccountStatus?.[0] || ''}
        onChange={onAccountStatusChange}
        options={accountStatusOptions}
      />
      <Select
        label={getCopy('credentialStatus')}
        name="credential-status"
        value={value?.utilityCredentialStatus?.[0] || ''}
        onChange={onCredentialStatusChange}
        options={credentialStatusOptions}
      />
    </Wrapper>
  );
};

interface HandleChangeParam extends Props {
  key: keyof UtilityAccountFilter;
}
const handleChange =
  ({ key, value, onChange }: HandleChangeParam) =>
  (status: string) => {
    const update = { ...value };
    if (status && status !== 'All')
      // shrike sets option value to text if value is falsey
      update[key] = [status] as
        | UtilityCredentialStatusEnum[] & UtilityAccountStatusEnum[];
    else delete update[key];
    onChange(update);
  };

export const UTILITY_ACCOUNT_STATUSES: Record<
  string,
  UtilityAccountStatusEnum
> = {
  active: 'ACTIVE',
  incomplete: 'INCOMPLETE',
  closed: 'CLOSED',
  locked: 'LOCKED',
};
const accountStatusOptions = [
  { text: 'All', value: '' },
  { text: 'Active', value: UTILITY_ACCOUNT_STATUSES.active },
  { text: 'Incomplete', value: UTILITY_ACCOUNT_STATUSES.incomplete },
  { text: 'Closed', value: UTILITY_ACCOUNT_STATUSES.closed },
  { text: 'Locked', value: UTILITY_ACCOUNT_STATUSES.locked },
];
export const UTILITY_CREDENTIAL_STATUSES: Record<
  string,
  UtilityCredentialStatusEnum
> = {
  verified: 'VERIFIED',
  pending: 'PENDING',
  error: 'ERROR',
  rejected: 'REJECTED',
};
const credentialStatusOptions = [
  { text: 'All', value: '' },
  { text: 'Verified', value: UTILITY_CREDENTIAL_STATUSES.verified },
  { text: 'Pending', value: UTILITY_CREDENTIAL_STATUSES.pending },
  { text: 'Error', value: UTILITY_CREDENTIAL_STATUSES.error },
  { text: 'Rejected', value: UTILITY_CREDENTIAL_STATUSES.rejected },
];
