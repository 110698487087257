import { Page } from 'styles';
import { NavBarAuthed } from 'components/nav-bar';
import { Outlet } from 'react-router-dom';

export const Dashboard = (): JSX.Element => {
  return (
    <Page>
      <NavBarAuthed />
      <Outlet />
    </Page>
  );
};
