import { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { copyFor, getErrorCopy } from 'config/copy';
import { PageContent } from 'styles';
import { Alert, Skeleton } from '@arcadiapower/shrike';
import { Prompt } from 'components/overview/prompt';
import { Resource } from 'components/overview/resource';
import { GET_OVERVIEW_USER } from './overview.api';
import { getPrompts, RESOURCES } from './overview.util';
import {
  Section,
  WelcomeContainer,
  WelcomeSubtitle,
  WelcomeTitle,
} from './overview.style';

const getCopy = copyFor('overview');

export const Overview = (): JSX.Element => {
  const { data, error, loading } = useQuery<
    GetOverviewUserQuery,
    GetOverviewUserQueryVariables
  >(GET_OVERVIEW_USER);

  const renderWelcome = useCallback(
    ({ name, setUp }: { name?: Maybe<string>; setUp: boolean }) => {
      const accountStatus = setUp ? 'setUp' : 'preSetUp';

      return (
        <WelcomeContainer data-testid="welcome">
          <WelcomeTitle>
            {getCopy(`welcome.${accountStatus}.title`, {
              name: name ?? getCopy('welcome.defaultName'),
            })}
          </WelcomeTitle>
          <WelcomeSubtitle>
            {getCopy(`welcome.${accountStatus}.subtitle`)}
          </WelcomeSubtitle>
        </WelcomeContainer>
      );
    },
    []
  );

  const renderSections = useCallback(
    ({ uploadedLogo }: { uploadedLogo: boolean }) => {
      const prompts = getPrompts({ uploadedLogo });
      return (
        <div>
          <Section>
            {prompts.map(prompt => (
              <Prompt {...prompt} key={prompt.title} />
            ))}
          </Section>
          <Section>
            {RESOURCES.map(resource => (
              <Resource {...resource} key={resource.title} />
            ))}
          </Section>
        </div>
      );
    },
    []
  );

  const renderOverview = useCallback(() => {
    if (data?.resourceOwner) {
      const name = data.resourceOwner.firstName;
      const uploadedLogo = !!data.resourceOwner.tenant.logoUrl;
      return (
        <>
          {renderWelcome({ name, setUp: uploadedLogo })}
          {renderSections({ uploadedLogo })}
        </>
      );
    }
  }, [data, renderWelcome, renderSections]);

  const Body = useMemo(() => {
    if (loading) return <Skeleton height="100%" width="100%" />;
    if (error) return <Alert>{getErrorCopy('generic')}</Alert>;
    return renderOverview();
  }, [renderOverview, loading, error]);

  return <PageContent>{Body}</PageContent>;
};
