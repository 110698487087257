import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 1.5rem;
`;

export const ListItem = styled.li``;

export const StatementList = styled.ol`
  display: flex;
  flex-flow: column;
  gap: 1.5rem;
`;

export const StatementTimelineHeader = styled.header`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`;

export const BillingEnrollmentStatus = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const EmptyState = styled.div`
  padding: 1.5rem;
  background: ${props => props.theme.colors.background.secondary};
  border-radius: ${props => props.theme.borderRadii.large};
`;
