import { formatDate, formatDateEST, capitalize } from '@arcadiapower/warbler';
import { PaymentTransactionForTimeline } from 'components/customer-detail/statement-timeline/statement-timeline.api';
import { TableSection } from 'styles/table.style';
import { DataRow } from 'components/payment-timeline/data-row';
import { copyFor } from 'config/copy';
import {
  SectionHeader,
  Column,
  DataContainer,
  HeaderIcon,
  HeaderPrimary,
  SectionTitle,
} from '../sections.style';

const getCopy = copyFor(
  'components.paymentTimeline.sections.paymentTransaction'
);

export type Props = {
  paymentTransaction: PaymentTransactionForTimeline;
};

export const PaymentTransaction = ({
  paymentTransaction,
}: Props): JSX.Element => {
  return (
    <TableSection data-testid={`payment-transaction-${paymentTransaction.id}`}>
      <SectionHeader>
        <HeaderPrimary>
          <HeaderIcon icon="CreditCard" />
          <SectionTitle>{getCopy('title')}</SectionTitle>
        </HeaderPrimary>
      </SectionHeader>
      <DataContainer>
        <Column>
          <DataRow value={paymentTransaction.id} label={getCopy('fields.id')} />
          <DataRow
            value={paymentTransaction.paymentMethodId}
            label={getCopy('fields.paymentMethodId')}
          />
        </Column>
        <Column>
          <DataRow
            value={paymentTransaction.status}
            label={getCopy('fields.status')}
            formatter={capitalize}
          />
          <DataRow
            value={new Date(paymentTransaction.createdAt)}
            label={getCopy('fields.chargedAt')}
            formatter={formatDate}
          />
          <DataRow
            value={new Date(paymentTransaction.finishedAt)}
            label={getCopy('fields.finishedAt')}
            formatter={formatDateEST}
          />
        </Column>
      </DataContainer>
    </TableSection>
  );
};
