import { useState } from 'react';
import { Alert } from '@arcadiapower/shrike';
import { isValidPassword } from 'utils/validators';
import { copyFor } from 'config/copy';
import {
  Form,
  PrimaryButton,
  ActionWrapper,
} from 'styles/single-content.style';
import { NewPasswordInput } from 'components/new-password-input';

export type ChangePasswordParams = { password: string };

export interface Props {
  className?: string;
  onSubmit: (params: ChangePasswordParams) => void;
  pending: boolean;
  errorMessage?: string;
}

const getCopy = copyFor('forms.changePassword');

export const ChangePassword = ({
  onSubmit,
  className,
  errorMessage,
  pending,
}: Props): JSX.Element => {
  const [password, setPassword] = useState<string>('');
  const canSubmit = isValidPassword(password);

  const handleSubmit = () => {
    onSubmit({ password });
  };

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <NewPasswordInput
        onChange={setPassword}
        value={password}
        margin={{ bottom: '24px', top: '24px' }}
      />
      {errorMessage && <Alert>{errorMessage}</Alert>}
      <ActionWrapper>
        <PrimaryButton
          type="submit"
          aria-label={getCopy('submit')}
          loading={pending}
          disabled={!canSubmit}
        >
          {getCopy('submit')}
        </PrimaryButton>
      </ActionWrapper>
    </Form>
  );
};
