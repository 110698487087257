import styled from 'styled-components';
import { Input } from '@arcadiapower/shrike';
import { marginStyles } from 'styles/margin.style';

export const InputWithConditionalColorHelperText = styled(Input)<{
  isValid?: boolean;
}>`
  ${props => marginStyles(props.margin)}
  p:last-child {
    color: ${props =>
      props.isValid
        ? props.theme.colors.content.success
        : props.theme.colors.content.secondary};
  }
`;

export const InputWithHidingHelperText = styled(
  InputWithConditionalColorHelperText
)`
  p:last-child {
    aria-hidden: ${props => (props.isValid ? 'true' : 'false')};
    display: ${props => (props.isValid ? 'none' : 'block')};
    color: ${props => props.theme.colors.content.secondary};
  }
`;
