import { GOOGLE_ANALYTICS_ID } from 'config/constants';
import { captureError } from 'utils/analytics';
/* eslint-disable */

window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', GOOGLE_ANALYTICS_ID);

const initGoogleAnalytics = () => {
  if (GOOGLE_ANALYTICS_ID) {
    const gaScript = document.createElement('script');
    gaScript.type = 'text/javascript';
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`;
    gaScript.async = true;
    // Insert our script in the head
    document.head.appendChild(gaScript);
  }
};

// Safely init Google Analytics, so that if anything weird happens,
// we do not crash the application
try {
  initGoogleAnalytics();
} catch (e) {
  captureError(e);
}
