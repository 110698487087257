import { UtilityRemittanceLineItemForTimeline } from 'components/customer-detail/statement-timeline/statement-timeline.api';
import { TableSection } from 'styles/table.style';
import { DataRow } from 'components/payment-timeline/data-row';
import { lightning } from 'assets/icons/paths';
import { copyFor } from 'config/copy';
import { formatDateEST, formatAsMoney } from '@arcadiapower/warbler';
import {
  SectionHeader,
  Column,
  DataContainer,
  HeaderIcon,
  HeaderPrimary,
  SectionTitle,
} from '../sections.style';

const getCopy = copyFor(
  'components.paymentTimeline.sections.utilityRemittanceLineItem'
);

export type Props = {
  utilityRemittanceLineItem: UtilityRemittanceLineItemForTimeline;
};

export const UtilityRemittanceLineItem = ({
  utilityRemittanceLineItem,
}: Props): JSX.Element => {
  return (
    <TableSection
      data-testid={`utility-remittance-${utilityRemittanceLineItem.id}`}
    >
      <SectionHeader>
        <HeaderPrimary>
          <HeaderIcon path={lightning} />
          <SectionTitle>{getCopy('title')}</SectionTitle>
        </HeaderPrimary>
      </SectionHeader>
      <DataContainer>
        <Column>
          <DataRow
            value={utilityRemittanceLineItem.id}
            label={getCopy('fields.id')}
          />
          <DataRow
            value={new Date(utilityRemittanceLineItem.updatedAt)}
            label={getCopy('fields.updatedAt')}
            formatter={formatDateEST}
          />
          <DataRow
            value={new Date(utilityRemittanceLineItem.createdAt)}
            label={getCopy('fields.createdAt')}
            formatter={formatDateEST}
          />
        </Column>
        <Column>
          <DataRow
            value={formatAsMoney(utilityRemittanceLineItem.amountCents, {
              conversion: 'fromCents',
            })}
            label={getCopy('fields.amountCents')}
          />
          <DataRow
            value={utilityRemittanceLineItem.name}
            label={getCopy('fields.name')}
          />
          <DataRow
            value={utilityRemittanceLineItem.type}
            label={getCopy('fields.type')}
          />
        </Column>
      </DataContainer>
    </TableSection>
  );
};
