import { store } from 'config/store';
import * as analytics from 'utils/analytics';
import { history } from 'browser-history';
import {
  AUTH_KEY,
  CURRENT_USER_KEY,
  ACCOUNT_SETUP_IN_PROGRESS,
  SSO_PATH,
} from './constants';
import { login } from './routes';
import { client } from './apollo-client';
import { gql } from '@apollo/client';

export const LOG_OUT_TENANT_USER = gql`
  mutation logOutTenantUser($input: LogOutTenantUserInput!) {
    logOutTenantUser(input: $input) {
      success
    }
  }
`;

export const logout = ({ expired = false, navigateToLogin = true } = {}) => {
  const token = store.get(AUTH_KEY);
  if (token) {
    client.mutate({
      mutation: LOG_OUT_TENANT_USER,
      variables: { input: { token: token } },
    });
  }
  store.remove(CURRENT_USER_KEY);
  store.remove(AUTH_KEY);
  store.remove(ACCOUNT_SETUP_IN_PROGRESS);
  // TODO: includes the "return" value here, if applicable:
  // Ex. http:/arcadia.com/login?expired=true&next=/path-to-attempted-url
  analytics.reset();
  client.cache.reset();
  if (navigateToLogin) {
    let destination = login;
    if (expired) {
      destination += `?expired=true`;
    }
    history.push(destination);
  }
};

type UserToIdentify = {
  id: string;
  email: string;
  status?: string;
};

export const authenticate = (
  accessToken: string,
  expiresAt: string,
  {
    navigateTo,
    accountSetupInProgress = false,
    resourceOwner,
  }: {
    navigateTo?: string;
    accountSetupInProgress?: boolean;
    resourceOwner?: UserToIdentify;
  } = {}
) => {
  client.cache.reset();
  const expiredAtTime = new Date(expiresAt).getTime();
  store.set(AUTH_KEY, accessToken, expiredAtTime);
  store.set(ACCOUNT_SETUP_IN_PROGRESS, accountSetupInProgress, expiredAtTime);

  if (resourceOwner) {
    analytics.identify(resourceOwner);
  }

  if (navigateTo) {
    history.push(navigateTo);
  }
};

export const setPathForSSORedirect = (path: string | null) => {
  if (!path) {
    store.remove(SSO_PATH);
  } else {
    const TWO_HOURS = 60 * 60 * 1000 * 2;
    store.set(SSO_PATH, path, Date.now() + TWO_HOURS);
  }
};

export const getPathForSSORedirect = (): string | null => {
  return store.get('sso_path');
};

export const hasValidLoginToken = (): boolean => !!store.get(AUTH_KEY);
export const accountSetupInProgress = (): boolean =>
  !!store.get(ACCOUNT_SETUP_IN_PROGRESS);
