import { isString } from '@arcadiapower/warbler';

export const ENVIRONMENT_ENDPOINTS = {
  staging: {
    ARC_API_BASE_URL: 'https://staging.api.arcadia.com',
    DEMO_CONNECT_ENV: 'staging',
    GRAPHQL_URL: 'https://staging.api.arcadia.com/api/enterprise/graphql',
  },
  local: {
    ARC_API_BASE_URL: 'http://api.arcadia.local:3000',
    DEMO_CONNECT_ENV: 'local',
    GRAPHQL_URL: 'http://api.arcadia.local:3000/api/enterprise/graphql',
  },
  localSecure: {
    ARC_API_BASE_URL: 'https://api.arcadia.local:3200',
    DEMO_CONNECT_ENV: 'localSecure',
    GRAPHQL_URL: 'https://api.arcadia.local:3200/api/enterprise/graphql',
  },
  production: {
    ARC_API_BASE_URL: 'https://api.arcadia.com',
    DEMO_CONNECT_ENV: 'sandbox',
    GRAPHQL_URL: 'https://api.arcadia.com/api/enterprise/graphql',
  },
} as const;

const checkEnvForBoolean = (value?: string) => value?.toUpperCase() === 'TRUE';

export const getAPIEnvironment = (): keyof typeof ENVIRONMENT_ENDPOINTS => {
  const rawValue = import.meta.env.ARCADIA_API_ENVIRONMENT;
  if (isString(rawValue) && rawValue in ENVIRONMENT_ENDPOINTS)
    return rawValue as keyof typeof ENVIRONMENT_ENDPOINTS;
  return 'local';
};

const API_ENVIRONMENT = getAPIEnvironment();

export const { GRAPHQL_URL, ARC_API_BASE_URL, DEMO_CONNECT_ENV } =
  ENVIRONMENT_ENDPOINTS[API_ENVIRONMENT];

export const APOLLO_LOGGER = checkEnvForBoolean(
  import.meta.env.ARCADIA_APOLLO_LOGGER
);

export const AUTH_KEY = 'auth_token';
export const CURRENT_USER_KEY = 'current_user';
export const CUSTOMERS_QUERY_PARAMS_KEY = 'customer_query_params';
export const ACCOUNT_SETUP_IN_PROGRESS = 'account_setup_in_progress';
export const SSO_PATH = 'sso_path';

// The default value is the developer environment segment key
export const SEGMENT_KEY =
  import.meta.env.ARCADIA_SEGMENT_KEY ?? 'rBOYCjWi18uukOQKJDJUEGAbTA9GTQv1';
export const SENTRY_DSN =
  import.meta.env.ARCADIA_SENTRY_DSN ??
  'https://examplePublicKey@o0.ingest.sentry.io/0';

export const SENTRY_RELEASE = import.meta.env.ARCADIA_SENTRY_RELEASE;

export const APP_BUILD_ENV = import.meta.env.MODE ?? 'development';
export const CUSTOMERS_PAGE_SIZE = 50;
export const COVERAGE_PAGE_SIZE = 25;
export const IS_TEST = APP_BUILD_ENV === 'test';
export const IS_PROD = APP_BUILD_ENV === 'production';
export const DEBUG_ANALYTICS = checkEnvForBoolean(
  import.meta.env.ARCADIA_DEBUG_ANALYTICS
);

export const GOOGLE_ANALYTICS_ID = import.meta.env.ARCADIA_GOOGLE_ANALYTICS_ID;
