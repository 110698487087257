import { copyFor } from 'config/copy';
import { Text } from '@arcadiapower/shrike';
import {
  Header,
  SectionWrapper,
  PaymentMethodWrapper,
  NoPaymentMethodsWrapper,
} from './payment-methods.style';
import { CreditCard } from './credit-card';
import { AchTransfer } from './ach-transfer';

const getCopy = copyFor('components.paymentMethods');

export type Props = {
  paymentMethods?: PaymentMethod[] | null;
};

export const PaymentMethods = ({ paymentMethods }: Props): JSX.Element => {
  const renderPaymentMethodsList = () => {
    if (!paymentMethods || paymentMethods.length < 1) {
      return (
        <NoPaymentMethodsWrapper>
          <Text color="secondary">{getCopy('noPaymentMethods')}</Text>
        </NoPaymentMethodsWrapper>
      );
    }

    return paymentMethods.map(method => {
      switch (method.paymentSource?.__typename) {
        case 'CreditCardPayment':
          return (
            <CreditCard
              isDefault={method.default || false}
              isExpired={method.expired}
              key={method.paymentSource.id}
              paymentSource={method.paymentSource}
            />
          );
        case 'ACHPayment':
          return (
            <AchTransfer
              key={method.paymentSource.id}
              isDefault={method.default || false}
              paymentSource={method.paymentSource}
            />
          );
        default:
          return null;
      }
    });
  };

  return (
    <SectionWrapper>
      <Header>{getCopy('header')}</Header>
      <PaymentMethodWrapper>{renderPaymentMethodsList()}</PaymentMethodWrapper>
    </SectionWrapper>
  );
};
