import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import { store } from 'config/store';
import { GRAPHQL_URL, AUTH_KEY, APOLLO_LOGGER } from './constants';
import { onError } from '@apollo/client/link/error';
import apolloLogger from 'apollo-link-logger';
import { logout } from './auth';
import { GraphQLError } from 'graphql';
import { captureError } from 'utils/analytics/error-reporting';
import { NetworkError } from '@apollo/client/errors';
import { Extras } from '@sentry/types';

const httpLink = new HttpLink({
  headers: {
    Accept: 'application/json',
  },
  uri: GRAPHQL_URL,
});

const UNAUTHORIZED_MESSAGES = [
  'was hidden due to permissions',
  'The provided token has expired',
];

const isUnauthorizedErrorMessage = (errorMessage: string) =>
  UNAUTHORIZED_MESSAGES.some(unauthorizedMessageText =>
    errorMessage.includes(unauthorizedMessageText)
  );

const flattenApolloErrors = (
  graphQLErrors?: readonly GraphQLError[],
  networkError?: NetworkError
) => {
  const result: NonNullable<GraphQLError | NetworkError>[] = [];
  graphQLErrors?.forEach(error => {
    // For whatever reason Apollo does not actually return an error here,
    // so we need to wrap this supposed error into a new error object
    const errorMessage = `${error.message}, Location: ${JSON.stringify(
      error.locations
    )}, Path: ${error.path}`;
    result.push(new Error(errorMessage));
  });

  if (networkError) {
    result.push(networkError);
  }
  return result;
};

const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  const errors = flattenApolloErrors(graphQLErrors, networkError);
  errors.forEach(error => {
    if (isUnauthorizedErrorMessage(error.message)) {
      logout({ expired: true });
    }
    console.error(error); // eslint-disable-line no-console

    const extras: Extras = { operation };
    // Save status code
    if ('statusCode' in error) {
      extras.statusCode = error.statusCode;
    }
    // Save bodyText for ServerParseErrors
    if ('bodyText' in error) {
      extras.bodyText = error.bodyText;
    }
    // Save result for ServerErrors
    if ('result' in error) {
      extras.result = error.result;
    }

    captureError({ error, extras });
  });
});

const authLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = store.get(AUTH_KEY);
  const authorization = token ? `Bearer ${token}` : '';
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });

  return forward(operation);
});

const apolloLinkArray = [errorLink, authLink, httpLink];
if (APOLLO_LOGGER) apolloLinkArray.unshift(apolloLogger);

export const client = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache(),
  link: ApolloLink.from(apolloLinkArray),
  name: 'PlatformDashboard',
});
