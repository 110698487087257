import { Text } from '@arcadiapower/shrike';
import styled from 'styled-components';

export const Wrapper = styled.div<{ hovering?: boolean }>`
  width: 100%;
  min-width: 270px;
  padding: 32px;
  height: 300px;

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  border-radius: ${props => props.theme.borderRadii.small};
  background-color: ${props => props.theme.colors.background.secondary};

  ${props => props.theme.media.phone} {
    min-width: 0;
  }

  &::before {
    z-index: -1;
    content: '';
    position: absolute;
    border-radius: ${props => props.theme.borderRadii.small};
    border: 2px dashed
      ${props => {
        return props.hovering
          ? props.theme.colors.content.secondary
          : props.theme.colors.background.tertiary;
      }};
    top: -3px;
    bottom: -3px;
    left: -3px;
    right: -3px;
  }
`;

export const Header = styled(Text).attrs({
  tag: 'h3',
  textStyle: 'heading600',
})`
  pointer-events: none;
`;

export const InfoText = styled(Text).attrs({
  textStyle: 'paragraph400',
})`
  pointer-events: none;
`;

export const HiddenInput = styled.input`
  display: none;
`;
