import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Text, Input, Modal } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { parseErrorMessage } from 'config/errors';
import { VALIDATE_PASSWORD } from './password-verification-modal.api';

const getCopy = copyFor('settings.profile.passwordVerification');

export type Props = {
  onClose: () => void;
  onSuccess: (password: string) => void;
};

export const PasswordVerificationModal = ({
  onClose,
  onSuccess,
}: Props): JSX.Element => {
  const [error, setError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [pending, setPending] = useState<boolean>(false);
  const [validatePassword] = useMutation<
    ValidatePasswordMutation,
    ValidatePasswordMutationVariables
  >(VALIDATE_PASSWORD);

  const checkPassword = async () => {
    setPending(true);
    try {
      const input = { password };
      const { data } = await validatePassword({ variables: { input } });
      const success = data?.validatePassword.success;
      if (success) {
        onSuccess(password);
      } else {
        setPending(false);
        setError(getCopy('error.wrongPassword'));
      }
    } catch (submissionError) {
      setPending(false);
      setError(parseErrorMessage(submissionError as Error));
    }
  };

  return (
    <Modal aria-label={getCopy('modalDescription')} size="small">
      <Modal.Header
        aria-label={getCopy('header')}
        title={getCopy('header')}
        onClose={onClose}
      />
      <Modal.Content>
        <Text margin={{ bottom: '32px' }} aria-label={getCopy('message')}>
          {getCopy('message')}
        </Text>
        <Input
          label={getCopy('inputLabel')}
          aria-label={getCopy('inputLabel')}
          name="password"
          type="password"
          value={password}
          onChange={setPassword}
          margin={{ bottom: '8px', top: '8px' }}
        ></Input>
      </Modal.Content>
      <Modal.FooterStacked
        onSubmit={checkPassword}
        disabled={!password.length}
        onCancel={onClose}
        loading={pending}
        primaryText={getCopy('confirmButton')}
        secondaryText={getCopy('cancelButton')}
        aria-label={getCopy('confirmButton')}
        error={error}
      />
    </Modal>
  );
};
