import styled from 'styled-components';

export const LogoAndPreviewContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 32px;
  ${props => props.theme.media.phone} {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`;
