import { useQuery } from '@apollo/client';
import { copyFor } from 'config/copy';
import { Link, Text, Skeleton, Alert } from '@arcadiapower/shrike';
import { webhooksDocumentation } from 'config/external-links';
import { WebhookEndpointItem } from 'components/webhook-endpoint-item';
import { EmptyWebhooksWrapper } from './webhook-endpoints.style';
import { GET_WEBHOOK_ENDPOINTS } from './webhook-endpoints.api';

const getCopy = copyFor('configuration.webhooks.webhooksDisplay');

export type Props = {
  sandboxed: boolean;
};

const EmptyWebhooksContainer = () => {
  return (
    <EmptyWebhooksWrapper data-testid="empty-webhooks">
      <Text color="secondary">
        {getCopy('emptyWebhooks.text')}
        <Link type="inline" href={webhooksDocumentation}>
          {getCopy('emptyWebhooks.link')}
        </Link>
      </Text>
    </EmptyWebhooksWrapper>
  );
};

export const WebhookEndpoints = ({ sandboxed }: Props): JSX.Element => {
  const { data, error, loading } = useQuery<
    GetWebhookEndpointsQuery,
    GetWebhookEndpointsQueryVariables
  >(GET_WEBHOOK_ENDPOINTS);

  const allToggledEndpoints =
    data?.resourceOwner.tenant.webhookEndpoints.filter(
      endpoint => endpoint.sandboxed === sandboxed
    );

  const renderWebhookInfo = (webhookEndpoint: WebhookEndpoint) => {
    return (
      <div key={webhookEndpoint.id}>
        <WebhookEndpointItem webhookEndpoint={webhookEndpoint} />
      </div>
    );
  };

  if (loading)
    return <Skeleton height="250px" width="100%" withShimmer={true} />;
  if (error) {
    return (
      <Alert data-testid="webhook-endpoints-loading-error">
        {getCopy('loadingError')}
      </Alert>
    );
  }

  return allToggledEndpoints?.length ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {allToggledEndpoints?.map(webhookEndpoint =>
        renderWebhookInfo(webhookEndpoint as WebhookEndpoint)
      )}
    </>
  ) : (
    <EmptyWebhooksContainer />
  );
};
