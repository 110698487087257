import styled from 'styled-components';

export const AdditionalTextWrapper = styled.div`
  margin-top: 32px;
`;

export const Points = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 1fr));
  grid-column-gap: 48px;
  list-style-position: outside;
  padding: 0;
  margin: 0;
  list-style: disc;

  li {
    margin-left: 16px;
    color: ${props => props.theme.colors.content.secondary};
  }
`;
