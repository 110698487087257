import { Text, Link } from '@arcadiapower/shrike';
import { PlatformDashboardTheme } from 'config/theme';
import { track, TrackEvents } from 'utils/analytics';
import { Wrapper, Image, LinkContainer } from './resource.style';

export type Props = {
  title: string;
  links: LinkInputs[];
  icon: string;
  description: string;
  variant: keyof PlatformDashboardTheme['colors']['background'];
};

interface LinkInputs {
  label: string;
  link: string;
  trackEvent?: TrackEvents;
}

export const Resource = ({
  title,
  links,
  icon,
  description,
  variant,
}: Props): JSX.Element => {
  const allLinks = links.map(currLink => {
    return (
      <Link
        href={currLink.link}
        key={currLink.label}
        onClick={
          currLink.trackEvent
            ? () => track(currLink.trackEvent as string)
            : undefined
        }
      >
        {currLink.label}
      </Link>
    );
  });

  return (
    <Wrapper variant={variant}>
      <Image src={icon} alt={title} />
      <div>
        <Text tag="h3" textStyle="heading600" margin={{ bottom: '8px' }}>
          {title}
        </Text>
        <Text color="secondary" margin={{ bottom: '16px' }}>
          {description}
        </Text>
        <LinkContainer>{allLinks}</LinkContainer>
      </div>
    </Wrapper>
  );
};
