export const setUpAccount = {
  title: 'Set your account password',
  expiredLinkModal: {
    title: 'This link has expired',
    description:
      'If you need a new invitation link, you can request another from your Arc dashboard admin.',
    cta: 'Okay',
  },
  successModal: {
    title: 'Your new password is set.',
    description: 'Your account is now confirmed!',
    cta: 'Continue to dashboard',
  },
};
