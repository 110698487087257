import { store } from 'config/store';
import * as Sentry from '@sentry/react';
import { Extras } from '@sentry/types';
import { Integrations } from '@sentry/tracing';

import axios from 'axios';
import { isObject } from '@arcadiapower/warbler';
import {
  APP_BUILD_ENV,
  CURRENT_USER_KEY,
  SENTRY_DSN,
  SENTRY_RELEASE,
} from 'config/constants';

export const sanitizeObjectDeep = <T extends object>(
  input: T,
  keysToSanitize: (RegExp | string)[]
): T => {
  return Object.entries(input).reduce((sanitizedObject, [key, value]) => {
    let sanitizedValue = value;
    if (
      keysToSanitize.some(sanitizeKey =>
        typeof sanitizeKey === 'string'
          ? sanitizeKey === key
          : sanitizeKey.test(key)
      )
    ) {
      sanitizedValue = '*******';
    } else if (isObject(value)) {
      sanitizedValue = sanitizeObjectDeep(value, keysToSanitize);
    }
    return { ...sanitizedObject, [key]: sanitizedValue };
  }, {}) as T;
};

const initSentry = () => {
  const shouldUseSentry = ['staging', 'production'].includes(APP_BUILD_ENV);
  if (!shouldUseSentry) return;
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: APP_BUILD_ENV,
    release: SENTRY_RELEASE,
    normalizeDepth: 5,
    integrations: [new Integrations.BrowserTracing()],
  });
};

type IdentifyingUser = { id: string; email?: string };

const identifyUserForSentry = (user: IdentifyingUser) => {
  Sentry.setUser({ email: user.email, id: user.id });
};

export const identifyUserForErrorReporting = (user: IdentifyingUser): void => {
  identifyUserForSentry(user);
};

export const clearUserForErrorReporting = (): void => {
  Sentry.configureScope(scope => scope.setUser(null));
};

export const initErrorReporting = (): void => {
  initSentry();
};

const tenantAlreadyExistsRegex =
  /Validation failed: Company name [\s\S]* is already registered/;
export const TENANT_ALREADY_EXISTS =
  'Validation failed: Company name is already registered';

export const setSentryFingerprint = (scope: Sentry.Scope, error?: Error) => {
  const errorMessage = error?.message;
  if (errorMessage) {
    if (tenantAlreadyExistsRegex.test(errorMessage))
      scope.setFingerprint([TENANT_ALREADY_EXISTS]);
    else scope.setFingerprint([errorMessage]);
  }
};

export const captureError = ({
  error,
  extras = {},
}: {
  error: Error;
  extras?: Extras;
}): void => {
  if (axios.isAxiosError(error)) {
    extras.responseData = error.response?.data;
    extras.axiosConfig = error.config;
  }
  Sentry.withScope(scope => {
    const user = scope.getUser();
    if (!user?.email) {
      const localUser = store.get(CURRENT_USER_KEY);
      if (localUser) scope.setUser(localUser);
    }
    const sanitizedExtras = sanitizeObjectDeep(extras, [
      /password/i,
      /code/i,
      /Authorization/i,
      /token/i,
    ]);
    scope.setExtras(sanitizedExtras);
    setSentryFingerprint(scope, error);
    Sentry.captureException(error);
  });
};
