import styled, { keyframes } from 'styled-components';
import { Text, Card, Alert } from '@arcadiapower/shrike';
import loadingSpinnerSvg from 'assets/loading-spinner.png';
// TODO: Move this under the styles
export const DataContainer = styled.div`
  display: grid;
  padding: 24px;
  grid-template-columns: repeat(auto-fit, 300px);
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  overflow-x: auto;

  &:not(:last-child) {
    margin-bottom: 24px;
    border-bottom: 1px solid ${props => props.theme.colors.background.tertiary};
  }
`;

export const DataTitle = styled.div`
  grid-column: 1 / -1;
`;

export const DataTitleHeader = styled(Text).attrs({
  color: 'secondary',
  tag: 'h4',
  textStyle: 'heading600',
})`
  font-size: 18px;
`;

export const Cell = styled.div`
  overflow-wrap: break-word;
  white-space: pre-line;
`;

export const LoadingCell = styled(Cell)`
  margin: 1rem 0;
`;

export const Table = styled(Card)`
  width: 100%;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const TableTitleRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.background.tertiary};

  ${props => props.theme.media.phone} {
    flex-direction: column;

    > *:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const TableTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
`;

export const TableTitle = styled(Text).attrs({
  margin: { right: '9px' },
  tag: 'h3',
  textStyle: 'heading700',
})``;

export const TableSubTitle = styled(Text).attrs({
  tag: 'h4',
  color: 'secondary',
})`
  width: 100%;
  margin-top: 16px;
`;

export const DataTableAlert = styled(Alert)`
  width: 100%;
  margin-top: 16px;
`;

export const LoadingContainer = styled.div`
  align-items: center;
  margin-top: 16px;
  display: flex;
  width: 100%;
  gap: 8px;
`;

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.img.attrs({
  src: loadingSpinnerSvg,
  alt: 'loading',
})`
  animation: ${rotate} 1s linear infinite;
  height: 18px;
  width: 18px;
`;
