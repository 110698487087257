import { useState, useMemo, useEffect } from 'react';
import { lightning } from 'assets/icons/paths';
import { copyFor } from 'config/copy';
import { plug, spark } from 'config/paths';
import {
  DataError,
  useFetchUtilityData,
} from 'hooks/use-fetch-utility-data.hook';
import { Plug } from 'pages/demo/plug';
import { Spark } from 'pages/demo/spark';
import { Navigate, Route, Routes } from 'react-router';
import { Text, Icon, Button } from '@arcadiapower/shrike';
import { Content } from 'styles/side-bar-grid.style';
import { RouteProp } from 'components/nav-bar-item';
import { ActionConfirmationModal } from 'components/action-confirmation-modal';
import { PageContent } from 'styles/page.style';
import { useArcAPIClientContext } from 'contexts/demo/arc-api-client';
import { SubNav } from '../sub-nav';
import { TitleRow, ConnectedWrapper } from './demo-results.style';

const getCopy = copyFor('demo');

type ErrorModalProps = {
  error: DataError | undefined;
  onConfirmation: () => void;
  onClose: () => void;
  showErrorModal: boolean;
};

const ErrorModal = ({
  error,
  showErrorModal,
  onConfirmation,
  onClose,
}: ErrorModalProps) => {
  if (!error || !showErrorModal) return null;
  const copy = {
    cta: getCopy('errorModal.cta'),
    description: error.message,
    title: getCopy('errorModal.title'),
  };
  return (
    <ActionConfirmationModal
      copy={copy}
      onClose={onClose}
      onConfirmation={onConfirmation}
    />
  );
};

export type Props = {
  resetDemo: () => void;
};

export const DemoResults = ({ resetDemo }: Props): JSX.Element => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { apiClient } = useArcAPIClientContext();

  const { utilityAccountQuery, utilityCredentialQuery, fetchingResource } =
    useFetchUtilityData({ apiClient });

  const errorThatShouldShowModal = useMemo(() => {
    const error = [
      utilityAccountQuery.error,
      utilityCredentialQuery.error,
    ].find(error => error instanceof DataError && error.showModal);
    // This is what the "find" could be
    return error as DataError | undefined;
  }, [utilityAccountQuery.error, utilityCredentialQuery.error]);

  useEffect(() => {
    if (errorThatShouldShowModal) {
      setShowErrorModal(true);
    }
  }, [errorThatShouldShowModal]);

  const routes: RouteProp[] = [
    {
      icon: 'Chart',
      label: getCopy('nav.links.plug'),
      route: plug,
    },
    {
      label: getCopy('nav.links.spark'),
      route: spark,
      svgPath: lightning,
    },
  ];

  const utilityName = utilityCredentialQuery.data?.data?.[0]?.utility_name;

  return (
    <PageContent>
      <TitleRow>
        <Text>{getCopy('results.description')}</Text>
        <ConnectedWrapper>
          <Icon icon="CheckmarkCircleInverted" margin={{ right: '8px' }} />
          <Text textStyle="heading800" tag="h2">
            {getCopy('results.connectedText', {
              utility: utilityName ?? getCopy('results.fallbackUtilityName'),
            })}
          </Text>
        </ConnectedWrapper>
        <Button backgroundless color="primary" onClick={() => resetDemo()}>
          {getCopy('results.resetCredentials')}
        </Button>
      </TitleRow>
      <SubNav routes={routes} />
      <Content data-testid="demo-results">
        <Routes>
          <Route
            path="plug"
            element={
              <Plug
                utilityAccountQuery={utilityAccountQuery}
                fetchingResource={fetchingResource}
                utilityCredentialQuery={utilityCredentialQuery}
              />
            }
          />
          <Route
            path="spark"
            element={
              <Spark
                apiClient={apiClient}
                utilityAccountId={utilityAccountQuery.data?.data?.[0]?.id}
                fetchingResource={fetchingResource}
              />
            }
          />
          <Route path="*" element={<Navigate to="plug" replace />} />
        </Routes>
        <ErrorModal
          showErrorModal={showErrorModal}
          error={errorThatShouldShowModal}
          onConfirmation={() => resetDemo()}
          onClose={() => setShowErrorModal(false)}
        />
      </Content>
    </PageContent>
  );
};
