import { useMemo, useReducer, Dispatch } from 'react';
import qs from 'qs';
import { useLocation } from 'react-router';
import { removeProps } from '@arcadiapower/warbler';
import { CUSTOMERS_PAGE_SIZE } from 'config/constants';
import { SortingState } from '@tanstack/react-table';
import { copyFor } from 'config/copy';

const getCopy = copyFor('customers');

export const useCustomerPageReducer = (): [
  CustomersTableState,
  Dispatch<Action>
] => {
  const location = useLocation();
  const [customersTableState, dispatch] = useReducer<
    typeof customerTableReducer
  >(customerTableReducer, initializeTableState(location));
  const dispatchFns = useMemo(
    () => customersTableState.dispatchFns(dispatch),
    [customersTableState, dispatch]
  );
  const state = useMemo(
    () => ({ ...customersTableState, dispatchFns }),
    [customersTableState, dispatchFns]
  );

  return [state, dispatch];
};

const defaultSort: UtilityAccountSort = {
  key: 'ACCOUNT_NUMBER',
  order: 'DESC',
};

const initializeTableState = (location: {
  search: string;
}): CustomersTableState => {
  const searchParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as ParsedQueryString;

  const result: CustomersTableState = {
    after: searchParams.after,
    before: searchParams.before,
    first: (searchParams.first && Number(searchParams.first)) as number,
    last: (searchParams.last && Number(searchParams.last)) as number,
    page: Number(searchParams.page ?? 1),
    sandboxed: searchParams.sandboxed === 'true',
    searchTerm: searchParams.searchTerm,
    filters:
      searchParams.filters as GetTenantUtilityAccountsQueryVariables['filters'],
    sort:
      (searchParams.sort as GetTenantUtilityAccountsQueryVariables['sort']) ||
      defaultSort,
    dispatchFns: (dispatch: Dispatch<Action>) => ({
      updateSort: (sort: SortingState) =>
        dispatch({ payload: { sort }, type: 'SORT' }),
      updateFilters: (filters: UtilityAccountFilter) =>
        dispatch({ payload: { filters }, type: 'FILTER' }),
      updateSearch: (searchTerm: string) =>
        dispatch({ payload: { searchTerm }, type: 'SEARCH' }),
      paginateForwards: (endCursor: string) => () =>
        endCursor &&
        dispatch({
          payload: { endCursor },
          type: 'PAGINATE_FORWARDS',
        }),
      paginateBackwards: (startCursor: string) => () =>
        startCursor &&
        dispatch({
          payload: { startCursor },
          type: 'PAGINATE_BACKWARDS',
        }),
      toggleSandbox: () => dispatch({ type: 'TOGGLE_SANDBOX' }),
    }),
  };

  if (!result.first && !result.last) {
    result.first = CUSTOMERS_PAGE_SIZE;
  }
  return removeProps(result, [undefined, null]) as CustomersTableState;
};

const customerTableReducer = (
  state: CustomersTableState,
  action: Action
): CustomersTableState => {
  const paginationReset = {
    after: undefined,
    before: undefined,
    first: CUSTOMERS_PAGE_SIZE,
    last: undefined,
    page: 1,
  };

  switch (action.type) {
    case 'PAGINATE_FORWARDS':
      return {
        ...state,
        after: action.payload.endCursor,
        before: undefined,
        first: CUSTOMERS_PAGE_SIZE,
        last: undefined,
        page: state.page + 1,
      };
    case 'PAGINATE_BACKWARDS':
      return {
        ...state,
        after: undefined,
        before: action.payload.startCursor,
        first: undefined,
        last: CUSTOMERS_PAGE_SIZE,
        page: state.page - 1,
      };
    case 'SEARCH':
      return {
        ...state,
        ...paginationReset,
        searchTerm: action.payload.searchTerm || undefined,
      };
    case 'FILTER':
      return {
        ...state,
        ...paginationReset,
        filters: action.payload.filters,
      };
    case 'TOGGLE_SANDBOX':
      return {
        ...state,
        ...paginationReset,
        sandboxed: !state.sandboxed,
        filters: undefined,
        sort: defaultSort,
        searchTerm: undefined,
      };
    case 'SORT':
      return {
        ...state,
        ...paginationReset,
        sort: tableSortToQuerySort(action.payload.sort),
      };
  }
};

type CustomersTableState = GetTenantUtilityAccountsQueryVariables & {
  page: number;
  dispatchFns: any;
};

type Action =
  | {
      type: 'PAGINATE_FORWARDS';
      payload: { endCursor: string };
    }
  | { type: 'PAGINATE_BACKWARDS'; payload: { startCursor: string } }
  | { type: 'SEARCH'; payload: { searchTerm: string } }
  | { type: 'FILTER'; payload: { filters: CustomersTableState['filters'] } }
  | { type: 'SORT'; payload: { sort: SortingState } }
  | { type: 'TOGGLE_SANDBOX' };

type ParsedQueryString = {
  [Property in keyof CustomersTableState]: string;
};

const idSortKeyMap: Record<string, UtilityAccountSortKeyEnum> = {
  [getCopy('table.columns.accountNumber')]: 'ACCOUNT_NUMBER',
  [getCopy('table.columns.accountStatus')]: 'ACCOUNT_STATUS',
  [getCopy('table.columns.customerName')]: 'CUSTOMER_NAME',
  [getCopy('table.columns.serviceAddress')]: 'SERVICE_ADDRESS',
  [getCopy('table.columns.username')]: 'USERNAME',
  [getCopy('table.columns.credentialsStatus')]: 'CREDENTIAL_STATUS',
};

const tableSortToQuerySort = (sortArray: SortingState): UtilityAccountSort => {
  const [sortOrder] = sortArray; // Assumes only one sort at a time
  const { id, desc } = sortOrder;
  return {
    key: idToSortKey(id),
    order: desc ? 'DESC' : 'ASC',
  };
};

const idToSortKey = (id: string): UtilityAccountSortKeyEnum => {
  return idSortKeyMap[id] || 'CUSTOMER_NAME';
};

export const sortKeyIdMap: Record<UtilityAccountSortKeyEnum, string> = {
  ACCOUNT_NUMBER: getCopy('table.columns.accountNumber'),
  ACCOUNT_STATUS: getCopy('table.columns.accountStatus'),
  CUSTOMER_NAME: getCopy('table.columns.customerName'),
  SERVICE_ADDRESS: getCopy('table.columns.serviceAddress'),
  USERNAME: getCopy('table.columns.username'),
  CREDENTIAL_STATUS: getCopy('table.columns.credentialsStatus'),
};

export const querySortToTableSort = (
  sort?: Maybe<UtilityAccountSort>
): SortingState => {
  if (!sort) return [];
  const { key, order } = sort;
  return [
    {
      id: sortKeyToId(key),
      desc: order === 'DESC',
    },
  ];
};

export const sortKeyToId = (key: UtilityAccountSortKeyEnum): string => {
  return sortKeyIdMap[key] || getCopy('table.columns.customerName');
};
