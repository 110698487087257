import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Input, Button, Alert } from '@arcadiapower/shrike';
import { parseErrorMessage } from 'config/errors';
import { Form } from 'components/form';
import { isValidEmail } from 'utils/validators';
import { copyFor, getInputPropsFromCopy } from 'config/copy';
import * as analytics from 'utils/analytics';
import { Wrapper, NoWrapButton } from './add-team-member.style';
import { ADD_TEAM_MEMBER } from './add-team-member.api';

const getCopy = copyFor('forms.addTeamMember');

export type Props = {
  onSuccess: () => void;
  onCancel: () => void;
};

export const AddTeamMember = ({ onSuccess, onCancel }: Props): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<Error | undefined>();
  const [pending, setPending] = useState<boolean>(false);
  const [addTeamMember] = useMutation<
    AddTeamMemberMutation,
    AddTeamMemberMutationVariables
  >(ADD_TEAM_MEMBER);

  const canSubmit = isValidEmail(email);

  const handleSubmit = async () => {
    analytics.track(analytics.TrackEvents.ADD_USER_SUBMITTED);
    setPending(true);
    try {
      const input = { email };
      await addTeamMember({ variables: { input } });
      setError(undefined);
      onSuccess();
    } catch (submissionError) {
      setError(submissionError as Error);
      setPending(false);
    }
  };

  return (
    <Form data-testid="add-team-member-form" onSubmit={handleSubmit}>
      <Wrapper>
        <Input
          {...getInputPropsFromCopy(getCopy, 'email')}
          value={email}
          onChange={setEmail}
          margin={{
            right: '8px',
            top: '8px',
          }}
        />
        <NoWrapButton type="submit" disabled={!canSubmit} loading={pending}>
          {getCopy('submit')}
        </NoWrapButton>
        <Button
          disabled={pending}
          onClick={onCancel}
          backgroundless
          margin={{ top: 'auto' }}
        >
          {getCopy('cancel')}
        </Button>
      </Wrapper>
      {error && (
        <Alert margin={{ top: '8px' }}>
          {parseErrorMessage(error, getCopy('errors.generic'))}
        </Alert>
      )}
    </Form>
  );
};
