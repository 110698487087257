import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const SectionWrapper = styled.div``;

export const Header = styled(Text).attrs({ textStyle: 'heading600' })`
  padding: 24px 0px;
`;

export const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const NoPaymentMethodsWrapper = styled.div`
  background-color: ${props => props.theme.colors.background.secondary};
  border-radius: 8px;
  width: 292px;
  height: 112px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
`;

// Child styles: styles are used by components in ./payment-methods

export const Wrapper = styled.div<{
  isExpired?: boolean;
  isDefault?: boolean;
}>`
  border: 1px solid ${props => props.theme.colors.background.tertiary};
  border-radius: 8px;
  width: 292px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 24px;
  ${props =>
    props.isExpired
      ? `
      background-color: ${props.theme.colors.background.secondary};
      border: 1px solid ${props.theme.colors.background.secondary};
    `
      : `
      background-color: ${props.theme.colors.background.primary};
  `}
  ${props =>
    props.isDefault
      ? `
      order: 0;
    `
      : `
      order: 1;
  `}
`;

export const PaymentHeader = styled(Text).attrs({
  color: 'secondary',
  textStyle: 'paragraph400',
})<{ isExpired?: boolean }>`
  padding-bottom: 4px;
  ${props =>
    props.isExpired
      ? `
      color: ${props.theme.colors.content.error};
    `
      : `
      color: ${props.theme.colors.content.secondary};
  `}
`;

export const PaymentMethodInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconWrapper = styled.div`
  ${props => props.theme.media.phone} {
    display: none;
  }
  display: flex;
  align-items: center;
`;

export const PaymentNameAndIconWrapper = styled.div`
  display: flex;
  gap: 10px;
`;
