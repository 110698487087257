import { gql } from '@apollo/client';

export const GET_TENANT = gql`
  query getTenant {
    resourceOwner {
      id
      tenant {
        id
        logoFilename
        logoUrl
        name
      }
    }
  }
`;

export const generateProfileResourceOwner = (): GetTenantQuery => {
  return {
    resourceOwner: {
      id: '1',
      tenant: {
        id: '22',
        logoFilename: 'logo.png',
        logoUrl:
          'https://resources.ediblearrangements.com/resources/en-us/i/revamp_p/edible_arrangement_logo.png?v=09302019',
        name: 'Edible Arrangements',
      },
    },
  };
};
