import styled from 'styled-components';
import { Badge } from '@arcadiapower/shrike';
import { TenantUserStatus } from 'typings/arcadia-api';

export const VerifiedBadge = styled(Badge)<{ status: TenantUserStatus }>`
  ${props => {
    switch (props.status) {
      case 'confirmed':
        return `
          color: ${props.theme.colors.content.success};
          background-color: ${props.theme.colors.background.success};
        `;
      default:
        return `
          color: ${props.theme.colors.content.warning};
          background-color: ${props.theme.colors.background.warning};
        `;
    }
  }}
`;
