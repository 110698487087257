import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';
import { marginStyles, Margin } from 'styles/margin.style';

export const InputGroup = styled.div<{ margin?: Margin }>`
  ${props => marginStyles(props.margin)}
  flex: 1;
`;

export const ValueWrapper = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const Header = styled(Text).attrs({
  tag: 'h3',
  textStyle: 'heading600',
})`
  margin-bottom: 24px;
`;
