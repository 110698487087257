import axios from 'axios';
import { getErrorCopy } from 'config/copy';

export const parseSparkFormError = (error?: unknown): string | undefined => {
  if (!(error instanceof Error)) {
    return;
  }
  let result = getErrorCopy('generic');
  if (axios.isAxiosError(error)) {
    const errorMessage =
      error?.response?.data?.error?.description || error.message;

    if (errorMessage.includes("User's tariff or region not yet supported")) {
      result = getErrorCopy('sparkFormRegionNotSupported');
    } else if (
      errorMessage.includes(
        'feature is not available for this UtilityAccount'
      ) ||
      errorMessage.includes('feature is not ready yet')
    ) {
      result = getErrorCopy('sparkFormFeatureNotAvailable');
    }
  }
  return result;
};
