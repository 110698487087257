import { gql } from '@apollo/client';
import deepmerge from 'deepmerge';

export const GET_UTILITY_ACCOUNT = gql`
  query getUtilityAccount($id: ID!) {
    utilityAccount(id: $id) {
      accountNumber
      derivedServices
      id
      scrapedPhoneNumber
      serviceCustomerName
      serviceAddress
      serviceAddressCity
      serviceAddressStreetTwo
      serviceAddressState
      serviceAddressZip
      status
      account {
        id
        userId
        accountNumber
      }
      utilityCredential {
        id
        status
        username
        utility {
          id
          name
          derivedName
        }
      }
      user {
        id
        email
        tenantUserId
        relatedLinkTerms
        relatedLinkConfirmation
        paymentMethods {
          default
          expired
          id
          paymentSource {
            ... on CreditCardPayment {
              __typename
              id
              vendorName
              filteredCardNumber
              expirationDate
            }
            ... on ACHPayment {
              __typename
              id
              bankName
              filteredAccountNumber
            }
          }
        }
      }
    }
  }
`;

export type UtilityAccountType = NonNullable<GetUtilityAccountQuery>;

export const generateUtilityAccount = (
  utilityAccount: Partial<UtilityAccountType> = {}
): UtilityAccountType => {
  const baseUtilityAccount: UtilityAccountType = {
    utilityAccount: {
      __typename: 'UtilityAccount',
      account: {
        accountNumber: 1232,
        id: 1,
        userId: 999888,
        __typename: 'Account',
      },
      accountNumber: '555',
      derivedServices: ['gas'],
      id: 2,
      scrapedPhoneNumber: '555-555-5555',
      serviceAddress: '123 fake street',
      serviceAddressCity: 'Rockville',
      serviceAddressState: 'Maryland',
      serviceAddressStreetTwo: 'Unit 3',
      serviceAddressZip: '90210',
      serviceCustomerName: 'Cool Brandon',
      status: 'active',
      user: {
        __typename: 'User',
        email: 'useremail@email.com',
        id: 234,
        paymentMethods: [
          {
            default: true,
            expired: false,
            id: 222,
            __typename: 'PaymentMethod',
            paymentSource: {
              __typename: 'ACHPayment',
              bankName: 'test bank',
              filteredAccountNumber: '4444',
              id: 555,
            },
          },
          {
            default: false,
            __typename: 'PaymentMethod',
            expired: false,
            id: 666,
            paymentSource: {
              __typename: 'CreditCardPayment',
              expirationDate: '2024-01-01',
              filteredCardNumber: '8888',
              id: 999,
              vendorName: 'visa',
            },
          },
        ],
        relatedLinkConfirmation: null,
        relatedLinkTerms: null,
        tenantUserId: '987',
      },
      utilityCredential: {
        __typename: 'UtilityCredential',
        id: 123,
        status: 'verified',
        username: 'username123',
        utility: {
          __typename: 'Utility',
          id: 14,
          derivedName: 'Util',
          name: 'Util Co',
        },
      },
    },
  };
  return deepmerge(baseUtilityAccount, utilityAccount);
};

export const generateGetUtilityAccount = (
  utilityAccount: Partial<UtilityAccountType> = {}
): GetUtilityAccountQuery => generateUtilityAccount(utilityAccount);
