import { useMutation } from '@apollo/client';
import { copyFor } from 'config/copy';
import { useState } from 'react';
import { Button, Text, Alert } from '@arcadiapower/shrike';
import { TenantUserStatus } from 'typings/arcadia-api';
import * as analytics from 'utils/analytics';
import {
  TableText,
  StatusBadgeWrapper,
  ResendWrapper,
} from './team-member-table-row.style';
import { RESEND_INVITATION_EMAIL } from './team-member-table-row.api';
import { AccountStatusBadge } from '../account-status-badge';

export type Props = {
  tenantUser: GetTeamQuery['resourceOwner']['tenant']['tenantUsers'][number];
};

const getCopy = copyFor('components.teamMemberTable');

export const TeamMemberTableRow = ({ tenantUser }: Props): JSX.Element => {
  const { firstName, lastName, email, status } = tenantUser;

  const [initiateResendEmail] = useMutation<
    ResendInvitationEmailMutation,
    ResendInvitationEmailMutationVariables
  >(RESEND_INVITATION_EMAIL);

  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const sendEmail = async () => {
    analytics.track(analytics.TrackEvents.ADD_USER_RESEND);
    try {
      const { data } = await initiateResendEmail({
        variables: { input: { email } },
      });
      if (data?.resendTenantUserInvitation.resourceOwner) {
        setEmailSent(true);
        setError(false);
      } else {
        throw Error('Failed to initiate resend email.');
      }
    } catch (error) {
      setError(true);
    }
  };

  const renderTableCell = (value?: string | null) => (
    <td>
      <TableText>{value || getCopy('noValue')}</TableText>
    </td>
  );

  const renderEmptyTableCell = () => <td data-testid="empty-cell"></td>;

  const renderResendButton = () => {
    if (emailSent) {
      return (
        <Text margin={{ right: '16px' }} color="secondary">
          {getCopy('resendInvite.emailSent')}
        </Text>
      );
    } else {
      return (
        <Button
          backgroundless
          size="small"
          margin={{ right: '16px' }}
          onClick={sendEmail}
        >
          {getCopy('resendInvite.button')}
        </Button>
      );
    }
  };

  const renderStatusBadge = (status: TenantUserStatus) => (
    <td>
      <ResendWrapper>
        <StatusBadgeWrapper>
          {status === 'invited' && renderResendButton()}
          <AccountStatusBadge status={status} />
        </StatusBadgeWrapper>
        {error && (
          <Alert margin="0 0 0 1rem">{getCopy('resendInvite.error')}</Alert>
        )}
      </ResendWrapper>
    </td>
  );

  const displayName = (
    firstName?: string | null,
    lastName?: string | null
  ): string => {
    if (!firstName && !lastName) return '';
    if (lastName && !firstName) return lastName;
    if (firstName && !lastName) return firstName;
    return `${firstName} ${lastName}`;
  };

  const fullName = displayName(firstName, lastName);

  return (
    <tr key={tenantUser.email}>
      {fullName ? renderTableCell(fullName) : renderEmptyTableCell()}
      {renderTableCell(email)}
      {renderStatusBadge(status as TenantUserStatus)}
    </tr>
  );
};
