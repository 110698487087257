import styled from 'styled-components';
import { Switch } from '@arcadiapower/shrike';

export const SearchBarFieldsWrapper = styled.div`
  align-items: center;
  width: 100%;
  gap: 16px;
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: minmax(232px, 432px) auto;

  ${props => props.theme.media.phone} {
    display: flex;
    flex-direction: column;
  }
`;

export const Wrapper = styled.div``;

export const HelperText = styled.div`
  display: flex;
  gap: 8px;
`;

export const CustomerTypeToggle = styled(Switch)`
  width: inherit;
`;
