import { connectIframeUrl } from 'config/external-links';
import { TableSectionTitle } from 'styles/table.style';
import {
  InputGroup,
  ConnectPreviewIFrame,
} from './utility-connect-preview.styles';

export type Props = {
  className?: string;
  label: string;
  logoUrl: string;
  tenantName: string;
};

export const UtilityConnectPreview = ({
  label,
  logoUrl,
  tenantName,
  className,
}: Props): JSX.Element => {
  return (
    <InputGroup className={className}>
      <TableSectionTitle>{label}</TableSectionTitle>
      <ConnectPreviewIFrame
        data-testid="connect-iframe"
        src={connectIframeUrl(tenantName, logoUrl)}
        title={`${tenantName} logo preview`}
      />
    </InputGroup>
  );
};
