import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Input, Button, Alert } from '@arcadiapower/shrike';
import { parseErrorMessage } from 'config/errors';
import { copyFor, getInputPropsFromCopy } from 'config/copy';
import { Form } from 'components/form';
import { authenticate } from 'config/auth';
import { isValidEmail, isValidPassword } from 'utils/validators';
import { NewPasswordInput } from 'components/new-password-input';
import { UPDATE_RESOURCE_OWNER } from './account-details-form.api';
import { InputWrapper } from './account-details.style';

export type AccountDetailsFormProps = {
  onSuccess: () => void;
  onCancel: () => void;
  resourceOwner: NonNullable<GetProfileResourceOwnerQuery['resourceOwner']>;
  previousPassword: string;
};

const getCopy = copyFor('forms.accountDetails');

export const AccountDetailsForm = ({
  onCancel,
  onSuccess,
  resourceOwner,
  previousPassword,
}: AccountDetailsFormProps): JSX.Element => {
  const [error, setError] = useState<Error | undefined>();
  const [pending, setPending] = useState<boolean>(false);
  const [updateResourceOwner] = useMutation<
    UpdateResourceOwnerMutation,
    UpdateResourceOwnerMutationVariables
  >(UPDATE_RESOURCE_OWNER);

  const [email, setEmail] = useState<string>(resourceOwner.email);
  const [password, setPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>(
    resourceOwner.firstName || ''
  );
  const [lastName, setLastName] = useState<string>(
    resourceOwner.lastName || ''
  );

  const canSubmit = () => {
    const hasChanged =
      email !== resourceOwner.email ||
      password ||
      firstName !== resourceOwner.firstName ||
      lastName !== resourceOwner.lastName;
    const canSubmitPassword = !password || isValidPassword(password);
    return (
      hasChanged &&
      isValidEmail(email) &&
      canSubmitPassword &&
      firstName &&
      lastName
    );
  };

  const handleSubmit = async () => {
    setPending(true);
    try {
      const input = { email, firstName, lastName, password, previousPassword };
      const { data } = await updateResourceOwner({ variables: { input } });
      const accessToken = data?.updateResourceOwner.accessToken;
      if (accessToken) {
        authenticate(accessToken.token, accessToken.expiresAt);
      }
      onSuccess();
    } catch (submissionError) {
      setError(submissionError as Error);
      setPending(false);
    }
  };

  return (
    <Form data-testid="account-details-form" onSubmit={handleSubmit}>
      <InputWrapper>
        <Input
          {...getInputPropsFromCopy(getCopy, 'firstName')}
          value={firstName}
          onChange={setFirstName}
          margin={{ bottom: '32px', right: '32px', top: '8px' }}
        />
        <Input
          {...getInputPropsFromCopy(getCopy, 'lastName')}
          value={lastName}
          onChange={setLastName}
          margin={{ bottom: '32px', top: '8px' }}
        />
      </InputWrapper>
      <Input
        {...getInputPropsFromCopy(getCopy, 'email')}
        value={email}
        onChange={setEmail}
        margin={{ bottom: '32px', top: '8px' }}
      />
      <NewPasswordInput
        value={password}
        onChange={setPassword}
        margin={{ bottom: '24px' }}
      />
      <Button
        type="submit"
        disabled={!canSubmit()}
        loading={pending}
        margin={{ right: '8px' }}
      >
        {getCopy('submit')}
      </Button>
      <Button disabled={pending} onClick={() => onCancel()} backgroundless>
        {getCopy('cancel')}
      </Button>
      {error && (
        <Alert margin={{ top: '16px' }}>{parseErrorMessage(error)}</Alert>
      )}
    </Form>
  );
};
