import { gql } from '@apollo/client';
import deepmerge from 'deepmerge';

export const GET_UTILITIES = gql`
  query getUtilities(
    $after: String
    $before: String
    $last: Int
    $first: Int
    $searchTerm: String
    $filter: UtilityFilterEnum
    $strictFilters: UtilityStrictFilter
  ) {
    utilities(
      after: $after
      before: $before
      last: $last
      first: $first
      searchTerm: $searchTerm
      filter: $filter
      strictFilters: $strictFilters
    ) {
      nodes {
        id
        name
        shortName
        state {
          id
          abbreviation
        }
        productAvailability {
          bundleSupported
          sparkSupported
          plugStatementsSupported
          plugIntervalsSupported
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export type UtilityType = NonNullable<
  GetUtilitiesQuery['utilities']['nodes']
>[number];

export const generateUtility = (
  utility?: Partial<UtilityType>
): UtilityType => {
  const baseUtility: UtilityType = {
    id: 1,
    name: 'My Utility',
    shortName: 'U of T',
    state: {
      id: 2,
      abbreviation: 'NY',
    },
    productAvailability: {
      bundleSupported: true,
      sparkSupported: true,
      plugStatementsSupported: true,
      plugIntervalsSupported: false,
    },
  };
  return deepmerge(baseUtility, utility ?? {});
};
