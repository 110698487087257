import { copyFor } from 'config/copy';
import { Page } from 'styles';
import { Text, Tooltip, Icon } from '@arcadiapower/shrike';
import SparkSvg from 'assets/demo/spark.svg';
import { UseFetchUtilityData } from 'hooks/use-fetch-utility-data.hook';
import {
  Table,
  TableTitle,
  TableTitleRow,
  TableTitleWrapper,
} from 'components/demo/data-table';
import { SparkForm } from 'components/forms/spark-form';
import { useEnergyAnalytics } from 'hooks/use-energy-analytics.hook';
import { ArcAPIClient } from 'classes/arc-api-client';
import { SparkResults } from 'components/demo/demo-spark-results';
import { DemoDescriptionWrapper, DemoDescriptionImage } from 'styles/demo';

const getCopy = copyFor('spark');

export interface SparkProps {
  apiClient: ArcAPIClient;
  utilityAccountId?: number;
  fetchingResource: UseFetchUtilityData['fetchingResource'];
}

export const Spark = ({
  apiClient,
  fetchingResource,
  utilityAccountId,
}: SparkProps): JSX.Element => {
  const {
    canSubmit,
    fields,
    updateField,
    queryInput,
    energyAnalyticsQuery,
    submitEnergyAnalyticsQuery,
  } = useEnergyAnalytics({ apiClient, utilityAccountId });

  return (
    <Page>
      <DemoDescriptionWrapper>
        <DemoDescriptionImage src={SparkSvg} alt="spark" />
        <Text>{getCopy('description')}</Text>
      </DemoDescriptionWrapper>
      <Table>
        <TableTitleRow>
          <TableTitleWrapper>
            <TableTitle>{getCopy('tableTitle')}</TableTitle>
            <Tooltip
              content={getCopy('tableHelperText')}
              tooltipId="spark-helper-text"
              label="Spark helper text"
              maxWidth={300}
              data-testid="spark-table-helper-text"
            >
              <Icon icon="InfoCircleInverted"></Icon>
            </Tooltip>
          </TableTitleWrapper>
        </TableTitleRow>
        <SparkForm
          canSubmit={canSubmit}
          fetchingResource={fetchingResource}
          fields={fields}
          updateField={updateField}
          energyAnalyticsQuery={energyAnalyticsQuery}
          submitEnergyAnalyticsQuery={submitEnergyAnalyticsQuery}
        />
        {energyAnalyticsQuery.data && (
          <SparkResults
            queryInput={queryInput}
            data={energyAnalyticsQuery.data}
          />
        )}
      </Table>
    </Page>
  );
};
