import { gql } from '@apollo/client';

export const UPDATE_TENANT = gql`
  mutation updateTenant($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      tenant {
        id
        name
      }
    }
  }
`;

export const generateUpdateTenantOwnerOutput = ({
  id,
  name,
}: {
  id: string;
  name: string;
}): UpdateTenantMutation => {
  const result: UpdateTenantMutation = {
    updateTenant: {
      tenant: {
        id,
        name,
      },
    },
  };
  return result;
};
