import styled, { keyframes } from 'styled-components';
import { Text } from '@arcadiapower/shrike';
import { Margin, marginStyles } from 'styles/margin.style';

export const InputGroup = styled.div<{ margin?: Margin }>`
  ${props => marginStyles(props.margin)}
  width: 100%;
`;

export const ValueWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  border-radius: ${props => props.theme.borderRadii.small};
  background-color: ${props => props.theme.colors.background.secondary};
  padding: 0 16px;
  width: 100%;
`;

export const Value = styled(Text).attrs({ color: 'secondary' })`
  padding: 16px 0;
  overflow-x: auto;
`;
export const ActionButton = styled.button`
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: block;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const ActionNotification = styled(Text).attrs({
  textStyle: 'heading400',
})<{ isVisible?: boolean }>`
  padding: 4px 12px;
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
  animation: ${props => (props.isVisible ? fadeIn : fadeOut)} 0.2s linear;
  transition: visibility 0.2s linear;
  border-radius: ${props => props.theme.borderRadii.small};
  margin-right: 10px;
  background-color: ${props => props.theme.colors.background.success};
  color: #0d8044;
`;

export const ActionIcon = styled.img`
  height: 24px;
  width: 24px;
`;

export const ActionsWrapper = styled.div`
  align-self: center;
  margin-left: auto;
  display: flex;

  ${ActionButton}:not(:last-child) {
    margin-right: 8px;
  }
`;
