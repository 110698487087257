import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Alert, Skeleton, IconButton } from '@arcadiapower/shrike';
import { copyFor, getErrorCopy } from 'config/copy';
import { customizationDocumentation } from 'config/external-links';
import {
  Table,
  TableHeader,
  TableTitle,
  TableLink,
  TableSection,
  TableSectionHeader,
  TableSectionTitle,
  TableSpacer,
} from 'styles/table.style';
import {
  TenantNameReadOnly,
  TenantNameForm,
} from 'components/forms/tenant-name';
import {
  TenantLogoPreview,
  TenantLogoUpload,
} from 'components/forms/tenant-logo';
import { ReadOnlyLogoUpload } from 'components/forms/read-only-logo-upload';
import { LogoAndPreviewContainer } from './customization.style';
import { GET_TENANT } from './customization.api';

const getCopy = copyFor('configuration.customization');
const getFormCopy = copyFor('forms.customization');

export const Customization = (): JSX.Element => {
  const { data, loading, error } = useQuery<
    GetTenantQuery,
    GetTenantQueryVariables
  >(GET_TENANT);

  const [editing, setEditing] = useState<boolean>(false);

  const CompanyDetails = useMemo(() => {
    if (loading) return <Skeleton height="250px" width="100%" />;
    if (error) return <Alert>{getErrorCopy('generic')}</Alert>;

    const resourceOwner = data?.resourceOwner;
    if (resourceOwner) {
      const NameForm = editing ? (
        <TenantNameForm
          resourceOwner={resourceOwner}
          onSuccess={() => setEditing(false)}
          onCancel={() => setEditing(false)}
        />
      ) : (
        <TenantNameReadOnly resourceOwner={resourceOwner} />
      );

      const LogoForm =
        resourceOwner.tenant.logoUrl && resourceOwner.tenant.logoFilename ? (
          <ReadOnlyLogoUpload
            data-testid="read-only-logo-upload"
            value={resourceOwner.tenant.logoFilename}
            label={getFormCopy('inputs.logoUrl.label')}
          />
        ) : (
          <TenantLogoUpload />
        );

      return (
        <>
          <TableSectionHeader data-testid="customization-section">
            <TableSectionTitle>
              {getCopy('companyDetails.title')}
            </TableSectionTitle>
            <IconButton
              disabled={editing}
              icon="Pencil"
              onClick={() => setEditing(!editing)}
              aria-label="edit"
            />
          </TableSectionHeader>
          {NameForm}
          <TableSpacer />
          <LogoAndPreviewContainer>
            {LogoForm}
            <TenantLogoPreview
              tenantName={resourceOwner.tenant.name}
              logoUrl={resourceOwner.tenant.logoUrl || ''}
            />
          </LogoAndPreviewContainer>
        </>
      );
    }
  }, [data, loading, error, editing]);

  return (
    <Table>
      <TableHeader>
        <TableTitle>{getCopy('title')}</TableTitle>
        <TableLink href={customizationDocumentation}>
          {getCopy('documentationLink')}
        </TableLink>
      </TableHeader>
      <TableSection>{CompanyDetails}</TableSection>
    </Table>
  );
};
