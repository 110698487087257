import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Alert } from '@arcadiapower/shrike';
import { parseErrorMessage } from 'config/errors';
import { copyFor, getInputPropsFromCopy } from 'config/copy';
import { Form } from 'components/form';
import { isValidCompanyName } from 'utils/validators';
import { InputWithHidingHelperText } from 'styles/input-with-conditional-helper-text.style';
import { UPDATE_TENANT } from './tenant-name-form.api';

export type TenantNameFormProps = {
  onSuccess: () => void;
  onCancel: () => void;
  resourceOwner: NonNullable<GetTenantQuery['resourceOwner']>;
};

const getCopy = copyFor('forms.customization');

export const TenantNameForm = ({
  onCancel,
  onSuccess,
  resourceOwner,
}: TenantNameFormProps): JSX.Element => {
  const [error, setError] = useState<Error | undefined>();
  const [pending, setPending] = useState<boolean>(false);
  const [updateTenant] = useMutation<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
  >(UPDATE_TENANT);

  const [name, setName] = useState<string>(resourceOwner.tenant.name);

  const canSubmit = () => {
    const hasChanged = name !== resourceOwner.tenant.name;
    return hasChanged && isValidCompanyName(name);
  };

  const handleSubmit = async () => {
    setPending(true);
    try {
      const input = { name };
      await updateTenant({ variables: { input } });
      onSuccess();
    } catch (submissionError) {
      setError(submissionError as Error);
      setPending(false);
    }
  };

  return (
    <Form data-testid="customization-form" onSubmit={handleSubmit}>
      <InputWithHidingHelperText
        {...getInputPropsFromCopy(getCopy, 'name')}
        value={name}
        onChange={setName}
        isValid={isValidCompanyName(name)}
        margin={{ bottom: '20px' }}
      />
      <Button
        type="submit"
        disabled={!canSubmit()}
        loading={pending}
        margin={{ right: '8px' }}
      >
        {getCopy('submit')}
      </Button>
      <Button disabled={pending} onClick={() => onCancel()} backgroundless>
        {getCopy('cancel')}
      </Button>
      {error && (
        <Alert margin={{ top: '16px' }}>{parseErrorMessage(error)}</Alert>
      )}
    </Form>
  );
};
