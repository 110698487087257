import { ArcAPIClient } from 'classes/arc-api-client';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const Context = createContext<ArcAPIClientContext | undefined>(undefined);

export type ArcAPIClientContext = {
  apiClient: ArcAPIClient;
};

export type Props = PropsWithChildren<{
  accessToken: string;
  clientUserId: string;
  onUnauthorized?: () => void;
}>;

export const ArcAPIClientProvider = ({
  accessToken,
  clientUserId,
  onUnauthorized,
  children,
}: Props): JSX.Element => {
  const [apiClient, setAPIClient] = useState(
    () => new ArcAPIClient({ accessToken, clientUserId, onUnauthorized })
  );

  useEffect(() => {
    // Update client if the access token or client id changes
    setAPIClient(
      new ArcAPIClient({ accessToken, clientUserId, onUnauthorized })
    );
  }, [accessToken, clientUserId, onUnauthorized]);

  const value = useMemo(
    () => ({
      apiClient,
    }),
    [apiClient]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useArcAPIClientContext = (): ArcAPIClientContext => {
  const value = useContext(Context);
  if (!value)
    throw new Error('This must be located inside the ArcAPIClientProvider');
  return value;
};
