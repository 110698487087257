import { copyFor } from 'config/copy';
import { formatDate } from '@arcadiapower/warbler';
import { BundleEnrollmentForTimeline } from '../../statement-timeline.api';

const getCopy = copyFor('statementTimeline');

export const displayText = (
  type?: Statement['type'],
  id?: Statement['id'],
  date?: Statement['date']
): string => {
  return `${formatDate(date)} - ${statementDisplayType(type)} ID: ${displayId(
    id
  )}`;
};

export const statementDisplayType = (type?: Statement['type']): string => {
  switch (type) {
    case 'INVOICE':
      return getCopy('invoiceTitle');
    case 'UTILITY_STATEMENT':
      return getCopy('statementTitle');
    case 'UTILITY_REMITTANCE_ITEMS':
      return getCopy('remittanceTitle');
    default:
      return getCopy('statementTitle');
  }
};

export const displayId = (id?: Statement['id']): string => {
  // Ids are in the format statement-id, invoice-id, remittance-id
  // For display purposes, we only want the id
  const replacement = id?.split('-')[1] || '';
  return replacement;
};

export const calculateEnrollmentStatusText = (
  props: BundleEnrollmentForTimeline
): string | null => {
  const { hostedPayments, utilityRemittance } = props;
  const hostedPaymentsStatus = calculateHostedPaymentsText(hostedPayments);
  const utilityRemittanceStatus =
    calculateUtilityRemittanceText(utilityRemittance);
  return hostedPaymentsStatus || utilityRemittanceStatus;
};

export const calculateHostedPaymentsText = (
  status: BundleEnrollmentForTimeline['hostedPayments']
) => {
  if (!status) return null;
  switch (status) {
    case 'REMOVED':
      return getCopy('hostedBilling.availableWithPastSubscription');
    case 'INELIGIBLE':
      return null;
    case 'ENROLLED':
      return getCopy('hostedBilling.enrolled');
    case 'PENDING_ENROLLMENT':
      return getCopy('hostedBilling.pendingEnrollment');
    case 'PENDING_REMOVAL':
      return getCopy('hostedBilling.pendingRemoval');
    default:
      // No text currently for NOT READY state
      return null;
  }
};

export const calculateUtilityRemittanceText = (
  status: BundleEnrollmentForTimeline['utilityRemittance']
) => {
  if (!status) return null;
  switch (status) {
    case 'REMOVED':
      return getCopy('utilityRemittance.availableWithPastSubscription');
    case 'INELIGIBLE':
      return null;
    case 'ENROLLED':
      return getCopy('utilityRemittance.enrolled');
    case 'PENDING_ENROLLMENT':
      return getCopy('utilityRemittance.pendingEnrollment');
    case 'PENDING_REMOVAL':
      return getCopy('utilityRemittance.pendingRemoval');
    default:
      // No text currently for NOT READY state
      return null;
  }
};
