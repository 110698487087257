import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Alert, Text, Link, Loading } from '@arcadiapower/shrike';
import { copyFor, getInputPropsFromCopy } from 'config/copy';
import {
  Form,
  PrimaryButton,
  ActionWrapper,
} from 'styles/single-content.style';
import { useSignupForm } from 'hooks/use-signup-form.hook';
import { login } from 'config/routes';
import { parseErrorMessage } from 'config/errors';
import { AgreeToTerms } from 'components/forms/agree-to-terms';
import { CenteredText } from 'styles/centered-text.style';
import { isValidCompanyName } from 'utils/validators';
import { NewPasswordInput } from 'components/new-password-input';
import { InputWithHidingHelperText } from 'styles/input-with-conditional-helper-text.style';
import { Input } from './signup.style';
import { GET_DEV_PLATFORM_CONTRACT_VERSIONS } from './signup.api';

export type onSubmitParams = {
  email: string;
  company: string;
  password: string;
  firstName: string;
  lastName: string;
  contractVersionIds: [number];
};

export type Props = {
  errorMessage?: string;
  onSubmit: (params: onSubmitParams) => void;
  pending: boolean;
};

const getCopy = copyFor('forms.signup');

export const Signup = ({
  errorMessage,
  onSubmit,
  pending,
}: Props): JSX.Element => {
  const {
    data: contractsData,
    loading: contractsLoading,
    error: contractError,
  } = useQuery<
    GetDeveloperPlatformContractsVersionsQuery,
    GetDeveloperPlatformContractsVersionsQueryVariables
  >(GET_DEV_PLATFORM_CONTRACT_VERSIONS);

  const { inputs, canSubmit, updateInput } = useSignupForm();
  const { email, password, firstName, lastName, company } = inputs;

  const handleSubmit = () => {
    onSubmit(inputs as onSubmitParams);
  };

  useEffect(() => {
    if (contractsData) {
      const contractVersionsIds = contractsData.contractVersions.map(c => c.id);
      updateInput('contractVersionIds')(contractVersionsIds);
    }
  }, [updateInput, contractsData]);

  if (contractError) return <Alert>{parseErrorMessage(contractError)}</Alert>;
  if (contractsLoading || !contractsData) return <Loading />;

  return (
    <Form onSubmit={handleSubmit} data-testid="forms.signup">
      <Input
        {...getInputPropsFromCopy(getCopy, 'firstName')}
        value={firstName}
        onChange={updateInput('firstName')}
      />
      <Input
        {...getInputPropsFromCopy(getCopy, 'lastName')}
        value={lastName}
        onChange={updateInput('lastName')}
      />
      <InputWithHidingHelperText
        {...getInputPropsFromCopy(getCopy, 'company')}
        value={company}
        onChange={updateInput('company')}
        isValid={isValidCompanyName(company)}
        margin={{ bottom: '24px' }}
      />
      <Input
        {...getInputPropsFromCopy(getCopy, 'email')}
        value={email}
        onChange={updateInput('email')}
      />
      <NewPasswordInput
        {...getInputPropsFromCopy(getCopy, 'password')}
        value={password}
        onChange={updateInput('password')}
        margin={{ bottom: '36px' }}
      />
      <AgreeToTerms
        contractVersions={contractsData.contractVersions}
        margin={{ bottom: '8px', top: '8px' }}
      />
      {errorMessage && <Alert>{errorMessage}</Alert>}
      <ActionWrapper>
        <PrimaryButton
          loading={pending}
          disabled={!canSubmit}
          data-testid="submit-signup"
          type="submit"
        >
          {getCopy('cta')}
        </PrimaryButton>
        <CenteredText>
          <Text color="secondary">
            {getCopy('signInCta.text')}{' '}
            <Link to={login} type="inline" openInNewTab={false}>
              {getCopy('signInCta.link')}
            </Link>
          </Text>
        </CenteredText>
      </ActionWrapper>
    </Form>
  );
};
