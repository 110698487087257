export const configuration = {
  apiKeys: {
    title: 'API Keys',
    documentationLink: 'View documentation',
    apiKeysDisplay: {
      clientId: 'Client ID',
      clientSecret: 'Secret',
      error: 'An error occurred while rotating %{environment} keys',
      rotateKeysCta: 'Rotate keys',
      copyKeys: 'Copied',
      success: 'Your %{environment} keys have been rotated',
      confirmationModal: {
        cta: 'Rotate %{environment} keys',
        description:
          'Are you sure? Any %{environment} applications using these keys will need to be updated.',
        title: 'Rotate %{environment} keys?',
      },
    },
    sandbox: {
      description: 'These keys are used for the tutorial or test environments.',
      environment: 'Sandbox',
      additionalText: {
        title:
          'Sandbox API Keys provide full access to API features but are not permitted for commercial use. In order to view your Live API keys, sign up for a paid plan by ',
        titleLinkCopy: 'contacting our team.',
        points: [],
      },
      rotateKeys: 'Rotate keys',
    },
    live: {
      description: 'These are your live keys for commercial use.',
      environment: 'Live',
      additionalText: {
        title: 'Live API Keys provide full access to API features.',
        points: [],
        titleLinkCopy: '',
      },
      rotateKeys: 'Rotate keys',
    },
  },
  customization: {
    title: 'Customization',
    documentationLink: 'View documentation',
    customizationDisplay: {
      companyName: 'Company Name',
      companyLogo: 'Company Logo',
      error: 'an unexpected error occured',
    },
    companyDetails: {
      title: 'Company Details',
    },
  },
  customers: {
    title: 'Customers',
  },
  sideNav: {
    title: 'Configuration',
    links: {
      apiKeys: 'API keys',
      customization: 'Customization',
      webhooks: 'Webhooks',
    },
  },
  webhooks: {
    title: 'Webhooks',
    documentationLink: 'View documentation',
    webhooksDisplay: {
      headerTitle: 'Configured webhooks',
      newWebhookButton: 'New endpoint',
      loadingError: 'Something went wrong. Please try again.',
      emptyWebhooks: {
        text: 'Configure your webhooks here. To learn more, ',
        link: 'visit the docs.',
      },
      id: 'ID',
      signingKey: 'Signing key',
      deleteButton: 'Delete Webhook Endpoint',
      sandboxToggle: 'Sandbox endpoints',
      liveToggle: 'Live endpoints',
      testButton: 'Test',
      testError: 'Test failed to queue, try again later.',
      testQueuing: 'Queuing...',
      testQueued: 'Your test is queued; check your endpoint.',
      enableButton: 'Enable',
      enableMessage:
        'We disabled this endpoint because it consistently failed to send a successful response to our webhook events. Please fix the issue before re-enabling.',
      enableError: 'Failed to re-enable this webhook. Please try again later.',
    },
    newWebhookForm: {
      inputLabel: 'Endpoint URL',
      inputName: 'Endpoint URL',
      placeholder: 'https://example.com',
      saveButtonText: 'Save',
      cancelButtonText: 'Cancel',
      errorMessage:
        'Your webhook endpoint could not be created at this time. Please reload the page or try again later.',
    },
    deleteWebhookModal: {
      cta: 'Delete webhook',
      description:
        'Are you sure? Any application using this webhook will need to be updated.',
      title: 'Delete this webhook?',
      error:
        'Your webhook endpoint could not be deleted at this time. Please try again later.',
    },
  },
};
