import { getErrorCopy, KeysFromCopyScope } from 'config/copy';

type ErrorKey = KeysFromCopyScope<'errors.messages'>;

/**
 * First value: Regex that will match the error message
 * Second value: Key in config/copy/errors.ts
 */
export const ERROR_MESSAGE_KEY_TUPLES: [RegExp, ErrorKey][] = [
  [/Authentication failed/, 'wrongCredentials'],
  [
    /Validation failed: Company name .+ is already registered/,
    'tenantAlreadyExists',
  ],
  [/Email is already registered/, 'emailExists'],
  [/Email already has a pending invitation./, 'pendingInvitation'],
  [/already exists in another tenant/, 'emailExistsOtherTenant'],
];
// This is a global function to parse API errors and return
// user-friendly strings, defaulting to the generic error
export const parseErrorMessage = (
  error: Error,
  genericErrorOverride = ''
): string => {
  const errorMessage = error.message;

  let userFriendlyError;

  for (const [messageSubstring, errorKey] of ERROR_MESSAGE_KEY_TUPLES) {
    if (messageSubstring.test(errorMessage)) {
      userFriendlyError = getErrorCopy(errorKey);
      break;
    }
  }
  return userFriendlyError || genericErrorOverride || getErrorCopy('generic');
};
