import axios, { AxiosInstance } from 'axios';
import { ARC_API_BASE_URL } from 'config/constants';
import { EnergyAnalyticsInput } from 'hooks/use-energy-analytics.hook';
import { operations } from 'typings/arcadia-api-generated';
import { captureError } from 'utils/analytics';
import { calculateEnergyUsageKwhFromSmartChargeSchedule } from 'utils/energy-analytics';

export type OperationResponse<OperationKey extends keyof operations> =
  operations[OperationKey]['responses'] extends {
    200: { content: { 'application/json': unknown } };
  }
    ? operations[OperationKey]['responses'][200]['content']['application/json']
    : never;

export type OperationRequestBody<OperationKey extends keyof operations> =
  operations[OperationKey] extends {
    requestBody: { content: { 'application/json': unknown } };
  }
    ? operations[OperationKey]['requestBody']['content']['application/json']
    : never;

export type APIRequestBody<OperationKey extends keyof operations> =
  OperationRequestBody<OperationKey>;

export type APIResponse<OperationKey extends keyof operations> = Promise<
  OperationResponse<OperationKey>
>;

// Given how headers are defined in OpenAPI, it is too hard at this time to generate
// a generic header type from the schema -- xelzi 29-July-2022
type ArcHeaders = {
  Authorization: string;
  'Arc-Version': string;
};

class ArcAPIClient {
  baseURL: string;

  client: AxiosInstance;

  clientUserId: string;

  headers: ArcHeaders;

  constructor({
    accessToken,
    clientUserId,
    onUnauthorized,
  }: {
    accessToken: string;
    clientUserId: string;
    onUnauthorized?: () => void;
  }) {
    this.baseURL = ARC_API_BASE_URL;
    this.clientUserId = clientUserId;
    this.headers = {
      Authorization: `Bearer ${accessToken}`,
      // When updating the version specified here, be sure to also update the schema referenced
      // by the generate:types:arcadia-api task
      'Arc-Version': '2021-11-17',
    };
    this.client = axios.create({
      baseURL: this.baseURL,
      headers: this.headers,
    });
    this.client.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        captureError({ error });
        if (error.response.status === 401) {
          onUnauthorized?.();
        }
        throw error;
      }
    );
  }

  private _get = async <Data>(
    url: string,
    params?: Record<string, unknown>
  ): Promise<Data> => {
    const result = await this.client.get(url, { params });
    return result.data;
  };

  private _post = async <Data>(
    url: string,
    body: Record<string, unknown>
  ): Promise<Data> => {
    const result = await this.client.post(url, body);
    return result.data;
  };

  getUtilityCredential = (
    utilityCredentialId: number
  ): APIResponse<'getUtilityCredential'> =>
    this._get(`utility_credentials/${utilityCredentialId}`);

  getUtilityCredentials = (): APIResponse<'getUtilityCredentials'> =>
    this._get('utility_credentials', { client_user_id: this.clientUserId });

  getUtilityAccounts = (): APIResponse<'getUtilityAccounts'> => {
    return this._get('utility_accounts', {
      client_user_id: this.clientUserId,
      limit: 100,
    });
  };

  getUtilityStatements = ({
    utilityAccountId,
  }: {
    utilityAccountId: number;
  }): APIResponse<'getUtilityStatements'> =>
    this._get('plug/utility_statements', {
      utility_account_id: utilityAccountId,
      limit: 100,
    });

  postChargeCurveCost = (
    input: APIRequestBody<'calculateChargeCost'>
  ): APIResponse<'calculateChargeCost'> => {
    return this._post('spark/charge_curve/cost', input);
  };

  postTariffRates = (
    input: APIRequestBody<'retrieveTariffRates'>
  ): APIResponse<'retrieveTariffRates'> => {
    return this._post('spark/tariff_rates', input);
  };

  postChargeCurveSchedule = (
    input: APIRequestBody<'calculateSmartChargeSchedule'>
  ): APIResponse<'calculateSmartChargeSchedule'> => {
    return this._post('spark/charge_curve/schedule', input);
  };

  getSmartChargeData = async (
    energyAdded: number,
    chargePower: number,
    chargeCurveScheduleInput?: APIRequestBody<'calculateSmartChargeSchedule'>
  ) => {
    if (!chargeCurveScheduleInput) return;
    const chargeCurveSchedule = await this.postChargeCurveSchedule(
      chargeCurveScheduleInput
    );

    const energyUsageKwh = calculateEnergyUsageKwhFromSmartChargeSchedule(
      energyAdded,
      chargePower,
      chargeCurveSchedule.charge_block_active
    );
    const chargeCurveCostInput: APIRequestBody<'calculateChargeCost'> = {
      charge_block_resolution: '15m',
      energy_usage_kwh: energyUsageKwh,
      start_time: chargeCurveSchedule.start_time,
      utility_account_id: chargeCurveScheduleInput.utility_account_id,
    };
    const chargeCurveCost = await this.postChargeCurveCost(
      chargeCurveCostInput
    );
    return {
      chargeCurveCost,
      chargeCurveCostInput,
      chargeCurveSchedule,
    };
  };

  submitEnergyAnalytics = async (input: EnergyAnalyticsInput) => {
    const [chargeCurveCost, tariffRates, smartCharge] = await Promise.all([
      this.postChargeCurveCost(input.chargeCurveCostInput),
      this.postTariffRates(input.tariffRatesInput),
      this.getSmartChargeData(
        input.energyAdded,
        input.chargePower,
        input.chargeCurveScheduleInput
      ),
    ]);
    return {
      chargeCurveCost,
      smartCharge,
      tariffRates,
    };
  };

  getIntervalData = ({
    utilityAccountId,
    startTime,
    endTime,
  }: {
    utilityAccountId: number;
    startTime: Date;
    endTime: Date;
  }): APIResponse<'getUtilityIntervals'> =>
    this._get('plug/utility_intervals', {
      utility_account_id: utilityAccountId,
      start_time: startTime.toISOString(),
      end_time: endTime.toISOString(),
    });
}

export { ArcAPIClient };
