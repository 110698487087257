import styled from 'styled-components';
import { Margin, marginStyles } from 'styles/margin.style';

export const InputGroup = styled.div<{ margin?: Margin }>`
  ${props => marginStyles(props.margin)}
  width: fit-content;
  justify-self: flex-end;
  flex: 1;
`;

export const ConnectPreviewIFrame = styled.iframe`
  margin-top: 16px;
  width: 400px;

  height: 676px;
  border-radius: 18px;
  border: 1px solid ${props => props.theme.colors.background.tertiary};

  ${props => props.theme.media.phone} {
    min-width: 0;
    max-width: 100%;
    min-height: 700px;
  }
`;
