import { useState, useMemo } from 'react';
import { copyFor } from 'config/copy';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { UtilityStatementArc } from 'typings/arcadia-api';
import { useArcAPIClientContext } from 'contexts/demo/arc-api-client';
import { Table } from 'components/table';
import { formatUtilityStatements } from './statement-table.utils';
import { TableSection, TableSectionWrapper } from './statement-table.style';
import { DataTableHeader } from '../data-table';
import { APIExplorer } from '../api-explorer';

const getCopy = copyFor('demo.results.plug.tables.utilityStatements');

export type Props = {
  available: boolean;
  accountError?: unknown;
  accountString?: string;
  selectedAccountId?: number;
  title: string;
};

export const StatementTable = ({
  accountString,
  accountError,
  available,
  selectedAccountId,
  title,
}: Props) => {
  const { apiClient } = useArcAPIClientContext();

  const {
    data: statementsData,
    error: statementsError,
    isLoading: statementsLoading,
  } = useQuery(
    ['getUtilityStatementsByAccountId', { selectedAccountId }],
    async () => {
      if (selectedAccountId)
        return apiClient.getUtilityStatements({
          utilityAccountId: selectedAccountId,
        });
    },
    {
      enabled: available,
    }
  );

  const [showJSONData, setShowJSONData] = useState(false);

  const error =
    (statementsError as Error | undefined) ||
    (accountError as Error | undefined);
  const loading = !error && statementsLoading;
  const data = statementsData?.data
    ? formatUtilityStatements(statementsData.data)
    : undefined;

  type EnhancedUtilityStatement = UtilityStatementArc & {
    service_period: string;
  };

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<EnhancedUtilityStatement>();
    return [
      columnHelper.accessor('statement_date', {
        id: getCopy('columns.statementDate'),
        meta: {
          width: '19%',
          overflow: 'wrap',
        },
      }),
      columnHelper.accessor('service_period', {
        id: getCopy('columns.servicePeriod'),
        meta: {
          width: '24%',
          overflow: 'wrap',
        },
      }),
      columnHelper.accessor('due_date', {
        id: getCopy('columns.dueDate'),
        meta: {
          width: '22%',
        },
      }),
      columnHelper.accessor('kwh', {
        id: getCopy('columns.kwh'),
        meta: {
          width: '20%',
        },
      }),
      columnHelper.accessor('utility_charge', {
        id: getCopy('columns.total'),
        meta: {
          width: '15%',
          horizontalAlign: 'right',
        },
      }),
    ];
  }, []);

  const renderBody = () => {
    if (showJSONData && statementsData) {
      return (
        <APIExplorer
          data={statementsData}
          requestType="GET"
          endpoint="/plug/utility_statements?utility_account_id={utility_account_id}"
        />
      );
    }

    return (
      <TableSection>
        <Table
          loading={loading}
          error={error}
          data={data}
          columns={columns}
          minWidth="750px"
          maxHeight="650px"
          sticky={true}
        />
      </TableSection>
    );
  };

  return (
    <TableSectionWrapper data-testid={`${title}-data-table`}>
      <DataTableHeader
        apiExplorerButtonEnabled={!!statementsData}
        title={title}
        error={error}
        loading={loading}
        helperText={getCopy('helperText')}
        subTitle={accountString}
        showAPIData={showJSONData}
        setShowAPIData={setShowJSONData}
      />
      {renderBody()}
    </TableSectionWrapper>
  );
};
