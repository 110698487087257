export const components = {
  paginationControls: {
    results: '%{range} of %{totalCount} results',
  },
  navBar: {
    backLink: {
      label: 'Back to results',
    },
    clientUserId: {
      label: 'Client user ID',
    },
    noValue: 'None available',
  },
  newPasswordInput: {
    label: 'Password',
    placeholder: '8+ characters',
    requirementsText: 'Must contain at least 8 characters and include a number',
    iconButtonAriaLabel: 'Toggle password visibility',
    name: 'password',
  },
  paymentMethods: {
    noPaymentMethods: 'No Payment Methods',
    header: 'Payment Methods',
  },
  paymentSource: {
    status: {
      default: 'Default',
      expired: 'Expired',
      other: 'Other',
    },
    creditCard: {
      expirationDate: 'Expires',
    },
    achTransfer: {
      name: 'Checking',
    },
  },
  paymentTimeline: {
    sections: {
      utilityStatement: {
        title: 'Utility Statement',
        pdfDownloadCTA: 'Download PDF',
        fields: {
          id: 'ID',
          kwh: 'Power consumption',
          utilityCharge: 'Utility charge',
          statementDate: 'Issue date',
          dueDate: 'Due date',
          gracePeriod: 'Utility payment window',
          servicePeriod: 'Service period',
          updatedAt: 'Last modified',
        },
        formatter: {
          gracePeriod: '%{value} days',
        },
      },
      paymentTransaction: {
        title: 'Payment Transaction',
        fields: {
          id: 'ID',
          paymentMethodId: 'Payment Method ID',
          status: 'Status',
          chargedAt: 'Charged at',
          finishedAt: 'Finished at',
          dispute: {
            pillCopy: 'Dispute',
            status: 'Dispute status',
            createdAt: 'Dispute created',
            closedStatus: 'Closed status',
            reason: 'Dispute reason',
          },
        },
      },
      utilityRemittance: {
        title: 'Utility Remittance',
        fields: {
          utilityRemittanceId: 'Utility Remittance ID',
          utilityName: 'Utility',
          checkNumber: 'eCheck check number',
          status: 'Status',
          submitted: 'Submitted',
          posted: 'Posted',
        },
      },
      utilityRemittanceItem: {
        title: 'Utility Remittance Item',
        fields: {
          id: 'ID',
          utilityStatementId: 'Utility Statement ID',
          clientUserId: 'Client user ID',
          status: 'Status',
          amountCents: 'Amount',
          createdAt: 'Created at',
          updatedAt: 'Updated at',
          scheduledAt: 'Scheduled at',
          remittedAt: 'Remitted at',
          sandboxed: 'Sandboxed',
        },
      },
      utilityRemittanceLineItem: {
        title: 'Utility Remittance Line Item',
        fields: {
          id: 'ID',
          createdAt: 'Created at',
          updatedAt: 'Updated at',
          amountCents: 'Amount',
          name: 'Name',
          type: 'Type',
        },
      },
      customLineItem: {
        title: 'Custom Line Item',
        fields: {
          id: 'ID',
          createdAt: 'Created at',
          updatedAt: 'Updated at',
          amountCents: 'Amount',
          name: 'Name',
          type: 'Type',
        },
      },
    },
  },
  teamMemberTable: {
    tableHeaders: {
      name: 'Name',
      email: 'Email',
    },
    noValue: 'None available',
    noResults: 'No results',
    resendInvite: {
      button: 'Resend',
      emailSent: 'Email sent',
      error: 'Something went wrong',
    },
  },
  table: {
    noValue: 'Not available',
    noResults: 'No results',
  },
};
