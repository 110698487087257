import { useQuery } from '@apollo/client';
import { Alert, Skeleton } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { useParams } from 'react-router';
import { Page, PageContent } from 'styles';
import { CustomerOverview } from 'components/customer-detail/customer-overview';
import { CustomerDetailNavBar } from 'components/customer-detail/nav-bar';
import { StatementTimeline } from 'components/customer-detail/statement-timeline';
import { PaymentMethods } from 'components/customer-detail/payment-methods';
import { GET_UTILITY_ACCOUNT } from './customer-detail.api';

const getCopy = copyFor('customerDetail');

export type CustomerDetailParams = {
  id: string;
};

export const CustomerDetail = (): JSX.Element => {
  const { id } = useParams<CustomerDetailParams>();
  const { data, error, loading } = useQuery<
    GetUtilityAccountQuery,
    GetUtilityAccountQueryVariables
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  >(GET_UTILITY_ACCOUNT, { variables: { id: id! } });

  const renderCustomerDetail = () => {
    if (loading) return <Skeleton />;
    if (error) {
      return (
        <Alert data-testid="customer-detail-loading-error">
          {getCopy('loadingError')}
        </Alert>
      );
    }
    if (data?.utilityAccount) {
      return (
        <>
          <CustomerDetailNavBar />
          <CustomerOverview utilityAccount={data.utilityAccount} />
        </>
      );
    }
  };

  const renderPaymentMethods = () => {
    if (loading) return <Skeleton />;
    if (data?.utilityAccount?.user) {
      return (
        <PaymentMethods
          paymentMethods={data.utilityAccount.user.paymentMethods}
        />
      );
    }
  };

  return (
    <Page>
      <PageContent>
        {renderCustomerDetail()}
        {renderPaymentMethods()}
        {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
        <StatementTimeline utilityAccountId={id!} hideStatements={loading} />
      </PageContent>
    </Page>
  );
};
