import { createContext, useMemo, useState } from 'react';
import { ActionConfirmationModal } from 'components/action-confirmation-modal';
import { noop } from 'utils/functions';

interface ModalContext {
  setNotificationModal: (params: NotificationPayload) => void;
}

const Context = createContext<ModalContext>({
  setNotificationModal: noop,
});

export interface NotificationPayload {
  cta: string;
  description: string;
  title: string;
}

interface Props {
  children: JSX.Element;
}

export const Provider = ({ children }: Props): JSX.Element => {
  const [notification, setNotification] = useState<NotificationPayload | null>(
    null
  );
  const clearNotification = (): void => {
    setNotification(null);
  };

  const contextValue = useMemo(
    () => ({
      setNotificationModal: setNotification,
    }),
    [setNotification]
  );

  const renderNotification = (): JSX.Element | null => {
    if (!notification) return null;
    const { cta, description, title } = notification;
    return (
      <ActionConfirmationModal
        onConfirmation={clearNotification}
        onClose={clearNotification}
        loading={false}
        copy={{
          cta,
          description,
          title,
        }}
      />
    );
  };

  return (
    <Context.Provider value={contextValue}>
      {children}
      {renderNotification()}
    </Context.Provider>
  );
};

export const NotificationModal = { Context, Provider };
