import styled from 'styled-components';
import { Button } from '@arcadiapower/shrike';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;

  ${props => props.theme.media.phone} {
    flex-direction: column;
  }
`;

export const NoWrapButton = styled(Button)`
  white-space: nowrap;
  margin-top: auto;
  margin-right: 8px;

  ${props => props.theme.media.phone} {
    margin: 8px 0;
  }
`;
