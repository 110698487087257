// The routes in this file should be used for any links or navigation commands.
// Use the paths file to define paths for the router in App.tsx
import * as paths from './paths';
export const resetPassword = `/${paths.resetPassword}`;
export const verifyResetToken = `/${paths.verifyResetToken}`;
export const confirmEmail = `/${paths.confirmEmail}`;
export const changePassword = `/${paths.changePassword}`;

export const login = `/${paths.login}`;
export const signup = `/${paths.signup}`;

export const overview = `/${paths.overview}`;
export const customers = `/${paths.customers}`;
export const customerDetail = `/${paths.customerDetail}`;
export const getCustomerDetailPath = (id: number) => `/customers/detail/${id}`;

export const settings = `/${paths.settings}`;

export const profile = `/${paths.settings}/${paths.profile}`;
export const team = `/${paths.settings}/${paths.team}`;

export const configuration = `/${paths.configuration}`;

export const apiKeys = `/${paths.configuration}/${paths.apiKeys}`;
export const customization = `/${paths.configuration}/${paths.customization}`;
export const webhooks = `/${paths.configuration}/${paths.webhooks}`;

export const demo = `/${paths.demo}`;
export const plug = `/${paths.demo}/${paths.plug}`;
export const spark = `/${paths.demo}/${paths.spark}`;

export const setUpAccount = `/${paths.setUpAccountBase}/in-progress`;

export const coverage = `/${paths.coverage}`;
