import { useCallback, useReducer, useMemo, useEffect } from 'react';
import qs from 'qs';
import { COVERAGE_PAGE_SIZE } from 'config/constants';
import { copyFor } from 'config/copy';
import { Page } from 'styles';
import { Select, Text } from '@arcadiapower/shrike';
import { useQuery } from '@apollo/client';
import { NavBarWithTitle } from 'components/nav-bar';
import { Table } from 'components/table';
import { createColumnHelper } from '@tanstack/react-table';
import { track, TrackEvents } from 'utils/analytics';
import { useLocation, useNavigate } from 'react-router';
import { removeProps } from '@arcadiapower/warbler';
import { formatSupportedArcProducts } from 'utils/formatters';
import {
  DebouncedSearchBar,
  EnhancedFilterOption,
} from 'components/debounced-search-bar';
import { parseSearchValue } from 'components/debounced-search-bar/debounced-search-utils';
import {
  TableHeaderSection,
  TableSection,
  TableSectionWrapper,
  TableHeaderSectionContent,
} from './coverage.style';
import { GET_UTILITIES, UtilityType } from './coverage.api';

export type UtilitiesTableState = GetUtilitiesQueryVariables & {
  page: number;
};

type Action =
  | {
      type: 'PAGINATE_FORWARDS';
      payload: { endCursor: string };
    }
  | { type: 'PAGINATE_BACKWARDS'; payload: { startCursor: string } }
  | { type: 'SEARCH'; payload: { searchTerm: string } }
  | {
      type: 'FILTER';
      payload: { filters: UtilitiesTableState['strictFilters'] };
    };

const utilitiesTableReducer = (
  state: UtilitiesTableState,
  action: Action
): UtilitiesTableState => {
  switch (action.type) {
    case 'PAGINATE_FORWARDS':
      return {
        after: action.payload.endCursor,
        first: COVERAGE_PAGE_SIZE,
        page: state.page + 1,
        searchTerm: state.searchTerm,
        strictFilters: state.strictFilters,
      };
    case 'PAGINATE_BACKWARDS':
      return {
        before: action.payload.startCursor,
        last: COVERAGE_PAGE_SIZE,
        page: state.page - 1,
        searchTerm: state.searchTerm,
        strictFilters: state.strictFilters,
      };
    case 'SEARCH':
      return {
        first: COVERAGE_PAGE_SIZE,
        page: 1,
        searchTerm: action.payload.searchTerm || undefined,
        strictFilters: state.strictFilters,
      };
    case 'FILTER':
      return {
        first: COVERAGE_PAGE_SIZE,
        page: 1,
        searchTerm: state.searchTerm,
        strictFilters: action.payload.filters,
      };
  }
};

const getCopy = copyFor('coverage');

export const FILTER_OPTIONS: EnhancedFilterOption<UtilityFilterEnum>[] = [
  { key: 'ID', tag: getCopy('tableControls.searchFilter.id') },
  { key: 'NAME', tag: getCopy('tableControls.searchFilter.name') },
  { key: 'SHORT_NAME', tag: getCopy('tableControls.searchFilter.shortName') },
  { key: 'STATE', tag: getCopy('tableControls.searchFilter.state') },
  { key: 'ZIP', tag: getCopy('tableControls.searchFilter.zip') },
];

interface ProductFilterOption {
  text: string;
  value: UtilityProductAvailabilityEnum | 'ALL_PRODUCTS';
  disabled?: boolean;
}

const PRODUCT_FILTER_OPTIONS: ProductFilterOption[] = [
  {
    text: getCopy('tableControls.productAvailabilityFilter.allProducts'),
    value: 'ALL_PRODUCTS',
  },
  {
    text: getCopy('tableControls.productAvailabilityFilter.plugIntervals'),
    value: 'PLUG_INTERVALS_SUPPORTED',
  },
  {
    text: getCopy('tableControls.productAvailabilityFilter.bundle'),
    value: 'BUNDLE_SUPPORTED',
    disabled: true,
  },
  {
    text: getCopy('tableControls.productAvailabilityFilter.plugStatements'),
    value: 'PLUG_STATEMENTS_SUPPORTED',
    disabled: true,
  },
  {
    text: getCopy('tableControls.productAvailabilityFilter.spark'),
    value: 'SPARK_SUPPORTED',
    disabled: true,
  },
];

type ParsedQueryString = {
  [Property in keyof UtilitiesTableState]: string;
};

export const Coverage = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();
  const initializeTableState = useCallback(() => {
    const searchParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as ParsedQueryString;

    const { after, before, first, last, page, searchTerm, strictFilters } =
      searchParams;

    const result: UtilitiesTableState = {
      after,
      before,
      first: first ? parseInt(first ?? `${COVERAGE_PAGE_SIZE}`) : undefined,
      last: last ? parseInt(last) : undefined,
      page: parseInt(page ?? '1'),
      searchTerm,
      strictFilters: strictFilters as UtilitiesTableState['strictFilters'],
    };

    if (!result.first && !result.last) {
      result.first = COVERAGE_PAGE_SIZE;
    }
    return removeProps(result, [undefined, null]) as UtilitiesTableState;
  }, [location]);

  const [utilitiesTableState, dispatch] = useReducer<
    typeof utilitiesTableReducer
  >(utilitiesTableReducer, initializeTableState());

  useEffect(() => {
    const persistTableState = () => {
      const locationWithQueryVariables = `${location.pathname}?${qs.stringify(
        utilitiesTableState,
        { encode: false }
      )}`;
      navigate(locationWithQueryVariables, { replace: true });
    };
    persistTableState();
    // Note - need to specify location.pathname in the dependency array instead of
    // location to prevent infinite rerenders
  }, [utilitiesTableState, location.pathname, navigate]);

  const queryVariables: GetUtilitiesQueryVariables = useMemo(() => {
    // the variables for get tenant utility account does not include page
    const { page, searchTerm: rawSearchTerm, ...value } = utilitiesTableState;
    if (rawSearchTerm) {
      const { filterKey, searchTerm } = parseSearchValue(
        rawSearchTerm,
        FILTER_OPTIONS
      );
      return { ...value, filter: filterKey, searchTerm };
    }
    return value;
  }, [utilitiesTableState]);

  const { data, previousData, error, loading } = useQuery<
    GetUtilitiesQuery,
    GetUtilitiesQueryVariables
  >(GET_UTILITIES, {
    fetchPolicy: 'network-only',
    variables: queryVariables,
  });

  const utilities = useMemo(
    () => data?.utilities || previousData?.utilities,
    [data, previousData]
  );

  const paginateForwards = useCallback(() => {
    const endCursor = utilities?.pageInfo.endCursor;
    if (endCursor) {
      dispatch({
        payload: { endCursor },
        type: 'PAGINATE_FORWARDS',
      });
    }
  }, [utilities]);

  const paginateBackwards = useCallback(() => {
    const startCursor = utilities?.pageInfo.startCursor;
    if (startCursor) {
      dispatch({
        payload: { startCursor },
        type: 'PAGINATE_BACKWARDS',
      });
    }
  }, [utilities]);

  const handleSearch = useCallback((searchTerm: string) => {
    track(TrackEvents.COVERAGE_PAGE_SEARCHED, { searchTerm });
    dispatch({ payload: { searchTerm }, type: 'SEARCH' });
  }, []);

  const selectProductFilter = useCallback(
    (filter: ProductFilterOption['value']) => {
      track(TrackEvents.COVERAGE_PAGE_PRODUCTS_FILTERED, { filter });
      if (filter === 'ALL_PRODUCTS') {
        dispatch({ payload: { filters: {} }, type: 'FILTER' });
      } else {
        dispatch({
          payload: { filters: { productAvailability: [filter] } },
          type: 'FILTER',
        });
      }
    },
    []
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<UtilityType>();
    return [
      columnHelper.accessor('id', {
        id: getCopy('table.columns.id'),
        meta: {
          width: '10%',
        },
        enableHiding: true,
      }),
      columnHelper.accessor('name', {
        id: getCopy('table.columns.name'),
        meta: {
          width: ['40%', '30%'],
          overflow: 'wrap',
        },
      }),
      columnHelper.accessor('shortName', {
        id: getCopy('table.columns.shortName'),
        meta: {
          width: '15%',
        },
        enableHiding: true,
      }),
      columnHelper.accessor('state.abbreviation', {
        id: getCopy('table.columns.state'),
        meta: {
          width: ['20%', '10%'],
        },
      }),
      columnHelper.accessor(
        row => formatSupportedArcProducts(row.productAvailability),
        {
          id: getCopy('table.columns.products'),
          meta: {
            width: ['40%', '30%'],
            overflow: 'wrap',
          },
        }
      ),
    ];
  }, []);

  const renderFilters = () => (
    <Select
      name="supported-products"
      label={getCopy('tableControls.productAvailabilityFilter.label')}
      value={utilitiesTableState.strictFilters?.productAvailability?.[0] || ''}
      onChange={value =>
        selectProductFilter(value as ProductFilterOption['value'])
      }
      options={PRODUCT_FILTER_OPTIONS}
    />
  );

  const renderTable = () => {
    const paginationProps = {
      pageInfo: utilities?.pageInfo,
      pageSize: COVERAGE_PAGE_SIZE,
      page: utilitiesTableState.page,
      handlePaginationForwards: paginateForwards,
      handlePaginationBackwards: paginateBackwards,
      totalCount: utilities?.totalCount,
    };
    return (
      <Table
        loading={loading}
        error={error}
        data={utilities?.nodes}
        columns={columns}
        renderFilters={renderFilters}
        paginationProps={paginationProps}
        minWidth={['350px', '700px', '1000px']}
      />
    );
  };

  return (
    <Page secondaryBackground>
      <NavBarWithTitle title={getCopy('navTitle')} />
      <TableHeaderSection>
        <TableHeaderSectionContent>
          <Text textStyle="heading800" margin={{ bottom: '8px' }}>
            {getCopy('title')}
          </Text>
          <Text color="secondary" margin={{ bottom: '16px' }}>
            {getCopy('subtitle')}
          </Text>
          <DebouncedSearchBar
            initialSearchInput={utilitiesTableState.searchTerm}
            onSearch={handleSearch}
            label={getCopy('tableControls.searchLabel')}
            name="search"
            filterOptions={FILTER_OPTIONS}
          />
        </TableHeaderSectionContent>
      </TableHeaderSection>
      <TableSectionWrapper>
        <TableSection>{renderTable()}</TableSection>
      </TableSectionWrapper>
    </Page>
  );
};
