import { useState, useMemo, useCallback, useEffect } from 'react';
import { FilteredSearchInputProps } from '@arcadiapower/shrike';
import { debounce } from 'lodash-es';
import { StyledFilteredSearchInput } from './debounced-search-bar.style';

// Add on "key" to the filter option in Osprey so the "label" of the filter can
// be different than the tag
export type EnhancedFilterOption<Key = string> = Readonly<
  FilteredSearchInputProps['filterOptions'][number] & {
    key: Key;
  }
>;

export type Props = {
  className?: string;
  initialSearchInput?: string | null;
  onSearch: (
    searchTerm: string,
    filterKey?: string,
    filterValue?: string
  ) => void;
  label: string;
  name: string;
  filterOptions?: EnhancedFilterOption[];
};

/**
 *
 * Note - this component acts like the Shrike component, and does not attempt to
 * parse the search values on its own.
 *
 * You should use helper functions under debounced-search-bar.utils to
 * assist you in an internationalization friendly way
 */
export const DebouncedSearchBar = ({
  className,
  initialSearchInput,
  onSearch,
  label,
  filterOptions,
  name,
}: Props): JSX.Element => {
  const [searchInput, setSearchInput] = useState<string>(
    initialSearchInput || ''
  );

  const debouncedSearch = useMemo(() => debounce(onSearch, 300), [onSearch]);

  useEffect(() => {
    if (initialSearchInput === undefined) {
      setSearchInput('');
    }
  }, [initialSearchInput]);

  useEffect(() => {
    return () => debouncedSearch.cancel();
  }, [debouncedSearch]);

  const shortCircuitSearch = useCallback(
    (value: string) => {
      debouncedSearch.cancel();
      onSearch(value);
    },
    [onSearch, debouncedSearch]
  );

  const handleSearchChange = useCallback(
    (value: string) => {
      // Immediately set search input for user feedback
      setSearchInput(value);
      // Only send to parent once the debounce is done
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  return (
    <StyledFilteredSearchInput
      className={className}
      value={searchInput}
      label={label}
      placeholder={label}
      hideLabel={true}
      name={name}
      onChange={handleSearchChange}
      onEnter={shortCircuitSearch}
      filterOptions={filterOptions ?? []}
    />
  );
};
