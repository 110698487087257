export const signup = {
  title: 'Sign up for access to the Arc platform.',
  signUpSubHead: {
    startText:
      'Not ready to sign up, but want to learn more? Fill out a quick ',
    link: 'contact form',
    endText: ' and a member of our team will be in touch.',
  },
  autofillButton: 'Autofill',
};
