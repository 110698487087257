import { useState } from 'react';
import { Icon, Text, Tooltip } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import {
  EnergyAnalyticsInput,
  EnergyAnalyticsQueryData,
} from 'hooks/use-energy-analytics.hook.js';
import { TableTitleRow, TableTitleWrapper } from 'components/demo/data-table';
import { APIExplorer } from '../api-explorer';
import { APIExplorerButton } from '../api-explorer-button';
import { EnergyAnalyticsChart } from '../energy-analytics-chart';
import { Wrapper } from './demo-spark-results.style';

const getCopy = copyFor('spark.results');

export interface MapApiExplorerPropsInput {
  chargeCurveSchedule?: NonNullable<
    EnergyAnalyticsQueryData['smartCharge']
  >['chargeCurveSchedule'];
  chargeCurveCost: EnergyAnalyticsQueryData['chargeCurveCost'];
  queryInput: EnergyAnalyticsInput | null;
}

// Exported only for testing
export const mapApiExplorerProps = ({
  chargeCurveSchedule,
  chargeCurveCost,
  queryInput,
}: MapApiExplorerPropsInput) => {
  if (chargeCurveSchedule) {
    return {
      data: chargeCurveSchedule,
      endpoint: '/spark/charge_curve/schedule',
      payload: queryInput?.chargeCurveScheduleInput,
      requestType: 'POST' as const,
    };
  }
  return {
    data: chargeCurveCost,
    endpoint: '/spark/charge_curve/cost',
    payload: queryInput?.chargeCurveCostInput,
    requestType: 'POST' as const,
  };
};

export interface SparkResultsProps {
  data: EnergyAnalyticsQueryData;
  queryInput: EnergyAnalyticsInput | null;
}

export const SparkResults = ({ data, queryInput }: SparkResultsProps) => {
  const { chargeCurveCost, tariffRates, smartCharge } = data;
  const [showJSONData, setShowJSONData] = useState(false);

  const renderBody = () => {
    if (showJSONData) {
      const apiExplorerProps = mapApiExplorerProps({
        chargeCurveCost,
        chargeCurveSchedule: smartCharge?.chargeCurveSchedule,
        queryInput,
      });
      return <APIExplorer {...apiExplorerProps} />;
    }

    const chargeCurveCostToChart =
      smartCharge?.chargeCurveCostInput ?? queryInput?.chargeCurveCostInput;

    if (!chargeCurveCostToChart) return null;

    return (
      <EnergyAnalyticsChart
        chargeCurveCostToChart={chargeCurveCostToChart}
        chargeCurveCostData={chargeCurveCost}
        tariffRatesData={tariffRates}
        smartChargeData={smartCharge}
      />
    );
  };

  return (
    <Wrapper data-testid="spark-results">
      <TableTitleRow>
        <TableTitleWrapper>
          <Text textStyle="heading700" tag="h3" margin={{ right: '9px' }}>
            {getCopy('title')}
          </Text>
          <Tooltip
            content={getCopy('energyAnalyticsChart.helperText')}
            tooltipId="energy-analytics-chart-tooltip"
            label="Energy analytics chart helper text"
            maxWidth={300}
            data-testid="energy-analytics-chart-helper-text"
          >
            <Icon icon="InfoCircleInverted"></Icon>
          </Tooltip>
        </TableTitleWrapper>
        <APIExplorerButton
          showAPI={showJSONData}
          setShowAPI={setShowJSONData}
          type="graph"
        />
      </TableTitleRow>
      {renderBody()}
    </Wrapper>
  );
};
