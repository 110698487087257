import styled, { css } from 'styled-components';
import { Text, Button } from '@arcadiapower/shrike';
import { Form as FormComponent } from 'components/form';

const singleContentMixin = css`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;
  min-height: 100%;
`;

export const SingleContentNarrow = styled.div`
  ${singleContentMixin}
  max-width: 550px;
  width: 100%;
  margin: 0 auto;

  ${props => props.theme.media.desktopAndTablet} {
    padding: 48px;
  }

  ${props => props.theme.media.phone} {
    padding: 40px 32px 48px;
  }
`;

export const Title = styled(Text).attrs({
  fontWeight: 700,
  margin: { bottom: '20px' },
  tag: 'h1',
  textStyle: 'heading900',
})``;

export const SubTitle = styled(Text).attrs({
  margin: { bottom: '10px' },
})``;

export const StandaloneTitle = styled(Title).attrs({
  margin: { bottom: '40px' },
})``;

export const PrimaryButton = styled(Button).attrs({
  backgroundColor: 'primaryInverse',
  fullWidth: true,
  margin: { bottom: '40px' },
})``;

export const ActionWrapper = styled.div`
  margin-top: 24px;
`;

export const CenteredContentNarrow = styled(SingleContentNarrow)`
  margin-bottom: 10rem; // Adjust centering to account for the nav bar.
  justify-content: center;
  text-align: center;
`;

export const Form = styled(FormComponent)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
