import storePackage from 'store/dist/store.modern';
import expirePlugin from 'store/plugins/expire';

type Store = Omit<StoreJsAPI, 'set'> & {
  set(key: string, value: any, expiration?: number): void;
};

storePackage.addPlugin(expirePlugin);

export const store = storePackage as Store;
