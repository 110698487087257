import { gql } from '@apollo/client';

export const UPDATE_RESOURCE_OWNER = gql`
  mutation updateResourceOwner($input: UpdateResourceOwnerInput!) {
    updateResourceOwner(input: $input) {
      resourceOwner {
        id
        email
        firstName
        lastName
        status
      }
      accessToken {
        token
        expiresAt
      }
    }
  }
`;

export const generateUpdateResourceOwnerOutput = ({
  id,
  email,
  withToken,
  firstName,
  lastName,
  status = 'confirmed',
}: {
  id: string;
  email: string;
  withToken: boolean;
  status: string;
  firstName?: string | null;
  lastName?: string | null;
}): UpdateResourceOwnerMutation => {
  const result: UpdateResourceOwnerMutation = {
    updateResourceOwner: {
      accessToken: null,
      resourceOwner: {
        email,
        firstName,
        id,
        lastName,
        status,
      },
    },
  };
  if (withToken) {
    result.updateResourceOwner.accessToken = {
      expiresAt: '2021-10-14 19:27:28 -0400',
      token: 'myToken',
    };
  }
  return result;
};
