import { css } from 'styled-components';

export type Margin =
  | string
  | { top?: string; bottom?: string; left?: string; right?: string };

// Taken from shrike
export const marginStyles = (margin?: Margin): ReturnType<typeof css> => css`
  ${typeof margin === 'string' &&
  `margin: ${typeof margin === 'string' && margin};`}

  ${typeof margin === 'object' &&
  `
    margin-top: ${margin?.top};
    margin-right: ${margin?.right};
    margin-bottom: ${margin?.bottom};
    margin-left: ${margin?.left};

  `}
`;
