import styled from 'styled-components';

export const Wrapper = styled.article`
  display: flex;
  border: 1px solid ${props => props.theme.colors.background.tertiary};
  border-radius: 8px;
  padding: 22px;
  flex-flow: column nowrap;
`;

export const StatementCardContents = styled.section`
  margin-top: 22px;
`;
