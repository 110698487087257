import { gql } from '@apollo/client';
import { formatDate } from '@arcadiapower/warbler';

export const GET_STATEMENT_TIMELINE = gql`
  query getStatementTimeline($utilityAccountId: ID!) {
    utilityAccount(id: $utilityAccountId) {
      id
      bundleEnrollment {
        utilityRemittance
        hostedPayments
      }
      featureAvailability
      productSubscriptions {
        id
        billStartDate
        enrollmentEndDate
        product {
          id
          key
          name
        }
      }
      statementTimeline {
        id
        date
        type
        status
        total
        links {
          text
          url
        }
        utilityStatements {
          id
          createdAt
          updatedAt
          pdfUrl
          dueDate
          gracePeriod
          kwh
          serviceDays
          statementDate
          utilityCharge
          serviceStartDate
          serviceEndDate
        }
        utilityRemittanceItems {
          id
          utilityStatementId
          clientUserId
          status
          amountCents
          createdAt
          updatedAt
          scheduledAt
          remittedAt
          sandboxed
        }
        utilityRemittanceLineItem {
          id
          createdAt
          updatedAt
          amountCents
          name
          utilityStatementId
          type
        }
        customLineItems {
          id
          createdAt
          updatedAt
          amountCents
          name
          type
        }
        utilityRemittances {
          id
          createdAt
          posted
          submitted
          status
          checkNumbers
          utility {
            id
            name
          }
        }
        paymentTransactions {
          brand
          id
          lastFour
          paymentMethodId
          status
          createdAt
          finishedAt
        }
      }
    }
  }
`;

export const generateGetStatementTimeline = ({
  hostedPaymentsStatus = 'ENROLLED',
  utilityRemittanceStatus = 'ENROLLED',
  statements = [generateStatement()],
  productSubscriptions = [generateProductSubscription()],
}: {
  statements?: StatementTimelineItem[];
  hostedPaymentsStatus?: BundleEnrollmentForTimeline['hostedPayments'];
  utilityRemittanceStatus?: BundleEnrollmentForTimeline['utilityRemittance'];
  productSubscriptions?: ProductSubscription[];
} = {}): GetStatementTimelineQuery => ({
  utilityAccount: {
    __typename: 'UtilityAccount',
    id: 2,
    bundleEnrollment: {
      utilityRemittance: utilityRemittanceStatus,
      hostedPayments: hostedPaymentsStatus,
    },
    featureAvailability: {
      hosted_payments: hostedPaymentsStatus,
      utility_remittance: utilityRemittanceStatus,
    },
    productSubscriptions,
    statementTimeline: statements,
  },
});

export const generateProductSubscription = ({
  billStartDate = '2022-01-01',
  enrollmentEndDate = null,
}: {
  billStartDate?: string;
  enrollmentEndDate?: string | null;
} = {}): ProductSubscription => ({
  billStartDate,
  enrollmentEndDate,
  id: 1234,
  product: { id: 123, key: 'hosted_payments', name: 'Hosted Payment' },
});

export type StatementTimelineItem = NonNullable<
  GetStatementTimelineQuery['utilityAccount']
>['statementTimeline'][0];

export const generateStatement = (
  statement?: Partial<Statement>
): StatementTimelineItem => ({
  date: formatDate(new Date(), { style: 'input' }),
  id: 'invoice-12',
  links: [],
  paymentTransactions: [generatePaymentTransaction()],
  status: 'paid',
  total: 12345,
  type: 'INVOICE',
  utilityRemittances: [generateUtilityRemittance()],
  utilityStatements: [generateUtilityStatement()],
  utilityRemittanceItems: [generateUtilityRemittanceItem()],
  utilityRemittanceLineItem: generateUtilityRemittanceLineItem(),
  customLineItems: [generateCustomLineItem()],
  ...statement,
});

export type UtilityStatementForTimeline = NonNullable<
  GetStatementTimelineQuery['utilityAccount']
>['statementTimeline'][0]['utilityStatements'][0];

export type PaymentTransactionForTimeline = NonNullable<
  GetStatementTimelineQuery['utilityAccount']
>['statementTimeline'][0]['paymentTransactions'][0];

export type UtilityRemittanceForTimeline = NonNullable<
  GetStatementTimelineQuery['utilityAccount']
>['statementTimeline'][0]['utilityRemittances'][0];

export type UtilityRemittanceItemForTimeline = NonNullable<
  GetStatementTimelineQuery['utilityAccount']
>['statementTimeline'][0]['utilityRemittanceItems'][0];

export type CustomLineItemForTimeline = NonNullable<
  NonNullable<
    GetStatementTimelineQuery['utilityAccount']
  >['statementTimeline'][0]['customLineItems']
>[0];

export type UtilityRemittanceLineItemForTimeline = NonNullable<
  NonNullable<
    GetStatementTimelineQuery['utilityAccount']
  >['statementTimeline'][0]['utilityRemittanceLineItem']
>;

export type BundleEnrollmentForTimeline = NonNullable<
  GetStatementTimelineQuery['utilityAccount']
>['bundleEnrollment'];

export const generateUtilityStatement = (
  utilityStatement?: Partial<UtilityStatementForTimeline>
): UtilityStatementForTimeline => {
  const baseUtilityStatement: UtilityStatementForTimeline = {
    createdAt: '2022-01-24T18:22:35-05:00',
    dueDate: '2022-02-08',
    gracePeriod: 13,
    id: 12,
    kwh: 143,
    pdfUrl: 'sample_url',
    serviceDays: 28,
    serviceEndDate: '2022-01-06',
    serviceStartDate: '2021-12-09',
    statementDate: '2022-01-08',
    updatedAt: '2022-01-24T18:22:35-05:00',
    utilityCharge: 35,
  };
  return { ...baseUtilityStatement, ...utilityStatement };
};

export const generatePaymentTransaction = (
  paymentTransaction?: Partial<PaymentTransactionForTimeline>
): PaymentTransactionForTimeline => {
  const basePaymentTranaction: PaymentTransactionForTimeline = {
    brand: 'master card',
    createdAt: '2022-01-24T18:22:35-05:00',
    finishedAt: '2022-01-24T18:22:35-05:00',
    id: '1',
    lastFour: '9876',
    paymentMethodId: 408,
    status: 'test',
  };
  return { ...basePaymentTranaction, ...paymentTransaction };
};

export const generateUtilityRemittance = (
  utilityRemittance?: Partial<UtilityRemittanceForTimeline>
): UtilityRemittanceForTimeline => {
  const baseUtilityRemittance: UtilityRemittanceForTimeline = {
    checkNumbers: ['12349'],
    createdAt: '2022-01-24T18:22:35-05:00',
    id: '123459',
    posted: '2022-01-24T18:22:35-05:00',
    status: 'Approved',
    submitted: '2022-01-24T18:22:35-05:00',
    utility: {
      id: 777,
      name: 'testing',
    },
  };
  return { ...baseUtilityRemittance, ...utilityRemittance };
};

export const generateUtilityRemittanceItem = (
  utilityRemittanceItem?: Partial<UtilityRemittanceItemForTimeline>
): UtilityRemittanceItemForTimeline => {
  const baseUtilityRemittanceItem: UtilityRemittanceItemForTimeline = {
    id: '123-fake',
    utilityStatementId: 123,
    clientUserId: 'fake',
    status: 'test',
    amountCents: 123,
    createdAt: '2022-01-24T18:22:35-05:00',
    updatedAt: '2022-01-24T18:22:35-05:00',
    scheduledAt: '2022-01-24T18:22:35-05:00',
    remittedAt: '2022-01-24T18:22:35-05:00',
    sandboxed: true,
  };
  return { ...baseUtilityRemittanceItem, ...utilityRemittanceItem };
};

export const generateUtilityRemittanceLineItem = (
  utilityRemittanceLineItem?: Partial<UtilityRemittanceLineItemForTimeline>
): UtilityRemittanceLineItemForTimeline => {
  const baseUtilityRemittanceLineItem: UtilityRemittanceLineItemForTimeline = {
    id: '123-fake',
    createdAt: '2022-01-24T18:22:35-05:00',
    updatedAt: '2022-01-24T18:22:35-05:00',
    amountCents: 123,
    name: 'test',
    utilityStatementId: 123,
    type: 'test',
  };
  return { ...baseUtilityRemittanceLineItem, ...utilityRemittanceLineItem };
};

export const generateCustomLineItem = (
  customLineItem?: Partial<CustomLineItemForTimeline>
): CustomLineItemForTimeline => {
  const baseCustomLineItem: CustomLineItemForTimeline = {
    id: '123-fake',
    createdAt: '2022-01-24T18:22:35-05:00',
    updatedAt: '2022-01-24T18:22:35-05:00',
    amountCents: 123,
    name: 'some dope credit',
    type: 'credit',
  };
  return { ...baseCustomLineItem, ...customLineItem };
};
