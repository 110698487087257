import { gql } from '@apollo/client';

export const VALIDATE_PASSWORD = gql`
  mutation validatePassword($input: ValidatePasswordInput!) {
    validatePassword(input: $input) {
      success
    }
  }
`;

export const generateValidatePassword = ({
  success,
}: {
  success: boolean;
}): ValidatePasswordMutation => ({
  validatePassword: {
    success,
  },
});
