import { format } from 'date-fns';
import { EnergyAnalyticsQueryData } from 'hooks/use-energy-analytics.hook';
import { addTime } from '@arcadiapower/warbler';

export const getStartOfNextHour = (date = new Date()) => {
  date.setHours(date.getHours() + 1, 0, 0, 0);
  return date;
};

type Frequency =
  EnergyAnalyticsQueryData['tariffRates']['rates_cost_per_kwh']['freq'];

export const formatTimeSeriesItem = (
  value: string | number,
  interval: Frequency
) => {
  const startTime = new Date(value);
  const minutes = determineIntervalMinutes(interval);
  const endTime = addTime(startTime, { minutes });
  const formatDate = (date: Date) => format(date, 'h:mm aa');
  return `${formatDate(startTime)} - ${formatDate(endTime)}`;
};

export const determineIntervalMinutes = (interval: Frequency): number => {
  switch (interval) {
    case '5m':
      return 5;
    case '10m':
      return 10;
    case '15m':
      return 15;
    case '30m':
      return 30;
    case '1h':
      return 60;
    default:
      // eslint-disable-next-line no-console
      console.error('Unknown interval: ', interval);
      return 15;
  }
};
