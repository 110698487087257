import styled from 'styled-components';
import { Button } from '@arcadiapower/shrike';

export const ShowAPIButton = styled(Button).attrs({
  backgroundless: true,
  color: 'primary',
  size: 'small',
})`
  ${props => props.theme.media.phone} {
    margin-left: auto;
    margin-right: auto;
  }
`;
