import styled from 'styled-components';
import { TableSectionHeader } from 'styles/table.style';

export const ReadOnlyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const URLWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-wrap: anywhere;
  max-width: 90%;
  flex-grow: 2;
`;

export const ItemHeader = styled(TableSectionHeader)`
  margin-bottom: 16px;
`;

export const TestOrEnableHeader = styled(TableSectionHeader)`
  justify-content: flex-start;
  gap: 10px;
`;
