import { gql } from '@apollo/client';

export const CONVERT_SAML_TOKEN_TO_ACCESS_TOKEN = gql`
  mutation convertSamlTokenToAccessToken(
    $input: ConvertSAMLTokenToAccessTokenInput!
  ) {
    convertSamlTokenToAccessToken(input: $input) {
      accessToken {
        token
        expiresAt
      }
      resourceOwner {
        id
        email
      }
    }
  }
`;

export const generateConvertSamlTokenToAccessToken =
  (): ConvertSamlTokenToAccessTokenMutation => ({
    convertSamlTokenToAccessToken: {
      accessToken: {
        expiresAt: '2021-10-14 19:27:28 -0400',
        token: 'myToken',
      },
      resourceOwner: {
        email: 'my.cool.email@gmail.com',
        id: '2',
      },
    },
  });
