import { UtilityAccountArc, UtilityCredentialArc } from 'typings/arcadia-api';

// We can assume there is only one credential as the credential was just created by the widget
export const utilityCredentialStatusIs = (
  utilityCredentials: UtilityCredentialArc[],
  status: UtilityCredentialArc['verification_status']
): boolean => utilityCredentials[0].verification_status === status;

export const isRealCredential = (
  utilityCredentials: UtilityCredentialArc[]
): boolean => !utilityCredentials[0].uses_test_scenario;

export const utilityCredentialDataLoadInProgress = (
  utilityCredentials: UtilityCredentialArc[]
): boolean => utilityCredentials[0].accounts_load_in_progress;

export const utilityStatementDataLoadInProgress = (
  utilityAccounts: UtilityAccountArc[]
): boolean =>
  utilityAccounts.some(account =>
    ['NOT_READY'].includes(account.feature_availability.utility_statements)
  );

export const intervalDataLoadInProgress = (
  utilityAccounts: UtilityAccountArc[]
): boolean =>
  utilityAccounts.some(account =>
    ['NOT_READY'].includes(account.feature_availability.utility_intervals)
  );

export const hasUtilityAccounts = (
  utilityAccounts: UtilityAccountArc[]
): boolean => !!utilityAccounts.length;
