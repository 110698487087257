import styled from 'styled-components';
import JSONPretty from 'react-json-pretty';
import { Text } from '@arcadiapower/shrike';
import { theme } from 'config/theme';

export const Wrapper = styled.div`
  display: grid;
  max-width: 100%;
  color-scheme: dark;
  grid-template-areas:
    'request_header response_header'
    'request response';
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr 1fr;
  background-color: #1e1e1e;
  align-items: start;
  ${props => props.theme.media.phone} {
    overflow-x: auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      'request_header'
      'request'
      'response_header'
      'response';
  }
`;

const APIHeader = styled(Text).attrs({
  color: 'accent8',
  tag: 'h4',
  textStyle: 'heading600',
})`
  background-color: ${props => props.theme.colors.background.primaryInverse};
  padding: 16px 24px;
`;

export const RequestsHeader = styled(APIHeader)`
  grid-area: request_header;
`;

export const ResponseHeader = styled(APIHeader)`
  grid-area: response_header;
`;

export const RequestWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  grid-area: request;
  padding: 24px;
  max-width: 100%;
  overflow-x: auto;
`;

export const prettyJSONTheme = {
  boolean: `color:${theme.colors.content.accent6};`,
  error: `line-height:1.3;color:${theme.colors.content.primaryInverse};background:#1e1e1e;overflow:auto;`,
  key: `color:${theme.colors.content.accent4};`,
  main: `line-height:1.3;color:${theme.colors.content.primaryInverse};background:#1e1e1e;overflow:auto;max-width:100%;font-family:courier;font-size:14px`,
  string: `color:${theme.colors.content.accent3};`,
  value: `color:${theme.colors.content.accent7};`,
};

export const Response = styled(JSONPretty).attrs({ theme: prettyJSONTheme })`
  grid-area: response;
  padding: 24px;
  max-width: 100%;
  max-height: 750px;
  overflow: auto;
`;

export const RequestType = styled(Text).attrs({
  fontSize: 'small',
  variant: 'onBackground',
})<{ type: 'GET' | 'POST' }>`
  background-color: ${props =>
    props.type === 'GET'
      ? props.theme.colors.background.accent8
      : props.theme.colors.background.accent7};

  padding: 3px 8px;
  height: min-content;
  border-radius: 4px;
  margin-right: 16px;
`;

export const Endpoint = styled(Text).attrs({ fontSize: 'small' })`
  color: white;
`;
