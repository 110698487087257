// eslint-disable-next-line no-useless-escape
const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidEmail = (email: string): boolean => emailRegExp.test(email);

export const validPasswordRegex = /^(?=.*\d)(?=.*[A-Za-z]).{8,}$/;

export const isValidPassword = (password: string): boolean =>
  !!password.match(validPasswordRegex);

export const isValidCompanyName = (name: string): boolean => name.length < 50;
