import styled from 'styled-components';

import { StandaloneTitle, SubTitle } from 'styles/single-content.style';

export const SignUpStandAloneTitle = styled(StandaloneTitle).attrs({
  margin: { bottom: '40px' },
})``;

export const SignUpSubTitle = styled(SubTitle).attrs({
  margin: { bottom: '40px' },
})``;

export const AutofillButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  ${props => props.theme.media.phone} {
    justify-content: flex-start;
  }
`;
