import { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Text, Link, Input } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import {
  Form,
  PrimaryButton,
  ActionWrapper,
} from 'styles/single-content.style';
import { debounce } from 'lodash-es';
import { resetPassword, signup } from 'config/routes';
import { CenteredText } from 'styles/centered-text.style';
import { PaddedCenteredText } from './login.style';

export type LoginSubmitParams = { email: string; password: string };

export type Props = {
  className?: string;
  onSubmit: (params: LoginSubmitParams) => void;
  pending: boolean;
  errorMessage?: string;
  checkSsoLogin: (email: string) => void;
};

const getCopy = copyFor('forms.login');

export const Login = ({
  onSubmit,
  className,
  errorMessage,
  pending,
  checkSsoLogin,
}: Props): JSX.Element => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const canSubmit = email && password;

  const handleSubmit = () => {
    onSubmit({ email, password });
  };

  const debouncedSsoCheck = useMemo(
    () => debounce(checkSsoLogin, 600),
    [checkSsoLogin]
  );

  useEffect(() => {
    return () => debouncedSsoCheck.cancel();
  }, [debouncedSsoCheck]);

  const handleEmailChange = useCallback(
    (value: string) => {
      setEmail(value);
      debouncedSsoCheck(value);
    },
    [debouncedSsoCheck]
  );

  return (
    <Form onSubmit={handleSubmit} className={className}>
      <PaddedCenteredText>
        <Text color="secondary">
          {getCopy('signUpCta.text')}
          <Link to={signup} type="inline" openInNewTab={false}>
            {getCopy('signUpCta.link')}
          </Link>
        </Text>
      </PaddedCenteredText>
      <Input
        onChange={handleEmailChange}
        name={getCopy('inputs.email.name')}
        label={getCopy('inputs.email.label')}
        placeholder={getCopy('inputs.email.placeholder')}
        value={email}
        margin={{ bottom: '24px', top: '24px' }}
      />
      <Input
        onChange={setPassword}
        name={getCopy('inputs.password.name')}
        label={getCopy('inputs.password.label')}
        placeholder={getCopy('inputs.password.placeholder')}
        type="password"
        value={password}
        autoComplete="current-password"
        margin={{ bottom: '24px' }}
      />
      {errorMessage && <Alert>{errorMessage}</Alert>}
      <ActionWrapper>
        <PrimaryButton type="submit" loading={pending} disabled={!canSubmit}>
          {getCopy('cta')}
        </PrimaryButton>
        <CenteredText>
          <Text color="secondary">
            <Link to={resetPassword} type="inline" openInNewTab={false}>
              {getCopy('forgotPassword.link')}
            </Link>
          </Text>
        </CenteredText>
      </ActionWrapper>
    </Form>
  );
};
