import { gql } from '@apollo/client';

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: UpdateResourceOwnerInput!) {
    updateResourceOwner(input: $input) {
      accessToken {
        token
        expiresAt
      }
      resourceOwner {
        id
        email
      }
    }
  }
`;

export const generateUpdatePasswordOutput = ({
  withToken,
}: {
  withToken: boolean;
}): UpdatePasswordMutation => {
  const result: UpdatePasswordMutation = {
    updateResourceOwner: {
      accessToken: null,
      resourceOwner: {
        email: 'my.email@gmail.com',
        id: '2',
      },
    },
  };
  if (withToken) {
    result.updateResourceOwner.accessToken = {
      expiresAt: '2021-10-14 19:27:28 -0400',
      token: 'myToken',
    };
  }
  return result;
};
