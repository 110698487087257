import { gql } from '@apollo/client';

export const RESEND_INVITATION_EMAIL = gql`
  mutation resendInvitationEmail($input: ResendTenantUserInvitationInput!) {
    resendTenantUserInvitation(input: $input) {
      resourceOwner {
        id
      }
    }
  }
`;

export const resendInvitationEmailOutput =
  (): ResendInvitationEmailMutation => {
    return {
      resendTenantUserInvitation: {
        resourceOwner: {
          id: '123',
        },
      },
    };
  };
