import { gql } from '@apollo/client';

export const DELETE_TENANT_LOGO = gql`
  mutation deleteTenantLogo($input: DeleteTenantLogoInput!) {
    deleteTenantLogo(input: $input) {
      tenant {
        id
        name
        logoUrl
      }
    }
  }
`;

export const generateDeleteTenantLogo = (): DeleteTenantLogoMutation => ({
  deleteTenantLogo: {
    tenant: {
      id: '1',
      logoUrl: null,
      name: 'My Company',
    },
  },
});
