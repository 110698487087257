import { IconButton, Text, Link } from '@arcadiapower/shrike';
import { centsToDollarString, capitalize } from '@arcadiapower/warbler';
import { TrackEvents, track } from 'utils/analytics';
import {
  HeaderSection,
  Title,
  Wrapper,
  StatusPill,
  LinkHolder,
} from './statement-card-header.style';
import { displayText } from './statement-card-header.utils';

export type Props = Pick<
  Statement,
  'date' | 'type' | 'total' | 'status' | 'links' | 'id'
> & {
  expanded: boolean;
  setExpanded: (value: boolean) => void;
};

export const StatementCardHeader = ({
  date,
  type,
  total,
  status,
  links,
  id,
  expanded,
  setExpanded,
}: Props): JSX.Element => {
  const handleToggleExpanded = () => {
    if (!expanded) track(TrackEvents.STATEMENT_OPENED);
    setExpanded(!expanded);
  };

  const renderInvoiceDetails = () => {
    return (
      <HeaderSection data-testid="invoice-details">
        <Text>${centsToDollarString(total || 0)}</Text>
        <StatusPill margin="0 1rem" status={status}>
          {capitalize(status || 'unknown')}
        </StatusPill>
      </HeaderSection>
    );
  };

  const renderLinks = () => {
    return (
      <ul data-testid="links">
        {links.map(link => (
          <LinkHolder key={link.url}>
            <Link href={link.url}>{link.text}</Link>
          </LinkHolder>
        ))}
      </ul>
    );
  };

  return (
    <Wrapper expanded={expanded}>
      <HeaderSection>
        <Title>{displayText(type, id, date)}</Title>
        {renderLinks()}
      </HeaderSection>

      <HeaderSection>
        {type === 'INVOICE' && renderInvoiceDetails()}
        <IconButton
          data-testid="toggle-statement"
          icon={expanded ? 'ChevronUp' : 'ChevronDown'}
          aria-label={expanded ? 'collapse' : 'expand'}
          onClick={handleToggleExpanded}
        />
      </HeaderSection>
    </Wrapper>
  );
};
