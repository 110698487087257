import { gql } from '@apollo/client';

export const GET_API_KEYS = gql`
  query getApiKeys {
    resourceOwner {
      id
      tenant {
        id
        credentials {
          id
          clientId
          clientSecret
          sandboxed
        }
      }
    }
  }
`;

export const generateApiKeysQuery = (): GetApiKeysQuery => ({
  resourceOwner: {
    id: '1',
    tenant: {
      credentials: [
        {
          clientId: 'myclientid-sandbox',
          clientSecret: 'mysupersecretclientsecretsand',
          id: '111',
          sandboxed: true,
        },
        {
          clientId: 'myclientid-production',
          clientSecret: 'mysupersecretclientsecretprod',
          id: '222',
          sandboxed: false,
        },
      ],
      id: '22',
    },
  },
});
