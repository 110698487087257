import styled from 'styled-components';
import { Switch, Select, Skeleton } from '@arcadiapower/shrike';
import { Form } from 'components/form';

export const Wrapper = styled.div`
  padding: 24px;
`;

export const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 432px));
  grid-row-gap: 24px;
  grid-column-gap: 24px;
`;

export const ButtonWrapper = styled.div`
  grid-column: 1/ -1;
`;

export const SmartChargeWrapper = styled.div`
  grid-column: 1/ -1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SmartChargeSwitch = styled(Switch)`
  grid-column: 1/ -1;
  ${props => props.theme.media.phoneAndTablet} {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Dropdown = styled(Select)`
  max-width: 600px; // Necessary to match the inputs
`;

export const InputSkeleton = styled(Skeleton).attrs({
  height: '48px',
  width: '100%',
})`
  margin: 1rem 0;
`;
