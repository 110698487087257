import { useMutation } from '@apollo/client';
import { copyFor } from 'config/copy';
import { useState } from 'react';
import { Text, Button, Alert } from '@arcadiapower/shrike';
import { TenantUserStatus } from 'typings/arcadia-api';
import { INITIATE_CONFIRM_EMAIL } from './email-confirmation-status';
import { CTAWrapper, Wrapper } from './email-confirmation-status.style';
import { AccountStatusBadge } from '../account-status-badge/account-status-badge';

export type Props = {
  status: TenantUserStatus;
};

const getCopy = copyFor('confirmAccountLabel');

export const EmailConfirmationStatus = ({ status }: Props): JSX.Element => {
  const [initiateConfirmEmail] = useMutation<
    InitiateConfirmEmailMutation,
    InitiateConfirmEmailMutationVariables
  >(INITIATE_CONFIRM_EMAIL);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const sendEmail = async () => {
    try {
      const { data } = await initiateConfirmEmail({ variables: { input: {} } });
      if (data?.initiateConfirmEmail.received) {
        setEmailSent(true);
        setError(false);
      } else {
        throw Error('Failed to initiate confirm email.');
      }
    } catch (error) {
      setError(true);
    }
  };

  const renderCTA = () => {
    if (emailSent) {
      return (
        <Text data-testid="confirmation" color="secondary">
          {getCopy('emailLink.sent')}
        </Text>
      );
    } else {
      return (
        <Button
          data-testid="resend-link"
          backgroundless
          size="small"
          onClick={sendEmail}
        >
          {getCopy('emailLink.cta')}
        </Button>
      );
    }
  };

  return (
    <Wrapper margin={{ bottom: '10px' }}>
      <CTAWrapper>{status === 'unconfirmed' && renderCTA()}</CTAWrapper>
      <AccountStatusBadge status={status} />
      {error && <Alert margin="0 0 0 1rem">{getCopy('emailLink.error')}</Alert>}
    </Wrapper>
  );
};
