import { Button, Text } from '@arcadiapower/shrike';
import { useConnect } from '@arcadia-eng/connect-react';
import { copyFor } from 'config/copy';
import { DEMO_CONNECT_ENV } from 'config/constants';
import * as analytics from 'utils/analytics';
import { featureFlagTariffDemo } from 'config/feature-flags';
import {
  Background,
  ConnectContainer,
  DemoImage,
  TextWrapper,
  DemoAlert,
  Wrapper,
} from './demo-connect.style';

export type Props = {
  errorMessage?: string;
  loading: boolean;
  setShowResults: (show: boolean) => void;
  refetch: () => void;
  initializeUser: (utilityCredentialId: string) => void;
  connectToken?: string;
};

const getCopy = copyFor('demo');

export const DemoConnect = ({
  setShowResults,
  errorMessage,
  refetch,
  loading,
  initializeUser,
  connectToken,
}: Props): JSX.Element => {
  const [{ loading: connectLoading, error: connectError }, open] = useConnect();

  const openWidget = async () => {
    try {
      const config = generateConfig(connectToken);
      await open(config);
    } catch (error) {
      refetch();
    }
  };

  const onClose = ({
    status,
  }: {
    status:
      | 'correct'
      | 'verified'
      | 'timed_out'
      | 'no_submit'
      | 'error'
      | 'rejected';
  }) => {
    if (['verified', 'correct', 'timed_out'].includes(status)) {
      analytics.track(analytics.TrackEvents.DEMO_COMPLETED);
      setShowResults(true);
    }
  };

  const onCredentialsSubmitted = ({
    utilityCredentialId,
  }: {
    utilityCredentialId: string;
  }) => {
    initializeUser(utilityCredentialId);
  };

  const generateConfig = (connectToken?: string) => {
    const variant = featureFlagTariffDemo() ? 'tariff' : 'connect';
    return {
      _experimental: { showDemoExplanations: variant !== 'tariff' },
      callbacks: {
        onClose,
        onCredentialsSubmitted,
      },
      connectToken,
      env: DEMO_CONNECT_ENV,
      variant,
      poll: {
        timeout: 30000,
      },
    };
  };

  const alertText: string | undefined = connectError
    ? getCopy('errors.connectFailure')
    : errorMessage;

  return (
    <Background>
      <Wrapper>
        <ConnectContainer>
          <DemoImage />
          <Button
            disabled={!connectToken || connectError}
            onClick={openWidget}
            data-testid="launch-widget"
            fullWidth={true}
            loading={connectLoading || loading}
            backgroundColor="primary"
          >
            {getCopy('launchConnectButton')}
          </Button>
          {alertText && (
            <DemoAlert margin={{ top: '16px' }}>{alertText}</DemoAlert>
          )}
        </ConnectContainer>
        <TextWrapper>
          <Text
            tag="h3"
            color="primaryInverse"
            textStyle="heading800"
            margin={{ bottom: '16px' }}
          >
            {getCopy('title')}
          </Text>
          {getCopy('description').map(descriptionPoint => (
            <Text
              margin={{ bottom: '16px' }}
              color="primaryInverse"
              key={descriptionPoint}
            >
              {descriptionPoint}
            </Text>
          ))}
        </TextWrapper>
      </Wrapper>
    </Background>
  );
};
