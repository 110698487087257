import { gql } from '@apollo/client';

export const GET_TEAM = gql`
  query getTeam {
    resourceOwner {
      id
      tenant {
        id
        canInviteUsers
        tenantUsers {
          id
          email
          firstName
          lastName
          status
        }
      }
    }
  }
`;

export const generateTeam = (): GetTeamQuery => {
  return {
    resourceOwner: {
      id: '123',
      tenant: {
        canInviteUsers: true,
        id: '456',
        tenantUsers: [
          {
            email: 'sara@gmail.com',
            firstName: 'sara',
            id: '2',
            lastName: 'cwynar',
            status: 'confirmed',
          },
          {
            email: 'ed@gmail.com',
            firstName: 'ed',
            id: '3',
            lastName: 'atkins',
            status: 'unconfirmed',
          },
        ],
      },
    },
  };
};
