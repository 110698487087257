export const confirmEmail = {
  success: {
    cta: 'Continue',
    description: 'Your account is now confirmed!',
    title: 'Your account is now confirmed.',
  },
  failure: {
    cta: 'Okay',
    description:
      'You can resend a confirmation email by going to your profile under Settings.',
    title: 'This link has expired.',
  },
  invalid: {
    alert: 'This confirmation link is invalid or has expired',
    cta: 'Return to sign in',
  },
  pending: 'Verifying confirmation token, you should be redirected shortly.',
};
