export const errors = {
  messages: {
    generic: 'Something went wrong. Please try again.',
    fileSize:
      'File size is too large. Please upload an image smaller than 1mb.',
    validImage:
      'Unrecognized file type. Please upload an image (.png, .jpg, or .svg).',
    emailExists: 'Email is already registered.',
    emailExistsOtherTenant:
      'Email is already registered under a different organization.',
    pendingInvitation: 'Email is already invited.',
    tenantAlreadyExists:
      'This organization is already registered. You can be added to this team via invitation. If you are not a member of this team, try another name or contact arcsupport@arcadia.com',
    wrongCredentials: 'Your credentials were incorrect. Please try again',
    sparkFormFeatureNotAvailable:
      'This feature is not available for this Utility Account. To see energy analytics in action, you should restart the demo and enter the suggested utility and zip code.',
    sparkFormRegionNotSupported:
      'Your region is not yet supported for the energy analytics feature. To see energy analytics in action, you should restart the demo and enter the suggested utility and zip code.',
    genericDemo: 'We were unable to load your data',
  },
  errorBoundary: {
    title: 'Something went wrong',
    description1: 'Please contact ',
    description2: ' if the issue persists.',
  },
};
