import styled, { css } from 'styled-components';
import { Text } from '@arcadiapower/shrike';

const borderColor = css`
  ${props => props.theme.colors.background.tertiary}
`;

export const Table = styled.table`
  width: 100%;
  overflow-x: auto;
`;

export const TableHeaderText = styled(Text).attrs({ textStyle: 'heading500' })`
  text-align: left;
  padding: 8px 16px 8px 0;
`;

export const TableBody = styled.tbody`
  border-top: 1px solid ${borderColor};
`;
