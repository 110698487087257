import { useMemo } from 'react';
import { DataTable } from 'components/demo/data-table';
import { copyFor, getErrorCopy } from 'config/copy';
import PlugSvg from 'assets/demo/plug.svg';
import { Text } from '@arcadiapower/shrike';
import {
  DataError,
  RESOURCE_STATES,
  UseFetchUtilityData,
} from 'hooks/use-fetch-utility-data.hook';
import { Page } from 'styles';
import { utilityCredentialMapper } from 'utils/demo-mappers';
import { DemoDescriptionWrapper, DemoDescriptionImage } from 'styles/demo';
import { Accounts } from 'components/demo/accounts';
import { isRealCredential } from 'utils/utility-data';

const getCopy = copyFor('demo.results.plug');

export type Props = {
  utilityCredentialQuery: UseFetchUtilityData['utilityCredentialQuery'];
  utilityAccountQuery: UseFetchUtilityData['utilityAccountQuery'];
  fetchingResource: UseFetchUtilityData['fetchingResource'];
};

export const Plug = ({
  utilityCredentialQuery,
  utilityAccountQuery,
  fetchingResource,
}: Props): JSX.Element => {
  const inErrorState = fetchingResource === RESOURCE_STATES.ERROR;

  // We want to pass a data error to the accounts component if something goes
  // wrong fetching credentials so the tables aren't stuck in an "inbetween"
  // state
  const accountsError = useMemo(() => {
    if (utilityAccountQuery.error) return utilityAccountQuery.error;
    if (inErrorState) return new DataError(getErrorCopy('genericDemo'));
  }, [utilityAccountQuery.error, inErrorState]);

  const isRealAccount = useMemo(() => {
    const utilityCredentials = utilityCredentialQuery.data?.data;
    if (utilityCredentials) return isRealCredential(utilityCredentials);
  }, [utilityCredentialQuery]);

  return (
    <Page>
      <DemoDescriptionWrapper>
        <DemoDescriptionImage src={PlugSvg} alt="plug" />
        <Text>{getCopy('description')}</Text>
      </DemoDescriptionWrapper>
      <DataTable
        requestType="GET"
        endpoint="/utility_credentials?client_user_id={client_user_id}"
        data={utilityCredentialQuery.data}
        loading={utilityCredentialQuery.isLoading && !inErrorState}
        error={utilityCredentialQuery.error}
        mapper={utilityCredentialMapper}
        title={getCopy('tables.utilityCredentials.title')}
        helperText={getCopy('tables.utilityCredentials.helperText')}
      />
      <Accounts
        isRealAccount={isRealAccount}
        accountsData={utilityAccountQuery.data}
        loading={utilityAccountQuery.isLoading && !inErrorState}
        error={accountsError}
      />
    </Page>
  );
};
