import { EmailConfirmationStatus } from 'components/email-confirmation-status';
import { ReadOnlyInput } from 'components/read-only-input';
import { copyFor } from 'config/copy';
import { TenantUserStatus } from 'typings/arcadia-api';
import { Wrapper, InputWrapper } from './account-details.style';

export type AccountDetailsReadOnlyProps = {
  resourceOwner: NonNullable<GetProfileResourceOwnerQuery['resourceOwner']>;
};

const getCopy = copyFor('forms.accountDetails');

export const AccountDetailsReadOnly = ({
  resourceOwner,
}: AccountDetailsReadOnlyProps): JSX.Element => {
  return (
    <Wrapper data-testid="account-details-read-only">
      <InputWrapper>
        <ReadOnlyInput
          value={resourceOwner.firstName}
          label={getCopy('inputs.firstName.label')}
          margin={{ bottom: '32px', right: '32px' }}
        />
        <ReadOnlyInput
          value={resourceOwner.lastName}
          label={getCopy('inputs.lastName.label')}
          margin={{ bottom: '32px' }}
        />
      </InputWrapper>
      <InputWrapper>
        <ReadOnlyInput
          value={resourceOwner.email}
          label={getCopy('inputs.email.label')}
          margin={{ bottom: '32px' }}
        />
        <EmailConfirmationStatus
          status={resourceOwner.status as TenantUserStatus}
        />
      </InputWrapper>
      <ReadOnlyInput
        value="password"
        label={getCopy('inputs.password.label')}
        masked
      />
    </Wrapper>
  );
};
