import { copyFor } from 'config/copy';
import {
  Logo,
  LogoContainer,
  NavBarContainer,
  NavBarContent,
  BrandText,
} from './nav-bar.style';

const getCopy = copyFor('nav');

export const NavBarUnauthed = (): JSX.Element => {
  return (
    <NavBarContainer withBorder={false}>
      <NavBarContent>
        <LogoContainer>
          <>
            <Logo />
            <BrandText>{getCopy('brandText')}</BrandText>
          </>
        </LogoContainer>
      </NavBarContent>
    </NavBarContainer>
  );
};
