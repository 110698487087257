import { RouteProp } from 'components/nav-bar-item';
import { ListItem, SubNavBarItem, Wrapper } from './sub-nav.style';

export type Props = {
  routes: RouteProp[];
};

export const SubNav = ({ routes }: Props): JSX.Element => {
  return (
    <Wrapper>
      {routes.map(({ route, label, onClick, icon, svgPath }) => {
        return (
          <ListItem key={label}>
            <SubNavBarItem
              route={route}
              label={label}
              icon={icon}
              svgPath={svgPath}
              onClick={onClick}
            />
          </ListItem>
        );
      })}
    </Wrapper>
  );
};
