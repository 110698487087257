import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavBarLink = styled(Link)<{ $isActive: boolean }>`
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  // Prevent the component from "jumping up" when clicked
  border-bottom: 2px solid transparent;

  ${props =>
    props.$isActive &&
    `
    && {
      border-bottom: 2px solid ${props.theme.colors.content.primary}
    }
  `}
`;
