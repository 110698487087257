import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const chartBackgroundColor = 'black';
export const chartGridColor = '#4A4E51';

export const ChartWrapper = styled.div`
  background: ${chartBackgroundColor};
  padding-right: 32px;
  padding-top: 32px;
  padding-bottom: 65px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${props => props.theme.media.phoneAndTablet} {
    padding: 32px 12px;
  }
`;

export const ApexChartWrapper = styled.div`
  height: 310px;
  .apexcharts-series {
    path:hover {
      stroke: ${props => props.theme.colors.content.accent3};
      fill: ${props => props.theme.colors.content.accent3};
    }
  }
`;

export const ChartControlsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
`;

export const ChartTitleWrapper = styled.div`
  background: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  max-width: fit-content;
  align-self: center;
  padding: 8px;
  height: 24px;
  display: flex;
  align-items: center;
`;

export const BoldText = styled(Text)`
  font-weight: 700;
`;

export const axisLabelStyle = {
  fontFamily: 'DM Sans Regular, sans-serif',
  fontSize: '14px',
  fontWeight: 100,
};
