import {
  formatAsMoney,
  formatDate,
  formatDateEST,
} from '@arcadiapower/warbler';
import { TableSection } from 'styles/table.style';
import { DataRow } from 'components/payment-timeline/data-row';
import { formatDateRangeForUtilityStatement } from 'utils/formatters';
import { UtilityStatementForTimeline } from 'components/customer-detail/statement-timeline/statement-timeline.api';
import { copyFor } from 'config/copy';
import { Text } from '@arcadiapower/shrike';
import {
  SectionHeader,
  Column,
  DataContainer,
  HeaderIcon,
  HeaderPrimary,
  SectionTitle,
  DownloadLink,
  DownloadIcon,
} from '../sections.style';

const getCopy = copyFor('components.paymentTimeline.sections.utilityStatement');

export type Props = {
  utilityStatement: UtilityStatementForTimeline;
};

export const UtilityStatement = ({ utilityStatement }: Props): JSX.Element => {
  return (
    <TableSection data-testid={`utility-statement-${utilityStatement.id}`}>
      <SectionHeader>
        <HeaderPrimary>
          <HeaderIcon icon="Envelope" />
          <SectionTitle>{getCopy('title')}</SectionTitle>
          {utilityStatement.pdfUrl && (
            <DownloadLink href={utilityStatement.pdfUrl} type="inline">
              <DownloadIcon icon="UIDownload" />
              <Text textStyle="paragraph500">{getCopy('pdfDownloadCTA')}</Text>
            </DownloadLink>
          )}
        </HeaderPrimary>
      </SectionHeader>
      <DataContainer>
        <Column>
          <DataRow value={utilityStatement.id} label={getCopy('fields.id')} />
          <DataRow
            value={utilityStatement.kwh}
            label={getCopy('fields.kwh')}
            formatter={value => `${value} kWh`}
          />
          <DataRow
            value={utilityStatement.utilityCharge}
            label={getCopy('fields.utilityCharge')}
            formatter={formatAsMoney}
          />
          <DataRow
            value={utilityStatement.statementDate}
            label={getCopy('fields.statementDate')}
            formatter={formatDate}
          />
        </Column>
        <Column>
          <DataRow
            value={utilityStatement.dueDate}
            label={getCopy('fields.dueDate')}
            formatter={formatDate}
          />
          <DataRow
            value={utilityStatement.gracePeriod}
            label={getCopy('fields.gracePeriod')}
            formatter={value => getCopy('formatter.gracePeriod', { value })}
          />
          <DataRow
            value={formatDateRangeForUtilityStatement(utilityStatement)}
            label={getCopy('fields.servicePeriod')}
          />
          {/* Needs to cast to date here because Warbler isn't loving the date format from the BE */}
          <DataRow
            value={new Date(utilityStatement.updatedAt)}
            label={getCopy('fields.updatedAt')}
            formatter={formatDateEST}
          />
        </Column>
      </DataContainer>
    </TableSection>
  );
};
