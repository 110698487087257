import { RESOURCE_STATES } from 'hooks/use-fetch-utility-data.hook';
import { Alert, Button, Input, Tooltip, Icon } from '@arcadiapower/shrike';
import { addTime } from '@arcadiapower/warbler';
import { copyFor } from 'config/copy';
import {
  EnergyAnalyticsQuery,
  Fields,
  useEnergyAnalytics,
} from 'hooks/use-energy-analytics.hook';
import { DateTimeInput } from 'components/date-time-input';
import { getStartOfNextHour } from 'utils/dates';
import { noop } from 'lodash-es';
import * as analytics from 'utils/analytics';
import { parseSparkFormError } from './spark-form.util';
import {
  Wrapper,
  StyledForm,
  ButtonWrapper,
  SmartChargeWrapper,
  SmartChargeSwitch,
  Dropdown,
  InputSkeleton,
} from './spark-form.style';

const getCopy = copyFor('forms.spark');

const CHARGE_POWER_OPTIONS_KWH = [1.4, 3.3, 7.7, 9.6, 11.5, 19.2];
const CHARGE_POWER_OPTIONS = CHARGE_POWER_OPTIONS_KWH.map(value => ({
  text: `${value} kW`,
  value,
}));

const { SMART_CHARGE_CALCULATED, CHARGE_COST_CALCULATED } =
  analytics.TrackEvents;

export interface SparkFormProps {
  canSubmit: boolean;
  fields: Fields;
  updateField: ReturnType<typeof useEnergyAnalytics>['updateField'];
  energyAnalyticsQuery: EnergyAnalyticsQuery;
  submitEnergyAnalyticsQuery: () => void;
  fetchingResource: RESOURCE_STATES;
}

export const SparkForm = ({
  canSubmit,
  fields,
  updateField,
  energyAnalyticsQuery,
  submitEnergyAnalyticsQuery,
  fetchingResource,
}: SparkFormProps) => {
  const {
    energyAdded,
    chargePower,
    minimumChargeTimeDate,
    smartChargingEnabled,
    startTime,
    endTime,
  } = fields;
  const loadingStatementsError = fetchingResource === RESOURCE_STATES.ERROR;
  const loadingStatements =
    fetchingResource !== RESOURCE_STATES.DONE && !loadingStatementsError;

  if (loadingStatementsError)
    return (
      <Wrapper>
        <Alert>{getCopy('errors.loadingStatements')}</Alert>
      </Wrapper>
    );

  if (loadingStatements)
    return (
      <Wrapper>
        <StyledForm data-testid="spark-form-loading" onSubmit={noop}>
          <InputSkeleton />
          <InputSkeleton />
          <InputSkeleton />
        </StyledForm>
      </Wrapper>
    );

  const errorMessage = parseSparkFormError(energyAnalyticsQuery.error);

  const chargeExceedsTwentyFourHours = energyAdded / chargePower > 24;
  const shouldShowChargeExceedsTimeError =
    chargeExceedsTwentyFourHours && !smartChargingEnabled;

  const onSubmit = () => {
    if (smartChargingEnabled) {
      analytics.track(SMART_CHARGE_CALCULATED);
    } else analytics.track(CHARGE_COST_CALCULATED);

    submitEnergyAnalyticsQuery();
  };

  return (
    <Wrapper>
      <StyledForm onSubmit={onSubmit} data-testid="spark-form">
        <Input
          label={getCopy('inputs.energyAdded.label')}
          name={getCopy('inputs.energyAdded.name')}
          type="number"
          onChange={value => updateField('energyAdded')(Number(value))}
          value={energyAdded.toString()}
        />
        <DateTimeInput
          label={getCopy('inputs.startTime.label')}
          name={getCopy('inputs.startTime.name')}
          minDate={getStartOfNextHour()}
          onChange={value => updateField('startTime')(value)}
          value={startTime}
        />
        <Dropdown
          label={getCopy('dropdown.label')}
          name={getCopy('dropdown.name')}
          options={CHARGE_POWER_OPTIONS}
          onChange={value => updateField('chargePower')(Number(value))}
          value={chargePower}
        />
        <SmartChargeWrapper>
          <SmartChargeSwitch
            name={getCopy('smartCharge.name')}
            onChange={() =>
              updateField('smartChargingEnabled')(!smartChargingEnabled)
            }
            checked={smartChargingEnabled}
            label={getCopy('smartCharge.label')}
            width="max-content"
            margin={{ right: '9px' }}
          >
            {getCopy('smartCharge.name')}
          </SmartChargeSwitch>
          <Tooltip
            content={getCopy('smartCharge.helperText')}
            tooltipId="smart-charge-helper-text"
            label={getCopy('smartCharge.helperTextLabel')}
            maxWidth={300}
            data-testid="smart-charge-helper-text"
          >
            <Icon icon="InfoCircleInverted"></Icon>
          </Tooltip>
        </SmartChargeWrapper>

        {smartChargingEnabled && (
          <DateTimeInput
            label={getCopy('inputs.endTime.label')}
            name={getCopy('inputs.endTime.name')}
            minDate={minimumChargeTimeDate}
            maxDate={addTime(minimumChargeTimeDate, { hours: 24 })}
            onChange={value => updateField('endTime')(new Date(value))}
            value={endTime}
          />
        )}
        <ButtonWrapper>
          <Button
            loading={energyAnalyticsQuery.isFetching}
            disabled={!canSubmit || shouldShowChargeExceedsTimeError}
            type="submit"
            data-testid="submit-spark"
          >
            {getCopy('button')}
          </Button>
          {shouldShowChargeExceedsTimeError && (
            <Alert margin={{ top: '24px' }}>
              {getCopy('errors.excessiveChargeTime')}
            </Alert>
          )}
          {errorMessage && (
            <Alert margin={{ top: '24px' }} data-testid="spark-error">
              {errorMessage}
            </Alert>
          )}
        </ButtonWrapper>
      </StyledForm>
    </Wrapper>
  );
};
