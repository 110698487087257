import { Margin } from 'styles/margin.style';
import { TenantUserStatus } from 'typings/arcadia-api';
import { capitalize } from '@arcadiapower/warbler';
import { VerifiedBadge } from './account-status-badge.style';

export type Props = {
  status: TenantUserStatus;
  margin?: Margin;
};

export const AccountStatusBadge = ({ status, margin }: Props) => {
  return (
    <VerifiedBadge
      data-testid="account-status-badge"
      status={status}
      margin={margin}
    >
      {capitalize(status)}
    </VerifiedBadge>
  );
};
