import { ReadOnlyInput } from 'components/read-only-input';
import { copyFor } from 'config/copy';
import { Wrapper } from './tenant-name-read-only.style';

export type TenantNameReadOnlyProps = {
  resourceOwner: NonNullable<GetTenantQuery['resourceOwner']>;
};

const getCopy = copyFor('forms.customization');

export const TenantNameReadOnly = ({
  resourceOwner,
}: TenantNameReadOnlyProps): JSX.Element => {
  return (
    <Wrapper data-testid="customization-read-only">
      <ReadOnlyInput
        value={resourceOwner.tenant.name}
        label={getCopy('inputs.name.label')}
      />
    </Wrapper>
  );
};
