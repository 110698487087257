import { useCallback, useState } from 'react';
import { Page } from 'styles';
import { overview } from 'config/paths';
import { copyFor } from 'config/copy';
import * as analytics from 'utils/analytics';
import { SingleContentNarrow, Title } from 'styles/single-content.style';
import {
  ChangePassword as ChangePasswordForm,
  ChangePasswordParams,
} from 'components/forms/change-password';
import { useMutation } from '@apollo/client';
import { authenticate } from 'config/auth';
import { NavBarUnauthed } from 'components/nav-bar';
import { parseErrorMessage } from 'config/errors';
import { UPDATE_PASSWORD } from './change-password.api';

const getCopy = copyFor('changePassword');

export const ChangePassword = (): JSX.Element => {
  const [pending, setPending] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');

  const [updateResourceOwner] = useMutation<
    UpdateResourceOwnerMutation,
    UpdateResourceOwnerMutationVariables
  >(UPDATE_PASSWORD);

  const handleSubmit = useCallback(
    async ({ password }: ChangePasswordParams) => {
      setPending(true);
      setErrorMessage(undefined);
      try {
        const { data } = await updateResourceOwner({
          variables: { input: { password } },
        });
        if (data?.updateResourceOwner.accessToken) {
          analytics.track(analytics.TrackEvents.PASSWORD_RESET_COMPLETE);
          const { accessToken, resourceOwner } = data.updateResourceOwner;
          authenticate(accessToken.token, accessToken.expiresAt, {
            navigateTo: overview,
            resourceOwner,
          });
        }
      } catch (e) {
        const errorMessage = parseErrorMessage(e as Error);
        setErrorMessage(errorMessage);
      } finally {
        setPending(false);
      }
    },
    [updateResourceOwner]
  );

  return (
    <Page>
      <NavBarUnauthed />
      <SingleContentNarrow>
        <Title>{getCopy('title.normal')}</Title>
        <ChangePasswordForm
          onSubmit={handleSubmit}
          pending={pending}
          errorMessage={errorMessage}
        />
      </SingleContentNarrow>
    </Page>
  );
};
