import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const WelcomeContainer = styled.div`
  max-width: 540px;
  margin-bottom: 64px;
`;

export const WelcomeTitle = styled(Text).attrs({
  margin: { bottom: '16px' },
  tag: 'h2',
  textStyle: 'heading800',
})``;

export const WelcomeSubtitle = styled(Text).attrs({
  color: 'secondary',
})``;

export const Section = styled.div`
  display: grid;
  grid-row-gap: 24px;
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 48px;
  }

  ${props => props.theme.media.phone} {
    grid-template-columns: repeat(auto-fit, 100%);
  }
`;
