import React from 'react';
import { captureError } from 'utils/analytics';
import { copyFor } from 'config/copy';
import { Link, Text } from '@arcadiapower/shrike';
import { Wrapper } from './error-boundary.style';

const SUPPORT_EMAIL = 'platformsupport@arcadia.com';

const getCopy = copyFor('errors.errorBoundary');

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<Props, State> {
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    hasError: false,
  };

  public componentDidCatch(error: Error) {
    captureError({ error });
  }

  render() {
    if (this.state.hasError)
      return (
        <Wrapper>
          <Text textStyle="heading700" tag="h1" margin={{ bottom: '24px' }}>
            {getCopy('title')}
          </Text>
          <Text>
            {getCopy('description1')}
            <Link
              href={`mailto:${SUPPORT_EMAIL}`}
              openInNewTab={true}
              type="inline"
            >
              {SUPPORT_EMAIL}
            </Link>
            {getCopy('description2')}
          </Text>
        </Wrapper>
      );
    return this.props.children;
  }
}
