import { gql } from '@apollo/client';

export const GET_DEV_PLATFORM_CONTRACT_VERSIONS = gql`
  query getDeveloperPlatformContractsVersions {
    contractVersions: contractVersionsDeveloperPlatformTenant {
      contractType
      displayName
      id
    }
  }
`;

export const generateDeveloperPlatformContractVersions =
  (): GetDeveloperPlatformContractsVersionsQuery => ({
    contractVersions: [
      {
        contractType: 'privacy',
        displayName: 'Privacy',
        id: 111,
      },
      {
        contractType: 'dev_platform_tenant',
        displayName: 'Terms of Service',
        id: 222,
      },
    ],
  });
