import { Page } from 'styles';
import { Outlet } from 'react-router-dom';
import { apiKeys, customization, webhooks } from 'config/paths';
import { SideNav } from 'components/side-nav';
import { copyFor } from 'config/copy';
import { GridContainer, SideBar, Content } from 'styles/side-bar-grid.style';
import {
  apiKeySvgPath,
  customizationSvgPath,
  webhooksSvgPath,
} from 'assets/icons/paths';

const getCopy = copyFor('configuration');

const routes = [
  {
    label: getCopy('sideNav.links.apiKeys'),
    route: apiKeys,
    svgPath: apiKeySvgPath,
  },
  {
    label: getCopy('sideNav.links.webhooks'),
    route: webhooks,
    svgPath: webhooksSvgPath,
  },
  {
    label: getCopy('sideNav.links.customization'),
    route: customization,
    svgPath: customizationSvgPath,
  },
];

export const Configuration = (): JSX.Element => {
  return (
    <Page>
      <GridContainer>
        <SideBar>
          <SideNav routes={routes} title={getCopy('sideNav.title')} />
        </SideBar>
        <Content>
          <Outlet />
        </Content>
      </GridContainer>
    </Page>
  );
};
