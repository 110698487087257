import { gql } from '@apollo/client';

export const GET_PROFILE_RESOURCE_OWNER = gql`
  query getProfileResourceOwner {
    resourceOwner {
      id
      email
      firstName
      lastName
      status
    }
  }
`;

export const generateProfileResourceOwner =
  (): GetProfileResourceOwnerQuery => {
    return {
      resourceOwner: {
        email: 'kanye@west.com',
        firstName: 'Kanye',
        id: '2',
        lastName: 'West',
        status: 'confirmed',
      },
    };
  };
