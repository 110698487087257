import styled from 'styled-components';
import { Margin, marginStyles } from 'styles/margin.style';
import { Text, IconButton } from '@arcadiapower/shrike';

export const InputGroup = styled.div<{ margin?: Margin }>`
  ${props => marginStyles(props.margin)}
  flex: 1;
`;

export const ValueWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  width: 100%;
`;

export const Value = styled(Text).attrs({ color: 'secondary' })`
  padding: 6px 12px;
  overflow-x: auto;
  display: flex;
`;

export const CustomIcon = styled(IconButton)`
  border-radius: none;
  border: none;
  padding: 0px;
  border-color: transparent;
  pointer-events: none;
`;

export const LogoFileWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  width: 100%;
`;
