import { Wrapper, Label, DisplayValue } from './data-row.style';

export type Props<Value = DefaultValueTypes> = {
  formatter?: (
    value: NonNullable<Value>,
    ...args: never[]
  ) => string | null | number | undefined;
  hideIfNull?: boolean;
  label: string;
  value: Value;
};

export type DefaultValueTypes = Date | string | number | null | undefined;

export const DataRow = <Value,>({
  label,
  value,
  formatter,
  hideIfNull,
}: Props<Value>): JSX.Element | null => {
  const displayValue =
    formatter && value ? formatter(value as NonNullable<Value>) : value;

  if (!displayValue && hideIfNull) return null;

  return (
    <Wrapper data-testid={`data-row-${label}`}>
      <Label>{label}</Label>
      <DisplayValue>{displayValue || '--'}</DisplayValue>
    </Wrapper>
  );
};
