import { gql } from '@apollo/client';

export const CREATE_WEBHOOK_ENDPOINT = gql`
  mutation createWebhookEndpoint($input: CreateWebhookEndpointInput!) {
    createWebhookEndpoint(input: $input) {
      webhookEndpoint {
        createdAt
        id
        url
        tenantId
        sandboxed
        arcVersion
      }
    }
  }
`;

export const generateCreateWebhookEndpoint =
  (): CreateWebhookEndpointMutation => ({
    createWebhookEndpoint: {
      webhookEndpoint: {
        createdAt: '2022-08-01',
        id: '4bddf027-a72f-4c6a-a9a3-45499838e0d5',
        url: 'https://www.liveOne.com',
        tenantId: '12097',
        sandboxed: false,
        arcVersion: '2021-11-17',
      },
    },
  });
