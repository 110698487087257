import { gql } from '@apollo/client';

export const INITIATE_CONFIRM_EMAIL = gql`
  mutation initiateConfirmEmail($input: InitiateConfirmEmailInput!) {
    initiateConfirmEmail(input: $input) {
      received
    }
  }
`;

export const generateInitiateConfirmEmail = (
  success = true
): InitiateConfirmEmailMutation => ({
  initiateConfirmEmail: {
    received: success,
  },
});
