import { Text, Icon, Tooltip } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { DebouncedSearchBar } from 'components/debounced-search-bar';
import {
  SearchBarFieldsWrapper,
  CustomerTypeToggle,
  Wrapper,
  HelperText,
} from './customer-search-bar.style';

export type Props = {
  initialSearchInput?: string | null;
  toggleSandboxed: () => void;
  onSearch: (searchTerm: string) => void;
  sandboxed: boolean;
};

const getCopy = copyFor('forms.customerSearch');

export const CustomerSearchBar = ({
  initialSearchInput,
  onSearch,
  sandboxed,
  toggleSandboxed,
}: Props): JSX.Element => {
  const customerTypeCopyKey = sandboxed ? 'sandbox' : 'live';

  return (
    <Wrapper>
      <SearchBarFieldsWrapper>
        <DebouncedSearchBar
          initialSearchInput={initialSearchInput}
          name="search"
          label={getCopy('inputs.search.label')}
          onSearch={onSearch}
        />
        <CustomerTypeToggle
          data-testid="toggle-customer-type"
          checked={!sandboxed}
          onChange={toggleSandboxed}
          // For accessibility reasons, the aria-label should always be live
          aria-label={getCopy(`inputs.customerType.live.label`)}
          name={getCopy(`inputs.customerType.${customerTypeCopyKey}.name`)}
          label={getCopy(`inputs.customerType.${customerTypeCopyKey}.label`)}
        />
      </SearchBarFieldsWrapper>
      <HelperText>
        <Text color="secondary" textStyle="paragraph400">
          {getCopy('helperText')}
        </Text>
        <Tooltip
          content={getCopy('helperTextTooltip')}
          tooltipId="search-helper-text"
          label="Search helper text"
          maxWidth={300}
        >
          <Icon icon="InfoCircleInverted"></Icon>
        </Tooltip>
      </HelperText>
    </Wrapper>
  );
};
