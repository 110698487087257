/* eslint-disable camelcase */
import { UtilityStatementArc } from 'typings/arcadia-api';
import { sortBy } from 'lodash-es';
import { formatAsMoney, formatDate } from '@arcadiapower/warbler';
import { copyFor } from 'config/copy';

const getCopy = copyFor('demo.results.plug.tables.utilityStatements.columns');

type EnhancedUtilityStatement = UtilityStatementArc & {
  service_period: string;
};

type UtilityStatementColumn = {
  [K in keyof EnhancedUtilityStatement]: {
    key: K;
    formatter?: (value: EnhancedUtilityStatement[K]) => string | null;
    label: string;
    textAlign?: 'right' | 'left';
  };
}[keyof EnhancedUtilityStatement];

export const UTILITY_STATEMENT_COLUMNS: UtilityStatementColumn[] = [
  {
    formatter: dateString => formatDate(dateString, { format: 'MMM d, yyyy' }),
    key: 'statement_date',
    label: getCopy('statementDate'),
  },
  { key: 'service_period', label: getCopy('servicePeriod') },
  {
    formatter: value => formatDate(value),
    key: 'due_date',
    label: getCopy('dueDate'),
  },
  { key: 'kwh', label: getCopy('kwh') },
  {
    formatter: value => formatAsMoney(value),
    key: 'utility_charge',
    label: getCopy('total'),
    textAlign: 'right',
  },
];

export const formatUtilityStatements = (
  statements: UtilityStatementArc[]
): EnhancedUtilityStatement[] => {
  const sortedStatements = sortBy(statements, 'statement_date').reverse();

  const enhancedStatements = sortedStatements.map(statement => ({
    ...statement,
    service_period: `${formatDate(statement.service_start_date)}-${formatDate(
      statement.service_end_date
    )}`,
  }));

  enhancedStatements.forEach(statement => {
    (Object.keys(statement) as Array<keyof UtilityStatementArc>).forEach(
      key => {
        const formatter = UTILITY_STATEMENT_COLUMNS.find(
          column => column.key === key
        )?.formatter;
        if (formatter) {
          // Typescript hates this for some reason
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (statement[key] as any) = (formatter as any)(statement[key]);
        }
      }
    );
  });
  return enhancedStatements;
};
