import { TableText } from 'components/table';
import { isNumber, isString } from '@arcadiapower/warbler';
import { CellContext } from '@tanstack/react-table';
import { copyFor } from 'config/copy';
import { UtilityAccountType } from 'pages/customers/customers.api';

const getTableCopy = copyFor('components.table');

export const getCellInfo = (
  props: CellContext<UtilityAccountType, unknown>
) => {
  const { shellAccount, accountNumber } = props.row.original;
  const value = props.getValue();
  const shouldDisplayValue = isNumber(value) || (isString(value) && value);
  const displayValue = shouldDisplayValue ? value : getTableCopy('noValue');
  const secondary = !value || !accountNumber || shellAccount;
  return {
    secondary,
    displayValue,
  };
};

export const DefaultCell = (
  props: CellContext<UtilityAccountType, unknown>
) => {
  const { secondary, displayValue } = getCellInfo(props);
  const overflow = props.column?.columnDef.meta?.overflow;
  return (
    <TableText color={secondary ? 'secondary' : 'primary'} $overflow={overflow}>
      {displayValue}
    </TableText>
  );
};
