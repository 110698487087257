import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const chartBackgroundColor = 'black';
export const chartGridColor = '#4A4E51';

export const Wrapper = styled.div`
  padding: 36px 24px;
  background-color: ${chartBackgroundColor};
`;

export const CostWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CostItem = styled.div``;

export const CostHeader = styled(Text).attrs({
  textStyle: 'heading500',
})`
  margin-bottom: 8px;
  color: ${props => props.theme.colors.content.primaryInverse};
`;

export const CostDollars = styled(Text).attrs({
  textStyle: 'heading500',
})<{ savings?: boolean }>`
  font-size: 32px;
  color: ${props => props.theme.colors.content.primaryInverse};
  ${props =>
    props.savings &&
    `
    && {
      color: #36D098
    }
  `}
`;

export const ChartWrapper = styled.div`
  height: 500px;
`;

export const axisLabelStyle = {
  fontFamily: 'DM Sans Regular, sans-serif',
  fontSize: '14px',
  fontWeight: 100,
};
