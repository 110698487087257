import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';
import { codeTextMixin } from 'styles/typography.style';

export const DataFieldWrapper = styled.div`
  flex-direction: column;
  display: flex;
  overflow-wrap: anywhere;
`;

export const Section = styled.div`
  padding: 0 24px;
`;

export const CustomerOverviewWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.background.tertiary};
  border-radius: 8px;

  ${Section}:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.background.tertiary};
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 275px);
  gap: 24px;
  padding: 24px 0;
  ${props => props.theme.media.phone} {
    grid-template-columns: repeat(auto-fit, minmax(min-content, 100%));
  }
`;

export const LinkContent = styled(Content)`
  display: flex;
  gap: 20px;
  padding: 24px 0;
  flex-wrap: wrap;
`;

export const SectionTitleWrapper = styled.div<{ equalHeightPadding?: boolean }>`
  padding: ${props => (props.equalHeightPadding ? '24px 0' : '24px 0 16px')};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px;
`;

export const SectionSubtitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const SectionSubtitleText = styled(Text).attrs({
  textStyle: 'paragraph400',
})`
  ${codeTextMixin}
`;

export const SectionTitle = styled(Text).attrs({
  color: 'primary',
  textStyle: 'heading600',
})``;

export const DataLabel = styled(Text).attrs({
  color: 'primary',
  textStyle: 'heading500',
})`
  padding-bottom: 8px;
`;

export const DataWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const LinkHolder = styled.li`
  margin-bottom: 1.5rem;
`;
