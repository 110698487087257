import { useCallback, useState } from 'react';
import { Page } from 'styles';
import { copyFor, getErrorCopy } from 'config/copy';
import { parseErrorMessage } from 'config/errors';
import * as analytics from 'utils/analytics';
import {
  SingleContentNarrow,
  SubTitle,
  Title,
} from 'styles/single-content.style';
import { useMutation } from '@apollo/client';
import { NavBarUnauthed } from 'components/nav-bar';
import {
  ResetPassword as ResetPasswordForm,
  InitiateResetPasswordParams,
} from 'components/forms/reset-password';
import { INITIATE_RESET_PASSWORD } from './reset-password.api';

const getCopy = copyFor('resetPassword');

export const ResetPassword = (): JSX.Element => {
  const [pending, setPending] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [pendingInvitation, setPendingInvitation] = useState<boolean>(false);
  const [email, setEmail] = useState<string | undefined>('');

  const [initiateResetPassword] = useMutation<
    InitiateResetPasswordMutation,
    InitiateResetPasswordMutationVariables
  >(INITIATE_RESET_PASSWORD);

  const handleSubmit = useCallback(
    async ({ email }: InitiateResetPasswordParams) => {
      setSubmitted(false);
      setPending(true);
      setErrorMessage(undefined);
      setEmail(email);
      analytics.track(analytics.TrackEvents.PASSWORD_RESET_REQUESTED, {
        email,
      });
      try {
        const { data } = await initiateResetPassword({
          variables: { input: { email } },
        });
        if (data?.initiateResetPassword.receivedResetRequest) {
          setSubmitted(true);
        }
      } catch (e) {
        const errorMessage = parseErrorMessage(e as Error);
        if (errorMessage === getErrorCopy('pendingInvitation')) {
          setSubmitted(true);
          setPendingInvitation(true);
        } else {
          setErrorMessage(errorMessage);
        }
      } finally {
        setPending(false);
      }
    },
    [initiateResetPassword]
  );

  const titleCopy = () => {
    if (pendingInvitation) {
      return getCopy('title.pendingInvitation');
    } else if (submitted) {
      return getCopy('title.submitted');
    }
    return getCopy('title.normal');
  };

  const subTitleCopy = () => {
    if (pendingInvitation) {
      return getCopy('subtitle.pendingInvitation', { email });
    } else if (submitted) {
      return getCopy('subtitle.submitted', { email });
    }
    return getCopy('subtitle.normal');
  };

  return (
    <Page>
      <NavBarUnauthed />
      <SingleContentNarrow>
        <Title>{titleCopy()}</Title>
        <SubTitle>{subTitleCopy()}</SubTitle>
        <ResetPasswordForm
          onSubmit={handleSubmit}
          submitted={submitted}
          pending={pending}
          errorMessage={errorMessage}
        />
      </SingleContentNarrow>
    </Page>
  );
};
