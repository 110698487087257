import styled from 'styled-components';

export const TitleRow = styled.div`
  width: 100%;
  margin-bottom: 3rem;
  align-items: center;

  display: grid;
  grid-column-gap: 32px;

  grid-template-columns: minmax(auto, 580px) 1fr max-content;

  ${props => props.theme.media.phoneAndTablet} {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const ConnectedWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
