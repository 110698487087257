import { ImageUpload } from 'components/image-upload';
import { copyFor } from 'config/copy';
import { InputGroup, ValueWrapper, Header } from './tenant-logo-upload.style';

export type TenantLogoUploadProps = {
  className?: string;
};

const getCopy = copyFor('forms.customization');

export const TenantLogoUpload = ({
  className,
}: TenantLogoUploadProps): JSX.Element => {
  return (
    <InputGroup data-testid="tenant-logo-upload" className={className}>
      <Header>{getCopy('inputs.logoUrl.label')}</Header>
      <ValueWrapper>
        <ImageUpload />
      </ValueWrapper>
    </InputGroup>
  );
};
