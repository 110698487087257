export const statementTimeline = {
  loadingError: 'Could not load statements',
  noData: 'No utility statements posted',
  statementTitle: 'Utility Statement',
  invoiceTitle: 'Invoice',
  remittanceTitle: 'Utility Payment',
  hostedBilling: {
    availableWithPastSubscription: 'Hosted Payments enrollment ended',
    enrolled: 'Hosted Payments enrollment started',
    notAvailable: 'Hosted Payments is not available',
    pendingEnrollment: 'Hosted Payments enrollment is pending',
    pendingRemoval: 'Hosted Payments is pending removal',
  },
  utilityRemittance: {
    availableWithPastSubscription: 'Utility Remittance enrollment ended',
    enrolled: 'Utility Remittance enrollment started',
    notAvailable: 'Utility Remittance is not available',
    pendingEnrollment: 'Utility Remittance enrollment is pending',
    pendingRemoval: 'Utility Remittance is pending removal',
  },
};
