import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { copyFor } from 'config/copy';
import { Alert, Button, Input } from '@arcadiapower/shrike';
import { Form } from 'components/form';
import { GET_WEBHOOK_ENDPOINTS } from 'components/webhook-endpoints/webhook-endpoints.api';
import { parseErrorMessage } from 'config/errors';
import { track, TrackEvents } from 'utils/analytics';
import { CREATE_WEBHOOK_ENDPOINT } from './new-webhook-endpoint.api';
import { Wrapper } from './new-webhook-endpoint.style';

const getCopy = copyFor('configuration.webhooks.newWebhookForm');

export type Props = {
  toggleAddingWebhookStatus: () => void;
  sandboxed: boolean;
};

export const NewWebhookEndpoint = ({
  toggleAddingWebhookStatus,
  sandboxed,
}: Props): JSX.Element => {
  const [pending, setPending] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [url, setUrl] = useState<string>('');

  const queryVariables: CreateWebhookEndpointMutationVariables = {
    input: {
      url,
      sandboxed,
      arcVersion: '2021-11-17',
    },
  };

  const refetchQueries = [
    {
      query: GET_WEBHOOK_ENDPOINTS,
    },
  ];

  const [createWebhookEndpoint] = useMutation<
    CreateWebhookEndpointMutation,
    CreateWebhookEndpointMutationVariables
  >(CREATE_WEBHOOK_ENDPOINT, {
    awaitRefetchQueries: true,
    refetchQueries,
    variables: queryVariables,
  });

  const canSubmit = () => {
    if (pending) return false;
    let checkURL;
    try {
      checkURL = new URL(url);
    } catch (_) {
      return false;
    }
    return checkURL.protocol === 'https:';
  };

  const handleSubmit = async () => {
    if (!canSubmit()) return;
    setPending(true);
    try {
      await createWebhookEndpoint();
      track(TrackEvents.WEBHOOK_ENDPOINT_CREATED, { sandboxed });
      clearCreateWebhookContainer();
    } catch (e) {
      setPending(false);
      setError(e as Error);
    }
  };

  const clearCreateWebhookContainer = () => {
    toggleAddingWebhookStatus();
    setUrl('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        label={getCopy('inputLabel')}
        name={getCopy('inputName')}
        value={url}
        onChange={setUrl}
        placeholder={getCopy('placeholder')}
      />
      <Wrapper>
        <Button
          onClick={handleSubmit}
          disabled={!canSubmit()}
          loading={pending}
          type="submit"
        >
          {getCopy('saveButtonText')}
        </Button>
        <Button
          onClick={clearCreateWebhookContainer}
          backgroundless={true}
          color="primary"
        >
          {getCopy('cancelButtonText')}
        </Button>
      </Wrapper>
      {error && (
        <Alert margin={{ top: '8px' }}>
          {parseErrorMessage(error, getCopy('errorMessage'))}
        </Alert>
      )}
    </Form>
  );
};
