export const coverage = {
  navTitle: 'Coverage',
  title: 'Utilities',
  subtitle: "Explore Arc's coverage at 100+ utilities and growing.",
  tableControls: {
    searchLabel: 'Search by utility name, id, short name, state, or zip code',
    searchFilter: {
      id: 'ID',
      name: 'Name',
      shortName: 'Short Name',
      state: 'State',
      zip: 'Zip',
    },
    productAvailabilityFilter: {
      label: 'Filter by product',
      allProducts: '',
      plugIntervals: 'Plug‑Intervals',
      bundle: 'Bundle (available for all utilities)',
      plugStatements: 'Plug‑Statements (available for all utilities)',
      spark: 'Spark (available for all utilities)',
    },
  },
  table: {
    columns: {
      id: 'ID',
      name: 'Utility',
      shortName: 'Short Name',
      state: 'State',
      products: 'Products',
    },
    plugNames: {
      intervals: 'Intervals',
      statements: 'Statements',
    },
  },
};
