import { Form } from 'components/form';
import { copyFor, getInputPropsFromCopy } from 'config/copy';
import { parseErrorMessage } from 'config/errors';
import { useCallback, useMemo, useState } from 'react';
import { Alert } from '@arcadiapower/shrike';
import { ActionWrapper, PrimaryButton } from 'styles/single-content.style';
import { isValidPassword } from 'utils/validators';
import { NewPasswordInput } from 'components/new-password-input';
import { Input } from './set-up-account.style';

const getCopy = copyFor('forms.setUpAccount');

export type Props = {
  onSubmit: ({
    firstName,
    lastName,
    password,
  }: {
    firstName: string;
    lastName: string;
    password: string;
  }) => void;
  error?: Error;
  pending: boolean;
};

export const SetUpAccount = ({
  onSubmit,
  error,
  pending,
}: Props): JSX.Element => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');

  const canSubmit = useMemo(() => {
    return !!(firstName && lastName && isValidPassword(password));
  }, [firstName, lastName, password]);

  const handleSubmit = useCallback(() => {
    if (canSubmit) onSubmit({ firstName, lastName, password });
  }, [firstName, lastName, password, onSubmit, canSubmit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Input
        {...getInputPropsFromCopy(getCopy, 'firstName')}
        value={firstName}
        onChange={setFirstName}
      />
      <Input
        {...getInputPropsFromCopy(getCopy, 'lastName')}
        value={lastName}
        onChange={setLastName}
      />
      <NewPasswordInput
        value={password}
        onChange={setPassword}
        margin={{ bottom: '32px' }}
      />
      {error && <Alert>{parseErrorMessage(error)}</Alert>}
      <ActionWrapper>
        <PrimaryButton
          type="submit"
          disabled={!canSubmit}
          loading={pending}
          aria-label={getCopy('cta')}
        >
          {getCopy('cta')}
        </PrimaryButton>
      </ActionWrapper>
    </Form>
  );
};
