import { gql } from '@apollo/client';

export const CONFIRM_EMAIL = gql`
  mutation confirmEmailAndGrantAccessToken(
    $input: ConfirmEmailAndGrantAccessTokenInput!
  ) {
    confirmEmailAndGrantAccessToken(input: $input) {
      resourceOwner {
        id
      }
    }
  }
`;

export const generateConfirmEmailAndGrantAccessToken =
  (): ConfirmEmailAndGrantAccessTokenMutation => ({
    confirmEmailAndGrantAccessToken: {
      resourceOwner: {
        id: '2',
      },
    },
  });
