import { gql } from '@apollo/client';

export const CYCLE_APPLICATION_CREDENTIALS = gql`
  mutation cycleApplicationCredentials(
    $input: CycleApplicationCredentialsInput!
  ) {
    cycleApplicationCredentials(input: $input) {
      credentials {
        clientId
        clientSecret
        id
      }
    }
  }
`;

// Specifying id here so we can ensure the client id type matches and the
// cache is updated for "full tests"
export const generateCycleApplicationCredentialsOutput = ({
  id,
}: {
  id: string;
}): CycleApplicationCredentialsMutation => ({
  cycleApplicationCredentials: {
    credentials: {
      clientId: 'Mynewid',
      clientSecret: 'Mynewsecret',
      id,
    },
  },
});
