import { gql } from '@apollo/client';

export const INITIATE_RESET_PASSWORD = gql`
  mutation initiateResetPassword($input: InitiateResetPasswordInput!) {
    initiateResetPassword(input: $input) {
      receivedResetRequest
    }
  }
`;

export const generateInitiateResetPassword = (
  result = true
): InitiateResetPasswordMutation => ({
  initiateResetPassword: {
    receivedResetRequest: result,
  },
});
