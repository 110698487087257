export type TenantUser =
  GetTeamQuery['resourceOwner']['tenant']['tenantUsers'][number];

// Currently this just puts all of the invited users at the bottome
// In the future we will probably want to sort by other things
export const getSortedTenantUsers = (tenantUsers: TenantUser[]) => {
  return [...tenantUsers].sort((a, b) => {
    if (userIsInvited(a) && !userIsInvited(b)) return 1;
    if (!userIsInvited(a) && userIsInvited(b)) return -1;
    return 0;
  });
};

export const userIsInvited = (tenantUser: TenantUser) =>
  tenantUser.status === 'invited';
