import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Alert, Input, Text, Link } from '@arcadiapower/shrike';
import { isValidEmail } from 'utils/validators';
import { login } from 'config/routes';
import { copyFor, getInputPropsFromCopy } from 'config/copy';
import {
  Form,
  PrimaryButton,
  ActionWrapper,
} from 'styles/single-content.style';
import { CenteredText } from 'styles/centered-text.style';

export type InitiateResetPasswordParams = { email: string };

export interface Props {
  className?: string;
  onSubmit: (params: InitiateResetPasswordParams) => void;
  pending: boolean;
  submitted: boolean;
  errorMessage?: string;
}

const getCopy = copyFor('forms.resetPassword');

export const ResetPassword = ({
  onSubmit,
  className,
  errorMessage,
  pending,
  submitted,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const canSubmit = isValidEmail(email);

  const handleSubmit = () => {
    if (submitted) {
      navigate(login);
    } else {
      onSubmit({ email });
    }
  };

  return (
    <Form onSubmit={handleSubmit} className={className}>
      {!submitted && (
        <Input
          onChange={setEmail}
          {...getInputPropsFromCopy(getCopy, 'email')}
          value={email}
          margin={{ bottom: '24px', top: '24px' }}
        />
      )}
      {errorMessage && <Alert>{errorMessage}</Alert>}
      <ActionWrapper>
        <PrimaryButton type="submit" loading={pending} disabled={!canSubmit}>
          {submitted ? getCopy('cta.submitted') : getCopy('cta.normal')}
        </PrimaryButton>
        <CenteredText>
          <Text color="secondary">
            {getCopy('secondaryCta.text')}{' '}
            <Link to={login} type="inline" openInNewTab={false}>
              {getCopy('secondaryCta.link')}
            </Link>
          </Text>
        </CenteredText>
      </ActionWrapper>
    </Form>
  );
};
