/* eslint-disable camelcase */
import { pick, startCase } from 'lodash-es';
import { formatDate } from '@arcadiapower/warbler';
import { UtilityAccountArc, UtilityCredentialArc } from 'typings/arcadia-api';
import { formatAddress } from './formatters';

export const formatDatetime = (dateString: string): string =>
  formatDate(dateString, { format: 'M/dd/yyyy, h:mm:ss a' });
const formatArray = (array: number[] | string[]): string => array.join(', ');
const formatServices = (services: string[] | undefined): string | null =>
  services ? formatArray(services.map(service => startCase(service))) : null;

type Formatters<T> = Partial<
  {
    [K in keyof T]: (value: T[K]) => string | null;
  }
>;

type SpecialLabels<T> = Partial<
  {
    [K in keyof T]: string;
  }
>;

type AllowedKeys<T> = Array<keyof T>;

export type TableSection = {
  title: string | undefined;
  rows: {
    key: string;
    label: string;
    rawValue: unknown;
    value: string | number;
  }[];
};

const mapObjectForTable = <T extends Record<string, unknown>>(
  input: T,
  {
    specialFormatters = {},
    specialLabels = {},
    allowedKeys = [],
    title,
  }: {
    specialFormatters?: Formatters<T>;
    specialLabels?: SpecialLabels<T>;
    allowedKeys?: AllowedKeys<T>;
    title?: string;
  } = {}
): TableSection => {
  const filteredInput = allowedKeys.length ? pick(input, allowedKeys) : input;
  const rows = Object.entries(filteredInput).map(([key, value]) => {
    return {
      key,
      label: specialLabels[key] ?? startCase(key),
      rawValue: value,
      value: specialFormatters[key]
        ? // Tried very hard to get this working, but typescript is not yet smart
          // enough to get the key/value here
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (specialFormatters[key] as any)(value)
        : value,
    };
  });
  return {
    rows,
    title,
  };
};

export const utilityCredentialMapper = (utilityCredentialData: {
  data: UtilityCredentialArc[];
}): TableSection[] => {
  const allowedKeys: AllowedKeys<UtilityCredentialArc> = [
    'username',
    'verification_status',
    'created_at',
    'verification_updated_at',
  ];

  const specialFormatters: Formatters<UtilityCredentialArc> = {
    created_at: formatDatetime,
    verification_updated_at: (value: string | null) =>
      value ? formatDatetime(value) : null,
  };

  const specialLabels: SpecialLabels<UtilityAccountArc> = {
    created_at: 'Created',
  };

  return [
    mapObjectForTable(utilityCredentialData.data[0], {
      allowedKeys,
      specialFormatters,
      specialLabels,
    }),
  ];
};

type EnrichedUtilityAccount = UtilityAccountArc & {
  service_address: string | null;
};

export const utilityAccountMapper = (
  utilityAccountData: {
    data: UtilityAccountArc[];
  },
  selectedId?: number
): TableSection[] => {
  const allowedKeys: AllowedKeys<EnrichedUtilityAccount> = [
    'account_number',
    'service_address',
    'services',
    'created_at',
    'updated_at',
  ];

  const specialFormatters: Formatters<EnrichedUtilityAccount> = {
    created_at: formatDatetime,
    services: formatServices,
    updated_at: formatDatetime,
  };

  const specialLabels: SpecialLabels<EnrichedUtilityAccount> = {
    account_number: 'Utility Account Number',
    created_at: 'Created',
    updated_at: 'Last Updated',
  };

  return utilityAccountData.data
    .filter(account => account.id === selectedId)
    .map(account => {
      // We need to assemble the address from a few different fields, but we don't
      // want to edit the object itself because it will affect the JSON display
      return mapObjectForTable(
        {
          ...account,
          service_address: formatAddress({
            city: account.service_address_city,
            state: account.service_address_state,
            streetOne: account.service_address_street_one,
            streetTwo: account.service_address_street_two,
            zip: account.service_address_zip,
          }),
        },
        {
          allowedKeys,
          specialFormatters,
          specialLabels,
        }
      );
    });
};
