import { useCallback, useEffect, useMemo, useState } from 'react';
import { DemoResults } from 'components/demo/demo-results';
import { DemoConnect } from 'components/demo/demo-connect';
import { useMutation, useQuery } from '@apollo/client';
import { copyFor } from 'config/copy';
import { store } from 'config/store';
import { useQueryClient } from '@tanstack/react-query';
import { NavBarDemo } from 'components/nav-bar';
import * as analytics from 'utils/analytics';
import { ArcAPIClientProvider } from 'contexts/demo/arc-api-client';
import { Page } from 'styles';
import { demo } from 'config/routes';
import { useNavigate } from 'react-router-dom';
import {
  EXCHANGE_DEMO_CONNECT_TOKEN,
  GET_DEMO_CONNECT_TOKEN,
} from './demo.api';

const getCopy = copyFor('demo');

export const Demo = (): JSX.Element => {
  const queryClient = useQueryClient();
  const {
    data,
    loading,
    refetch,
    error: connectTokenError,
  } = useQuery<GetDemoConnectTokenQuery, GetDemoConnectTokenQueryVariables>(
    GET_DEMO_CONNECT_TOKEN,
    { fetchPolicy: 'network-only' }
  );

  const navigate = useNavigate();

  const [exchangeDemoConnectToken] = useMutation<
    ExchangeDemoConnectTokenMutation,
    ExchangeDemoConnectTokenMutationVariables
  >(EXCHANGE_DEMO_CONNECT_TOKEN);
  const [showAuthorizationExpiredMessage, setShowAuthorizationExpiredMessage] =
    useState(false);
  const [showResults, setShowResults] = useState(false);
  const [accessToken, setAccessToken] = useState<string | undefined>();
  const [authenticationError, setAuthenticationError] =
    useState<boolean>(false);

  useEffect(() => {
    analytics.track(analytics.TrackEvents.DEMO_VIEWED);
  }, []);

  const resetDemo = useCallback(() => {
    refetch();
    setShowResults(false);
    setAccessToken(undefined);
    queryClient.clear();
    store.remove('utilityCredentialId');
    navigate(demo, { replace: true });
  }, [queryClient, refetch, navigate]);

  const handleUnauthorized = useCallback(() => {
    resetDemo();
    setShowAuthorizationExpiredMessage(true);
  }, [resetDemo]);

  const initializeUser = async (utilityCredentialId: string) => {
    if (!data) return;
    setShowAuthorizationExpiredMessage(false);
    try {
      const { data: accessTokenPayload } = await exchangeDemoConnectToken({
        variables: {
          input: { demoToken: data.demoConnectToken.connectToken },
        },
      });
      if (accessTokenPayload) {
        setAccessToken(
          accessTokenPayload.exchangeDemoConnectToken.accessToken.token
        );
        store.set('utilityCredentialId', utilityCredentialId);
      }
    } catch (error) {
      setAuthenticationError(true);
    }
  };

  const errorMessage = useMemo(() => {
    if (authenticationError || connectTokenError)
      return getCopy('errors.authentication');
    if (showAuthorizationExpiredMessage)
      return getCopy('errors.authorizationExpired');
  }, [authenticationError, connectTokenError, showAuthorizationExpiredMessage]);

  // Prevent invited user from accessing dashboard before completing setup

  return (
    <Page>
      <NavBarDemo />
      {showResults && data && accessToken ? (
        <ArcAPIClientProvider
          onUnauthorized={handleUnauthorized}
          accessToken={accessToken}
          clientUserId={data.demoConnectToken.clientUserId}
        >
          <DemoResults resetDemo={resetDemo} />
        </ArcAPIClientProvider>
      ) : (
        <DemoConnect
          loading={loading}
          errorMessage={errorMessage}
          setShowResults={setShowResults}
          refetch={refetch}
          connectToken={data?.demoConnectToken?.connectToken}
          initializeUser={initializeUser}
        />
      )}
    </Page>
  );
};
