export const resetPassword = {
  title: {
    normal: 'Forgot password',
    submitted: "We've sent you a link to reset your password.",
    pendingInvitation: 'Check your email for your invitation.',
  },
  subtitle: {
    normal: 'No problem, we’ll email you a link where you can reset it.',
    submitted: 'An email with a special link should arrive soon to %{email}.',
    pendingInvitation:
      'An email with a link to set up your account was already sent to %{email}.',
  },
};
