import { Page } from 'styles';
import { Outlet } from 'react-router-dom';
import { login, profile, team } from 'config/routes';
import { SideNav } from 'components/side-nav';
import { copyFor } from 'config/copy';
import { GridContainer, SideBar, Content } from 'styles/side-bar-grid.style';
import { logout } from 'config/auth';
import {
  profileSvgPath,
  signOutSvgPath,
  teamSvgPath,
} from 'assets/icons/paths';

const getCopy = copyFor('settings');

const routes = [
  {
    label: getCopy('sideNav.links.profile'),
    route: profile,
    svgPath: profileSvgPath,
  },
  {
    label: getCopy('sideNav.links.team'),
    route: team,
    svgPath: teamSvgPath,
  },
  {
    label: getCopy('sideNav.links.signOut'),
    route: login,
    onClick: () => logout({ expired: false }),
    svgPath: signOutSvgPath,
  },
];

export const Settings = (): JSX.Element => {
  return (
    <Page>
      <GridContainer>
        <SideBar>
          <SideNav routes={routes} title={getCopy('sideNav.title')} />
        </SideBar>
        <Content>
          <Outlet />
        </Content>
      </GridContainer>
    </Page>
  );
};
