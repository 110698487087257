import { Page } from 'styles';
import * as analytics from 'utils/analytics';
import { Navigate, useParams } from 'react-router-dom';
import { login, changePassword } from 'config/routes';
import { CenteredContentNarrow } from 'styles/single-content.style';
import { Alert, Text, Loading, Link } from '@arcadiapower/shrike';
import { NavBarUnauthed } from 'components/nav-bar';
import { copyFor } from 'config/copy';
import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { authenticate, hasValidLoginToken, logout } from 'config/auth';
import { MessageContainer } from './verify-reset-token.style';
import { VERIFY_RESET_TOKEN } from './verify-reset-token.api';

const getCopy = copyFor('verifyResetToken');

export const VerifyResetToken = (): JSX.Element => {
  const [convertResetPasswordTokenToAccessToken] = useMutation<
    ConvertResetPasswordTokenToAccessTokenMutation,
    ConvertResetPasswordTokenToAccessTokenMutationVariables
  >(VERIFY_RESET_TOKEN);

  const params = useParams<{ resetPasswordToken: string }>();
  const { resetPasswordToken } = params;
  const [pending, setPending] = useState<boolean>(false);

  useEffect(() => {
    let unmounted = false;
    async function verifyToken() {
      logout({ navigateToLogin: false });
      setPending(true);
      try {
        const { data } = await convertResetPasswordTokenToAccessToken({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          variables: { input: { resetPasswordToken: resetPasswordToken! } },
        });
        if (data) {
          analytics.track(analytics.TrackEvents.PASSWORD_RESET_VERIFIED);
          const { token, expiresAt } =
            data.convertResetPasswordTokenToAccessToken.accessToken;
          authenticate(token, expiresAt);
        }
      } catch (e) {
        // TODO: log failures with sentry?
      } finally {
        if (!unmounted) setPending(false);
      }
    }
    verifyToken();
    return () => {
      unmounted = true;
    };
  }, [resetPasswordToken, convertResetPasswordTokenToAccessToken]);

  if (hasValidLoginToken()) return <Navigate to={changePassword} replace />;

  const loadingMessage = () => (
    <>
      <Text>{getCopy('pending')}</Text>
      <Loading />
    </>
  );
  const errorMessage = () => (
    <>
      <Alert>{getCopy('invalid.alert')}</Alert>
      <Link to={login} type="inline" openInNewTab={false}>
        {getCopy('invalid.cta')}
      </Link>
    </>
  );
  return (
    <Page>
      <NavBarUnauthed />
      <CenteredContentNarrow>
        <MessageContainer>
          {pending ? loadingMessage() : errorMessage()}
        </MessageContainer>
      </CenteredContentNarrow>
    </Page>
  );
};
