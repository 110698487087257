import styled from 'styled-components';
import { Text, Badge } from '@arcadiapower/shrike';
import { Margin } from 'styles/margin.style';

export const Wrapper = styled.header<{ expanded?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Text).attrs({
  tag: 'h3',
  textStyle: 'heading800',
})``;

export const HeaderSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

export const StatusPill = styled(Badge)<{
  status?: Statement['status'];
  margin?: Margin;
}>`
  ${props =>
    props.status === 'paid'
      ? `
      color: ${props.theme.colors.content.success};
      background-color: ${props.theme.colors.background.success};
    `
      : `
      color: ${props.theme.colors.content.warning};
      background-color: ${props.theme.colors.background.warning};
  `}
`;

export const LinkHolder = styled.li`
  margin: 0 1rem;
`;
