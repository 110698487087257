import { copyFor } from 'config/copy';
import { configuration, overview, settings, customers } from 'config/paths';
import { NavBarItem } from 'components/nav-bar-item';
import {
  consoleSvgPath,
  overviewSvgPath,
  settingsSvgPath,
  customersSvgPath,
} from 'assets/icons/paths';
import {
  LogoContainer,
  NavBarContainer,
  NavBarContent,
  NavGroup,
  Logo,
} from './nav-bar.style';

const getCopy = copyFor('nav');

export const NavBarAuthed = (): JSX.Element => {
  return (
    <NavBarContainer withBorder={true}>
      <NavBarContent>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <NavGroup>
          <NavBarItem
            label={getCopy('links.overview')}
            svgPath={overviewSvgPath}
            route={overview}
          />
          <NavBarItem
            label={getCopy('links.customers')}
            svgPath={customersSvgPath}
            route={customers}
          />
          <NavBarItem
            label={getCopy('links.configuration')}
            svgPath={consoleSvgPath}
            route={configuration}
          />
        </NavGroup>
        <NavGroup>
          <NavBarItem
            label={getCopy('links.settings')}
            svgPath={settingsSvgPath}
            route={settings}
          />
        </NavGroup>
      </NavBarContent>
    </NavBarContainer>
  );
};
