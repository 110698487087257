import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Text, IconButton, Icon, Button } from '@arcadiapower/shrike';
import { copyFor } from 'config/copy';
import { track, TrackEvents } from 'utils/analytics';
import { noop } from 'utils/functions';
import { ActionConfirmationModal } from 'components/action-confirmation-modal';
import { ReadOnlyInput } from 'components/read-only-input';
import { TableSectionTitle, TableSpacer } from 'styles/table.style';
import {
  UPDATE_WEBHOOK_ENDPOINT,
  DELETE_WEBHOOK_ENDPOINT,
  SEND_TEST_WEBHOOK_ENDPOINT_EVENT,
} from './webhook-endpoint-item.api';
import {
  ReadOnlyWrapper,
  URLWrapper,
  ItemHeader,
  TestOrEnableHeader,
} from './webhook-endpoint-item.style';

const getCopy = copyFor('configuration.webhooks');

export type Props = {
  webhookEndpoint: WebhookEndpoint;
};

export const WebhookEndpointItem = ({
  webhookEndpoint,
}: Props): JSX.Element => {
  const [showDeleteWebhookModal, setShowDeleteWebhookModal] =
    useState<boolean>(false);

  const trackSuccess = (event: TrackEvents) => {
    track(event, { sandboxed: webhookEndpoint.sandboxed });
  };

  const [
    sendTestWebhookEndpointEvent,
    { loading: testLoading, error: testError, data: testQueued },
  ] = useMutation<
    SendTestWebhookEndpointEventMutation,
    SendTestWebhookEndpointEventMutationVariables
  >(SEND_TEST_WEBHOOK_ENDPOINT_EVENT, {
    onError: noop,
    onCompleted: () => trackSuccess(TrackEvents.WEBHOOK_ENDPOINT_TESTED),
    variables: {
      input: {
        id: webhookEndpoint.id,
      },
    },
  });

  const [
    updateWebhookEndpoint,
    { error: enableError, loading: enableLoading },
  ] = useMutation<
    UpdateWebhookEndpointMutation,
    UpdateWebhookEndpointMutationVariables
  >(UPDATE_WEBHOOK_ENDPOINT, {
    onError: noop,
    onCompleted: () => trackSuccess(TrackEvents.WEBHOOK_ENDPOINT_ENABLED),
    variables: {
      input: {
        id: webhookEndpoint.id,
        enabled: true,
      },
    },
  });

  const [
    deleteWebhookEndpoint,
    { error: deleteError, loading: deleteLoading },
  ] = useMutation<
    DeleteWebhookEndpointMutation,
    DeleteWebhookEndpointMutationVariables
  >(DELETE_WEBHOOK_ENDPOINT, {
    onError: noop,
    onCompleted: () => trackSuccess(TrackEvents.WEBHOOK_ENDPOINT_DELETED),
    variables: {
      input: {
        id: webhookEndpoint.id,
      },
    },
  });

  const copy = {
    cta: getCopy('deleteWebhookModal.cta'),
    description: getCopy('deleteWebhookModal.description'),
    title: getCopy('deleteWebhookModal.title'),
  };

  const errorMessage = deleteError && getCopy('deleteWebhookModal.error');
  const testMessage = testLoading
    ? getCopy('webhooksDisplay.testQueuing')
    : getCopy('webhooksDisplay.testQueued');

  return (
    <>
      {showDeleteWebhookModal && (
        <ActionConfirmationModal
          copy={copy}
          onClose={() => {
            setShowDeleteWebhookModal(false);
          }}
          onConfirmation={() => deleteWebhookEndpoint()}
          variant="errorDark"
          loading={deleteLoading}
          errorMessage={errorMessage}
        />
      )}
      <TableSpacer />
      <ItemHeader>
        <URLWrapper>
          {!webhookEndpoint.enabled && (
            <Icon
              icon="ExclamationCircleInverted"
              color="errorBright"
              margin="2px 10px 0px 0px"
            ></Icon>
          )}
          <TableSectionTitle>{webhookEndpoint?.url}</TableSectionTitle>
        </URLWrapper>
        <IconButton
          aria-label={getCopy('webhooksDisplay.deleteButton')}
          min-width="32px"
          icon="UIDelete"
          data-testid="delete-button"
          onClick={() => {
            setShowDeleteWebhookModal(true);
          }}
        />
      </ItemHeader>
      {webhookEndpoint.enabled ? (
        <TestOrEnableHeader justify-content="flex-start">
          <Button
            backgroundless
            color="primary"
            size="small"
            onClick={() => sendTestWebhookEndpointEvent()}
            loading={testLoading}
          >
            {getCopy('webhooksDisplay.testButton')}
          </Button>
          {testError ? (
            <Text color="error" textStyle="paragraph400">
              {getCopy('webhooksDisplay.testError')}
            </Text>
          ) : (
            (testQueued || testLoading) && (
              <Text textStyle="paragraph400">{testMessage}</Text>
            )
          )}
        </TestOrEnableHeader>
      ) : (
        <TestOrEnableHeader>
          <Button
            backgroundless
            color="primary"
            size="small"
            onClick={() => updateWebhookEndpoint()}
            loading={enableLoading}
          >
            {getCopy('webhooksDisplay.enableButton')}
          </Button>
          <Text textStyle="paragraph400">
            {getCopy('webhooksDisplay.enableMessage')}
          </Text>
        </TestOrEnableHeader>
      )}
      {enableError && (
        <Text
          margin={{ top: '-2%', bottom: '2%' }}
          color="error"
          textStyle="paragraph400"
        >
          {getCopy('webhooksDisplay.enableError')}
        </Text>
      )}
      <ReadOnlyWrapper>
        <ReadOnlyInput
          value={webhookEndpoint.id}
          label={getCopy('webhooksDisplay.id')}
        />
        <ReadOnlyInput
          value={webhookEndpoint.signingKey}
          label={getCopy('webhooksDisplay.signingKey')}
          masked
          canReveal
          canCopy
        />
      </ReadOnlyWrapper>
    </>
  );
};
