import { useState, useCallback } from 'react';
import { TeamMemberTable } from 'components/team-member-table';
import { Text, Icon } from '@arcadiapower/shrike';
import * as analytics from 'utils/analytics';
import { copyFor } from 'config/copy';
import {
  TableSection,
  TableSectionHeader,
  TableSectionTitle,
} from 'styles/table.style';
import { AddTeamMember } from 'components/forms/add-team-member';
import {
  TableTitleWrapper,
  AddMemberWrapper,
  StyledButton,
} from './active-members.style';

const getCopy = copyFor('settings.team');

export interface Props {
  tenant: GetTeamQuery['resourceOwner']['tenant'];
}

export const ActiveMembers = ({ tenant }: Props) => {
  const { canInviteUsers, tenantUsers } = tenant;

  const [isAddingMember, setIsAddingMember] = useState(false);

  const closeForm = useCallback(
    () => setIsAddingMember(false),
    [setIsAddingMember]
  );

  const openForm = useCallback(() => {
    analytics.track(analytics.TrackEvents.ADD_USER_CLICKED);
    setIsAddingMember(true);
  }, [setIsAddingMember]);

  return (
    <TableSection>
      <TableSectionHeader>
        <AddMemberWrapper>
          <TableTitleWrapper>
            <TableSectionTitle>
              {getCopy('activeMembers.title')}
            </TableSectionTitle>
            {canInviteUsers && (
              <StyledButton
                size="small"
                color="primaryInverse"
                aria-label={getCopy('addMembersButton')}
                onClick={openForm}
                disabled={isAddingMember}
              >
                <Icon
                  icon="Plus"
                  color="primaryInverse"
                  margin={{ right: '10px' }}
                />
                <Text color="primaryInverse" textStyle="heading500">
                  {getCopy('addMembersButton')}
                </Text>
              </StyledButton>
            )}
          </TableTitleWrapper>
          {!canInviteUsers && (
            <Text margin={{ top: '11px' }}>
              {getCopy('addMembersDisclaimer')}
            </Text>
          )}
          {isAddingMember && (
            <AddTeamMember onSuccess={closeForm} onCancel={closeForm} />
          )}
        </AddMemberWrapper>
      </TableSectionHeader>
      <TeamMemberTable tenantUsers={tenantUsers} />
    </TableSection>
  );
};
