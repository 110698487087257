import styled from 'styled-components';
import { Text } from '@arcadiapower/shrike';

export const TableText = styled(Text)`
  overflow: hidden;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 8px 16px 8px 0;
`;

export const StatusBadgeWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ResendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
