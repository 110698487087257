import { EnhancedFilterOption } from './debounced-search-bar.component';

export const parseSearchValue = <T extends EnhancedFilterOption[]>(
  rawValue: string,
  filterOptions: T
): { searchTerm: string; filterKey?: T[number]['key'] } => {
  let searchTerm = rawValue;
  let filterKey;
  const pivotPoint = rawValue.indexOf(':');
  if (pivotPoint !== -1) {
    const filterTag = rawValue.slice(0, pivotPoint);
    filterKey = filterOptions
      .find(option => option.tag === filterTag)
      ?.key?.trim();
    searchTerm = rawValue.slice(pivotPoint + 1).trim();
  }
  return { searchTerm, filterKey };
};
