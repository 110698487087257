import { SetStateAction, Dispatch } from 'react';
import { Icon } from '@arcadiapower/shrike';
import { tableSvgPath, consoleSvgPath, graphSvgPath } from 'assets/icons/paths';
import { copyFor } from 'config/copy';
import { ShowAPIButton } from './api-explorer-button.style';

const getCopy = copyFor('demo.results.apiExplorerButton');

const typeToSvgMapping = {
  graph: graphSvgPath,
  table: tableSvgPath,
};

export type APIExplorerButtonType = 'graph' | 'table';

export type Props = {
  setShowAPI: Dispatch<SetStateAction<boolean>>;
  showAPI: boolean;
  disabled?: boolean;
  type?: APIExplorerButtonType;
};

export const APIExplorerButton = ({
  disabled,
  setShowAPI,
  showAPI,
  type = 'table',
}: Props): JSX.Element => {
  return (
    <ShowAPIButton
      disabled={disabled}
      onClick={() => setShowAPI(previous => !previous)}
      data-testid="api-explorer-button"
    >
      <Icon
        path={showAPI ? typeToSvgMapping[type] : consoleSvgPath}
        color="primary"
        margin={{ right: '8px' }}
      />
      {showAPI
        ? getCopy(`labelShowingAPI.${type}`)
        : getCopy('labelShowingTable')}
    </ShowAPIButton>
  );
};
