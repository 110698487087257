import { formatDate, isSameDay } from '@arcadiapower/warbler';
import { IntervalDataItem } from 'typings/arcadia-api';

export type EnrichedIntervals = (IntervalDataItem & { midpointDate: Date })[];

export const enrichIntervals = (
  intervalData: IntervalDataItem[]
): EnrichedIntervals =>
  intervalData.map(interval => ({
    ...interval,
    midpointDate: new Date(
      Math.round(
        (new Date(interval.start_time).getTime() +
          new Date(interval.end_time).getTime()) /
          2
      )
    ),
  }));

export const generateIntervalDataSeries = (
  intervalData: EnrichedIntervals,
  currentDate: Date
) => {
  const all = intervalData
    .filter(d => isSameDay(currentDate, d.midpointDate))
    .map(d => {
      return {
        x: d.midpointDate.getTime(),
        y: d.kwh,
        ...d,
      };
    });
  return all;
};

export const tooltipFormatter = (startTime: string, endTime: string) => {
  return `${formatDate(startTime, {
    format: 'h:mm',
    style: 'date',
  })}-${formatDate(endTime, {
    format: 'h:mmaaa',
    style: 'date',
  })}   `;
};

export const xAxisLabelFormatter = (value: string) => {
  const date = new Date(value);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  if (minutes === 0) {
    if (hours % 3 === 0) {
      return formatDate(date, {
        format: 'haaa',
        style: 'date',
      });
    } else return '\u2060';
  }
  return '';
};

export const getQueryRange = () => {
  const today = new Date();
  const yesterday = new Date(today.getTime() - 60 * 60 * 24 * 1000);
  const oneWeekBeforeYesterday = new Date(
    yesterday.getTime() - 60 * 60 * 24 * 7 * 1000
  );
  // We want to query from midnight to midnight so that we only see full day's of data
  yesterday.setHours(23, 59, 59, 999);
  oneWeekBeforeYesterday.setHours(0, 0, 0, 0);
  return {
    yesterday,
    oneWeekBeforeYesterday,
  };
};
