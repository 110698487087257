import { UtilityRemittanceForTimeline } from 'components/customer-detail/statement-timeline/statement-timeline.api';
import { isBoolean } from 'lodash-es';
import { UtilityAccountArc } from 'typings/arcadia-api';
import {
  formatDate,
  capitalize,
  isString,
  isNumber,
} from '@arcadiapower/warbler';
import { copyFor } from 'config/copy';

export type AddressObject = {
  city?: Maybe<string>;
  state?: Maybe<string>;
  streetOne?: Maybe<string>;
  streetTwo?: Maybe<string>;
  zip?: Maybe<string>;
};

export const formatServiceAddressForUtilityAccount = (
  utilityAccount: Pick<
    UtilityAccount,
    | 'serviceAddressCity'
    | 'serviceAddressState'
    | 'serviceAddress'
    | 'serviceAddressStreetTwo'
    | 'serviceAddressZip'
  >
): string | null =>
  formatAddress({
    city: utilityAccount.serviceAddressCity,
    state: utilityAccount.serviceAddressState,
    streetOne: utilityAccount.serviceAddress,
    streetTwo: utilityAccount.serviceAddressStreetTwo,
    zip: utilityAccount.serviceAddressZip,
  });

export const formatAddress = (
  address?: Maybe<AddressObject>
): string | null => {
  if (!address) return null;
  const { city, state, streetOne, streetTwo, zip } = address;
  if (!streetOne || !city || !state || !zip) return null;

  let result = streetOne;
  if (streetTwo) {
    result += `, ${streetTwo}`;
  }
  result += ` • ${city}, ${state} ${zip}`;

  return result;
};

export const formatMaskedAccountNumber = (
  { maskedBlocks }: { maskedBlocks: number },
  filteredCardNumber?: string | null
): string => {
  if (!filteredCardNumber) return ``;
  const maskedString = '•••• '.repeat(maskedBlocks);
  return `${maskedString}${filteredCardNumber.slice(
    filteredCardNumber.length - 4
  )}`;
};

export const formatDateRangeForUtilityStatement = (
  utilityStatement: Pick<
    UtilityStatement,
    'serviceEndDate' | 'serviceStartDate'
  >
): string | null =>
  formatDateRange({
    endDate: utilityStatement.serviceEndDate,
    startDate: utilityStatement.serviceStartDate,
  });

export const formatDateRange = ({
  endDate,
  startDate,
}: {
  endDate: string;
  startDate: string;
}): string | null => {
  const end = formatDate(endDate);
  const start = formatDate(startDate);
  if (!start || !end) return null;
  return `${start} - ${end}`;
};

export const formatCheckingNumbersList = (
  utilityRemittance: Pick<UtilityRemittanceForTimeline, 'checkNumbers'>
): string | null => {
  if (!utilityRemittance.checkNumbers) return null;
  if (!utilityRemittance.checkNumbers.length) return null;
  return utilityRemittance.checkNumbers.join(', ');
};

export const formatDerivedServices = (
  utilityAccount: Pick<UtilityAccount, 'derivedServices'>
): string | null => {
  if (!utilityAccount.derivedServices.length) return null;
  return utilityAccount.derivedServices
    .map(service => capitalize(service))
    .join(', ');
};

export const formatArcUtilityAccountLabel = (
  utilityAccount: UtilityAccountArc
) => {
  let label = utilityAccount.account_number;
  if (utilityAccount.service_address_street_one) {
    label += ` - ${utilityAccount.service_address_street_one}`;
  }
  return label;
};

export const formatSupportedArcProducts = (
  productAvailability: Partial<ProductAvailability>
) => {
  const getCopy = copyFor('coverage.table.plugNames');
  const products = [];
  if (productAvailability.bundleSupported) products.push('Bundle');
  if (productAvailability.plugIntervalsSupported)
    products.push(`Plug‑${getCopy('intervals')}`);
  if (productAvailability.plugStatementsSupported)
    products.push(`Plug‑${getCopy('statements')}`);
  if (productAvailability.sparkSupported) products.push('Spark');
  return products.sort().join(', ');
};

// Currently supports strings and numbers
export const formatObjectForQueryParams = (
  input: Record<string, unknown>
): Record<string, string> => {
  const keys = Object.keys(input) as (keyof typeof input)[];

  return keys.reduce((cleanedState, key) => {
    let value = input[key];
    // Cast primative values to string
    if (isNumber(value)) value = String(value);
    if (isBoolean(value)) value = String(value);

    // Filter out empty strings here as well
    if (isString(value) && value) {
      return { ...cleanedState, [key]: value };
    }
    // Remove objects/null/nullable values
    return cleanedState;
  }, {});
};
