export enum TrackEvents {
  SIGN_UP = 'sign-up',
  API_KEYS_ROTATED = 'api-keys-rotated',
  PASSWORD_RESET_COMPLETE = 'password-reset-complete',
  PASSWORD_RESET_REQUESTED = 'password-reset-requested',
  PASSWORD_RESET_VERIFIED = 'password-reset-verified',
  EMAIL_CONFIRMED = 'email-confirmed',
  DEMO_VIEWED = 'demo-viewed',
  DEMO_COMPLETED = 'demo-completed',
  CHARGE_COST_CALCULATED = 'charge-cost-calculated',
  SMART_CHARGE_CALCULATED = 'smart-charge-calculated',
  ADD_USER_CLICKED = 'add-user-clicked',
  ADD_USER_SUBMITTED = 'add-user-submitted',
  ADD_USER_RESEND = 'add-user-resend',
  INVITED_USER_SET_UP_VIEWED = 'invited-user-viewed',
  INVITED_USER_SET_UP_COMPLETED = 'invited-user-completed',
  INVITED_USER_LINK_EXPIRED = 'invited-user-link-expired',
  INTERVAL_DATA_LOADED = 'interval-data-loaded',
  INTERVAL_DATA_TIMEOUT = 'interval-data-timeout',
  INTERVAL_DATA_UNAVAILABLE = 'interval-data-unavailable',
  INTERVAL_DATA_LAST_WEEK_EMPTY = 'interval-data-last-week-empty',
  INTERVAL_DATA_ERROR = 'interval-data-error',
  INTERVAL_DATA_PREVIOUS_DAY = 'interval-data-previous-day',
  INTERVAL_DATA_VIEW_API_DATA = 'interval-data-view-api-data',
  API_DOCS_CLICKED = 'api-docs-clicked',
  QUICKSTART_GUIDES_CLICKED = 'quickstart-guides-clicked',
  PRODUCT_GUIDES_CLICKED = 'product-guides-clicked',
  SUPPORT_CLICKED = 'support-clicked',
  CUSTOMER_OVERVIEW_VIEWED = 'customer-overview-viewed',
  CUSTOMER_PAGE_SEARCHED = 'customer-page-searched',
  CUSTOMER_PAGE_FILTERED = 'customer-page-filtered',
  CREDENTIAL_STATUS_INFORMATION_MODAL_LAUNCHED = 'credential-status-information-modal-launched',
  COVERAGE_PAGE_SEARCHED = 'coverage-page-searched',
  WEBHOOK_ENDPOINT_CREATED = 'webhook-endpoint-created',
  WEBHOOK_ENDPOINT_ENABLED = 'webhook-endpoint-enabled',
  WEBHOOK_ENDPOINT_DELETED = 'webhook-endpoint-deleted',
  WEBHOOK_ENDPOINT_TESTED = 'webhook-endpoint-tested',
  TABLE_SORTED = 'table-sorted',
  STATEMENT_OPENED = 'statement-opened',
  COVERAGE_PAGE_PRODUCTS_FILTERED = 'coverage-page-products-filtered',
}
