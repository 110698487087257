import { formatDateEST, capitalize } from '@arcadiapower/warbler';
import { UtilityRemittanceItemForTimeline } from 'components/customer-detail/statement-timeline/statement-timeline.api';
import { TableSection } from 'styles/table.style';
import { DataRow } from 'components/payment-timeline/data-row';
import { lightning } from 'assets/icons/paths';
import { copyFor } from 'config/copy';
import {
  SectionHeader,
  Column,
  DataContainer,
  HeaderIcon,
  HeaderPrimary,
  SectionTitle,
} from '../sections.style';

const getCopy = copyFor(
  'components.paymentTimeline.sections.utilityRemittanceItem'
);

export type Props = {
  utilityRemittanceItem: UtilityRemittanceItemForTimeline;
};

export const UtilityRemittanceItem = ({
  utilityRemittanceItem,
}: Props): JSX.Element => {
  return (
    <TableSection
      data-testid={`utility-remittance-item-${utilityRemittanceItem.id}`}
    >
      <SectionHeader>
        <HeaderPrimary>
          <HeaderIcon path={lightning} />
          <SectionTitle>{getCopy('title')}</SectionTitle>
        </HeaderPrimary>
      </SectionHeader>
      <DataContainer>
        <Column>
          <DataRow
            value={utilityRemittanceItem.id}
            label={getCopy('fields.id')}
          />
          <DataRow
            value={new Date(utilityRemittanceItem.updatedAt)}
            label={getCopy('fields.updatedAt')}
            formatter={formatDateEST}
          />
          <DataRow
            value={new Date(utilityRemittanceItem.createdAt)}
            label={getCopy('fields.createdAt')}
            formatter={formatDateEST}
          />
        </Column>
        <Column>
          <DataRow
            value={utilityRemittanceItem.status}
            label={getCopy('fields.status')}
            formatter={capitalize}
          />
          <DataRow
            value={
              utilityRemittanceItem.remittedAt
                ? new Date(utilityRemittanceItem.remittedAt)
                : null
            }
            label={getCopy('fields.remittedAt')}
            formatter={formatDateEST}
          />
          <DataRow
            value={
              utilityRemittanceItem.remittedAt
                ? new Date(utilityRemittanceItem.remittedAt)
                : null
            }
            label={getCopy('fields.scheduledAt')}
            formatter={formatDateEST}
          />
        </Column>
      </DataContainer>
    </TableSection>
  );
};
