import { gql } from '@apollo/client';

export const UPDATE_WEBHOOK_ENDPOINT = gql`
  mutation updateWebhookEndpoint($input: UpdateWebhookEndpointInput!) {
    updateWebhookEndpoint(input: $input) {
      webhookEndpoint {
        id
        createdAt
        enabled
        signingKey
        url
        sandboxed
        tenantId
        arcVersion
        deleted
      }
    }
  }
`;

export const generateUpdateWebhookEndpoint =
  (): UpdateWebhookEndpointMutation => ({
    updateWebhookEndpoint: {
      webhookEndpoint: {
        createdAt: '2022-08-01',
        enabled: true,
        signingKey: 'a1HGEoZIP1YypDuM',
        id: '4bddf027-a72f-4c6a-a9a3-45499838e0d5',
        url: 'https://www.liveOne.com',
        sandboxed: false,
        tenantId: '12097',
        arcVersion: '2021-11-17',
        deleted: false,
      },
    },
  });

export const DELETE_WEBHOOK_ENDPOINT = gql`
  mutation deleteWebhookEndpoint($input: DeleteWebhookEndpointInput!) {
    deleteWebhookEndpoint(input: $input) {
      tenant {
        id
        webhookEndpoints {
          createdAt
          id
          url
          signingKey
        }
      }
    }
  }
`;

export const generateDeleteWebhookEndpoint =
  (): DeleteWebhookEndpointMutation => ({
    deleteWebhookEndpoint: {
      tenant: {
        id: '12097',
        webhookEndpoints: [
          {
            createdAt: '2022-08-01',
            id: '4bddf027-a72f-4c6a-a9a3-00099987e0d5',
            url: 'https://liveTwo.com',
            signingKey: 'a1HGEoZIP1YypDuM',
          },
        ],
      },
    },
  });

export const SEND_TEST_WEBHOOK_ENDPOINT_EVENT = gql`
  mutation sendTestWebhookEndpointEvent(
    $input: SendTestWebhookEndpointEventInput!
  ) {
    sendTestWebhookEndpointEvent(input: $input) {
      webhookEndpoint {
        id
      }
    }
  }
`;

export const generateSendTestWebhookEndpointEvent =
  (): SendTestWebhookEndpointEventMutation => ({
    sendTestWebhookEndpointEvent: {
      webhookEndpoint: {
        id: '4bddf027-a72f-4c6a-a9a3-45499838e0d5',
      },
    },
  });
