import { copyFor } from 'config/copy';
import { Table, TableHeaderText, TableBody } from './team-member-table.style';
import { TeamMemberTableRow } from '../team-member-table-row';
import { getSortedTenantUsers, TenantUser } from './team-member-table.utils';

export type Props = {
  tenantUsers: TenantUser[];
};

const getCopy = copyFor('components.teamMemberTable');

export const TeamMemberTable = ({ tenantUsers }: Props): JSX.Element => {
  const sortedTenantUsers = getSortedTenantUsers(tenantUsers);

  const renderHeaders = () => {
    const keys = ['name', 'email'] as const;

    return keys.map(key => (
      <th key={key}>
        <TableHeaderText>{getCopy(`tableHeaders.${key}`)}</TableHeaderText>
      </th>
    ));
  };

  return (
    <Table data-testid="active-members-section">
      <thead>
        <tr>{renderHeaders()}</tr>
      </thead>
      <TableBody>
        {sortedTenantUsers.map(user => (
          <TeamMemberTableRow key={user.email} tenantUser={user} />
        ))}
      </TableBody>
    </Table>
  );
};
