import { CURRENT_USER_KEY, DEBUG_ANALYTICS } from 'config/constants';
import { store } from 'config/store';
import {
  identifyUserForErrorReporting,
  clearUserForErrorReporting,
} from './error-reporting';

export const generateId = (id: string): string => {
  // This is important to seperate admin here from users on the business to consumer side
  return `arc-admin-${id}`;
};

type UserProperties = 'email' | 'firstName' | 'lastName';

export interface AnalyticsIdentity
  extends Partial<Record<UserProperties, string>> {
  id: string;
}

export const reset = (): void => {
  logAnalytics({ type: 'reset' });
  window?.analytics?.reset();
  clearUserForErrorReporting();
};

type LogAnalyticsParams =
  | { type: 'page' }
  | { type: 'event'; eventLabel: string; payload?: Record<string, unknown> }
  | { type: 'identify'; user: unknown }
  | { type: 'reset' };

export const logAnalytics = (params: LogAnalyticsParams): void => {
  if (!DEBUG_ANALYTICS) return;
  let message;
  if (params.type === 'page') {
    message = `Page view: ${window.location.href}`;
  } else if (params.type === 'event') {
    const { eventLabel, payload } = params;
    message = `Tracking event: ${eventLabel}`;
    if (payload) {
      message += ` with properties ${JSON.stringify(payload)}`;
    }
  } else if (params.type === 'reset') {
    message = 'Resetting tracking';
  } else if (params.type === 'identify') {
    message = `Identifying user: ${JSON.stringify(params.user)}`;
  }
  // eslint-disable-next-line no-console
  console.log(message);
};

export const track = (
  eventLabel: string,
  payload?: Record<string, unknown>
) => {
  logAnalytics({ eventLabel, payload, type: 'event' });
  window?.analytics?.track(eventLabel, payload);
};

export const page = () => {
  logAnalytics({ type: 'page' });
  window?.analytics?.page();
};

export const identify = (user: AnalyticsIdentity): void => {
  const { id, ...userProperties } = user;
  const prefixedId = generateId(id);
  window?.analytics?.identify(
    prefixedId,
    {
      ...userProperties,
      tenant_user_id: id,
    },
    // need to add this so that salesforce leads will get created
    {
      // prettier-ignore
      'integrations': {
        // prettier-ignore
        'Salesforce': true,
      },
    }
  );
  const currentUser = {
    ...userProperties,
    id: prefixedId,
  };
  logAnalytics({ type: 'identify', user: currentUser });
  store.set(CURRENT_USER_KEY, currentUser);
  identifyUserForErrorReporting(currentUser);
};
