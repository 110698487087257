import styled from 'styled-components';
import { Text, Link, Icon } from '@arcadiapower/shrike';
import { responsiveStyle } from 'styles/responsive-style';

export const TableWrapper = styled.div<{ $maxHeight?: string }>`
  max-width: 100%;
  width: 100%;
  overflow-x: auto;
  max-height: ${props => (props.$maxHeight ? props.$maxHeight : '100%')};
`;

export const StyledTable = styled.div.attrs({ role: 'table' })<{
  $minWidth: string | string[];
}>`
  display: table;
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  ${props => responsiveStyle('min-width', props.$minWidth)}
`;

export const TableHeader = styled.div.attrs({ role: 'columnheader' })<{
  $width?: string | string[];
}>`
  display: table-cell;
  position: relative;
  ${props => responsiveStyle('width', props.$width)};
`;

export const TableHeaderInternal = styled.div<{
  $justifyContent?: 'flex-start' | 'flex-end' | 'center';
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  ${props =>
    props.$justifyContent &&
    `justify-content: ${props.$justifyContent};
  `}
`;

export const TableHeaderButton = styled.button<{
  $isSorted?: boolean;
  $justifyContent?: 'flex-start' | 'flex-end' | 'center';
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin: 0;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  ${props => `
    &:focus {
      outline: none;
      border-bottom: 2px solid ${props.theme.colors.background.primaryInverse};
    }
  `}
  ${props =>
    props.$isSorted &&
    `
    border-bottom: 2px solid ${props.theme.colors.background.primaryInverse};
  `}
  ${props =>
    props.$justifyContent &&
    `justify-content: ${props.$justifyContent};
  `}
`;

export const TableHeaderGroup = styled.div.attrs({ role: 'rowgroup' })<{
  $sticky?: boolean;
}>`
  display: table-header-group;
  background-color: ${props => props.theme.colors.background.primary};
  /* Using box shadow to workaround table border collapse for sticky header border bottom */
  /* This is effectively border bottom 1px solid gray */
  box-shadow: inset 0 -1px 0 ${props => props.theme.colors.background.tertiary};
  background-clip: padding-box; /* Fixes display issues on firefox */
  ${props =>
    props.$sticky &&
    `
      position: sticky;
      top: 0;
  `}
`;

export const TableBody = styled.div.attrs({ role: 'rowgroup' })`
  display: table-row-group;
`;

export const TableRow = styled.div.attrs({ role: 'row' })<{
  onClick?: () => void;
  $header?: boolean;
}>`
  display: table-row;
  border-bottom: ${props =>
    props.$header
      ? 'none'
      : `1px solid ${props.theme.colors.background.tertiary}`};
  ${props =>
    props.onClick &&
    `
    cursor: pointer;
    &:hover, &:focus {
      background-color: ${props.theme.colors.background.secondary};
    }
    &:focus {
      outline: transparent solid 2px;
      border: 1px solid ${props.theme.colors.background.primaryInverse};
      box-shadow: 0 0 0 1px ${props.theme.colors.background.primaryInverse};
    }
   `}
`;

export const TableHeaderText = styled(Text).attrs({
  textStyle: 'heading500',
})``;

export const TableCell = styled.div.attrs({ role: 'cell' })<{
  $overflow?: 'hidden' | 'scroll' | 'ellipsis' | 'wrap';
}>`
  display: table-cell;
  padding: 16px;
  white-space: nowrap;
  ${props => {
    switch (props.$overflow) {
      case 'hidden':
        return `

          overflow: hidden;
        `;
      case 'scroll':
        return `
          overflow: auto;
        `;
      case 'wrap':
        return `
            white-space: normal;
            overflow: hidden
            overflow-wrap: normal
          `;
      case 'ellipsis':
      default:
        return `
          overflow: hidden;
          text-overflow: ellipsis;
        `;
    }
  }}
`;

export const TableText = styled(Text)<{
  $overflow?: 'hidden' | 'scroll' | 'ellipsis' | 'wrap';
  $textAlign?: 'left' | 'right' | 'center';
}>`
  white-space: nowrap;
  text-align: ${props => props.$textAlign};
  ${props => {
    switch (props.$overflow) {
      case 'hidden':
        return `
          overflow: hidden;
        `;
      case 'scroll':
        return `
          overflow: auto;
        `;
      case 'wrap':
        return `
            white-space: normal;
            overflow: hidden
            overflow-wrap: normal
          `;
      case 'ellipsis':
      default:
        return `
          overflow: hidden;
          text-overflow: ellipsis;
        `;
    }
  }};
`;

export const TableLink = styled(Link)`
  overflow: auto;
  white-space: nowrap;
`;

export const TableInfoContainer = styled.div.attrs({
  'data-testid': 'table-info-container',
})`
  height: 120px;
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.colors.background.secondary};
  ${props => props.theme.media.phone} {
    height: auto;
  }
`;

export const NoResultsText = styled(Text).attrs({
  opacity: 'low',
  textStyle: 'heading800',
})``;

export const NoResultsWrapper = styled.div.attrs({
  'data-testid': 'no-results-wrapper',
})`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledIcon = styled(Icon)<{ $isSorted?: boolean }>`
  flex-shrink: 0;
  display: block;
  opacity: ${props => (props.$isSorted ? 1 : 0.25)};
`;

export const TableControlsWrapper = styled.div<{
  renderFilters?: boolean;
}>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 36px 0px 24px 0;
  ${props =>
    props.renderFilters
      ? `
      justify-content: space-between
    `
      : `
      justify-content: flex-end;
  `}
`;
