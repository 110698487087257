import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { copyFor } from 'config/copy';
import { Alert, Skeleton, Text, Icon } from '@arcadiapower/shrike';
import { StatementCard } from './statement-card';
import {
  BillingEnrollmentStatus,
  EmptyState,
  ListItem,
  StatementList,
  StatementTimelineHeader,
  Wrapper,
} from './statement-timeline.style';
import { GET_STATEMENT_TIMELINE } from './statement-timeline.api';
import {
  statementDisplayType,
  calculateEnrollmentStatusText,
} from './statement-card/statement-card-header/statement-card-header.utils';

const getCopy = copyFor('statementTimeline');

export type Props = {
  utilityAccountId: string;
  hideStatements: boolean;
};

export const StatementTimeline = ({
  utilityAccountId,
  hideStatements,
}: Props): JSX.Element => {
  const { data, loading, error } = useQuery<
    GetStatementTimelineQuery,
    GetStatementTimelineQueryVariables
  >(GET_STATEMENT_TIMELINE, { variables: { utilityAccountId } });

  const timelineTitle = () => {
    return `${statementDisplayType(
      data?.utilityAccount?.statementTimeline[0]?.type
    )}s`;
  };

  const enrollmentStatusText = useMemo(() => {
    return calculateEnrollmentStatusText({
      hostedPayments: data?.utilityAccount?.bundleEnrollment.hostedPayments,
      utilityRemittance:
        data?.utilityAccount?.bundleEnrollment.utilityRemittance,
    });
  }, [data]);

  const renderStatementTimelineHeader = () => {
    return (
      <StatementTimelineHeader>
        <Text tag="h4" textStyle="heading600">
          {timelineTitle()}
        </Text>
        {enrollmentStatusText && (
          <BillingEnrollmentStatus data-testid="enrollment-status">
            <Icon margin="0 0.5rem 0 0" icon="DollarCircle"></Icon>
            <Text>{enrollmentStatusText}</Text>
          </BillingEnrollmentStatus>
        )}
      </StatementTimelineHeader>
    );
  };

  const renderStatementTimeline = () => {
    if (error) return <Alert>{getCopy('loadingError')}</Alert>;
    if (loading || hideStatements) return <Skeleton />;
    // TODO: Eventually we need to replace this with a BE indicator of if they are enrolled
    const showTimeline = data?.utilityAccount?.statementTimeline.length;
    if (showTimeline) {
      return (
        <>
          {renderStatementTimelineHeader()}
          <StatementList data-testid="statement-list">
            {data?.utilityAccount?.statementTimeline.map(statement => {
              return (
                <ListItem key={statement.date}>
                  <StatementCard statement={statement} />
                </ListItem>
              );
            })}
          </StatementList>
        </>
      );
    } else {
      return (
        <>
          {renderStatementTimelineHeader()}
          <EmptyState>
            <Text color="secondary">{getCopy('noData')}</Text>
          </EmptyState>
        </>
      );
    }
  };

  return <Wrapper>{renderStatementTimeline()}</Wrapper>;
};
