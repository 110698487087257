import { useState } from 'react';
import { copyFor } from 'config/copy';
import { Button, Icon, Switch } from '@arcadiapower/shrike';
import {
  Table,
  TableHeader,
  TableTitle,
  TableLink,
  TableSection,
  TableSectionHeader,
  TableSectionTitle,
} from 'styles/table.style';
import { webhooksDocumentation } from 'config/external-links';
import { WebhookEndpoints } from 'components/webhook-endpoints';
import { NewWebhookEndpoint } from 'components/new-webhook-endpoint';

const getCopy = copyFor('configuration.webhooks');

export const Webhooks = (): JSX.Element => {
  const [addingWebhook, setAddingWebhook] = useState<boolean>(false);
  const [sandboxed, setSandboxed] = useState<boolean>(false);

  const endpointTypeCopyKey = sandboxed ? 'sandboxToggle' : 'liveToggle';

  return (
    <Table>
      <TableHeader>
        <TableTitle>{getCopy('title')}</TableTitle>
        <TableLink href={webhooksDocumentation}>
          {getCopy('documentationLink')}
        </TableLink>
      </TableHeader>
      <TableSection>
        <TableSectionHeader>
          <TableSectionTitle>
            {getCopy('webhooksDisplay.headerTitle')}
          </TableSectionTitle>
          <Button
            onClick={() => setAddingWebhook(true)}
            disabled={!!addingWebhook}
          >
            <Icon
              icon="Plus"
              color="primaryInverse"
              margin={{ right: '10px' }}
            />
            {getCopy('webhooksDisplay.newWebhookButton')}
          </Button>
        </TableSectionHeader>
        <Switch
          checked={!sandboxed}
          label={getCopy(`webhooksDisplay.${endpointTypeCopyKey}`)}
          onChange={() => setSandboxed(!sandboxed)}
          width="100%"
          margin={{ bottom: '40px' }}
        />
        {addingWebhook ? (
          <NewWebhookEndpoint
            sandboxed={sandboxed}
            toggleAddingWebhookStatus={() => setAddingWebhook(!addingWebhook)}
          />
        ) : (
          <WebhookEndpoints sandboxed={sandboxed} />
        )}
      </TableSection>
    </Table>
  );
};
